import createStyle from "@/utils/createStyle";

const styles = createStyle({
  input: {
    width: "100%"
  },
  notFound: {
    width: "100%",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: "#00A754",
    fontSize: "12px"
  },
  loading: {
    width: "100%",
    padding: "16px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold"
  },
  circularProgress: {
    color: "#00A754",
    marginBottom: 1
  },
  searchText: {
    color: "#00A754",
    fontSize: "12px"
  },
  searchIcon: {
    color: "#00A754"
  }
});

export default styles;
