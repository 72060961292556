import {
  useGetInAppNotif,
  usePostSeenNotif
} from "@/services/api/notification";
import { makeUrl } from "@/utils/routing";
import { SupportAgent } from "@mui/icons-material";
import { Button, IconButton, Modal, Stack, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { CancelOutlined } from "@mui/icons-material";
import { useState } from "react";
import notifImage from "@/assets/images/notif.png";

export const InspectionLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { inspectionJobId } = useParams();
  const isInTicketPage = pathname === "/inspection/ticket";

  const [notif, setNotif] = useState<any>();

  useGetInAppNotif({
    onSuccess(data) {
      try {
        const response = JSON.parse(
          data?.results?.[0]?.content.replaceAll("'", '"') ?? ""
        );
        setNotif({
          title: response?.title,
          description: response?.description,
          cta: response?.cta,
          link: response?.external_link,
          open: !data?.results?.[0]?.seen,
          id: data?.results?.[0]?.id
        });
      } catch {
        setNotif(undefined);
      }
    }
  });

  const { mutateAsync } = usePostSeenNotif();

  return (
    <Stack
      position="relative"
      width="100%"
      height="100%"
      overflow="auto"
      alignItems="center"
    >
      {!isInTicketPage && (
        <Stack
          onClick={() => {
            navigate(
              makeUrl(
                inspectionJobId && !inspectionJobId.includes("new")
                  ? `/inspection/ticket?orderId=${inspectionJobId}`
                  : "/inspection/ticket"
              )
            );
          }}
          position="absolute"
          left="1.5rem"
          bottom="1.5rem"
          p="0.5rem"
          borderRadius="50%"
          bgcolor="secondary.main"
          zIndex="10000"
          boxShadow="0px 2px 8px 0px #0000001F"
        >
          <SupportAgent sx={{ color: "white" }} />
        </Stack>
      )}
      <Outlet />
      <Modal
        open={notif?.open ?? false}
        onClose={() => {
          mutateAsync({
            id: notif?.id
          }).then(() => {
            setNotif(undefined);
          });
        }}
      >
        <Stack
          justifyContent={"space-between"}
          sx={{
            width: "80%",
            height: "50%",
            transform: "translate(12%, 50%)",
            backgroundColor: "white",
            borderRadius: 3,
            p: 2
          }}
        >
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontWeight={600} fontSize={"16px"}>
              {notif?.title}
            </Typography>
            <IconButton
              onClick={() => {
                mutateAsync({
                  id: notif?.id
                }).then(() => {
                  setNotif(undefined);
                });
              }}
            >
              <CancelOutlined />
            </IconButton>
          </Stack>
          <Stack
            width={"100%"}
            direction={"column"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={2}
          >
            <Stack
              sx={{
                width: "100%",
                height: "10rem",
                backgroundImage: `url(${notifImage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain"
              }}
            ></Stack>
            <Typography textAlign={"center"}>{notif?.description}</Typography>
          </Stack>
          {notif?.link ? (
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Button
                fullWidth
                onClick={() => {
                  mutateAsync({
                    id: notif?.id
                  }).then(() => {
                    setNotif(undefined);
                  });
                  window.open(notif?.link);
                }}
              >
                {notif?.cta}
              </Button>
            </Stack>
          ) : null}
        </Stack>
      </Modal>
    </Stack>
  );
};
