import { FC } from "react";
import {
  useGetCheckRepetitive,
  usePostRevertOrder
} from "@/services/api/concierge-sale/revert-order";
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import { useParams } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
}

const RevertModal: FC<Props> = ({ open, showModal }) => {
  const { id } = useParams();
  const { data, isLoading } = useGetCheckRepetitive(id as string, {
    source: "app"
  });

  const { mutateAsync } = usePostRevertOrder();

  const revertHandler = () => {
    mutateAsync({
      concierge_sale_id: Number(id),
      source: "app"
    }).then(() => {
      showModal(false);
    });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          bgcolor: "#fff",
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
          background: "#fff",
          p: 4,
          gap: 1.5
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Typography fontWeight="bold">بازگردانی سفارش</Typography>
          <IconButton onClick={() => showModal(false)}>
            <CloseIcon sx={{ width: 25, height: 25 }} />
          </IconButton>
        </Stack>
        {isLoading ? (
          <CircularProgress size="50px" />
        ) : (
          <>
            {data?.is_repetitive ? (
              <Stack gap={1.25}>
                <Typography lineHeight={1.7} fontSize={17}>
                  <WarningIcon
                    sx={{
                      width: 22,
                      height: 22,
                      color: "#CD0000",
                      mr: 1,
                      transform: "translateY(5px)"
                    }}
                  />
                  این سفارش لید فعال دارد و درصورت بازگردانی در وضعیت «{" "}
                  {data?.go_to_state} » قرار می‌گیرد.
                </Typography>
                <Typography lineHeight={1.7} fontSize={17} fontWeight="bold">
                  بعد از بازگردانی با تیم پشتیبانی تماس بگیرید.
                </Typography>
              </Stack>
            ) : (
              <Stack>
                <Typography lineHeight={1.7} fontSize={17}>
                  درصورت بازگردانی سفارش، سفارش به در وضعیت
                  <b> « {data?.go_to_state} »</b> قرار می‌گیرد.
                </Typography>
              </Stack>
            )}

            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1.5
              }}
            >
              <Button
                sx={{ width: "33%", color: "#6e6e6e", borderColor: "#6e6e6e" }}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => showModal(false)}
              >
                انصراف
              </Button>
              <Button
                sx={{
                  width: "63%"
                }}
                size="large"
                color={data?.is_repetitive ? "error" : "success"}
                onClick={revertHandler}
              >
                تایید و بازگردانی
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </SwipeableDrawer>
  );
};

export default RevertModal;
