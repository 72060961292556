import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  prepaymentValueContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  slider: {
    mt: "1.5rem"
  },
  sliderHintContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  monthListContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  },
  monthItemContainer: {
    width: { xs: "calc(100% / 3)", md: "100px" },
    maxWidth: { xs: "unset", md: "100px" },
    px: "0.25rem",
    mb: "0.5rem"
  },
  monthItem: {
    p: "0.5rem",
    width: "100%",
    border: (th) => `1px solid ${th.palette.surface.paper}`,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.5rem",
    transition: "background-color 0.3s",
    cursor: "pointer"
  }
});

export default styles;
