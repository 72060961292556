export const costStatistics = [
  {
    fa_title: "تعداد لید دریافتی",
    en_title: "lead_count"
  },
  {
    fa_title: "تعداد فروش",
    en_title: "deals_count"
  },
  {
    fa_title: "تعداد نردبان",
    en_title: "ladders_count"
  },
  {
    fa_title: "تعداد کارشناسی",
    en_title: "inspection_count"
  }
];

export const periodTimeItems = [
  { title: "۳۰ روز گذشته", days: 30 },
  { title: "۶۰ روز گذشته", days: 60 },
  { title: "۹۰ روز گذشته", days: 90 }
];

export const tableData = [
  {
    title: "مجموع کمیسیون فروش",
    count: "deals_count",
    price: "deals_income"
  },
  {
    title: "هزینه کارشناسی",
    count: "inspection_count",
    price: "inspection_cost"
  },
  {
    title: "هزینه نردبان",
    count: "ladders_count",
    price: "ladders_cost"
  }
];
