import Camera from "@/components/Camera";
import CustomSelect from "@/components/CustomSelect";
import DateInput from "@/components/DateInput";
import VariantModal from "@/components/ImageUploader/VariantModal";
import useLogic from "@/components/ImageUploader/useLogic";
import { useRootLayout } from "@/hooks/useRootLayout";
import { usePostCreateInvoice } from "@/services/api/concierge-sale/submit-deals";
import { useGetLeasingDetail } from "@/services/api/leasing/detail";
import {
  useCreatePosePayment,
  useUploadLeasingFile
} from "@/services/api/leasing/requests";
import { faNumToEn, toEngDigits } from "@/utils/toEnglishDigit";
import { Upload } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

const LeasingSendSms: FC = () => {
  const { id } = useParams();
  const [image, setImage] = useState<null | { file: File; url: string }>(null);
  const { setHeaderTitle, setBackUrl } = useRootLayout();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isInReceiptMode = searchParams.get("upload-receipt") === "true";
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [paymentTypes, setPaymentItems] = useState<
    { id: string; title: string }[]
  >([]);
  const { data: orderDetails } = useGetLeasingDetail(id as string, {
    enabled: !!id,
    onSuccess: (res) => {
      setFormData({
        ...formData,
        phone_number: res.customer.phone_number
      });
      const paymentItems = [];
      if (res.deposit_amount !== undefined && res.deposit_amount > 0) {
        paymentItems.push({
          id: "LEASING_DEPOSIT",
          title: `هزینه بیعانه ${res.deposit_amount.toLocaleString()} تومان`
        });
      }
      if (res.validation_amount !== undefined && res.validation_amount > 0) {
        paymentItems.push({
          id: "LEASING_VALIDATION",
          title: `هزینه اعتبارسنجی ${res.validation_amount.toLocaleString()} تومان`
        });
      }

      if (!!res.wallet_amount && res.wallet_amount > 0) {
        paymentItems.push({
          id: "LEASING_WALLET",
          title: `تخلیه کیف پول ${res.wallet_amount.toLocaleString()} تومان`
        });
      }

      setPaymentItems(paymentItems);
    }
  });

  const [formData, setFormData] = useState({
    phone_number: "",
    amount: 0,
    service_name: ""
  });
  const [amount, setAmount] = useState("");
  const { mutateAsync, isLoading: sendingInvoice } = usePostCreateInvoice(
    Number(id)
  );
  const {
    isLoading,
    mutate: uploadFile,
    data: uploadedFile
  } = useUploadLeasingFile(undefined, {
    onError: () => {
      setImage(null);
      toast.error("مشکلی در بارگذاری فایل پیش آمده است.");
    }
  });
  const { mutate: createPosePayment, isLoading: isCreateLoading } =
    useCreatePosePayment({
      onSuccess: () => {
        navigate(`/leasing/requests/${id}/payment/`);
      }
    });

  const getLeasingID = () => {
    if (
      formData.service_name === "LEASING_VALIDATION" &&
      orderDetails?.parent_id
    ) {
      return orderDetails?.parent_id;
    }

    return Number(id);
  };

  const submitHandler = () => {
    if (isInReceiptMode) {
      if (!uploadedFile?.data.file_url || !amount || isCreateLoading || !id) {
        toast.warn(
          !uploadedFile?.data.file_url
            ? "ارسال عکس رسید الزامی است."
            : "پر کردن فیلد مبلغ الزامی است."
        );
        return;
      }
      createPosePayment({
        amount: +amount,
        paid_at: date,
        id,
        image: uploadedFile.data.key
      });
      return;
    }
    mutateAsync({
      ...formData,
      name: orderDetails?.customer?.full_name,
      business_type: "P",
      send_sms: true,
      leasing_id: getLeasingID(),
      redirect_url:
        "https://leasing.karnameh.com/api/v1/orders/payment-callback/"
    });
  };

  const {
    inputRef,
    isCameraOpen,
    setIsCameraOpen,
    isSelectVariantOpen,
    setIsSelectVariantOpen,
    variantsList,
    imageUrl,
    onInputChangeHandler,
    onContainerClicked,
    openCamera,
    openUpload,
    onChangeCaller,
    setDate,
    date
  } = useLogic({
    onChange: (imageData) => {
      setImage(imageData);
      if (!imageData?.file) return;
      uploadFile({ file: imageData?.file, scope: "leasing-pose-payments" });
    },
    value: image?.file,
    variant: ["camera", "upload"]
  });

  useEffect(() => {
    setHeaderTitle(
      isInReceiptMode ? "استفاده از کارت‌خوان" : "ساخت لینک پرداخت"
    );
    setBackUrl(`/leasing/requests/${id}/payment/`);
  }, [isInReceiptMode, setHeaderTitle, id, setBackUrl]);

  return (
    <>
      <Stack
        sx={{
          background: "#FAFAFA",
          height: "100vh",
          width: "100%",
          px: 3,
          pt: 2,
          overflowY: "scroll"
        }}
      >
        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
          {`لطفا مبلغ ${
            isInReceiptMode ? "بیعانه" : "پیش‌پرداخت"
          } را وارد نمایید:`}
        </Typography>
        {isInReceiptMode ? (
          <Stack gap={3}>
            <TextField
              sx={{ mt: 2 }}
              size="small"
              inputProps={{ inputMode: "numeric" }}
              value={amount ? Number(amount).toLocaleString() : ""}
              InputProps={{
                endAdornment: (
                  <Typography color="#A9A8AA" fontSize="1rem">
                    تومان
                  </Typography>
                )
              }}
              fullWidth
              onChange={(event) =>
                setAmount(faNumToEn(event.target.value).replace(/[^0-9]/g, ""))
              }
              label="مبلغ بیعانه"
            />
            <Stack gap={1}>
              <Typography color="rgba(0, 0, 0, 0.6)" fontSize={"1rem"}>
                تاریخ واریز
              </Typography>
              <DateInput
                setValue={(val) => {
                  setDate(val);
                }}
                value={date}
                jYearsArray={[1402, 1403, 1404, 1405]}
              />
            </Stack>
          </Stack>
        ) : (
          <TextField
            sx={{ mt: 2 }}
            size="small"
            name="phone_number"
            inputProps={{ inputMode: "numeric" }}
            value={formData.phone_number}
            fullWidth
            onChange={(event) =>
              setFormData((form) => ({
                ...form,
                [event.target.name]: toEngDigits(event.target.value)
              }))
            }
            label="شماره موبایل"
          />
        )}

        {isInReceiptMode ? (
          <Stack
            border="1px dashed #B8EFD4"
            padding="0.75rem 1rem"
            alignItems="center"
            flexDirection="row"
            marginTop="1.5rem"
            borderRadius="0.5rem"
            justifyContent="space-between"
            width="100%"
            sx={{ backgroundColor: "#EBF8F1" }}
            onClick={onContainerClicked}
          >
            <Stack alignItems="center" flexDirection="row">
              {imageUrl ? (
                <Box
                  component="img"
                  sx={{
                    borderRadius: "0.5rem",
                    width: "2rem",
                    height: "2rem",
                    marginRight: "0.25rem"
                  }}
                  src={imageUrl}
                  alt="image"
                />
              ) : (
                <Upload
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    width: "1.25rem",
                    height: "1.25rem",
                    marginRight: "0.25rem"
                  }}
                />
              )}
              <Typography
                fontSize="0.875rem"
                fontWeight="600"
                lineHeight="2rem"
                color={(theme) => theme.palette.primary.main}
              >
                {imageUrl ? image?.file.name : "بارگذاری رسید پرداخت"}
              </Typography>
            </Stack>
            {isLoading && <CircularProgress size={24} />}
          </Stack>
        ) : (
          <CustomSelect
            label="نوع پرداخت"
            value={formData.service_name}
            onChange={(event) => {
              if (event.target.value === "LEASING_DEPOSIT") {
                setFormData((form) => ({
                  ...form,
                  service_name: event.target.value as string,
                  amount:
                    orderDetails && orderDetails.deposit_amount
                      ? orderDetails.deposit_amount
                      : 0
                }));
              }

              if (event.target.value === "LEASING_VALIDATION") {
                setFormData((form) => ({
                  ...form,
                  service_name: event.target.value as string,
                  amount:
                    orderDetails && orderDetails.validation_amount
                      ? orderDetails.validation_amount
                      : 0
                }));
              }

              if (event.target.value === "LEASING_WALLET") {
                setFormData((form) => ({
                  ...form,
                  service_name: event.target.value as string,
                  amount:
                    orderDetails && orderDetails.wallet_amount
                      ? orderDetails.wallet_amount
                      : 0
                }));
              }
            }}
            options={paymentTypes}
            sx={{ width: "100%", mt: 2 }}
          />
        )}
      </Stack>
      <Stack
        sx={{
          position: "fixed",
          bottom: 0,
          p: 2,
          width: "100%",
          bgcolor: "#fff",
          borderTop: "1px solid #ebebeb"
        }}
      >
        <Button onClick={submitHandler} size="large">
          {sendingInvoice ? (
            <CircularProgress size={20} sx={{ color: "#00000050" }} />
          ) : isInReceiptMode ? (
            "ذخیره اطلاعات"
          ) : (
            "ارسال لینک پرداخت"
          )}
        </Button>
      </Stack>

      <VariantModal
        open={isSelectVariantOpen}
        setOpen={setIsSelectVariantOpen}
        openCamera={openCamera}
        variantsList={variantsList}
        openUpload={openUpload}
      />
      <Box
        component="input"
        ref={inputRef}
        display="none"
        value=""
        type="file"
        onChange={onInputChangeHandler}
      />
      <Camera
        open={isCameraOpen}
        setOpen={setIsCameraOpen}
        onSubmit={(newCameraImage) => {
          if (newCameraImage.file && newCameraImage.dataUrl) {
            onChangeCaller(newCameraImage.file);
          }
        }}
      />

      <Modal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            bgcolor: "background.paper",
            outline: "none",
            borderRadius: "8px",
            px: 4,
            py: 2,
            textAlign: "center"
          }}
        >
          <Typography variant="body1" sx={{ lineHeight: "1.8", fontSize: 17 }}>
            لینک پرداخت با موفقیت ارسال شد
          </Typography>
          <Button
            sx={{ mt: 2, px: 3, height: "auto" }}
            onClick={() => {
              setIsOpenModal(false);
              navigate(`/leasing/requests/${id}/payment`, {
                replace: true
              });
            }}
            size="small"
          >
            تایید
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default LeasingSendSms;
