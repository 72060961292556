import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps
} from "@mui/material";
import { nanoid } from "nanoid";
import { CustomSelectProps } from "./type";

const CustomSelect = <T extends object>({
  value,
  onChange,
  label,
  options,
  multiple,
  sx,
  customItem,
  ...others
}: CustomSelectProps<T> & SelectProps) => {
  const labelId = nanoid(4);
  const CustomItem = customItem;
  return (
    <FormControl sx={{ width: "50%", ...sx }}>
      <InputLabel size="small" id={`select-custom-id-${labelId}`}>
        {label}
      </InputLabel>
      <Select
        {...others}
        labelId={`select-custom-id-${labelId}`}
        value={value}
        multiple={multiple}
        label={label}
        onChange={onChange}
        renderValue={customItem ? (item) => <>{item}</> : undefined}
        size="small"
      >
        {options.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            sx={customItem ? { padding: 0 } : {}}
          >
            {CustomItem && item.data ? (
              <CustomItem data={item.data} />
            ) : (
              item.title
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
