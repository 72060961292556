/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect } from "react";
import FileLoader from "@/components/FileLoader";
import { useUploadCarPhoto, UploadPhoto } from "@/services/api/sales";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box } from "@mui/material";

interface Props {
  title: string;
  image: string;
  files: File[];
  setFiles: (files: File[]) => void;
  setImages: any;
  images: string[];
  index: number;
}

const UploadImage: FC<Props> = ({
  title,
  image,
  files,
  setFiles,
  setImages,
  images,
  index
}) => {
  const { mutateAsync } = useUploadCarPhoto();
  useEffect(() => {
    if (files.length) {
      const formData = new FormData();
      formData.append("uploading_file", files[0]);
      mutateAsync(formData as unknown as UploadPhoto).then((data: any) => {
        const name = data?.data?.filename;
        addHandler(name);
      });
    }
  }, [files]);

  const deleteHandler = () => {
    const newImages = [...images];
    newImages.splice(index, 1, "");
    setImages(newImages);
    setFiles([]);
  };

  const addHandler = (name: string) => {
    if (images.length === 7) {
      const newImages = [...images];
      newImages.splice(index, 1, name);
      setImages(newImages);
    } else {
      const newImages = new Array(7);
      newImages.splice(index, 1, name);
      setImages(newImages);
    }
  };

  return images[index] ? (
    <Box
      sx={{
        position: "relative",
        width: "31%",
        height: "100px",
        marginBottom: "10px",
        borderRadius: "4px"
      }}
    >
      <img
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
        src={images[index]}
        alt="car image"
      />
      <DeleteOutlineOutlinedIcon
        onClick={deleteHandler}
        sx={{
          color: "#00A754",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)"
        }}
      />
    </Box>
  ) : files.length ? (
    <img
      style={{
        width: "100px",
        height: "100px",
        marginBottom: "10px",
        overflow: "hidden"
      }}
      src={URL.createObjectURL(files[0])}
      alt="car image"
    />
  ) : (
    <FileLoader
      file={files}
      setFile={setFiles}
      multi={false}
      title={title}
      image={image}
    />
  );
};

export default UploadImage;
