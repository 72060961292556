import { FromSchema } from "json-schema-to-ts";
import { buildYup } from "schema-to-yup";

const lastikHaSchema = {
  type: "object",
  properties: {
    lastikha_rarande_jolo: {
      type: "object",
      properties: {
        darsade_lastik: {
          type: "string",
          enum: [
            "",
            "0",
            "5",
            "10",
            "15",
            "20",
            "25",
            "30",
            "35",
            "40",
            "45",
            "50",
            "55",
            "60",
            "65",
            "70",
            "75",
            "80",
            "85",
            "90",
            "95",
            "100"
          ],
          enumNames: [
            "شروع نکردم",
            "۰",
            "۵",
            "۱۰",
            "۱۵",
            "۲۰",
            "۲۵",
            "۳۰",
            "۳۵",
            "۴۰",
            "۴۵",
            "۵۰",
            "۵۵",
            "۶۰",
            "۶۵",
            "۷۰",
            "۷۵",
            "۸۰",
            "۸۵",
            "۹۰",
            "۹۵",
            "۱۰۰"
          ]
        },
        salamat_zaheri_ring: {
          type: "string",
          enum: ["", "salamat_zaheri_darad", "salamat_zaheri_nadarad"],
          enumNames: ["شروع نکردم", "سلامت ظاهری دارد", "سلامت ظاهری ندارد"]
        }
      },
      additionalProperties: false,
      required: ["darsade_lastik", "salamat_zaheri_ring"]
    },
    lastikha_rarande_aghab: {
      type: "object",
      properties: {
        darsade_lastik: {
          type: "string",
          enum: [
            "",
            "0",
            "5",
            "10",
            "15",
            "20",
            "25",
            "30",
            "35",
            "40",
            "45",
            "50",
            "55",
            "60",
            "65",
            "70",
            "75",
            "80",
            "85",
            "90",
            "95",
            "100"
          ],
          enumNames: [
            "شروع نکردم",
            "۰",
            "۵",
            "۱۰",
            "۱۵",
            "۲۰",
            "۲۵",
            "۳۰",
            "۳۵",
            "۴۰",
            "۴۵",
            "۵۰",
            "۵۵",
            "۶۰",
            "۶۵",
            "۷۰",
            "۷۵",
            "۸۰",
            "۸۵",
            "۹۰",
            "۹۵",
            "۱۰۰"
          ]
        },
        salamat_zaheri_ring: {
          type: "string",
          enum: ["", "salamat_zaheri_darad", "salamat_zaheri_nadarad"],
          enumNames: ["شروع نکردم", "سلامت ظاهری دارد", "سلامت ظاهری ندارد"]
        }
      },
      additionalProperties: false,
      required: ["darsade_lastik", "salamat_zaheri_ring"]
    },
    lastikha_shagerd_jolo: {
      type: "object",
      properties: {
        darsade_lastik: {
          type: "string",
          enum: [
            "",
            "0",
            "5",
            "10",
            "15",
            "20",
            "25",
            "30",
            "35",
            "40",
            "45",
            "50",
            "55",
            "60",
            "65",
            "70",
            "75",
            "80",
            "85",
            "90",
            "95",
            "100"
          ],
          enumNames: [
            "شروع نکردم",
            "۰",
            "۵",
            "۱۰",
            "۱۵",
            "۲۰",
            "۲۵",
            "۳۰",
            "۳۵",
            "۴۰",
            "۴۵",
            "۵۰",
            "۵۵",
            "۶۰",
            "۶۵",
            "۷۰",
            "۷۵",
            "۸۰",
            "۸۵",
            "۹۰",
            "۹۵",
            "۱۰۰"
          ]
        },
        salamat_zaheri_ring: {
          type: "string",
          enum: ["", "salamat_zaheri_darad", "salamat_zaheri_nadarad"],
          enumNames: ["شروع نکردم", "سلامت ظاهری دارد", "سلامت ظاهری ندارد"]
        }
      },
      additionalProperties: false,
      required: ["darsade_lastik", "salamat_zaheri_ring"]
    },
    lastikha_shagerd_aghab: {
      type: "object",
      properties: {
        darsade_lastik: {
          type: "string",
          enum: [
            "",
            "0",
            "5",
            "10",
            "15",
            "20",
            "25",
            "30",
            "35",
            "40",
            "45",
            "50",
            "55",
            "60",
            "65",
            "70",
            "75",
            "80",
            "85",
            "90",
            "95",
            "100"
          ],
          enumNames: [
            "شروع نکردم",
            "۰",
            "۵",
            "۱۰",
            "۱۵",
            "۲۰",
            "۲۵",
            "۳۰",
            "۳۵",
            "۴۰",
            "۴۵",
            "۵۰",
            "۵۵",
            "۶۰",
            "۶۵",
            "۷۰",
            "۷۵",
            "۸۰",
            "۸۵",
            "۹۰",
            "۹۵",
            "۱۰۰"
          ]
        },
        salamat_zaheri_ring: {
          type: "string",
          enum: ["", "salamat_zaheri_darad", "salamat_zaheri_nadarad"],
          enumNames: ["شروع نکردم", "سلامت ظاهری دارد", "سلامت ظاهری ندارد"]
        }
      },
      additionalProperties: false,
      required: ["darsade_lastik", "salamat_zaheri_ring"]
    },
    lastikha_zapas: {
      type: "object",
      properties: {
        vaziat_zapas: {
          type: "string",
          enum: ["", "CantCheck", "available", "NotExist"],
          enumNames: [
            "شروع نکردم",
            "امکان بررسی وجود ندارد",
            "دارد",
            "زاپاس وجود ندارد"
          ]
        },
        darsade_lastik: {
          type: "string",
          enum: [
            "",
            "0",
            "5",
            "10",
            "15",
            "20",
            "25",
            "30",
            "35",
            "40",
            "45",
            "50",
            "55",
            "60",
            "65",
            "70",
            "75",
            "80",
            "85",
            "90",
            "95",
            "100"
          ],
          enumNames: [
            "شروع نکردم",
            "۰",
            "۵",
            "۱۰",
            "۱۵",
            "۲۰",
            "۲۵",
            "۳۰",
            "۳۵",
            "۴۰",
            "۴۵",
            "۵۰",
            "۵۵",
            "۶۰",
            "۶۵",
            "۷۰",
            "۷۵",
            "۸۰",
            "۸۵",
            "۹۰",
            "۹۵",
            "۱۰۰"
          ]
        },
        salamat_zaheri_ring: {
          type: "string",
          enum: ["", "salamat_zaheri_darad", "salamat_zaheri_nadarad"],
          enumNames: ["شروع نکردم", "سلامت ظاهری دارد", "سلامت ظاهری ندارد"]
        }
      },
      additionalProperties: false,
      required: ["vaziat_zapas"]
    },
    description: {
      type: "string",
      maxLength: 1000
    }
  },
  additionalProperties: false,
  required: [
    "lastikha_rarande_jolo",
    "lastikha_rarande_aghab",
    "lastikha_shagerd_jolo",
    "lastikha_shagerd_aghab",
    "lastikha_zapas"
  ]
} as const;

export const lastikHaConfig = {
  errMessages: {
    lastikha_rarande_jolo: {
      darsade_lastik: { required: "این فیلد اجباری است." },
      salamat_zaheri_ring: { required: "این فیلد اجباری است." }
    },
    lastikha_rarande_aghab: {
      darsade_lastik: { required: "این فیلد اجباری است." },
      salamat_zaheri_ring: { required: "این فیلد اجباری است." }
    },
    lastikha_shagerd_jolo: {
      darsade_lastik: { required: "این فیلد اجباری است." },
      salamat_zaheri_ring: { required: "این فیلد اجباری است." }
    },
    lastikha_shagerd_aghab: {
      darsade_lastik: { required: "این فیلد اجباری است." },
      salamat_zaheri_ring: { required: "این فیلد اجباری است." }
    },
    lastikha_zapas: {
      vaziat_zapas: "این فیلد اجباری است.",
      darsade_lastik: "این فیلد اجباری است.",
      salamat_zaheri_ring: "این فیلد اجباری است."
    }
  }
};

export const lastikHaValidation = buildYup(lastikHaSchema, lastikHaConfig);
export type LastikHaType = FromSchema<typeof lastikHaSchema>;
export default lastikHaSchema.properties;
