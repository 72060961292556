import { useRejectLeasingDetail } from "@/services/api/leasing/detail";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
  RadioGroup,
  Stack,
  styled,
  SwipeableDrawer,
  Typography,
  useRadioGroup
} from "@mui/material";
import Radio from "@mui/material/Radio";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useState } from "react";
import { useParams } from "react-router";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}
interface Props {
  openModal: boolean;
  setOpenModal: (item: boolean) => void;
}

const RejectReasonModal: FC<Props> = ({ openModal, setOpenModal }) => {
  const { id } = useParams();
  const [rejectReasonValue, setRejectReasonValue] = useState("");
  const { mutateAsync: rejectOrder, isLoading } = useRejectLeasingDetail(
    Number(id)
  );

  const rejectHandler = () => {
    rejectOrder({
      reject_reason: rejectReasonValue
    }).then(() => {
      setOpenModal(false);
    });
  };

  const StyledFormControlLabel = styled(
    (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />
  )(() => ({
    padding: "10px",
    width: "100%"
  }));
  const StyledRadio = styled(Radio)(() => ({
    color: "#694FB4",
    "&.Mui-checked": {
      color: "#694FB4"
    }
  }));

  const MyFormControlLabel = (props: FormControlLabelProps) => {
    const radioGroup = useRadioGroup();
    let checked = false;
    if (radioGroup && radioGroup.value === props.value) {
      checked = true;
      setRejectReasonValue(radioGroup.value);
    }
    return <StyledFormControlLabel checked={checked} {...props} />;
  };
  return (
    <SwipeableDrawer
      disableSwipeToOpen
      disableDiscovery
      disableBackdropTransition
      anchor="bottom"
      open={openModal}
      onClose={() => setOpenModal(false)}
      onOpen={() => setOpenModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            لغو درخواست
          </Typography>
          <CloseIcon onClick={() => setOpenModal(false)} />
        </Box>
        <Stack sx={{ flexDirection: "column" }}>
          <Typography
            sx={{
              marginBottom: "10px"
            }}
            variant="button"
          >
            در صورتی که از لغو این درخواست اطمینان دارید، یکی از دلایل زیر را
            انتخاب کنید.
          </Typography>
          <Stack
            sx={{
              overflowY: "auto",
              maxHeight: "65vh"
            }}
          >
            <RadioGroup
              sx={{
                border: "solid 1px #EBEBEB",
                borderRadius: "8px"
              }}
              name="use-radio-group"
            >
              {REJECT_REASON_LIST.map((item, i, array) => {
                return (
                  <MyFormControlLabel
                    sx={{
                      borderBottom:
                        i !== array.length - 1 ? "0.5px solid #EBEBEB" : "none"
                    }}
                    key={item.value + i}
                    value={item.value}
                    label={item.label}
                    control={<StyledRadio />}
                  />
                );
              })}
            </RadioGroup>
          </Stack>

          <Button
            disabled={!rejectReasonValue}
            sx={{ mt: "1rem" }}
            onClick={rejectHandler}
            size="medium"
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            ) : (
              "لغو درخواست"
            )}
          </Button>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
};

export default RejectReasonModal;
const REJECT_REASON_LIST = [
  { value: "reject_loan_fee", label: "عدم پذیرش هزینه تسهیلات" },
  { value: "not_validated", label: "رد شدن در اعتبارسنجی" },
  { value: "have_not_sayyadi_cheque", label: "نداشتن چک صیادی بنفش" },
  { value: "loan_amount_too_low", label: "کم بودن مبلغ وام" },
  { value: "out_of_tehran", label: "خارج از محدوده" },
  { value: "documents_not_sent", label: "عدم ارسال مدارک" },
  {
    value: "documents_not_validated",
    label: "رد شدن مدارک درمرحله بررسی"
  },
  {
    value: "not_enough_cash",
    label: "عدم تامین مالی بخش نقدی خودرو"
  },
  { value: "not_answered", label: "عدم پاسخگویی" },
  { value: "loan_installments_too_high", label: "زیاد بودن مبلغ اقساط" },
  { value: "loan_process_too_long", label: "طولانی شدن مراحل دریافت وام" },
  { value: "buy_car_cash", label: "خرید خودرو به صورت نقدی" },
  { value: "abort_car_purchase", label: "انصراف از خرید" }
];
