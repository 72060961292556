import { getAuthCookie, getAuthInfo, getIsLoggedIn } from "@/utils/auth";
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "..";

interface AuthSlice {
  data?: {
    token_type: string;
    exp: number;
    iat: number;
    jti: string;
    user_id: number;
    roles_in_services: string[];
    username: string;
    iss: string;
  };
  isLoggedIn: boolean;
  token?: string;
}

const initialState: AuthSlice = {
  isLoggedIn: false
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo: (state) => {
      const authInfo = getAuthInfo();
      const token = getAuthCookie();
      if (authInfo) {
        state.data = authInfo;
        state.token = token;
        state.isLoggedIn = getIsLoggedIn();
      } else {
        state.data = undefined;
        state.token = undefined;
        state.isLoggedIn = false;
      }
    }
  }
});

const {
  reducer: authReducer,
  actions: { setAuthInfo }
} = authSlice;

export default authReducer;
export { setAuthInfo };

export const useAuthInfo = () => {
  return useSelector((state: RootState) => state.authReducer);
};
