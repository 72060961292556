import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: "100%",
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
    backgroundColor: (th) => th.palette.background.default,
    transition: "transform 0.3s, opacity 0.3s",
    position: "fixed",
    bottom: 0,
    left: 0
  },
  header: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    p: "1rem",
    borderBottom: (th) => `1px solid ${th.palette.grey[300]}`
  },
  contents: {
    width: "100%",
    p: "1rem",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  backdrop: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#0000003d",
    transition: "opacity 0.3s"
  },
  messageBox: {
    width: "100%",
    border: (th) => `1px solid ${th.palette.info.main}`,
    borderRadius: '0.5rem',
    backgroundColor: "#6bc2ed63",
    p: "0.5rem",
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    mt: '1rem'
  }
});

export default styles;
