import { useRootLayout } from "@/hooks/useRootLayout";
import {
  useGetCarStatusColors,
  usePostKarnamehAds,
  useSubmitDivarAds,
  KarnamehAds,
  useGetSaleDetailV2
} from "@/services/api/sales";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import mainAdsCover from "@/assets/icons/main-car-img.svg";
import frontSideCar from "@/assets/icons/car_front_side_light.png";
import backSideCar from "@/assets/icons/car_back_side_light.png";
import kmCounter from "@/assets/icons/car_odometer_light.png";
import driverSide from "@/assets/icons/car_driver_side_light.png";
import passengerSide from "@/assets/icons/car_passenger_side_light.png";
import addIcon from "@/assets/icons/add.svg";
import UploadImage from "@/components/UploadImage";
import { toEngDigits } from "@/utils/toEnglishDigit";
import toFarsiNumberText from "@/utils/toFarsiNumberText";
import { GlobalErrorModel } from "@/utils/error-handling";
import { AxiosError } from "axios";
import useErrorHandler from "@/hooks/useErrorHandler";
import MainCover from "@/components/UploadImage/MainCover";

import CarFrontLeftStencil from "@/assets/images/stencils/front-left-1920-1080.svg";

const fieldNames = [
  "title",
  "brandModel",
  "city",
  "year",
  "color",
  "usage",
  "body_status",
  "price",
  "description",
  "third_party_insurance_deadline",
  "gearbox"
];

const SubmitAds: FC = () => {
  const { id } = useParams();
  const { setHeaderTitle } = useRootLayout();
  const [formData, setFormData] = useState<KarnamehAds>({
    title: "",
    brand_model: "",
    usage: 0,
    year: 0,
    color: "",
    city: null,
    third_party_insurance_deadline: 0,
    price: 0,
    gearbox: "",
    body_status: "",
    description: `کد آگهی: ${id}
ضمانت تامین خسارت تا سقف ۸۰۰ میلیون تومان (در صورت کارشناسی در زمان بازدید)
زمان پاسخ‌گویی: 9 الی 19`,
    images: []
  });

  const [colors, setColors] = useState({});
  const [persianBrand, setPersianBrand] = useState("");
  const { data: colorData } = useGetCarStatusColors({ retry: false });
  const { refetch: submitDivar, error: submitDivarAdsError } =
    useSubmitDivarAds(Number(id));
  const { mutateAsync, error: postKarnamehAdsError } = usePostKarnamehAds(
    String(id)
  );
  const { data } = useGetSaleDetailV2(Number(id), {
    retry: false
  });
  const navigate = useNavigate();

  const errorList = useMemo(
    () => [
      ((submitDivarAdsError as AxiosError) || undefined)?.response?.data,
      ((postKarnamehAdsError as AxiosError) || undefined)?.response?.data
    ],
    [submitDivarAdsError, postKarnamehAdsError]
  );

  const { errors, resolveField, resolveAll } = useErrorHandler(
    errorList as GlobalErrorModel[],
    fieldNames
  );

  //#region upload images
  const [fileCover, setFileCover] = useState<File[]>([]);
  const [fileBack, setFileBack] = useState<File[]>([]);
  const [fileFront, setFileFront] = useState<File[]>([]);
  const [fileKm, setFileKm] = useState<File[]>([]);
  const [fileDriver, setFileDriver] = useState<File[]>([]);
  const [filePassenger, setFilePassenger] = useState<File[]>([]);
  const [fileMain, setFileMain] = useState<File[]>([]);
  const [images, setImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  //#endregion
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("ثبت آگهی کارنامه");
    }
  }, [setHeaderTitle]);

  useEffect(() => {
    if (data?.car_brand_model_fa) {
      setPersianBrand(data?.car_brand_model_fa);
    }
  }, [data?.car_brand_model_fa]);

  useEffect(() => {
    setFormData({
      ...formData,
      images: images.filter(Boolean).map((item: string) => {
        const carPostsIndex = item.indexOf("car-posts");
        const result = item.substring(carPostsIndex);
        return result;
      })
    });
  }, [images]);

  useEffect(() => {
    if (colorData) {
      setColors(colorData);
    }
  }, [colorData]);

  const colorsList: string[] = Object.values(colors);

  useEffect(() => {
    if (data) {
      setFormData((prev) => ({
        ...prev,
        title: `${data?.car_brand_model_fa} مدل ${data?.djalali_year}`,
        city: data?.city,
        year: data?.djalali_year,
        usage: data?.usage
      }));
    }
  }, [data]);

  const changeHandler = (event: {
    target: { name: string; value: string };
  }) => {
    resolveField(event.target.value);
    setFormData({
      ...formData,
      [event.target.name]: toEngDigits(event.target.value)
    });
  };

  const isDisabled =
    !formData.title ||
    !formData.city ||
    !formData.year ||
    !formData.color ||
    !formData.body_status ||
    !formData.price;

  const submitHandler = () => {
    const body = {
      ...formData,
      brand_model: data?.car_brand_model as string
    };
    resolveAll(), setLoading(true);
    mutateAsync(body)
      .then(() => {
        setOpenModal(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Stack
        sx={{ width: "100%", px: "18px", height: "100vh", overflowY: "scroll" }}
      >
        <Typography
          variant="h3"
          sx={{ fontSize: "14px", fontWeight: "bold", my: 2 }}
        >
          اطلاعات الزامی
        </Typography>
        <TextField
          sx={{ mb: "20px", width: "100%" }}
          label="عنوان آگهی *"
          value={formData.title}
          name="title"
          variant="outlined"
          onChange={changeHandler}
          size="small"
          {...errors.title}
        />

        <TextField
          sx={{ mb: "20px", width: "100%" }}
          label="برند - مدل - تیپ *"
          value={persianBrand}
          name="brandModel"
          disabled
          variant="outlined"
          size="small"
          {...errors.brandModel}
        />

        <FormControl
          size="small"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <InputLabel id="demo-select-small">شهر *</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            sx={{ mb: 2.5 }}
            value={formData.city ? formData.city : ""}
            label="شهر *"
            name="city"
            onChange={(event) => {
              resolveField("city");
              setFormData({
                ...formData,
                city: +event.target.value
              });
            }}
            {...errors.city}
          >
            <MenuItem value={1}>تهران</MenuItem>
            <MenuItem value={2}>کرج</MenuItem>
            <MenuItem value={3}>مشهد</MenuItem>
            <MenuItem value={4}>اصفهان</MenuItem>
            <MenuItem value={5}>تبریز</MenuItem>
            <MenuItem value={6}>شیراز</MenuItem>
            <MenuItem value={7}>اهواز</MenuItem>
            <MenuItem value={12}>رشت</MenuItem>
            <MenuItem value={1763}>ری</MenuItem>
            <MenuItem value={1751}>فردیس</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ mb: "20px", width: "100%" }}
          label="سال ساخت *"
          name="year"
          variant="outlined"
          value={formData.year || ""}
          onChange={(event) => {
            resolveField("year");
            setFormData({
              ...formData,
              year: parseInt(toEngDigits(event.target.value))
            });
          }}
          size="small"
          {...errors.year}
        />
        <FormControl sx={{ mb: "20px" }} fullWidth size="small">
          <InputLabel id="demo-simple-select-label">رنگ *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.color}
            label="رنگ"
            name="color"
            onChange={changeHandler}
            {...errors.color}
          >
            {colorsList.map((color, index) => {
              return (
                <MenuItem key={`${color}`} value={colorsList[index]}>
                  {colorsList[index]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          sx={{ mb: "20px", width: "100%" }}
          label="کارکرد *"
          name="usage"
          value={formData.usage ? formData.usage : 0}
          variant="outlined"
          onChange={(event) => {
            resolveField("usage");
            setFormData({
              ...formData,
              usage: +toEngDigits(event.target.value)
            });
          }}
          size="small"
          {...errors.usage}
        />
        <FormControl fullWidth size="small" sx={{ mb: 2 }}>
          <InputLabel id="demo-simple-select-label2">وضعیت بدنه *</InputLabel>
          <Select
            labelId="demo-simple-select-label2"
            id="demo-simple-select2"
            value={formData.body_status}
            label="وضعیت بدنه *"
            name="body_status"
            onChange={changeHandler}
            {...errors.body_status}
          >
            <MenuItem value="witout-color">بدون رنگ</MenuItem>
            <MenuItem value="one-spot-of-color">یک لکه رنگ</MenuItem>
            <MenuItem value="two-spots-of-color">دو لکه رنگ</MenuItem>
            <MenuItem value="few-spots-of-color">چند لکه رنگ</MenuItem>
            <MenuItem value="by-accident">تصادفی</MenuItem>
            <MenuItem value="out">اوراقی</MenuItem>
            <MenuItem value="intact">سالم و بی خط و خش</MenuItem>
            <MenuItem value="some-scratches">خط و خش جزئی</MenuItem>
            <MenuItem value="paintless-dent-removal">صافکاری بی رنگ</MenuItem>
            <MenuItem value="one-spot-paint">رنگ شدگی ،‌ در ۱ ناحیه</MenuItem>
            <MenuItem value="two-spot-paint">رنگ شدگی ، در ۲ ناحیه</MenuItem>
            <MenuItem value="some-paint">رنگ شدگی ، در چند ناحیه</MenuItem>
            <MenuItem value="half-paint">دور رنگ</MenuItem>
            <MenuItem value="full-paint">تمام رنگ</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ width: "100%" }}
          label="قیمت فروش نقدی *"
          name="price"
          value={formData.price || ""}
          onChange={(event) => {
            resolveField("price");
            setFormData({
              ...formData,
              price: parseInt(toEngDigits(event.target.value))
            });
          }}
          type="tel"
          inputProps={{ inputMode: "numeric" }}
          variant="outlined"
          size="small"
          {...errors.price}
        />
        <Typography
          variant="body1"
          sx={{ mt: "5px", mb: "10px", fontSize: "13px" }}
        >
          {formData.price
            ? `${toFarsiNumberText(
                parseInt(toEngDigits(String(formData.price)))
              )}`
            : ""}
        </Typography>
        <TextField
          sx={{ mb: "20px", width: "100%" }}
          label="توضیحات"
          name="description"
          multiline
          rows={8}
          defaultValue={formData.description}
          onChange={(event) => {
            resolveField("description");
            setFormData({ ...formData, description: event.target.value });
          }}
          variant="outlined"
          size="small"
          {...errors.description}
        />
        <Typography
          variant="h3"
          sx={{ fontSize: "14px", fontWeight: "bold", mb: 2 }}
        >
          اطلاعات تکمیلی
        </Typography>
        <TextField
          sx={{ mb: "20px", width: "100%" }}
          label="مهلت بیمه شخص ثالث(ماه)"
          name="third_party_insurance_deadline"
          value={formData.third_party_insurance_deadline || ""}
          onChange={(event) => {
            resolveField("third_party_insurance_deadline");
            setFormData({
              ...formData,
              third_party_insurance_deadline: parseInt(
                toEngDigits(event.target.value)
              )
            });
          }}
          type="tel"
          inputProps={{ inputMode: "numeric" }}
          variant="outlined"
          size="small"
          {...errors.third_party_insurance_deadline}
        />
        <FormControl
          size="small"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <InputLabel id="demo-select-gearbox">گیربکس</InputLabel>
          <Select
            labelId="demo-select-gearbox"
            id="demo-select-gearbox"
            sx={{ mb: 2.5 }}
            label="گیربکس"
            value={formData.gearbox}
            name="gearbox"
            onChange={changeHandler}
            {...errors.gearbox}
          >
            <MenuItem value="manual">دستی</MenuItem>
            <MenuItem value="automatic">اتوماتیک</MenuItem>
          </Select>
        </FormControl>
        <Typography
          variant="h3"
          sx={{ fontSize: "14px", fontWeight: "bold", mb: 2 }}
        >
          تصاویر
        </Typography>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap"
          }}
        >
          <MainCover
            index={0}
            title="تصویر اصلی * (اجباری)"
            image={mainAdsCover}
            files={fileCover}
            setFiles={setFileCover}
            setImages={setImages}
            images={images}
            stencil={CarFrontLeftStencil}
            fileName={`front-left-${new Date().getTime()}`}
            width={1920}
            height={1080}
          />
          <UploadImage
            index={1}
            title="نمای جلو"
            image={frontSideCar}
            files={fileFront}
            setFiles={setFileFront}
            setImages={setImages}
            images={images}
          />

          <UploadImage
            index={2}
            title="نمای عقب"
            image={backSideCar}
            files={fileBack}
            setFiles={setFileBack}
            setImages={setImages}
            images={images}
          />

          <UploadImage
            index={3}
            title="کیلومتر شمار"
            image={kmCounter}
            files={fileKm}
            setFiles={setFileKm}
            setImages={setImages}
            images={images}
          />

          <UploadImage
            index={4}
            title="سمت راننده"
            image={driverSide}
            files={fileDriver}
            setFiles={setFileDriver}
            setImages={setImages}
            images={images}
          />

          <UploadImage
            index={5}
            title="سمت مسافر"
            image={passengerSide}
            files={filePassenger}
            setFiles={setFilePassenger}
            setImages={setImages}
            images={images}
          />

          <UploadImage
            index={6}
            title="سایر تصاویر"
            image={addIcon}
            files={fileMain}
            setFiles={setFileMain}
            setImages={setImages}
            images={images}
          />
        </Stack>
        <Button
          disabled={isDisabled || loading || fileCover.length < 1}
          sx={{ my: 2 }}
          onClick={submitHandler}
          size="large"
        >
          {loading ? (
            <CircularProgress size={20} sx={{ color: "#00000050" }} />
          ) : (
            "ثبت آگهی در کارنامه"
          )}
        </Button>
      </Stack>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            bgcolor: "background.paper",
            outline: "none",
            borderRadius: "8px",
            px: 4,
            py: 2,
            textAlign: "center"
          }}
        >
          <Typography
            variant="body1"
            sx={{ lineHeight: "1.8", fontWeight: "500", mb: 2 }}
          >
            آگهی با موفقیت در کارنامه ثبت شد مایل به ثبت آگهی در دیوار هستید؟
          </Typography>
          <Button
            variant="outlined"
            sx={{ mt: 1, px: 3, height: "auto", mr: 2 }}
            onClick={() => {
              setOpenModal(false);
              navigate(`/concierge-sale/requests/${id}/`);
            }}
          >
            انصراف
          </Button>
          <Button
            sx={{ mt: 1, px: 3, height: "auto" }}
            onClick={() => {
              submitDivar().then((data) =>
                window.open(data?.data?.url, "_parent")
              );
            }}
          >
            ثبت در دیوار
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default SubmitAds;
