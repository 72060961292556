import { ChangeEvent, FC, SyntheticEvent } from "react";
import {
  Stack,
  Box,
  Button,
  SwipeableDrawer,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import useDocumentHeight from "@/hooks/useDocumentHeight";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";

interface Props {
  submitHandler: (event: SyntheticEvent) => void;
  changeHandler: (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  open: boolean;
  showModal: (item: boolean) => void;

  category: string;
  content: string;
  title: string;
  loading: boolean;
}

const NoteModal: FC<Props> = ({
  submitHandler,
  open,
  showModal,
  changeHandler,
  category,
  content,
  title,
  loading
}) => {
  const { height: vpHeight, offset } = useDocumentHeight();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box sx={styles.modalBox}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <form onSubmit={submitHandler} style={{ width: "100%", height: "80%" }}>
          <Stack
            sx={{
              ...styles.formContainer,
              position: "sticky",
              bottom: `calc(100dvh - ${vpHeight}px - ${offset}px)`
            }}
          >
            <FormControl
              size="small"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <InputLabel id="demo-select-small-label">تگ یادداشت</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={category}
                sx={{ mb: 2.5 }}
                label="تگ یادداشت"
                name="category"
                onChange={changeHandler}
              >
                {CATEGOTY_TYPES.map((Item, index) => {
                  return (
                    <MenuItem key={index} value={Item.value}>
                      <Box sx={styles.selectItem}>
                        {Item.icon}
                        {Item.title}{" "}
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
              <TextField
                id="outlined-multiline-static"
                sx={styles.textArea}
                label="متن یادداشت"
                multiline
                rows={4}
                value={content}
                name="content"
                onChange={changeHandler}
              />
            </FormControl>
          </Stack>
          <Button
            disabled={Boolean(!category) || Boolean(!content) || loading}
            type="submit"
            sx={styles.modalBtn}
          >
            {loading ? (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            ) : (
              "ثبت یادداشت"
            )}
          </Button>
        </form>
      </Box>
    </SwipeableDrawer>
  );
};

export default NoteModal;

export const CATEGOTY_TYPES = [
  {
    title: "یادداشت",
    value: "note",
    icon: <DescriptionOutlinedIcon sx={styles.descIcon} />
  },
  {
    title: "تماس بی پاسخ",
    value: "missed-call",
    icon: <PhoneEnabledOutlinedIcon sx={styles.missCallIcon} />
  },
  {
    title: "تماس با مشتری",
    value: "note-call",
    icon: <PhoneEnabledOutlinedIcon sx={styles.customerCallIcon} />
  },
  {
    title: "نیاز به پیگیری",
    value: "need-to-follow-up",
    icon: <FollowTheSignsIcon sx={styles.descIcon} />
  }
];
