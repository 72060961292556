import { FC } from "react";
import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { GetAllSupervisorSaleExpertsResponse } from "@/services/api/concierge-sale/supervisor-offer";
import PersonIcon from "@mui/icons-material/Person";

interface Props extends GetAllSupervisorSaleExpertsResponse {
  active?: number | null;
  setActive?: React.Dispatch<React.SetStateAction<number>>;
  selectable: boolean;
}

const AssistantOfferCard: FC<Props> = ({
  id,
  name,
  active_leads,
  last_30_days_sale_total,
  last_30_days_sale_count,
  last_30_days_sale_average,
  active,
  setActive,
  selectable = true
}) => {
  return (
    <Stack
      sx={{
        border: "1px solid #C9CCCF",
        bgcolor: "#fff",
        p: 2,
        pt: 0.5,
        borderRadius: 2,
        mb: 2
      }}
      onClick={selectable ? () => setActive?.(id) : () => null}
    >
      {selectable ? (
        <Stack sx={{ borderBottom: "1px solid #C9CCCF", mb: 0.5 }}>
          <FormControlLabel
            checked={active === id}
            control={<Radio />}
            label={name || "-"}
          />
        </Stack>
      ) : (
        <Stack
          sx={{
            borderBottom: "1px solid #C9CCCF",
            mb: 0.5,
            pt: 1,
            pb: 1.5,
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <PersonIcon sx={{ mr: 0.5, color: "#00A754" }} />
          <Typography>{name}</Typography>
        </Stack>
      )}

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 1.5,
          borderBottom: "1px dashed #C9CCCF",
          pb: 1.5
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          <DirectionsCarIcon sx={{ color: "#6E6E6E" }} />
          <Typography sx={{ ml: 0.5, fontSize: 16 }}>
            {active_leads} لید فعال
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2
        }}
      >
        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
          فروش ۳۰ روز گذشته
        </Typography>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Typography sx={{ fontSize: 16, color: "#6E6E6E" }}>
            میانگین:
          </Typography>
          <Typography sx={{ ml: 1, fontSize: 16 }}>
            {Number(last_30_days_sale_average).toLocaleString()} تومان
          </Typography>
        </Stack>
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2
        }}
      >
        <Typography sx={{ fontSize: 16 }}>
          {last_30_days_sale_count} فروش موفق
        </Typography>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Typography sx={{ fontSize: 16, color: "#6E6E6E" }}>
            مبلغ کل:
          </Typography>
          <Typography sx={{ ml: 1, fontSize: 16 }}>
            {Number(last_30_days_sale_total).toLocaleString()} تومان
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AssistantOfferCard;
