import { FromSchema } from "json-schema-to-ts";
import { buildYup } from "schema-to-yup";

const talighVaFarmanSchema = {
  type: "object",
  properties: {
    tormoz_farman_va_system_taligh_azmone_ranandegi: {
      type: "object",
      properties: {
        taligh: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            sistem_jolobandi_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sistem_taligh_aghab_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        farman_azmone_ranandegi: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            farman_dar_heyn_harekat_sedyae_gheir_adi_ya_larzesh_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            farman_dar_heyn_harekat_be_yek_samt_keshide_mishavad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            gharbilak_dar_heyn_harekat_tanzim_nist: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        fanarbandi_samte_ranande_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_seday_gheir_adi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        fanarbandi_samte_sarneshin_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_seday_gheir_adi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        fanarbandi_samte_ranande_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_seday_gheir_adi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        fanarbandi_samte_sarneshin_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_seday_gheir_adi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        vaziat_amalkard_tormoz: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            tormoz_dar_heyn_harekat_amalkard_monaseb_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            tormoz_dar_heyn_harekat_be_yek_samt_keshide_mishavad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            tormoz_dar_heyn_harekat_seday_gheir_adi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abs_mayub_ast: {
              type: "string",
              enum: ["yes", "no", "unavailable"],
              enumNames: ["بله", "خیر", "ندارد"]
            },
            esp_mayub_ast: {
              type: "string",
              enum: ["yes", "no", "unavailable"],
              enumNames: ["بله", "خیر", "ندارد"],

              default: "unavailable"
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    farman: {
      type: "object",
      properties: {
        farman_mamooli: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khalasi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        farman_hydrolik: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sistem_hydrolik_nashti_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_maye_hydrolik_monaseb_nist: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        farman_barghi: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            niruye_komaki_barghi_eps_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    fanarbandi_dar_ja: {
      type: "object",
      properties: {
        fanarbandi_dar_ja_samte_ranande_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            roghan_zadegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        fanarbandi_dar_ja_samte_sarneshin_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            roghan_zadegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        fanarbandi_dar_ja_samte_ranande_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            roghan_zadegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        fanarbandi_dar_ja_samte_sarneshin_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            roghan_zadegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    tormoz: {
      type: "object",
      properties: {
        vaziat_tormoz_dar_halat_darja: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            boster_amalkard_monaseb_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            halat_esfanji_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            roghan_tormoz_nashti_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            roghan_tormoz_rang_gheir_tabie_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    tormoz_dasti: {
      type: "object",
      properties: {
        tormoz_dasti_mamooli: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        tormoz_dasti_barghi: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            tormoz_dasti_barghi_epb_be_doristi_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    description: {
      type: "string",
      maxLength: 1000
    }
  },
  additionalProperties: false
} as const;

export const talighVaFarmanValidation = buildYup(talighVaFarmanSchema);
export type TalighVaFarmanType = FromSchema<typeof talighVaFarmanSchema>;
export default talighVaFarmanSchema.properties;
