import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: "100%",
    p: "1rem"
  },
  header: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textField: {
    width: "100%",
    mt: "1rem"
  },
  cancelButton: {
    width: "100%",
    mt: "1rem"
  }
});

export default styles;
