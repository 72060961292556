import type { FromSchema } from "json-schema-to-ts";
import { buildYup } from "schema-to-yup";

const badanehSchema = {
  type: "object",
  properties: {
    darbha_va_saghf: {
      type: "object",
      properties: {
        saghf: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        darb_motor: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dar_jolo_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dar_aghab_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "unavailable", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dar_sandogh_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dar_aghab_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "unavailable", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dar_jolo_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    gelgirha: {
      type: "object",
      properties: {
        gelgir_jolo_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        gelgilr_aghab_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["نامشخص", "ریز موارد", "بررسی شد"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        gelgir_aghab_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        gelgir_jolo_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    sotonha: {
      type: "object",
      properties: {
        sotunhaye_jolo_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sotunhaye_vasat_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sotunhaye_aghab_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sotunhaye_aghab_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sotunhaye_vasat_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sotunhaye_jolo_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    rekabha: {
      type: "object",
      properties: {
        rekab_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        rekab_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            abrang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            foro_raftegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            safkari_bedonerang: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            lise_giri: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            khat_o_khash: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_sokhtegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    sarshasiha: {
      type: "object",
      properties: {
        sarshasi_jolo_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sarshasi_aghab_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didgegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sarshasi_jolo_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didegi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sarshasi_aghab_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didgegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    mohavateha: {
      type: "object",
      properties: {
        mohavate_motor: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didgegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        mohavate_sandogh: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didgegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        mohavate_charkh_zapas: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            tavizi_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            asib_didgegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    separha: {
      type: "object",
      properties: {
        separ_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            khat_o_khash_ya_ghalam_giri_ya_rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        separ_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            khat_o_khash_ya_ghalam_giri_ya_rang_shodegi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    shisheha: {
      type: "object",
      properties: {
        shishe_va_navarhaye_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sehat_nasb: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        shishe_va_navar_abgir_dar_ranande: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sehat_nasb: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        shishe_va_navar_abgir_dar_aghab_rast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "unavailable", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sehat_nasb: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        shishe_va_navarhaye_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sehat_nasb: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        shishe_va_navar_abgir_dar_aghab_chap: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "unavailable", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sehat_nasb: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        shishe_va_navar_abgir_dar_sarneshin_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "بررسی شد", "ریز موارد", "نامشخص"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sehat_nasb: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    description: {
      type: "string",
      maxLength: 1000
    }
  },
  additionalProperties: false
} as const;

const config = {
  errMessages: {
    // sale_sakht: { required: "این فیلد اجباری است." },
    // karkard: { required: "این فیلد اجباری است." },
    // range_badane: { required: "این فیلد اجباری است." },
    // vin: { required: "این فیلد اجباری است." },
    // shomare_shasi: { required: "این فیلد اجباری است." },
    // karte_mashin: { required: "این فیلد اجباری است." },
    // etebar_sales: {
    //   required: "این فیلد اجباری است.",
    //   pattern: "تاریخ معتبر نیست."
    // },
    // takhfif_sales_be_sal: { required: "این فیلد اجباری است." },
    // tedad_switch: { required: "این فیلد اجباری است." },
    // garanti: { required: "این فیلد اجباری است." },
    // description: { maxLength: "تعداد کاراکترها بیشتر از ۱۰۰۰ است." }
  }
};

export const badanehValidation = buildYup(badanehSchema, config);
export type BadanehType = FromSchema<typeof badanehSchema>;
export default badanehSchema.properties;
