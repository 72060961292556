import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styles } from "./styles";
import FilterCollapse from "./filterCollapse";
import { filtersList } from "./data";
import Spacer from "@/components/Spacer";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { getQueryAsArray } from "../offerUtils";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Filters: FC<Props> = (props) => {
  const { search } = useLocation();
  const query = queryString.parse(search);
  const navigate = useNavigate();
  const { open, setOpen } = props;
  const [currentQuery, setCurrentQuery] = useState(query);
  const [show, setShow] = useState(false);

  const backDropRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setCurrentQuery(query);
  }, [search]);

  useEffect(() => {
    if (backDropRef.current) {
      const clickHandler = () => {
        setOpen(false);
      };

      backDropRef.current.addEventListener("click", clickHandler);

      return () => {
        if ((backDropRef as MutableRefObject<HTMLDivElement>).current) {
          (
            backDropRef as MutableRefObject<HTMLDivElement>
          ).current.removeEventListener("click", clickHandler);
        }
      };
    }

    return () => null;
  }, [open, setOpen]);

  useEffect(() => {
    if (open) {
      setShow(true);
    } else {
      setTimeout(() => {
        setShow(false);
      }, 300);
    }
  }, [open]);

  const updateCurrentFilter = (
    key: string,
    value: string,
    isMultiple = false
  ) => {
    const newQuery = { ...currentQuery };
    if (isMultiple) {
      const queryValue =
        key in newQuery
          ? typeof newQuery[key] === "string"
            ? [newQuery[key]]
            : (newQuery[key] as Array<string>)
          : [];
      if (queryValue.includes(value)) {
        queryValue.splice(queryValue.indexOf(value), 1);
      } else {
        queryValue.push(value);
      }
      newQuery[key] = queryValue as string[];
    } else {
      newQuery[key] = value;
    }
    setCurrentQuery(newQuery);
  };

  const changeFilter = (newQuery: queryString.ParsedQuery<string>) => {
    navigate(
      { search: queryString.stringify(newQuery), hash: "" },
      { replace: true }
    );
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };

  useEffect(() => {
    if (open) {
      const backClickHandler = () => {
        window.history.forward();
        setOpen(false);
      };
      window.addEventListener("popstate", backClickHandler);
      return () => {
        window.removeEventListener("popstate", backClickHandler);
      };
    }
  }, [open, setOpen]);

  if (!show && !open) {
    return null;
  }

  return (
    <>
      <Box
        component="div"
        ref={backDropRef}
        sx={{
          ...styles.backDrop,
          opacity: show && open ? "1" : "0"
        }}
      />
      <Stack
        sx={{
          ...styles.container,
          transform: show && open ? "translateY(0)" : "translateY(100%)"
        }}
      >
        <Stack direction="row" sx={styles.header}>
          <IconButton onClick={() => setOpen(false)}>
            <ArrowForwardIcon />
          </IconButton>
          <Typography
            fontSize="14px"
            fontWeight="bold"
            color="rgba(0, 0, 0, 0.86)"
            ml={0.5}
          >
            فیلتر
          </Typography>
        </Stack>
        {filtersList.map((filterListItem) => {
          const isMultiple = filterListItem.isMultiple || false;
          let defaultValue: string | string[] | undefined = undefined;
          if (!isMultiple) {
            if (filterListItem.key in currentQuery) {
              if (typeof currentQuery[filterListItem.key] === "string") {
                defaultValue = currentQuery[filterListItem.key] as string;
              } else if (Array.isArray(currentQuery[filterListItem.key])) {
                defaultValue = currentQuery[filterListItem.key]?.at(
                  -1
                ) as string;
              }
            }
          } else {
            defaultValue = getQueryAsArray(currentQuery, filterListItem.key);
          }

          const collapseSubTitle = filterListItem.data
            .filter((item) => {
              if (isMultiple) {
                return ((defaultValue as string[]) || []).includes(item.value);
              } else {
                return (defaultValue as string) === item.value;
              }
            })
            .map((item) => item.label)
            .join(" - ");

          return (
            <FilterCollapse
              key={filterListItem.key}
              filterKey={filterListItem.key}
              onChange={updateCurrentFilter}
              label={filterListItem.label}
              defaultValue={defaultValue as string | string[] | undefined}
              data={filterListItem.data}
              subtitle={collapseSubTitle}
              isMultiple={isMultiple}
            />
          );
        })}
        <Spacer />
        <Stack direction="row" spacing={1} sx={styles.buttonsContainer}>
          <Button
            onClick={() => changeFilter({})}
            sx={styles.filterButton}
            variant="outlined"
            color="inherit"
          >
            حذف فیلتر
          </Button>
          <Button
            onClick={() => changeFilter(currentQuery)}
            sx={styles.filterButton}
          >
            اعمال فیلتر
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default Filters;
