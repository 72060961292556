import { Button, Slider, Stack, Typography } from "@mui/material";
import { FC, memo } from "react";
import styles from "./styles";
import useLeasingCalculator from "@/components/LeasingCalculator/useLogic";

interface PropTypes {
  paybackPeriodList: {
    value: number;
    dev_coefficient: number;
  }[];
  sellerShare: number;
  karnamehCommition: number;
  postPrice: number;
  karnamehCommitionPercent: number;
  minLeasing: number;
  maxLeasing: number;
  minPrePaymentSharePercent: number;
  rate: number;
  paymentPeriod: number;
  defaultPrepayment: number;
  defaultPaybackPeriod?: number;
  nationality: string;
}

const LeasingCalculator: FC<PropTypes> = (props) => {
  const {
    paybackPeriodList,
    sellerShare,
    karnamehCommition,
    postPrice,
    karnamehCommitionPercent,
    minLeasing,
    maxLeasing,
    minPrePaymentSharePercent,
    rate,
    paymentPeriod,
    defaultPrepayment,
    defaultPaybackPeriod,
    nationality
  } = props;

  const {
    prePayment,
    paybackPeriod,
    setPaybackPeriod,
    minPrePayment,
    maxPrePayment,
    onPrePaymentChangeHandler,
    factorList,
    monthlyInstallmentAmount,
    karnamehIncome,
    amountOfCheckToSeller,
    totalAmountPaidBuyerToSeller
  } = useLeasingCalculator(
    paybackPeriodList,
    sellerShare,
    karnamehCommition,
    postPrice,
    karnamehCommitionPercent,
    minLeasing,
    maxLeasing,
    minPrePaymentSharePercent,
    rate,
    paymentPeriod,
    defaultPrepayment,
    defaultPaybackPeriod
  );

  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.prepaymentContainer}>
        <Typography
          color={(th) => th.palette.common.black}
          fontSize="0.875rem"
          fontWeight={400}
        >
          مبلغ پیش‌پرداخت:
        </Typography>
        <Typography
          component="span"
          color={(th) => th.palette.common.black}
          fontSize="0.875rem"
          fontWeight={600}
        >
          {Number(prePayment).toLocaleString()}
          <Typography
            component="span"
            color={(th) => th.palette.common.black}
            fontSize="0.75rem"
            fontWeight={400}
          >
            {" "}
            تومان
          </Typography>
        </Typography>
      </Stack>

      <Slider
        min={minPrePayment}
        max={maxPrePayment}
        step={1_000_000}
        value={prePayment}
        onChange={onPrePaymentChangeHandler}
        color="secondary"
        sx={styles.slider}
      />

      <Typography
        color={(th) => th.palette.common.black}
        fontSize="0.875rem"
        fontWeight={400}
        mt={2}
      >
        مدت زمان بازپرداخت اقساط را انتخاب کنید:
      </Typography>

      <Stack sx={styles.paybackPeriodContainer}>
        {paybackPeriodList.map((paybackPeriodItem) => {
          return (
            <Stack
              key={paybackPeriodItem.value}
              sx={styles.paybackPeriodItemContainer}
            >
              <Button
                variant={
                  paybackPeriodItem.value === paybackPeriod
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setPaybackPeriod(paybackPeriodItem.value)}
                color={
                  paybackPeriodItem.value === paybackPeriod
                    ? "secondary"
                    : "secondary"
                }
                sx={styles.paybackPeriodItem}
              >
                {paybackPeriodItem.value} ماهه
              </Button>
            </Stack>
          );
        })}
      </Stack>

      <Stack sx={styles.installmentValueContainer}>
        <Typography
          color={(th) => th.palette.common.black}
          fontSize="0.875rem"
          fontWeight={400}
        >
          مبلغ اقساط ماهانه:
        </Typography>
        <Typography
          component="span"
          color={(th) => th.palette.common.black}
          fontSize="0.875rem"
          fontWeight={600}
        >
          {Number(monthlyInstallmentAmount).toLocaleString()}
          <Typography
            component="span"
            color={(th) => th.palette.common.black}
            fontSize="0.75rem"
            fontWeight={400}
          >
            {" "}
            تومان
          </Typography>
        </Typography>
      </Stack>
      <Typography
        color={(th) => th.palette.common.black}
        fontSize="0.9rem"
        fontWeight={500}
        sx={{ my: 2 }}
      >
        موارد پرداختی در روز معامله
      </Typography>
      <Stack sx={styles.paymentContainer}>
        <Stack
          sx={{
            ...styles.paymentRow,
            mt: 0
          }}
        >
          <Typography
            color={(th) => th.palette.common.black}
            fontSize="0.8rem"
            fontWeight={400}
          >
            بیعانه پرداختی به کارنامه
          </Typography>
          <Typography
            color={(th) => th.palette.common.black}
            fontSize="0.8rem"
            fontWeight={400}
          >
            {Number(
              karnamehIncome + (nationality === "iran" ? 806_000 : 1_191_000)
            ).toLocaleString()}
            <Typography
              component="span"
              color={(th) => th.palette.common.black}
              fontSize="0.75rem"
              fontWeight={400}
            >
              {" "}
              تومان
            </Typography>
          </Typography>
        </Stack>

        <Stack sx={styles.paymentRow}>
          <Typography
            color={(th) => th.palette.common.black}
            fontSize="0.8rem"
            fontWeight={400}
          >
            چک کارنامه به فروشنده
          </Typography>
          <Typography
            color={(th) => th.palette.common.black}
            fontSize="0.8rem"
            fontWeight={400}
          >
            {Number(amountOfCheckToSeller).toLocaleString()}
            <Typography
              component="span"
              color={(th) => th.palette.common.black}
              fontSize="0.75rem"
              fontWeight={400}
            >
              {" "}
              تومان
            </Typography>
          </Typography>
        </Stack>

        <Stack sx={styles.paymentRow}>
          <Typography
            color={(th) => th.palette.common.black}
            fontSize="0.8rem"
            fontWeight={400}
          >
            چک خریدار به فروشنده
          </Typography>
          <Typography
            color={(th) => th.palette.common.black}
            fontSize="0.8rem"
            fontWeight={400}
          >
            {Number(totalAmountPaidBuyerToSeller).toLocaleString()}
            <Typography
              component="span"
              color={(th) => th.palette.common.black}
              fontSize="0.75rem"
              fontWeight={400}
            >
              {" "}
              تومان
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <Typography
        color={(th) => th.palette.common.black}
        fontSize="0.9rem"
        fontWeight={500}
        sx={{ my: 2 }}
      >
        شرایط اقساط در یک نگاه
      </Typography>
      <Stack sx={styles.factorsContainer}>
        {factorList.map((factorItem, factorItemIndex) => {
          return (
            <Stack
              key={factorItem.label}
              sx={{
                ...styles.factorsItem,
                backgroundColor: (th) =>
                  factorItemIndex % 2 === 0
                    ? "#fff"
                    : th.palette.background.default
              }}
            >
              <Typography
                color={(th) => th.palette.grey[700]}
                fontSize="0.8rem"
                fontWeight={400}
              >
                {factorItem.label}
              </Typography>
              <Typography
                color={(th) => th.palette.common.black}
                fontSize="0.8rem"
                fontWeight={400}
              >
                {factorItem.value}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default memo(LeasingCalculator);
