const toFarsiNumberText = (num = 0) => {
  const arr = []

  for (let index = 0; index < Math.trunc(num).toString().length / 3; index++) {
    arr.push(Math.trunc((num % parseFloat(`1e${(index + 1) * 3}`)) / parseFloat(`1e${index * 3}`)))
  }

  const arr2 = ['', 'هزار', 'میلیون', 'میلیارد']

  return (
    arr
      .map((n, i) => (n > 0 ? n.toLocaleString('fa-IR') + ' ' + arr2[i] : ''))
      .filter((n) => n !== '')
      .reverse()
      .join(' و ') + ' تومان'
  )
}

export default toFarsiNumberText
