import {
  Button,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";
import styles from "./styles";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { usePostInvalidOffer } from "@/services/api/concierge-sale/supervisor-offer";

interface PropTypes {
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  modalIsOpen: boolean;

  conciergeSaleId: number;
}

const InvalidateModal: FC<PropTypes> = (props) => {
  const { setModalIsOpen, modalIsOpen, conciergeSaleId } = props;

  const [reason, setReason] = useState("");

  const { mutateAsync: invalidOffer } = usePostInvalidOffer();

  const onSubmitHandler = useCallback(() => {
    invalidOffer({ conciergeSaleId, note: reason }).then(() => {
      setModalIsOpen(false);
    });
  }, [conciergeSaleId, invalidOffer, reason, setModalIsOpen]);

  return (
    <Drawer
      open={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      anchor="bottom"
    >
      <Stack sx={styles.container}>
        <Stack sx={styles.header}>
          <Typography fontWeight={500}>علت نامعتبر بودن</Typography>
          <IconButton onClick={() => setModalIsOpen(false)}>
            <CloseRounded />
          </IconButton>
        </Stack>

        <TextField
          multiline
          rows={3}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          label="توضیحات"
          sx={styles.textField}
        />

        <Button
          onClick={onSubmitHandler}
          color="error"
          sx={styles.cancelButton}
        >
          لغو سفارش
        </Button>
      </Stack>
    </Drawer>
  );
};

export default InvalidateModal;
