import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { FC } from "react";
import styles from "./styles";
import { citiesList } from "./configs";
import CarSearchTextFeild from "@/components/CarSearchTextFeild";
import useLogic from "./useLogic";

const ConciergeSaleSubmitOrder: FC = () => {
  const {
    colors,
    isSubmitOrderLoading,
    formik,
    plansList,
    manufactureYearList,
    isValid,
    numericChangeHandler
  } = useLogic();

  return (
    <Stack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        if (isValid) {
          formik.handleSubmit();
        }
      }}
      sx={styles.container}
    >
      <Stack width="100%" spacing="1rem" mb="1rem">
        <TextField
          value={formik.values.fullName}
          name="fullName"
          size="small"
          fullWidth
          onChange={formik.handleChange}
          label="نام و نام خانوادگی مشتری"
        />

        <TextField
          value={formik.values.phoneNumber}
          name="phoneNumber"
          size="small"
          inputMode="tel"
          fullWidth
          onChange={(e) => {
            if (e.target.value.length <= 11) {
              numericChangeHandler(e.target.name, e.target.value);
            }
          }}
          label="شماره موبایل"
        />

        <FormControl fullWidth size="small">
          <InputLabel>شهر</InputLabel>
          <Select
            onChange={(e) => {
              formik.setFieldValue("city", e.target.value as string);
            }}
            fullWidth
            value={formik.values.city}
            label="شهر"
          >
            {citiesList.map((cityItem) => {
              return (
                <MenuItem key={cityItem.id} value={cityItem.id}>
                  {cityItem.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <CarSearchTextFeild
          value={formik.values.car}
          onChange={(newCar) => {
            formik.setFieldValue("car", newCar);
            formik.setFieldValue("plan", null);
          }}
          serviceName="concierge_sell"
          size="small"
        />

        <FormControl fullWidth size="small">
          <InputLabel>سال ساخت</InputLabel>
          <Select
            onChange={(e) => {
              formik.setFieldValue("year", e.target.value as string);
              formik.setFieldValue("plan", null);
            }}
            fullWidth
            value={formik.values.year}
            label="سال ساخت"
          >
            {manufactureYearList.map((cityItem) => {
              return (
                <MenuItem key={cityItem.id} value={cityItem.id}>
                  {cityItem.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          value={formik.values.usage}
          name="usage"
          size="small"
          inputMode="numeric"
          fullWidth
          onChange={(e) => numericChangeHandler(e.target.name, e.target.value)}
          label="کارکرد"
          InputProps={{
            endAdornment: (
              <Typography sx={styles.usageAndorment}>کیلومتر</Typography>
            )
          }}
        />

        <TextField
          multiline
          rows={3}
          value={formik.values.address}
          name="address"
          size="small"
          fullWidth
          onChange={formik.handleChange}
          label="آدرس محل بازدید"
        />

        <TextField
          multiline
          rows={3}
          value={formik.values.description}
          name="description"
          size="small"
          fullWidth
          onChange={formik.handleChange}
          label="توضیحات"
        />

        <FormControl fullWidth size="small">
          <InputLabel>پلن</InputLabel>
          <Select
            disabled={typeof plansList === "undefined"}
            onChange={(e) => {
              formik.setFieldValue("plan", e.target.value as string);
            }}
            fullWidth
            value={formik.values.plan}
            label="پلن"
          >
            {plansList?.map((planItem) => {
              return (
                <MenuItem key={planItem.uuid} value={planItem.uuid}>
                  {planItem.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth size="small">
          <InputLabel>رنگ</InputLabel>
          <Select
            onChange={(e) => {
              formik.setFieldValue("color", e.target.value as string);
            }}
            fullWidth
            value={formik.values.color}
            label="رنگ"
          >
            {typeof colors !== "undefined"
              ? Object.entries(colors).map(([colorId, colorLabel]) => {
                  return (
                    <MenuItem key={colorId} value={colorId}>
                      {colorLabel}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
      </Stack>

      <FormControlLabel
        value={formik.values.sendLinkToUser}
        onChange={(_, newChecked) =>
          formik.setFieldValue("sendLinkToUser", newChecked)
        }
        control={<Checkbox size="small" />}
        label={
          <Typography fontSize="0.75rem">
            لینک پرداخت برای کاربر ارسال شود.
          </Typography>
        }
      />

      <FormControlLabel
        value={formik.values.imReagent}
        onChange={(_, newChecked) =>
          formik.setFieldValue("imReagent", newChecked)
        }
        control={<Checkbox size="small" />}
        label={<Typography fontSize="0.75rem">معرف خودم هستم.</Typography>}
      />

      <FormControlLabel
        value={formik.values.assignToMe}
        onChange={(_, newChecked) =>
          formik.setFieldValue("assignToMe", newChecked)
        }
        control={<Checkbox size="small" />}
        label={
          <Typography fontSize="0.75rem">
            لید به خودم تخصیص داده شود.
          </Typography>
        }
      />

      <Button
        disabled={!isValid || isSubmitOrderLoading}
        type="submit"
        fullWidth
        sx={{ mt: "1rem" }}
      >
        ثبت و ارسال پیامک پرداخت
      </Button>
    </Stack>
  );
};

export default ConciergeSaleSubmitOrder;
