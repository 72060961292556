import {
  GetSalesExpertTimeItem,
  useGetSaleExpertTimeSlotWithOrderId
} from "@/services/api/sales";
import { Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import TimeSlotItem from "./TimeSlotItem";
import Spacer from "@/components/Spacer";
import { makeUrl } from "@/utils/routing";
import { useRootLayout } from "@/hooks/useRootLayout";
import { useInspectionRequestData } from "@/pages/ConciergeSale/requests/InspectionRequest";

export type PageParams = {
  id: string;
};

type GroupedTimeslots = {
  title: string;
  items: GetSalesExpertTimeItem[];
}[];

const SelectTimeslot: FC = () => {
  const { id } = useParams<PageParams>();
  const {
    timeslot,
    time,
    address,
    setData,
    selectedPackageId,
    selectedPackageName,
    selectedPackageSlug
  } = useInspectionRequestData();
  const navigate = useNavigate();
  const { data: timeslots } = useGetSaleExpertTimeSlotWithOrderId(Number(id), {
    enabled: typeof id !== "undefined"
  });

  const { setBackUrl } = useRootLayout();

  useEffect(() => {
    setBackUrl(makeUrl(`/concierge-sale/requests/${id}`));
  });

  const currentTimestamp = useMemo(() => new Date().getTime(), []);

  const [activeTimeslot, setActiveTimeslot] = useState<number | null>(() => {
    if (typeof timeslot === "number") {
      return timeslot;
    }
    return null;
  });
  const [activeTime, setActiveTime] = useState<string | null>(() => {
    if (typeof time === "string") {
      return time;
    }
    return null;
  });

  const [dateTitle, setDateTitle] = useState<string | null>(null);
  const [priceDiff, setPriceDiff] = useState<number | null>(null);
  const [priceDiffReason, setPriceDiffReason] = useState<string | null>(null);
  const [packageFinalPrice, setPackageFinalPrice] = useState<number | null>(
    null
  );

  const groupedTimeslots = timeslots?.reduce<GroupedTimeslots>(
    (result, timeslot) => {
      const findedGroupedIndex = result.findIndex(
        (resultItem) => resultItem.title === timeslot.title
      );

      if (findedGroupedIndex === -1) {
        result.push({
          title: timeslot.title,
          items: [timeslot]
        });
      } else {
        result[findedGroupedIndex].items.push(timeslot);
      }

      return result;
    },
    []
  );

  const [activeTab, setActiveTab] = useState<string | null>(() => {
    if (typeof groupedTimeslots !== "undefined" && groupedTimeslots.length) {
      return groupedTimeslots[0].title;
    } else {
      return null;
    }
  });

  const onSubmit = () => {
    setData({
      timeslot: activeTimeslot,
      time: activeTime,
      selectedPackageId,
      selectedPackageName,
      address,
      finalPrice: packageFinalPrice,
      packagePriceDiff: priceDiff,
      packagePriceDiffReason: priceDiffReason,
      selectedDate: dateTitle,
      selectedPackageSlug
    });

    navigate(
      makeUrl(`/concierge-sale/requests/${id}/inspection-request/address`),
      { replace: true }
    );
  };

  useEffect(() => {
    if (typeof selectedPackageSlug !== "string") {
      navigate(
        makeUrl(`/concierge-sale/requests/${id}/inspection-request/package`),
        { replace: true }
      );
    }
  }, [id, navigate, selectedPackageSlug]);

  const activeGroupedTimeslots =
    groupedTimeslots?.find((groupItem) => groupItem.title === activeTab) ||
    undefined;

  if (
    typeof timeslots === "undefined" ||
    typeof groupedTimeslots === "undefined"
  ) {
    return null;
  }

  return (
    <Stack
      sx={{
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingBottom: "100px"
      }}
    >
      <Typography
        color={(th) => th.palette.common.black}
        fontSize="1rem"
        fontWeight={600}
        alignSelf="flex-start"
        m={3}
      >
        چه زمانی مایل به دریافت کارشناسی هستید؟
      </Typography>

      <Tabs
        variant="fullWidth"
        sx={{ width: "100%" }}
        value={activeTab}
        onChange={(_, newValue) => {
          setActiveTab(newValue);
        }}
        aria-label="basic tabs example"
      >
        {groupedTimeslots.map((groupItem) => {
          return (
            <Tab
              key={groupItem.title}
              label={groupItem.title}
              value={groupItem.title}
            />
          );
        })}
      </Tabs>

      <Stack
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 3,
          alignSelf: "center",
          maxWidth: "400px"
        }}
        spacing={2}
      >
        {typeof activeGroupedTimeslots !== "undefined" &&
          activeGroupedTimeslots.items.map((timeslot) => {
            return (
              <TimeSlotItem
                available={timeslot.available_in_person}
                key={timeslot?.pk}
                checked={timeslot?.pk === activeTimeslot}
                startText={timeslot.start}
                endText={timeslot.end}
                startTimestamp={timeslot.start_timestamp}
                isImmediate={timeslot.is_immediate}
                onClick={() => {
                  setActiveTimeslot(timeslot?.pk);
                  setDateTitle(timeslot?.title);
                  setPriceDiff(timeslot?.price_diff);
                  setPriceDiffReason(timeslot?.price_diff_reason);
                  setPackageFinalPrice(timeslot?.final_price);
                }}
                activeTime={activeTime}
                currentTimestamp={currentTimestamp}
                onTimeClick={(newTime) => setActiveTime(newTime)}
              />
            );
          })}
      </Stack>

      <Spacer />

      <Stack
        sx={{
          width: "100%",
          background: "white",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          bottom: 0,
          left: 0,
          p: 2
        }}
      >
        <Button
          onClick={onSubmit}
          disabled={
            typeof activeTimeslot !== "number" || typeof activeTime !== "string"
          }
          sx={{
            width: "100%"
          }}
        >
          تأیید و ادامه
        </Button>
      </Stack>
    </Stack>
  );
};

export default SelectTimeslot;
