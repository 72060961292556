import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    px: "1rem",
    pt: "1rem",
    pb: "8rem",
    height: "100vh",
    overflowY: "scroll"
  },
  svgContainer: {
    width: "100%",
    mt: "2rem"
  },
  buttonContainer: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    mt: "1rem",
    p: "1rem",
    backgroundColor: "white",
    borderTop: (th) => `1px solid ${th.palette.grey[200]}`,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
});

export default styles;
