import {
  STATE_CONFIRMED_ASSIGNED,
  STATE_EXPERT_ARRIVED,
  STATE_EXPERT_EN_ROUTE,
  STATE_EXPERT_STARTED
} from "@/pages/inspection-job-details-dynamic/configs";
import { styled } from "@mui/material";

export const MuiImage = styled("img")({});

export const inspectionJobStateInfo = {
  [STATE_CONFIRMED_ASSIGNED]: {
    faName: "تخصیص نهایی",
    nextStateActionTitle: "اعزام شدم",
    color: "#009C05",
    title: "درحال اعزام به محل کارشناسی هستم.",
    warnningMessage: "قبل از اعزام با مشتری تماس بگیرید",
    actionTitle: "تأیید"
  },
  [STATE_EXPERT_EN_ROUTE]: {
    faName: "در حال اعزام",
    nextStateActionTitle: "به محل رسیدم",
    color: "#107BC1",
    title: "به محل کارشناسی رسیدم.",
    warnningMessage: "به سمت محل بازید حرکت کنید",
    actionTitle: "به محل رسیدم"
  },
  [STATE_EXPERT_ARRIVED]: {
    faName: "کارشناس در محل",
    nextStateActionTitle: "شروع کارشناسی",
    color: "#FC00A3",
    title: "مایل به شروع کارشناسی هستم.",
    warnningMessage: "بعد از هماهنگی با مشتری کارشناسی کنید",
    actionTitle: "شروع کارشناسی"
  },
  [STATE_EXPERT_STARTED]: {
    faName: "درحال کارشناسی",
    nextStateActionTitle: "ادامه کارشناسی",
    color: "#C12FCD",
    title: "مایل به ادامه کارشناسی هستم.",
    warnningMessage: "گزارش را تکمیل و آن را نهایی کنید",
    actionTitle: ""
  }
};

export type InspectionJobStateInfoKey = keyof typeof inspectionJobStateInfo;
