import { useNavigate, useParams } from "react-router";
import styles from "./styles";
import {
  getConciergeSaleCarStatusKey,
  useGetConciergeSaleCarStatus,
  usePutConciergeSaleCarStatus
} from "@/services/api/leasing";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import produce from "immer";
import { queryClient } from "@/providers/AppQueryProvider";
import { useMemo } from "react";

const MotorAndChassis = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { mutateAsync: updateCarStatus, isLoading: isUpdateCarStatusLoading } =
    usePutConciergeSaleCarStatus(id as string, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getConciergeSaleCarStatusKey({
            conciergeSaleId: id as string
          })
        });
      }
    });

  const { data: carStatus } = useGetConciergeSaleCarStatus(
    {
      conciergeSaleId: id as string
    },
    { retry: false }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...carStatus
    },
    onSubmit: () => undefined
  });

  const onSubmitHandler = () => {
    const formikValues = formik.values;
    if (
      typeof formikValues.health_detail !== "undefined" &&
      typeof formikValues.health_flags !== "undefined" &&
      typeof formikValues.health_detail_version !== "undefined"
    ) {
      updateCarStatus({
        health_detail: formikValues.health_detail,
        health_detail_version: formikValues.health_detail_version,
        health_flags: formikValues.health_flags,
        description: formikValues.description || ""
      }).then(() => {
        navigate(-1);
      });
    }
  };

  const changeHandler = (partKey: string, newValue: string) => {
    const formikHealthDetail = formik.values.health_detail;
    if (typeof formikHealthDetail !== "undefined") {
      formik.setFieldValue(
        "health_detail",
        produce(formikHealthDetail, (draft) => {
          draft[
            partKey as keyof GetConciergeSaleCarStatusResponse["health_detail"]
          ] = newValue;
        })
      );
    }
  };

  const isValid = useMemo(() => {
    if (
      formik.values?.health_detail?.chassis &&
      formik.values?.health_detail?.splash_shield &&
      formik.values?.health_detail?.motor
    ) {
      return true;
    }
    return false;
  }, [
    formik.values?.health_detail?.chassis,
    formik.values?.health_detail?.motor,
    formik.values?.health_detail?.splash_shield
  ]);

  return (
    <Stack sx={styles.container}>
      <Typography>وضعیت موتور خودرو:</Typography>
      <RadioGroup
        value={formik.values.health_detail?.motor}
        onChange={(_e, newValue) => {
          changeHandler("motor", newValue);
        }}
      >
        <FormControlLabel
          value="intact"
          control={<Radio />}
          label={<Typography fontSize="0.875rem">فابریک</Typography>}
        />
        <FormControlLabel
          value="changed"
          control={<Radio />}
          label={<Typography fontSize="0.875rem">تعویض شده</Typography>}
        />
      </RadioGroup>

      <Typography mt="1rem">وضعیت شاسی:</Typography>

      <RadioGroup
        value={formik.values.health_detail?.chassis}
        onChange={(_e, newValue) => {
          changeHandler("chassis", newValue);
        }}
      >
        <FormControlLabel
          value="intact"
          control={<Radio />}
          label={<Typography fontSize="0.875rem">سالم</Typography>}
        />
        <FormControlLabel
          value="damaged"
          control={<Radio />}
          label={<Typography fontSize="0.875rem">آسیب‌دیده</Typography>}
        />
      </RadioGroup>

      <Typography mt="1rem">وضعیت سینی:</Typography>

      <RadioGroup
        value={formik.values.health_detail?.splash_shield}
        onChange={(_e, newValue) => {
          changeHandler("splash_shield", newValue);
        }}
      >
        <FormControlLabel
          value="intact"
          control={<Radio />}
          label={<Typography fontSize="0.875rem">سالم</Typography>}
        />
        <FormControlLabel
          value="damaged"
          control={<Radio />}
          label={<Typography fontSize="0.875rem">آسیب‌دیده</Typography>}
        />
      </RadioGroup>

      <Typography mt="2rem" fontSize="0.875rem">
        لطفا سایر موارد مهم را بنویسید. (بدنه و فنی)
      </Typography>

      <TextField
        value={formik.values.description || ""}
        multiline
        fullWidth
        size="small"
        sx={{ mt: "0.5rem" }}
        rows={4}
        placeholder="توضیحات"
        onChange={(e) => {
          formik.setFieldValue("description", e.target.value);
        }}
      />

      <Stack sx={styles.buttonContainer}>
        <Button
          disabled={isUpdateCarStatusLoading || !isValid}
          onClick={onSubmitHandler}
          fullWidth
        >
          ثبت وضعیت بدنه
        </Button>
      </Stack>
    </Stack>
  );
};

export default MotorAndChassis;
