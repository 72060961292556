import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styles } from "./styles";

interface Props {
  data: {
    label: string;
    value: string;
  }[];
  isMultiple: boolean;
  defaultValue?: string | string[];
  label: string;
  filterKey: string;
  subtitle: string;
  onChange: (key: string, value: string, isMultiple?: boolean) => void;
}

const FilterCollapse: FC<Props> = ({
  data,
  defaultValue = "",
  label,
  filterKey,
  onChange,
  isMultiple,
  subtitle
}) => {
  const [open, setOpen] = useState(false);
  const headRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const toggleHandler = () => {
      setOpen((isOpen) => !isOpen);
    };
    const currentHead = headRef.current;
    currentHead?.addEventListener("click", toggleHandler);
    return () => {
      currentHead?.removeEventListener("click", toggleHandler);
    };
  }, []);

  const itemsHeight = useMemo(
    () => (open ? itemsRef.current?.clientHeight || 0 : 0),
    [open]
  );

  return (
    <>
      <Stack
        ref={headRef}
        sx={styles.collapseHead}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography fontSize="16px">{label}</Typography>
          {Boolean(subtitle) && (
            <Typography fontSize="12px" color="#5F5F5F" mt={0.5}>
              {subtitle}
            </Typography>
          )}
        </Stack>
        <KeyboardArrowDownIcon
          sx={{
            ...styles.collapseArrow,
            transform: open ? "rotate(0deg)" : "rotate(90deg)"
          }}
        />
      </Stack>
      <Stack
        sx={{
          ...styles.itemsOuterContainer,
          height: open ? itemsHeight + 20 : itemsHeight,
          minHeight: open ? itemsHeight + 20 : itemsHeight,
          paddingBottom: open ? "20px" : "0px"
        }}
        direction="column"
      >
        <Stack sx={styles.itemsContainer} ref={itemsRef}>
          {!isMultiple && (
            <RadioGroup
              value={defaultValue as string}
              onChange={(e) => onChange(filterKey, e.target.value)}
              sx={styles.formControlCheckbox}
            >
              {data.map((dataItem) => {
                return (
                  <FormControlLabel
                    control={<Radio />}
                    key={dataItem.value}
                    value={dataItem.value}
                    label={dataItem.label}
                  />
                );
              })}
            </RadioGroup>
          )}
          {isMultiple && (
            <FormGroup sx={styles.formControlCheckbox}>
              {data.map((dataItem) => {
                return (
                  <FormControlLabel
                    key={dataItem.value}
                    control={
                      <Checkbox
                        checked={(defaultValue as string[]).includes(
                          dataItem.value
                        )}
                        onChange={() =>
                          onChange(filterKey, dataItem.value, true)
                        }
                      />
                    }
                    value={dataItem.value}
                    label={dataItem.label}
                  />
                );
              })}
            </FormGroup>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default FilterCollapse;
