import createStyle from '@/utils/createStyle'

export const styles = createStyle({
  backDrop: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    transition: 'opacity 0.3s',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  container: {
    width: '100vw',
    maxHeight: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    position: 'fixed',
    bottom: '0',
    left: 0,
    transition: 'transform 0.3s',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  header: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'sticky',
    top: '0',
    backgroundColor: 'white',
    minHeight: '60px',
    zIndex: '1',
    marginBottom: 1,
    padding: '0 4px',
  },
  collapseHead: {
    width: '100%',
    height: '60px',
    minHeight: '60px',
    cursor: 'pointer',
    padding: '0 16px',
  },
  collapseArrow: {
    color: 'rgba(0, 0, 0, 0.86)',
    transition: 'transform 0.3s',
  },
  itemsOuterContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height 0.3s, min-height 0.3s, padding-bottom 0.3s',
    borderBottom: '1px solid #E3E3E3',
    ':last-of-type': {
      borderBottom: 'none',
    },
  },
  itemsContainer: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formControlCheckbox: {
    width: '100%',
    alignSelf: 'flex-start',
    paddingLeft: '16px',
  },
  filterButton: {
    flexGrow: 1,
  },
  buttonsContainer: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 2,
    padding: '16px',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
  },
})
