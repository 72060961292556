import createStyle from '@/utils/createStyle'

export const styles = createStyle({
  boxContainer: {
    border: '1px solid #C9CCCF',
    borderRadius: '8px',
    padding: '17px',
    marginBottom: '16px',
  },
  titleArea: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #C9CCCF',
    paddingBottom: '11px',
  },
  driverIcon: {
    color: '#00A754',
    mr: 1,
  },
  title: {
    margin: 0,
    fontSize: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
  },
  userContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: '12px',
  },
  cardUserNameArea: {
    display: 'flex',
    alignItems: 'center',
  },
  cardItem: {
    display: 'flex',
    alignItems: 'center',
    mt: '10px',
  },
  cardIcon: {
    color: '#6e6e6e',
    mr: '8px',
  },
  address: {
    fontSize: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  badge: {
    background: '#E0F4EA',
    padding: '4px 9px',
    borderRadius: '4px',
    fontSize: '14px',
  },
  name: {
    fontSize: '15px',
  },
  advertisedBadge: {
    fontSize: '14px',
    padding: '3px 16px',
    borderRadius: '14px',
    backgroundColor: '#F2EBFF',
    color: '#6514FF',
    display: 'flex',
    alignItems: 'center',
  },
  cancelBadge: {
    fontSize: '14px',
    padding: '3px 16px',
    borderRadius: '14px',
    backgroundColor: '#FFF4F4',
    color: '#CD0000',
    display: 'flex',
    alignItems: 'center',
  },
  soldBadge: {
    fontSize: '14px',
    padding: '3px 16px',
    borderRadius: '14px',
    backgroundColor: '#E0FFE1',
    color: '#00B906',
    display: 'flex',
    alignItems: 'center',
  },
  pricingBadge: {
    fontSize: '14px',
    padding: '3px 16px',
    borderRadius: '14px',
    backgroundColor: '#FFE8F7',
    color: '#D62497',
    display: 'flex',
    alignItems: 'center',
  },
  negotiationBadge: {
    fontSize: '14px',
    padding: '3px 16px',
    borderRadius: '14px',
    backgroundColor: '#FDEBDC',
    color: '#F4770D',
    display: 'flex',
    alignItems: 'center',
  },
  initialBadge: {
    fontSize: '14px',
    padding: '3px 16px',
    borderRadius: '14px',
    backgroundColor: '#E5F8FC',
    color: '#107BC1',
    display: 'flex',
    alignItems: 'center',
  },
})
