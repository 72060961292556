import { FC, useEffect, useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Typography,
  Stack,
  Button,
  CircularProgress,
  Snackbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useParams } from "react-router";
import {
  useGetLadderHistory,
  useLadderDivarAds,
  LadderDates
} from "@/services/api/sales";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
}

const LadderModal: FC<Props> = ({ open, showModal, title }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const { refetch: ladderDivar } = useLadderDivarAds(Number(id), {
    onSuccess: () => {
      refetch();
      setIsLoading(false);
      setIsSuccess(true);
    },
    onError: () => {
      setIsLoading(false);
      setIsFailed(true);
    }
  });

  const { data, refetch } = useGetLadderHistory(Number(id));
  const [dates, setDates] = useState<LadderDates[]>([]);

  useEffect(() => {
    if (data) {
      setDates(data);
    }
  }, [data]);

  const submitHandler = () => {
    setIsLoading(true);
    ladderDivar();
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box sx={styles.ladderModal}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack>
          <Stack sx={{ height: "20dvh", overflowY: "scroll" }}>
            {dates.length ? (
              dates.map((date) => (
                <Typography
                  key={date.id}
                  variant="body1"
                  sx={{ py: "12px", borderBottom: "1px solid #e2e2e2" }}
                >
                  {new Date(date.created_at)
                    .toLocaleTimeString("fa")
                    .slice(0, 5)}{" "}
                  - {new Date(date.created_at).toLocaleDateString("fa")}
                </Typography>
              ))
            ) : (
              <Typography variant="body1">
                آگهی تا به حال نردبان نشده است.
              </Typography>
            )}
          </Stack>
          <Button disabled={isLoading} onClick={submitHandler}>
            {!isLoading && "ثبت نردبان"}
            {isLoading && (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            )}
          </Button>
        </Stack>
      </Box>

      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: (th) => th.palette.success.main
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        message="آگهی با موفقیت نردبان شد"
      />

      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: (th) => th.palette.error.main
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isFailed}
        onClose={() => setIsFailed(false)}
        message="متاسفانه خطایی پیش آمده"
      />
    </SwipeableDrawer>
  );
};

export default LadderModal;
