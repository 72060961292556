import { ThemeOptions } from "@mui/material";

const palette: ThemeOptions["palette"] = {
  common: {
    black: "#212121",
    white: "#ffffff"
  },
  primary: {
    main: "#00A754",
    contrastText: "#ffffff"
  },
  secondary: {
    main: "#694FB4",
    contrastText: "#ffffff"
  },
  error: {
    main: "#CD0000",
    contrastText: "#ffffff"
  },
  warning: {
    main: "#FA6B00",
    contrastText: "#212121"
  },
  info: {
    main: "#006ED8",
    contrastText: "#212121"
  },
  success: {
    main: "#2EB974",
    contrastText: "#212121"
  },
  assistive: {
    main: "#FFCE32",
    contrastText: "#ffffff"
  },
  critical: {
    main: "#CD0000",
    contrastText: "#ffffff"
  },
  iconDefault: {
    main: "#6E6E6E",
    contrastText: "#ffffff"
  },
  iconDeactive: {
    main: "#a9a9aa",
    contrastText: "#ffffff"
  },
  iconInvert: {
    main: "#e5e5e6",
    contrastText: "#212121"
  },
  iconNotif: {
    main: "#f13739",
    contrastText: "#ffffff"
  },
  background: {
    default: "#fafafa",
    paper: "#f2f2f2" // background devider/border
  },
  surface: {
    default: "#ffffff",
    paper: "#ebebeb" // surface main devider/border
  }
};

export default palette;
