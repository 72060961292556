import { FC, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";
import { useGetInspectionSalePackage } from "@/services/api/concierge-sale/inspection-package";
import { useNavigate, useParams } from "react-router";
import { useGetSaleDetailV2 } from "@/services/api/sales";
import ServiceCard from "@/pages/ConciergeSale/requests/InspectionRequest/SelectPackage/components/ServiceCard";
import { makeUrl } from "@/utils/routing";
import { useInspectionRequestData } from "@/pages/ConciergeSale/requests/InspectionRequest";

const SelectPackage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    timeslot,
    time,
    address,
    setData,
    selectedPackageId,
    finalPrice,
    packagePriceDiff,
    packagePriceDiffReason,
    selectedDate
  } = useInspectionRequestData();
  const { setHeaderTitle } = useRootLayout();
  const { data } = useGetSaleDetailV2(Number(id));
  const { data: packageData } = useGetInspectionSalePackage(
    {
      brand_model: data?.car_brand_model,
      city_id: data?.city as number
    },
    {
      enabled: !!data?.city
    }
  );

  const [selectedPackage, setSelectedPackage] = useState<string | null>(null);
  const [pacakgeName, setPackageName] = useState<string | null>(null);

  const handleCardClick = (slug: string, title: string) => {
    setSelectedPackage(slug);
    setPackageName(title);
  };

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("آفرها");
    }
  }, [setHeaderTitle]);

  const onSubmit = () => {
    setData({
      timeslot,
      time,
      address,
      selectedPackageId,
      selectedPackageName: pacakgeName,
      finalPrice,
      packagePriceDiff,
      packagePriceDiffReason,
      selectedDate,
      selectedPackageSlug: selectedPackage
    });

    navigate(
      makeUrl(`/concierge-sale/requests/${id}/inspection-request/timeslot`),
      { replace: true }
    );
  };

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          px: 3,
          pt: 2,
          pb: 12
          // minHeight: "100vh"
        }}
      >
        <Typography fontWeight={600} fontSize="1rem" pt={1} pb={2.5}>
          سرویس مد نظر را انتخاب کنید:
        </Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedPackage}
          >
            <Stack gap={2}>
              {packageData?.map((item) => (
                <ServiceCard
                  key={item.pk}
                  id={item.pk}
                  slug={item.slug}
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  onClick={() => handleCardClick(item.slug, item.title)}
                  selectedItem={selectedPackage}
                />
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          bgcolor: "red",
          position: "fixed",
          bottom: 0,
          borderTop: "1px solid #EBEBEB",
          backgroundColor: "#fff",
          p: 2
        }}
      >
        <Button disabled={!selectedPackage} onClick={onSubmit}>
          تأیید و ادامه
        </Button>
      </Stack>
    </>
  );
};

export default SelectPackage;
