import { FC } from 'react';
import xmark from './../../../assets/icons/svg-paints/xmark.svg';
import hashur from './../../../assets/icons/svg-paints/hashur.svg';

const SVGPaints: FC = () => {
  return (
    <defs>
      <pattern
        id="xmark"
        patternUnits="userSpaceOnUse"
        width="4.5"
        height="4.5"
      >
        <image
          href={xmark}
          width="4.5"
          height="4.5"
        />
      </pattern>
      <pattern id="hashur" patternUnits="userSpaceOnUse" width="3" height="3">
        <image
          href={hashur}
          width="3"
          height="3"
        />
      </pattern>
    </defs>
  );
};

export default SVGPaints;
