import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { withProfiler } from "@sentry/react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  Modal,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText
} from "@mui/material";
import { toEngDigits } from "@/utils/toEnglishDigit";
import {
  usePostSubmitDeal,
  SubmitDealResoponse,
  useGetSaleDetailV2,
  useCalculateCommission,
  useUpdateSubmitDeal
} from "@/services/api/sales";
import { useNavigate, useParams } from "react-router";
import partyPopper from "@/assets/icons/party-popper.svg";
import DateInput from "@/components/DateInput";
import toFarsiNumberText from "@/utils/toFarsiNumberText";
import { useRootLayout } from "@/hooks/useRootLayout";
import useErrorHandler from "@/hooks/useErrorHandler";
import { GlobalErrorModel } from "@/utils/error-handling";
import { AxiosError } from "axios";
import ImageUploader from "@/components/ImageUploader";
import produce from "immer";
import { useUploadAffidavitPhoto } from "@/services/api/concierge-sale/submit-deals";

const fieldNames = [
  "deal_date",
  "visitor_name",
  "visitor_phone_number",
  "price",
  "karnameh_commission",
  "sale_concierge_commission",
  "buy_concierge_commission",
  "pos_receipt_number",
  "deal_description",
  "buy_id",
  "warranty_number",
  "sale_concierge_commission_percent",
  "buy_concierge_commission_percent",
  "is_warrantied"
];

const SubmitDeal: FC = () => {
  const { id } = useParams();
  const { setHeaderTitle } = useRootLayout();
  const { data } = useGetSaleDetailV2(Number(id));

  const navigate = useNavigate();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("ثبت معامله");
    }
  }, [setHeaderTitle]);

  const [dealTime, setDealTime] = useState({
    date: new Date().toISOString().split("T")[0],
    hour: "",
    min: ""
  });

  const [formData, setFormData] = useState<SubmitDealResoponse>({
    deal_date: new Date().toISOString(),
    visitor_name: "",
    visitor_phone_number: "",
    price: 0,
    karnameh_commission: 0,
    sale_concierge_commission: 0,
    buy_concierge_commission: 0,
    pos_receipt_number: "",
    deal_description: null,
    buy_id: 0,
    warranty_number: null,
    sale_concierge_commission_percent: 0,
    buy_concierge_commission_percent: 0,
    is_warrantied: false,
    affidavit_images: [],
    affidavit_documents: {
      affidavit: {
        name: null,
        url: null
      },
      payment_receipt: {
        name: null,
        url: null
      },
      other: {
        name: null,
        url: null
      }
    }
  });

  const [openModal, setOpenModal] = useState(false);
  const { mutateAsync, error: postSubmitDealError } = usePostSubmitDeal(
    String(id)
  );
  const { mutateAsync: updateMutateAsync, error: updateSubmitDealError } =
    useUpdateSubmitDeal(data?.deals?.id as number);
  const { mutateAsync: calculateCommission, error: calculateCommissionError } =
    useCalculateCommission();
  const [commission, setCommission] = useState({
    buy_commission: 0,
    sale_commission: 0
  });
  const [isChangeCalc, setIsChangeCalc] = useState(false);
  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState<
    Record<
      keyof SubmitDealResoponse["affidavit_documents"],
      {
        url: string | null;
        name: string | null;
        file: File | null;
      } | null
    >
  >({
    affidavit: null,
    other: null,
    payment_receipt: null
  });

  const errorsList = useMemo(
    () => [
      ((postSubmitDealError as AxiosError) || undefined)?.response?.data,
      ((updateSubmitDealError as AxiosError) || undefined)?.response?.data,
      calculateCommissionError
    ],
    [calculateCommissionError, postSubmitDealError, updateSubmitDealError]
  );

  const { errors, resolveAll, resolveField } = useErrorHandler(
    errorsList as GlobalErrorModel[],
    fieldNames
  );

  useEffect(() => {
    if (data?.deals) {
      setFormData(data?.deals);

      setImages(
        produce((currentImages) => {
          Object.keys(currentImages).forEach((_imageItemKey) => {
            const imageItemKey =
              _imageItemKey as keyof SubmitDealResoponse["affidavit_documents"];
            currentImages[imageItemKey] = {
              file: null,
              name: data.deals?.affidavit_documents[imageItemKey].name || null,
              url: data.deals?.affidavit_documents[imageItemKey].url || null
            };
          });
        })
      );
    }
  }, [data?.deals]);

  const changeHandler = (event: {
    target: { name: string; value: string };
  }) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    resolveField(event.target.name);
  };

  const changeNumberHandler = (event: {
    target: { name: string; value: string };
  }) => {
    setFormData({
      ...formData,
      [event.target.name]: toEngDigits(event.target.value)
    });
    resolveField(event.target.name);
  };

  useEffect(() => {
    if (formData.deal_date) {
      setDealTime(() => {
        return {
          date: new Date(formData.deal_date).toISOString().split("T")[0],
          hour: String(new Date(formData.deal_date).getHours()),
          min: String(new Date(formData.deal_date).getMinutes())
        };
      });
    }
  }, [formData.deal_date]);

  useEffect(() => {
    if (!formData.is_warrantied && formData.warranty_number) {
      setFormData((f) => ({ ...f, warranty_number: null }));
    }
  }, [formData.is_warrantied, formData.warranty_number]);

  const calculateCommissionHandler = useCallback(
    (commissions: { cKarnameh?: number; cSale?: number; cBuy?: number }) => {
      const {
        cKarnameh = formData.karnameh_commission,
        cSale = formData.sale_concierge_commission,
        cBuy = formData.buy_concierge_commission
      } = commissions;
      if (cKarnameh && (cBuy || cSale)) {
        calculateCommission({
          karnameh_commission: cKarnameh || 0,
          sale_concierge_commission: cSale || 0,
          buy_concierge_commission: cBuy || 0
        }).then((data) => {
          setCommission({
            buy_commission: data.data.buy_commission,
            sale_commission: data.data.sale_commission
          });
          setIsChangeCalc(true);
        });
      }
    },
    [
      calculateCommission,
      formData.buy_concierge_commission,
      formData.karnameh_commission,
      formData.sale_concierge_commission
    ]
  );

  const submitHandler = () => {
    setLoading(true);
    const dateArr = dealTime.date.split("-");
    const fullDate = new Date(
      parseInt(dateArr[0]),
      parseInt(dateArr[1]) - 1,
      parseInt(dateArr[2]),
      parseInt(dealTime.hour),
      parseInt(dealTime.min)
    );
    resolveAll();

    mutateAsync({
      ...formData,
      deal_date: fullDate.toISOString(),
      sale_concierge_commission: commission.sale_commission,
      buy_concierge_commission: commission.buy_commission,
      affidavit_documents: {
        affidavit: images.affidavit?.name || null,
        other: images.other?.name || null,
        payment_receipt: images.payment_receipt?.name || null
      }
    })
      .then(() => {
        setOpenModal(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editHandler = () => {
    setLoading(true);
    const dateArr = dealTime.date.split("-");
    const fullDate = new Date(
      parseInt(dateArr[0]),
      parseInt(dateArr[1]) - 1,
      parseInt(dateArr[2]),
      parseInt(dealTime.hour),
      parseInt(dealTime.min)
    );
    resolveAll();

    updateMutateAsync({
      ...formData,
      deal_date: fullDate.toISOString(),
      sale_concierge_commission:
        commission.sale_commission ||
        (data?.deals?.sale_concierge_commission as number),
      buy_concierge_commission:
        commission.buy_commission ||
        (data?.deals?.buy_concierge_commission as number),
      affidavit_documents: {
        affidavit: images.affidavit?.name || null,
        other: images.other?.name || null,
        payment_receipt: images.payment_receipt?.name || null
      }
    })
      .then(() => {
        setOpenModal(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { mutateAsync: mutateUploadImage } = useUploadAffidavitPhoto();

  const onImageUpload = useCallback(
    (
      imageKey: keyof SubmitDealResoponse["affidavit_documents"],
      newImage: {
        file: File;
        url: string;
      } | null
    ) => {
      if (newImage) {
        mutateUploadImage({ file: newImage.file }).then((res) => {
          setImages(
            produce((currentImages) => {
              const newImageItem = {
                url: res.url,
                name: res.name,
                file: newImage.file
              };
              currentImages[imageKey] = newImageItem;
            })
          );
        });
      } else {
        setImages(
          produce((currentImages) => {
            currentImages[imageKey] = {
              file: null,
              name: null,
              url: null
            };
          })
        );
      }
    },
    [mutateUploadImage]
  );

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          px: "24px",
          py: "20px",
          height: "100vh",
          overflowY: "scroll"
        }}
      >
        <Typography
          variant="h3"
          sx={{ fontSize: "14px", fontWeight: "bold", mb: 2 }}
        >
          تاریخ ثبت معامله
        </Typography>

        <DateInput
          value={dealTime.date}
          setValue={(value) => setDealTime({ ...dealTime, date: value })}
        />

        <Typography
          variant="h3"
          sx={{ fontSize: "14px", fontWeight: "bold", my: 2 }}
        >
          زمان
        </Typography>

        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
          <TextField
            sx={{ width: "48%" }}
            label="ساعت *"
            value={dealTime.hour}
            onChange={(event) =>
              setDealTime({
                ...dealTime,
                hour: toEngDigits(event.target.value)
              })
            }
            inputProps={{ maxLength: 2 }}
            variant="outlined"
            size="small"
          />

          <TextField
            sx={{ width: "48%" }}
            label="دقیقه *"
            value={dealTime.min}
            onChange={(event) =>
              setDealTime({ ...dealTime, min: toEngDigits(event.target.value) })
            }
            inputProps={{ maxLength: 2 }}
            variant="outlined"
            size="small"
          />
        </Stack>

        <TextField
          sx={{ my: 2, width: "100%" }}
          label="نام خریدار *"
          value={formData.visitor_name}
          name="visitor_name"
          onChange={changeHandler}
          variant="outlined"
          size="small"
          {...errors.visitor_name}
        />

        <TextField
          sx={{ mb: 2, width: "100%" }}
          label="تلفن تماس خریدار *"
          value={formData.visitor_phone_number}
          name="visitor_phone_number"
          onChange={changeNumberHandler}
          variant="outlined"
          size="small"
          {...errors.visitor_phone_number}
        />

        <TextField
          sx={{ width: "100%" }}
          label="مبلغ کل معامله (تومان) *"
          value={formData.price || ""}
          name="price"
          onChange={(event) =>
            setFormData({
              ...formData,
              price: parseInt(toEngDigits(event.target.value))
            })
          }
          variant="outlined"
          size="small"
          {...errors.price}
        />

        <Typography
          variant="body1"
          sx={{ mt: "5px", mb: "8px", fontSize: "13px" }}
        >
          {formData.price
            ? `${toFarsiNumberText(
                parseInt(toEngDigits(String(formData.price)))
              )}`
            : ""}
        </Typography>

        <TextField
          sx={{ width: "100%" }}
          label="کمیسیون کارنامه (تومان) *"
          value={formData.karnameh_commission || ""}
          name="karnameh_commission"
          onChange={(event) => {
            const newCkarnameh = parseInt(toEngDigits(event.target.value));
            setFormData({
              ...formData,
              karnameh_commission: newCkarnameh
            });
            calculateCommissionHandler({ cKarnameh: newCkarnameh });
          }}
          variant="outlined"
          size="small"
          {...errors.karnameh_commission}
        />

        <Typography
          variant="body1"
          sx={{ mt: "5px", mb: "8px", fontSize: "13px" }}
        >
          {formData.karnameh_commission
            ? `${toFarsiNumberText(
                parseInt(toEngDigits(String(formData.karnameh_commission)))
              )}`
            : ""}
        </Typography>

        <TextField
          sx={{ width: "100%", mb: 1 }}
          label="درصد کمیسیون کارشناس فروش"
          value={formData.sale_concierge_commission_percent || ""}
          name="sale_concierge_commission"
          onChange={(event) => {
            const newCSale = parseInt(toEngDigits(event.target.value));
            setFormData({
              ...formData,
              sale_concierge_commission_percent: newCSale,
              sale_concierge_commission: newCSale
            });
            calculateCommissionHandler({ cSale: newCSale });
          }}
          variant="outlined"
          size="small"
          inputProps={{ maxLength: 2 }}
          {...errors.sale_concierge_commission}
        />
        {formData.sale_concierge_commission_percent ? (
          <Typography variant="body1" sx={{ fontSize: "13px" }}>
            {isChangeCalc
              ? `${toFarsiNumberText(
                  parseInt(toEngDigits(String(commission.sale_commission)))
                )}`
              : `${toFarsiNumberText(
                  parseInt(
                    toEngDigits(String(data?.deals?.sale_concierge_commission))
                  )
                )}`}
          </Typography>
        ) : (
          ""
        )}

        <FormControl error={errors.buy_id.error} sx={{ flexGrow: 1, mt: 1 }}>
          <InputLabel size="small" htmlFor="buy_id">
            کد سفارش خرید مشترک (اختیاری){" "}
          </InputLabel>
          <OutlinedInput
            id="buy_id"
            sx={{ width: "100%", mb: 2 }}
            label="کد سفارش خرید مشترک (اختیاری)"
            value={formData.buy_id || ""}
            name="buy_id"
            onChange={(event) =>
              setFormData({
                ...formData,
                buy_id: +toEngDigits(event.target.value)
              })
            }
            size="small"
            error={errors.buy_id.error}
          />
          {errors.buy_id.helperText && (
            <FormHelperText>{errors.buy_id.helperText}</FormHelperText>
          )}
        </FormControl>

        {/* <TextField
          sx={{ width: "100%", mb: 2 }}
          label="کد سفارش خرید مشترک (اختیاری)"
          value={formData.buy_id || ""}
          name="buy_id"
          onChange={(event) =>
            setFormData({
              ...formData,
              buy_id: +toEngDigits(event.target.value)
            })
          }
          variant="outlined"
          size="small"
        /> */}

        <FormControl
          error={errors.buy_concierge_commission.error}
          sx={{ flexGrow: 1, mb: 1 }}
        >
          <InputLabel size="small" htmlFor="buy_concierge_commission">
            درصد کمیسیون کارشناس خرید (اختیاری)
          </InputLabel>
          <OutlinedInput
            id="buy_concierge_commission"
            label="درصد کمیسیون کارشناس خرید (اختیاری)"
            value={formData.buy_concierge_commission_percent || ""}
            name="buy_concierge_commission"
            onChange={(event) => {
              const newCBuy = parseInt(toEngDigits(event.target.value));
              setFormData({
                ...formData,
                buy_concierge_commission_percent: newCBuy,
                buy_concierge_commission: newCBuy
              });
              calculateCommissionHandler({ cBuy: newCBuy });
            }}
            size="small"
            inputProps={{ maxLength: 2 }}
          />
          {errors.buy_concierge_commission.helperText && (
            <FormHelperText>
              {errors.buy_concierge_commission.helperText}
            </FormHelperText>
          )}
        </FormControl>

        {/* <Typography
          variant="body1"
          sx={{ mt: "5px", mb: "8px", fontSize: "13px" }}
        >
          {data?.deals?.buy_concierge_commission
            ? `${toFarsiNumberText(
                parseInt(
                  toEngDigits(String(data?.deals?.buy_concierge_commission))
                )
              )}`
            : formData.buy_concierge_commission
            ? `${toFarsiNumberText(
                parseInt(toEngDigits(String(commission.buy_commission)))
              )}`
            : ""}
        </Typography> */}

        {formData.buy_concierge_commission_percent ? (
          <Typography variant="body1" sx={{ fontSize: "13px" }}>
            {isChangeCalc
              ? `${toFarsiNumberText(
                  parseInt(toEngDigits(String(commission.buy_commission)))
                )}`
              : `${toFarsiNumberText(
                  parseInt(
                    toEngDigits(String(data?.deals?.buy_concierge_commission))
                  )
                )}`}
          </Typography>
        ) : (
          ""
        )}

        <TextField
          sx={{ width: "100%", mb: 2, mt: 1 }}
          label="شماره پیگیری POS"
          value={formData.pos_receipt_number}
          name="pos_receipt_number"
          onChange={changeNumberHandler}
          variant="outlined"
          size="small"
          {...errors.pos_receipt_number}
        />

        <FormControl
          error={errors.is_warrantied.error}
          size="small"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <InputLabel id="waranty-select-label">ضمانت نامه</InputLabel>
          <Select
            labelId="waranty-select-label"
            id="waranty-select"
            value={formData.is_warrantied}
            sx={{ mb: 2.5 }}
            label="ضمانت نامه"
            name="is_warrantied"
            onChange={(event) =>
              setFormData({
                ...formData,
                is_warrantied: event.target.value === "true" ? true : false
              })
            }
          >
            <MenuItem value="true">دارد</MenuItem>
            <MenuItem value="false">ندارد</MenuItem>
          </Select>
          {errors.is_warrantied.helperText && (
            <FormHelperText>{errors.is_warrantied.helperText}</FormHelperText>
          )}
        </FormControl>

        {formData.is_warrantied && (
          <TextField
            sx={{ width: "100%", mb: 2 }}
            label="شماره ضمانت نامه *"
            value={formData.warranty_number || ""}
            name="warranty_number"
            onChange={changeHandler}
            variant="outlined"
            size="small"
            {...errors.warranty_number}
          />
        )}

        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2
          }}
        >
          <Stack width="calc(100% / 3)" sx={{ px: "0.25rem" }}>
            <ImageUploader
              value={images.affidavit?.file || images.affidavit?.url || null}
              onChange={(newImage) => onImageUpload("affidavit", newImage)}
              title="مبایعه نامه *"
            />
          </Stack>
          <Stack width="calc(100% / 3)" sx={{ px: "0.25rem" }}>
            <ImageUploader
              value={
                images.payment_receipt?.file ||
                images.payment_receipt?.url ||
                null
              }
              onChange={(newImage) =>
                onImageUpload("payment_receipt", newImage)
              }
              title="فیش پرداخت *"
            />
          </Stack>
          <Stack width="calc(100% / 3)" sx={{ px: "0.25rem" }}>
            <ImageUploader
              value={images.other?.file || images.other?.url || null}
              onChange={(newImage) => onImageUpload("other", newImage)}
              title="سایر"
            />
          </Stack>
        </Stack>

        <TextField
          sx={{ width: "100%" }}
          label="توضیحات"
          value={formData.deal_description || ""}
          name="deal_description"
          variant="outlined"
          onChange={changeHandler}
          multiline
          rows={4}
          size="small"
          {...errors.deal_description}
        />

        {data?.deals ? (
          <Button
            color={
              Object.keys(errors).some((i) => errors[i].error)
                ? "error"
                : "primary"
            }
            disabled={
              // Boolean(data?.deals) ||
              loading ||
              !formData.visitor_name ||
              !formData.visitor_phone_number ||
              !formData.price ||
              !formData.karnameh_commission ||
              !dealTime.hour ||
              !dealTime.min ||
              !images.affidavit?.url ||
              !images.payment_receipt?.url ||
              ((formData.is_warrantied && !formData.warranty_number) as boolean)
            }
            onClick={editHandler}
            sx={{ mt: 2 }}
          >
            {!loading && "ویرایش معامله"}
            {loading && (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            )}
          </Button>
        ) : (
          <Button
            color={
              Object.keys(errors).some((i) => errors[i].error)
                ? "error"
                : "primary"
            }
            disabled={
              Boolean(data?.deals) ||
              loading ||
              !formData.visitor_name ||
              !formData.visitor_phone_number ||
              !formData.price ||
              !formData.karnameh_commission ||
              !dealTime.hour ||
              !dealTime.min ||
              !images.affidavit?.url ||
              !images.payment_receipt?.url ||
              ((formData.is_warrantied && !formData.warranty_number) as boolean)
            }
            onClick={submitHandler}
            sx={{ mt: 2 }}
            size="large"
          >
            {loading ? (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            ) : (
              "ثبت معامله و ارسال پیامک"
            )}
          </Button>
        )}
      </Stack>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            bgcolor: "background.paper",
            outline: "none",
            borderRadius: "8px",
            px: 4,
            py: 2,
            textAlign: "center"
          }}
        >
          <Typography variant="body1" sx={{ lineHeight: "1.8" }}>
            تبریک
            <img src={partyPopper} width={25} alt="party-popper" />
            <br />
            خودرو با موفقیت فروخته شد
          </Typography>
          <Button
            sx={{ mt: 1, px: 3, height: "auto" }}
            onClick={() => {
              setOpenModal(false);
              navigate(`/concierge-sale/requests/${id}`);
            }}
          >
            تایید
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default withProfiler(SubmitDeal, { name: "SubmitDeal" });
