export const filtersList = [
  {
    label: "مرتب‌سازی بر اساس",
    data: [
      { label: "جدیدترین", value: "newest" },
      { label: "بیشترین قیمت", value: "high-price" }
    ],
    key: "sort"
  },
  // {
  //   label: 'انتخاب محله',
  //   data: [
  //     { label: 'شمال', value: 'N' },
  //     { label: 'جنوب', value: 'S' },
  //     { label: 'شرق', value: 'E' },
  //     { label: 'غرب', value: 'W' },
  //     { label: 'مرکز', value: 'C' },
  //   ],
  //   key: 'zone',
  //   isMultiple: true,
  // },
  {
    label: "پکیج",
    data: [
      {
        label: "کارشناسی رنگ و بدنه + فنی در محل شما",
        value: "3"
      },
      {
        label: "کارشناسی رنگ و بدنه + فنی + قیمت گذاری در محل شما",
        value: "4"
      },
      {
        label: "کارشناسی رنگ و بدنه در محل شما",
        value: "7"
      },
      {
        label: "کارشناسی رنگ و بدنه + قیمت گذاری در محل شما",
        value: "8"
      }
    ],
    key: "package",
    isMultiple: true
  },
  {
    label: "نوع سفارش",
    data: [
      { label: "کارشناسی خودرو", value: "light_car" },
      { label: "کارشناسی موتور سیکلت", value: "motor_cycle" },
      { label: "مکانیک همراه", value: "special" }
    ],
    key: "order",
    isMultiple: true
  }
];
