import { CircularProgress } from "@/components/CircularProgress";
import Stack from "@mui/material/Stack";
import type { FC } from "react";

const CircularLoading: FC = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        position: "absolute",
        overscrollBehavior: "none",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0
      }}
    >
      <CircularProgress />
    </Stack>
  );
};

export default CircularLoading;
