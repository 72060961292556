import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type BlockingStateKeys = 'safe' | 'firstWarn' | 'firstBlocking' | 'secondWarn' | 'secondBlocking'

const offer = createSlice({
  name: 'offer',
  initialState: {
    blockingState: 'safe',
  } as { blockingState: BlockingStateKeys },
  reducers: {
    setBlockingState: (state, action: PayloadAction<BlockingStateKeys>) => {
      state.blockingState = action.payload
    },
  },
})

export const { setBlockingState } = offer.actions
export default offer.reducer
