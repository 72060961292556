import { useInstalmentMonths } from "@/services/api/leasing-order";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "../styles";

interface Props {
  car_brand_model: string;
  car_manufacturer_year: string;
  car_price: number;
  onChange: (_data: GetLeasingInstalmentMonthsConfigItem) => void;
}

function InstalmentMonths({
  car_brand_model,
  car_price,
  car_manufacturer_year,
  onChange
}: Props) {
  const { data, isLoading, isFetching } = useInstalmentMonths({
    car_brand_model,
    car_price,
    channel: "B",
    car_manufacturer_year
  });

  const [instalmentMonthCountState, setInstalmentMonthCountState] =
    useState<GetLeasingInstalmentMonthsConfigItem | null>(null);

  useEffect(() => {
    if (data && instalmentMonthCountState === null) {
      if (data.length > 0) {
        setInstalmentMonthCountState(data[0]);
        onChange(data[0]);
      }
    }
  }, [data, onChange, instalmentMonthCountState]);

  if (isLoading && isFetching)
    return (
      <CircularProgress
        size="30px"
        sx={{
          width: "1.25rem",
          height: "1.25rem",
          alignSelf: "center",
          mt: "2rem"
        }}
      />
    );

  return data ? (
    <Stack sx={styles.monthListContainer}>
      {data.map((item) => {
        return (
          <Stack
            sx={styles.monthItemContainer}
            key={item.installment_month_count}
          >
            <Stack
              component="div"
              onClick={() => {
                setInstalmentMonthCountState(item);
                onChange(item);
              }}
              sx={{
                ...styles.monthItem,
                backgroundColor: (th) =>
                  instalmentMonthCountState?.installment_month_count ===
                  item.installment_month_count
                    ? th.palette.secondary.main
                    : "#ebebeb"
              }}
            >
              <Typography
                fontSize="0.875rem"
                color={() =>
                  instalmentMonthCountState?.installment_month_count ===
                  item.installment_month_count
                    ? "#ffffff"
                    : "#6E6E6E"
                }
                sx={{ transition: "color 0.3s" }}
              >
                {item.installment_month_count} ماهه
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  ) : (
    <></>
  );
}

export default InstalmentMonths;
