import { CircularProgress } from "@/components/CircularProgress";
import { Stack } from "@mui/material";
import { FC } from "react";

const IsLoading: FC = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0
      }}
    >
      <CircularProgress />
    </Stack>
  );
};

export default IsLoading;
