import { useRootLayout } from "@/hooks/useRootLayout";
import { useShifts } from "@/components/layouts/ShiftsLayout";
import {
  UpdateExpertStateDataItem,
  useUpdateExpertStates
} from "@/services/api/shifts";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import {
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import ShiftItemApprove from "./ShiftItemApprove";
import { styles } from "./styles";

const ShiftsApprove: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const [submitError, setSubmitError] = useState(false);
  const { groupedWeeklyExpertData, weeklyExpertData } = useShifts();
  const [actives, setActives] = useState(() => {
    return weeklyExpertData
      .filter((item) => item.state === 0 || item.state === 2)
      .map((item) => item.shift.id);
  });
  const navigate = useNavigate();
  const { mutateAsync, isLoading: isMutateLoading } = useUpdateExpertStates();

  const filteredDates = useMemo(() => {
    const tommorowDate = new Date();
    tommorowDate.setDate(tommorowDate.getDate() + 1);
    tommorowDate.setHours(0);
    tommorowDate.setMinutes(0);
    tommorowDate.setSeconds(0);
    return Object.keys(groupedWeeklyExpertData).filter((itemKey) => {
      if (new Date(itemKey).getTime() > tommorowDate.getTime()) {
        return true;
      } else {
        return false;
      }
    });
  }, [groupedWeeklyExpertData]);

  const activeNumbers = useMemo(() => {
    let sum = 0;
    Object.entries(groupedWeeklyExpertData).forEach(([gkey, gItem]) => {
      if (filteredDates.includes(gkey)) {
        gItem.forEach((item) => {
          if (actives.includes(item.shift.id)) {
            sum++;
          }
        });
      }
    });
    return sum;
  }, [filteredDates, actives, groupedWeeklyExpertData]);

  const update = useCallback(() => {
    const body: UpdateExpertStateDataItem[] = [];
    Object.entries(groupedWeeklyExpertData).forEach(([gkey, gItem]) => {
      if (filteredDates.includes(gkey)) {
        gItem.forEach((item) => {
          if (actives.includes(item.shift.id)) {
            body.push({ shift_id: item.shift.id, new_state: 0 });
          } else {
            body.push({ shift_id: item.shift.id, new_state: 1 });
          }
        });
      }
    });
    mutateAsync(body)
      .then(() => {
        navigate(-1);
      })
      .catch(() => {
        setSubmitError(true);
      });
  }, [actives]);

  useEffect(() => {
    if (typeof setHeaderTitle === "function") {
      setHeaderTitle("رزرو شیفت کاری");
    }
  }, []);

  return (
    <Stack sx={styles.container}>
      {Boolean(filteredDates.length) && (
        <>
          <Stack spacing={1} sx={styles.items}>
            {Object.entries(groupedWeeklyExpertData).map(([gkey, gItem]) => {
              if (filteredDates.includes(gkey)) {
                return (
                  <ShiftItemApprove
                    setActives={setActives}
                    data={gItem}
                    actives={actives}
                    date={gkey}
                    key={gkey}
                  />
                );
              } else {
                return null;
              }
            })}
          </Stack>

          <Stack direction="row" sx={styles.buttonsContainer}>
            <Stack
              direction="row"
              spacing={1}
              sx={styles.buttonsInnerContainer}
            >
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography color="#494949" fontSize="12px">
                  {activeNumbers} شیفت انتخاب شده
                </Typography>
                {actives.length < 10 && (
                  <Typography color="#FF0000" fontSize="12px">
                    هنوز ۱۰ شیفت شما پر نشده
                  </Typography>
                )}
              </Stack>
              <Button
                disabled={isMutateLoading || actives.length < 10}
                onClick={update}
                sx={styles.button}
              >
                {!isMutateLoading && <>رزرو شیفت</>}
                {isMutateLoading && (
                  <CircularProgress
                    size="15px"
                    sx={{ color: "rgba(0, 0, 0, 0.12)" }}
                  />
                )}
              </Button>
            </Stack>
          </Stack>
          <Snackbar
            open={submitError}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            onClose={() => setSubmitError(false)}
            autoHideDuration={2000}
            message="خطا در اعمال تغییرات"
          />
        </>
      )}

      {!filteredDates.length && (
        <Stack
          direction="row"
          sx={{ ...styles.warning, marginBottom: "0", marginTop: "0" }}
        >
          <ReportGmailerrorredOutlinedIcon sx={{ color: "#D7570F" }} />
          <Typography ml={1} color="#D7570F" fontSize="12px">
            در حال حاظر داده ای از روزهای کاری بعدی وجود ندارد.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ShiftsApprove;
