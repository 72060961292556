import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import createStyle from '@/utils/createStyle'

export const styles = createStyle({
  loadingContainer: {
    position: 'relative',
    backgroundColor: 'transparent',
  },
  loadingInnerContainer: {
    width: 'calc(100% - 2px)',
    height: 'calc(100% - 2px)',
    borderRadius: '50%',
    backgroundColor: 'white',
    position: 'absolute',
    '& img': {
      height: '24px',
      width: '99px',
    },
  },
})

const rotateKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const LoadingGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(90deg, rgba(94, 188, 94, 0) 20%, rgba(94, 188, 94, 1) 60%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: ${rotateKeyframe} 1s infinite linear;
`
