import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  prepaymentContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  slider: {
    mt: 2.5,
    width: "calc(100% - 16px)",
    alignSelf: "center"
  },
  paybackPeriodContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "wrap",
    mt: 2
  },
  paybackPeriodItemContainer: {
    width: { xs: "50%", md: "25%" },
    justifyContent: "center",
    alignItems: "center",
    px: 0.5,
    py: 0.5
  },
  paybackPeriodItem: {
    width: "100%"
  },
  installmentValueContainer: {
    width: "100%",
    py: 2,
    px: 3,
    borderRadius: "1rem",
    mt: "1.5rem",
    flexDirection: "row",
    backgroundColor: "#EDEBF4",
    justifyContent: "space-between",
    alignItems: "center"
  },
  factorsContainer: {
    width: "100%",
    border: "1px solid #ebebeb",
    borderRadius: "1rem",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden"
  },
  factorsItem: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    px: "1rem",
    py: "0.75rem"
  },
  paymentContainer: {
    width: "100%",
    py: 2,
    px: 3,
    borderRadius: "1rem",
    backgroundColor: "#EEEEEE"
  },
  paymentRow: {
    mt: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

export default styles;
