// SWITCH endpoints
export const SEND_OTP = "/api/switch/auth/otp/send/";
export const CHECK_OTP = "/api/switch/auth/otp/confirm/";
export const REFRESH_TOKEN = "/api/switch/auth/refresh/";
export const SERVICES_LIST = "/api/switch/services/";
export const UPDATE_CURRENT_USER = "/api/switch/users/current-user/";
export const UPLOAD_MEDIA = "/api/switch/media/";

// BOOGH endpoints
export const CURRENT_USER = "/api/switch/users/current-user/";
export const PUSH_TOKEN = "/api/boogh/push_token/";
export const NOTIFICATIONS_LIST = "/api/boogh/in_app_notif/";

export const INVALIDATE_PUSH_TOKEN = "/api/boogh/push_token";

// BUCK endpoints
export const FINANCIAL_REPORT_URL = `/api/buck/expert/financial-report/`;
export const FINANCIAL_PERFORMANCE_REPORT_URL = `/api/buck/expert/performance-report/`;
