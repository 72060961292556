import jwtDecode from "jwt-decode";
import cookie from "./cookie";
import { authorizationCookieKey } from "./env";

export interface DecodedToken {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  roles_in_services: string[];
  username: string;
  iss: string;
  first_name?: string;
  last_name?: string;
}

export const getAuthCookie = () => {
  const tokenOfCookie = cookie.get<string>(authorizationCookieKey);
  return tokenOfCookie;
};

export const getIsLoggedIn = () => {
  const token = getAuthCookie();
  return typeof token !== undefined && (token?.length || 0) > 0;
};

export const getAuthInfo = () => {
  const token = getAuthCookie();

  const isLoggedIn = getIsLoggedIn();

  if (!isLoggedIn) {
    return null;
  }

  const data: DecodedToken = jwtDecode(token as string);

  return data;
};
