import { Stack, Typography } from '@mui/material'
import classes from './styles.module.css'
import { FC } from 'react'
import { CheckCircle, styles } from './styles'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { Link } from 'react-router-dom'
import { makeUrl } from '@/utils/routing'

interface Props {
  title: string
  hasTick: boolean
  to: string
}

const ItemButton: FC<Props> = (props) => {
  const { hasTick, title, to } = props
  return (
    <Link className={classes.link} to={makeUrl(to)}>
      <Stack direction='row' sx={styles.itemButtonDetailsContainer}>
        <Typography color='rgba(0, 0, 0, 0.87)' fontSize='14px'>
          {title}
        </Typography>
        {hasTick && <CheckCircle />}
      </Stack>
      <ArrowBackIosNewRoundedIcon sx={{ height: '16px', color: '#323232' }} />
    </Link>
  )
}

export default ItemButton
