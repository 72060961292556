import { Stack, Typography } from "@mui/material";
import IsError from "./views/IsError";
import IsLoading from "./views/IsLoading";
import { MuiImage } from "./configs";
import {
  AssiegedOrders,
  useGetAssignedOrders
} from "@/services/api/inspection-jobs";
import JobItem from "./views/JobItem";
import { useMemo } from "react";
import { useRootLayout } from "@/hooks/useRootLayout";
import emptyState from "@/assets/icons/empty-inspection-jobs-list.svg";

export const AssignedJobs = () => {
  const { setHeaderTitle, setIsLoading, setBackUrl } = useRootLayout();

  const { data, isLoading, isError } = useGetAssignedOrders({
    // enabled: currentTab === 1,
    onSuccess(data) {
      const currentIDs = data?.map((a) => a?.id?.toString());
      const currentKeys = Object.keys(localStorage);
      currentKeys?.forEach((key) => {
        if (
          new RegExp("^[0-9]{6}-s*").test(key) &&
          !currentIDs.includes(key.split("-")?.[0])
        ) {
          localStorage?.removeItem(key);
        }
      });
    },
    onSettled: () => {
      setIsLoading(false);
    }
  });

  const { list, isEmpty } = useMemo(
    () => ({
      list: data ?? [],
      isEmpty: (data?.length ?? 0) < 1
    }),
    [data]
  );

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      {isLoading ? (
        <IsLoading />
      ) : isError ? (
        <IsError />
      ) : isEmpty ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            position: "absolute",
            top: "7rem",
            right: 0,
            left: 0,
            bottom: "7rem"
          }}
        >
          <MuiImage
            src={emptyState}
            sx={(theme) => ({
              width: theme.spacing(14),
              height: theme.spacing(14)
            })}
          />
          <Typography fontWeight={500} color="rgba(0, 0, 0, 0.60)">
            در حال حاضر سفارش فعالی وجود ندارد.
          </Typography>
        </Stack>
      ) : (
        (list as AssiegedOrders[]).map((job) => (
          <JobItem key={job.id} {...job} />
        ))
      )}
    </Stack>
  );
};
