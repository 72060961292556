import createStyle from '@/utils/createStyle'

export const styles = createStyle({
  container: {
    width: '100%',
  },
  innerContainer: {
    width: 'calc(100% - 48px)',
    maxWidth: '500px',
    paddingTop: '16px',
  },
  filterIcon: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
  },
  activeFilterButton: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    lineHeight: '0',
    whiteSpace: 'nowrap',
    outline: 'none',
    border: '1px solid rgba(73, 69, 79, 0.28)',
    padding: '0 12px',
    height: '32px',
    backgroundColor: 'rgba(73, 69, 79, 0.08)',
    borderRadius: '8px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'rgba(73, 69, 79, 0.12)',
    },
    ':last-of-type': {
      marginRight: 2,
    },
  },
  loginButton: {
    width: '100px',
    height: '32px',
    marginTop: '16px',
  },
  notifModal: {
    position: 'fixed',
    width: 'calc(100% - 32px)',
    maxWidth: '300px',
    top: '50%',
    left: '50%',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tickIcon: {
    width: '36px',
    height: '36px',
    color: '#5EBC5E',
  },
  cancelIcon: {
    width: '36px',
    height: '36px',
    color: 'red',
  },
  notFoundContainer: {
    '& img': {
      width: '150px',
      height: '150px',
    },
  },
})
