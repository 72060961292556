import { FC, useEffect } from "react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import PaymentCard from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals/components/paymentCard";
import { useRootLayout } from "@/hooks/useRootLayout";
import {
  useGetPaymentLists,
  GetPaymentListsResponse
} from "@/services/api/concierge-sale/submit-deals";
import { useNavigate, useParams } from "react-router";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

const DealPayments: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useGetPaymentLists(
    {
      concierge_sale_id: Number(id),
      service_name: "CONCIERGE_SALE_DEAL"
    },
    {
      enabled: !!id
    }
  );

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("پرداخت ها");
    }
  }, [setHeaderTitle]);

  if (isLoading) {
    return (
      <Stack
        width="100%"
        height="400px"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size="50px" />
      </Stack>
    );
  }

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          px: "24px",
          pt: "20px",
          height: "100vh",
          overflowY: "scroll",
          background: "#FAFAFA",
          pb: 10
        }}
      >
        {(data as GetPaymentListsResponse[]).length ? (
          (data as GetPaymentListsResponse[])?.map((item) => (
            <PaymentCard key={item.id} {...item} />
          ))
        ) : (
          <Stack sx={{ alignItems: "center", mt: 8 }}>
            <LocalAtmIcon sx={{ fontSize: "54px", color: "#A9A9AA" }} />
            <Typography
              sx={{
                color: "#A9A9AA",
                fontSize: "16px",
                mt: 1,
                fontWeight: 500,
                textAlign: "center"
              }}
            >
              در حال حاضر هیچ پیامک پرداختی ثبت نشده است.
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          p: 2,
          borderTop: "1px solid #ebebeb",
          background: "#fff"
        }}
      >
        <Button
          size="large"
          onClick={() =>
            navigate(`/concierge-sale/requests/${id}/submit-deals/send-sms`, {
              replace: true
            })
          }
        >
          ارسال پیامک درگاه پرداخت
        </Button>
      </Stack>
    </>
  );
};

export default DealPayments;
