import { Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useAuthInfo } from "@/store/slices/auth";

export const NotLoggedIn = () => {
  const { isLoggedIn, data: authData } = useAuthInfo();

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={styles.container}
    >
      <Stack
        width="100%"
        flexGrow={1}
        mt={20}
        justifyContent="center"
        alignItems="center"
      >
        <AccountCircleRoundedIcon
          sx={{
            marginBottom: 2,
            width: "100px",
            height: "100px",
            color: "rgba(0, 0, 0, 0.50)"
          }}
        />
        {Boolean(isLoggedIn && !authData?.user_id) && (
          <Typography
            color="rgba(0, 0, 0, 0.6)"
            fontSize="14px"
            textAlign="center"
            mb={1}
          >{`کارشناسی با شماره ${authData?.username} یافت نشد.`}</Typography>
        )}
        <Typography
          color="rgba(0, 0, 0, 0.6)"
          fontSize="14px"
          textAlign="center"
        >
          لطفا وارد حساب کارشناسی خود شوید
        </Typography>
      </Stack>
    </Stack>
  );
};
