import { FC, useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Typography,
  Stack,
  Button,
  Snackbar,
  CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useEditDivarAds } from "@/services/api/sales";
import { useParams } from "react-router";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
}
const DivarModal: FC<Props> = ({ open, showModal, title }) => {
  const { id } = useParams();
  const { refetch: editDivarAds } = useEditDivarAds(Number(id));
  const [toast, setToast] = useState(false);
  const [copyLoading, setCopyLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const copyToClipBoard = async (data: string) => {
    try {
      await navigator.clipboard.writeText(data);
      setToast(true);
      setCopyLoading(false);
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
      // Handle the error, maybe show a message to the user
    }
  };

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => showModal(false)}
        onOpen={() => showModal(true)}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <Stack sx={styles.divarModal}>
          <Box sx={styles.modalTitleArea}>
            <Typography fontWeight={600} fontSize={14}>
              {title}
            </Typography>
            <CloseIcon onClick={() => showModal(false)} />
          </Box>
          <Stack sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body1"
              sx={{
                lineHeight: "1.7",
                fontSize: "14px",
                fontWeight: "400",
                color: "#6E6E6E",
                textAlign: "justify"
              }}
            >
              در صورت وجود مشکل در ویرایش آگهی موارد زیر را امتحان کنید:{" "}
            </Typography>

            <Typography
              variant="h3"
              sx={{
                lineHeight: "1.7",
                color: "#000",
                fontSize: "14px",
                display: "flex",
                fontWeight: "500",
                my: 1
              }}
            >
              <HelpRoundedIcon
                sx={{
                  color: "#694FB4",
                  fontSize: "20px",
                  alignItems: "center",
                  mr: 0.75
                }}
              />
              در صفحه‌ی دیوار، دکمه‌ی ویرایش کار نمی‌کند{" "}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                lineHeight: "1.8",
                fontSize: "14px",
                fontWeight: "400",
                color: "#6E6E6E",
                textAlign: "justify"
              }}
            >
              لینک آگهی را کپی کرده و در مرورگر خود جای‌گذاری کنید و پس از ورود
              به اکانت (تنها برای بار اول) ویرایش آگهی را انجام دهید.
            </Typography>

            <Typography
              variant="h3"
              sx={{
                lineHeight: "1.7",
                color: "#000",
                fontSize: "14px",
                display: "flex",
                fontWeight: "500",
                my: 1
              }}
            >
              <HelpRoundedIcon
                sx={{
                  color: "#694FB4",
                  fontSize: "20px",
                  alignItems: "center",
                  mr: 0.75
                }}
              />
              در صفحه‌ی دیوار خطای 404 نمایش داده می‌شود{" "}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                lineHeight: "1.8",
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "justify",
                color: "#6E6E6E"
              }}
            >
              در صفحه‌ی مرورگر به بخش "دیوار من" رفته، مطمئن شوید که وارد حساب
              کاربری خود شده‌اید و شماره تلفن آن با شماره تلفن اپلیکیشن کارنامه
              یکسان است.
              <br />
              <Box sx={{ mt: 0.5, color: "#694FB4" }}>
                در صورت رفع نشدن مشکل، لطفا مشکل را در گروه گزارش خطاهای
                اپلیکیشن اعلام کنید.
              </Box>
            </Typography>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                my: 2
              }}
            >
              <Button
                onClick={() => {
                  setCopyLoading(true);
                  editDivarAds().then((data) => {
                    setCopyLoading(false);
                    copyToClipBoard(String(data?.data?.url));
                  });
                }}
                sx={{ width: "48%" }}
                disabled={copyLoading}
                variant="outlined"
              >
                {!copyLoading && " کپی لینک آگهی"}
                {copyLoading && (
                  <CircularProgress size={20} sx={{ color: "#00000050" }} />
                )}
              </Button>
              <Button
                sx={{ width: "48%", color: "#fff" }}
                disabled={editLoading}
                onClick={() => {
                  setEditLoading(true);
                  editDivarAds().then((data) => {
                    setEditLoading(false);
                    window.open(data?.data?.url, "_parent");
                    showModal(false);
                  });
                }}
              >
                ویرایش آگهی
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Snackbar
          autoHideDuration={1000}
          ContentProps={{
            sx: {
              background: "#00a754"
            }
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={toast}
          onClose={() => setToast(false)}
          message="کپی شد"
        />
      </SwipeableDrawer>
    </>
  );
};

export default DivarModal;
