import { FC } from "react";
import { Box, SwipeableDrawer, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useParams } from "react-router";
import { useGetPricingHistoryList } from "@/services/api/sales";
import { addComma } from "@/utils/number";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
}

const PriceHistoryModal: FC<Props> = ({ open, showModal, title }) => {
  const { id } = useParams();
  const { data: historyData } = useGetPricingHistoryList(Number(id), {
    enabled: !!id
  });

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box sx={styles.historyPriceModal}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack sx={{ display: "flex", flexDirection: "column" }}>
          {historyData?.map((item) => (
            <Stack
              key={item.concierge_sale_id}
              sx={{
                border: "1px solid #C9CCCF",
                borderRadius: "8px",
                px: "16px",
                py: "12px",
                mb: 3
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "14px",
                  color: "#6E6E6E"
                }}
              >
                <Typography fontSize={14}>{item.price_expert_name}</Typography>
                <Typography fontSize={14}>
                  {new Date(item.created_at).toLocaleTimeString("fa", {
                    hour: "numeric",
                    minute: "2-digit"
                  })}
                  {" - "}

                  {new Date(item.created_at).toLocaleDateString("fa")}
                </Typography>
              </Stack>
              <Typography variant="body1" sx={{ fontSize: "15px", mt: 2 }}>
                از {addComma(Number(item.price_lower_bound))} تا{" "}
                {addComma(Number(item.price_upper_bound))} تومان
              </Typography>
              <Typography
                key={item.concierge_sale_id}
                variant="body1"
                sx={{ fontSize: "15px", mt: 1 }}
              >
                {item?.pricing_description}
              </Typography>
            </Stack>
          ))}

          {/* <Button>در خواست قیمت گذاری مجدد</Button> */}
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};

export default PriceHistoryModal;
