import { FC } from "react";
import {
  Box,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { ErDocument, GetLeasingDetailResponse } from "@/services/api/leasing/detail";
import DownloadIcon from "@mui/icons-material/Download";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  documents: ErDocument[];
}

const PhotosModal: FC<Props> = ({ open, showModal, title, documents }) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        {documents?.map((item) => (
          <Stack
            key={item.key}
            sx={{
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mb: 1.5,
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Typography sx={{ fontSize: 14 }}>{item.label}</Typography>
            {item.url ? (
              <a style={{ color: "#6E6E6E" }} href={item.url} download>
                <IconButton>
                  <DownloadIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </a>
            ) : (
              <Typography sx={{ fontSize: 14, color: '#b4b4b4' }}>
                فایلی برای دانلود وجود ندارد
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>
    </SwipeableDrawer>
  );
};

export default PhotosModal;
