import { CloseRounded } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import Spacer from "@/components/Spacer";
import DateCmpInput from "@/components/DateCmp/DateCmpInput";
import createStyle from "@/utils/createStyle";


const styles = createStyle({
  dateContainer: {
    width: "100%",
    mt: "2rem",
    alignItems: "flex-start"
  },
  clearDateButton: {
    ml: "1rem"
  }
});

export const DateCmp = ({
                          date,
                          onDateChange,
                          years,
                          title
                        }: {
  date: string | undefined;
  onDateChange: (value: string | undefined) => void;
  years?: string[];
  title?: string;
}) => {
  return (
    <Stack sx={styles.dateContainer}>
      <Stack mb="1rem" direction="row" alignItems="center">
        <Typography fontSize="0.875rem" mr="1rem">
          {title ?? "تاریخ ساخت سفارش:"}
        </Typography>
        <Button
          onClick={() => {
            const today = new Date();
            onDateChange(
              `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`
            );
          }}
          size="small"
          variant="outlined"
          color="secondary"
        >
          برو به امروز
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <DateCmpInput
          fullWidth
          setValue={(newDate) => onDateChange(newDate)}
          value={date ?? null}
          years={years}
        />
        <Spacer />
        <IconButton
          onClick={() => onDateChange(undefined)}
          sx={styles.clearDateButton}
        >
          <CloseRounded />
        </IconButton>
      </Stack>
    </Stack>
  );
};
