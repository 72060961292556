import { FC, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";
import SupervisorOfferCard from "@/pages/concierge-sale-supervisor/components/SupervisorOfferCard";
import { useGetOfferList } from "@/services/api/concierge-sale/supervisor-offer";
import PokerFaceIcon from "@/assets/icons/poker-face.svg";

const SupervisorOffers: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const { data, isLoading } = useGetOfferList();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("سرپرست فروش");
    }
  }, [setHeaderTitle]);
  return (
    <Stack
      sx={{
        width: "100%",
        px: 2.5,
        py: 2,
        height: "100vh",
        overflowY: "scroll",
        bgcolor: "#FAFAFA"
      }}
    >
      {data?.length && !isLoading ? (
        data?.map((item) => (
          <SupervisorOfferCard key={item.order_id} {...item} />
        ))
      ) : (
        <Stack sx={{ justifyContent: "center", alignItems: "center", mt: 12 }}>
          <img src={PokerFaceIcon} width={80} alt="poker face icon" />
          <Typography sx={{ fontWeight: 600, fontSize: 16, mt: 2 }}>
            درحال حاضر آفر خرید جدیدی ندارید
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default SupervisorOffers;
