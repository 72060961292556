import { FC, useMemo, useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Typography,
  Stack,
  TextField,
  Autocomplete,
  Button,
  CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Car,
  getExactSearch,
  useGetBuyAssisstantCarConfig
} from "@/services/api/searchCar";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useParams } from "react-router";
import { useGetSaleDetailV2, useUpdateInfoData } from "@/services/api/sales";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
}

interface ManufactureYear {
  id: number;
  label: string;
}

const EditCarYear: FC<Props> = ({ open, showModal, title }) => {
  const { id } = useParams();
  const { data: detailData, refetch } = useGetSaleDetailV2(Number(id));
  const [loading, setLoading] = useState(false);
  const [carDetail, setCarDetail] = useState<Car | null>(null);
  const [manufactureYear, setManufactureYear] =
    useState<ManufactureYear | null>(null);

  const {
    refetch: getCarConfig,
    data: carConfig,
    isLoading: isGetCarConfigLoading
  } = useGetBuyAssisstantCarConfig({
    lastLevelId: carDetail?.last_level_id as number,
    levelId: carDetail?.last_level as string
  });

  const { data } = useGetSaleDetailV2(Number(id), {
    onSuccess: (initialSaleData) => {
      if (initialSaleData?.car_brand_model) {
        getExactSearch({
          text: initialSaleData?.car_brand_model,
          service_name: "concierge_sell"
        }).then((res) => {
          setCarDetail(res);
        });
      }
    },
    retry: false
  });

  const { mutateAsync } = useUpdateInfoData(String(id));

  const manufactureYearList = useMemo(() => {
    if (
      carDetail === null ||
      typeof carConfig?.data?.min_manufacture_year === "undefined"
    ) {
      return [];
    } else {
      const currentDate = new Date();
      return new Array(currentDate.getFullYear() - 2001 + 1)
        .fill(null)
        .map((_, yearIndex) => {
          currentDate.setFullYear(2001 + yearIndex);
          const jalaliYear = currentDate
            .toLocaleDateString("fa-IR-u-nu-latn", { timeZone: "Iran" })
            .split("/")[0];
          return {
            id: String(currentDate.getFullYear()),
            label: `${currentDate.getFullYear()} - ${jalaliYear}`
          };
        });
    }
  }, [carDetail, carConfig]);

  const value = useMemo(() => {
    if (
      typeof detailData !== "undefined" &&
      typeof detailData.car_brand_model_year === "string"
    ) {
      const resultIndex = manufactureYearList.findIndex(
        (item) => Number(item.id) == Number(detailData.car_brand_model_year)
      );
      return manufactureYearList[resultIndex];
    }
    return null;
  }, [detailData, manufactureYearList]);

  const submitHandler = () => {
    setLoading(true);
    mutateAsync({
      car_brand_model_year: String(manufactureYear?.id)
    }).then(() => {
      refetch();
      setLoading(false);
      showModal(false);
    });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box sx={styles.pirceModal}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack sx={{ display: "flex", flexDirection: "column" }}>
          <Autocomplete
            disablePortal
            size="small"
            loading={isGetCarConfigLoading}
            loadingText={
              <Stack
                width="100%"
                height="40px"
                justifyContent="center"
                alignItems="center"
              >
                در حال گرفتن لیست سال ساخت...
              </Stack>
            }
            noOptionsText={
              <Stack
                width="100%"
                p={2}
                justifyContent="center"
                alignItems="center"
              >
                <Typography>خطا در دریافت اطلاعات</Typography>
                {carDetail !== null && (
                  <Button
                    sx={{ marginTop: 1 }}
                    onClick={() => {
                      if (carDetail) {
                        getCarConfig();
                      }
                    }}
                  >
                    دوباره تلاش کنید
                  </Button>
                )}
              </Stack>
            }
            id="manufactureYear"
            options={manufactureYearList}
            value={value}
            onChange={(_, newValue) => {
              setManufactureYear(newValue as ManufactureYear | null);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => {
              return <TextField {...params} label="سال ساخت" />;
            }}
          />
          <Button disabled={loading} onClick={submitHandler} sx={{ mt: 2 }}>
            {!loading && "ثبت تغییرات"}
            {loading && (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            )}
          </Button>
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};

export default EditCarYear;
