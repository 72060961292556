import { queryClient } from "@/providers/AppQueryProvider";
import {
  getPosePaymentsKey,
  useDeletePosePayment
} from "@/services/api/leasing/requests";
import { useLeasingImage } from "@/store/slices/leasing";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router";

export const ImageDrawer = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const { imageUrl, imageId, setImageDrawerOpen, setImageId, setImageUrl } =
    useLeasingImage();
  const { id } = useParams();
  const { mutate: deleteImage } = useDeletePosePayment({
    onSuccess: () => {
      if (id) {
        queryClient.invalidateQueries({
          queryKey: getPosePaymentsKey(id)
        });
      }
      setConfirmOpen(false);
      setImageUrl("");
      setImageId("");
      setImageDrawerOpen(false);
    }
  });

  return (
    <>
      <Drawer open={open} onClose={onClose} anchor="bottom">
        <Stack
          sx={{
            borderTopLeftRadius: "1.75rem",
            borderTopRightRadius: "1.75rem",
            overflow: "hidden"
          }}
        >
          <Stack
            position="relative"
            padding="1.625rem 1.125rem"
            alignItems="center"
            borderBottom="1px solid #EBEBEB"
            marginBottom="1rem"
          >
            <IconButton
              onClick={onClose}
              sx={{
                position: "absolute",
                left: "1rem",
                top: "50%",
                transform: "translateY(-50%)"
              }}
            >
              <Close />
            </IconButton>
            <Typography>مشاهده رسید</Typography>
          </Stack>
          <Stack padding="0 1rem">
            <Box
              component="img"
              src={imageUrl}
              minHeight="18.75rem"
              maxHeight="18.75rem"
              sx={{ objectFit: "contain" }}
            />
          </Stack>
          <Stack padding="1.5rem" flexDirection="row">
            <Button
              variant="outlined"
              sx={{
                color: "#CD0000",
                borderColor: "#CD0000",
                flexGrow: 1,
                ":hover": { borderColor: "#CD0000" }
              }}
              onClick={() => {
                setConfirmOpen(true);
              }}
            >
              حذف تصویر
            </Button>

            <Button
              onClick={() => {
                fetch(imageUrl)
                  .then((res) => {
                    return res.blob();
                  })
                  .then((res) => {
                    const link = URL.createObjectURL(res);
                    const downloadLink = document.createElement("a");
                    downloadLink.href = link;
                    downloadLink.download = imageUrl
                      ?.split("leasing-pose-payments/")?.[1]
                      ?.split("?")?.[0];
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                  });
              }}
              sx={{ marginLeft: "1rem", flexGrow: 1 }}
            >
              دانلود رسید
            </Button>
          </Stack>
        </Stack>
      </Drawer>
      <Drawer
        open={isConfirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
        anchor="bottom"
      >
        <Stack>
          <Stack
            position="relative"
            padding="1.5rem 1rem"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="1rem"
            flexDirection="row"
          >
            <Typography>حذف تصویر</Typography>
            <IconButton
              onClick={() => {
                setConfirmOpen(false);
              }}
            >
              <Close />
            </IconButton>
          </Stack>
          <Typography margin="0 1rem 1.5rem">
            آیا از حذف تصویر اطمینان دارید؟
          </Typography>
          <Stack padding="1.5rem" flexDirection="row">
            <Button
              variant="text"
              sx={{ flexGrow: 1 }}
              onClick={() => {
                setConfirmOpen(false);
              }}
            >
              انصراف
            </Button>
            <Button
              sx={{ marginLeft: "1rem", flexGrow: 1 }}
              onClick={() => {
                if (!id) return;
                deleteImage({ id, imageId });
              }}
            >
              حذف تصویر
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};
