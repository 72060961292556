import { httpUserTrace } from "@/services/http-clients";
import { UseQueryOptions, useQuery } from "react-query";

//#region Get Ad Performance
export interface GetAdPerformanceResponse {
  viewers_count: 0,
  callers_count: 0
}

const getAdPerformance = async (car_post_token: string) => {
  return httpUserTrace<GetAdPerformanceResponse>({
    method: "GET",
    url: `/user-trace/v1/analytics/car-post/${car_post_token}/user-impact`
  }).then((res) => res.data);
};

const getAdPerformanceKey = "getAdPerformance";

export const useGetAdPerformance = (
  car_post_token: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdPerformance>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getAdPerformance>>
  >
) => {
  return useQuery({
    queryKey: [getAdPerformanceKey],
    staleTime: 0,
    queryFn: () => {
      return getAdPerformance(car_post_token);
    },
    ...options
  });
};

//#endregion
