import { useRootLayout } from "@/hooks/useRootLayout";
import { useGetSaleExpertTimeSlotWithOrderId } from "@/services/api/sales";
import { CircularProgress, Stack } from "@mui/material";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { Outlet, useParams } from "react-router";

type PageParams = {
  id: string;
};

interface InspectionRequestData {
  timeslot?: number | null;
  time?: string | null;
  address?: string;
  selectedPackageId?: number | null;
  selectedPackageSlug?: string | null;
  selectedPackageName?: string | null;
  selectedDate?: string | null;
  packagePriceDiff?: number | null;
  packagePriceDiffReason?: string | null;
  finalPrice?: number | null;
}

interface InspectionRequestDataContext extends InspectionRequestData {
  setData: Dispatch<SetStateAction<InspectionRequestData>>;
}

const InspectionRequestDataContext =
  createContext<InspectionRequestDataContext>({
    timeslot: null,
    time: null,
    address: "",
    setData: () => null,
    selectedPackageId: null,
    selectedPackageName: null,
    selectedDate: null,
    packagePriceDiff: null,
    packagePriceDiffReason: null,
    finalPrice: null,
    selectedPackageSlug: null
  });

export const useInspectionRequestData = () =>
  useContext(InspectionRequestDataContext);

const InspectionRequest: FC = () => {
  const { id } = useParams<PageParams>();
  const { setHeaderTitle } = useRootLayout();

  const [data, setData] = useState<InspectionRequestData>({
    timeslot: null,
    time: null,
    address: "",
    selectedPackageId: null,
    selectedPackageName: null,
    selectedDate: null,
    finalPrice: null,
    packagePriceDiff: null,
    packagePriceDiffReason: null,
    selectedPackageSlug: null
  });

  const {
    isLoading: isGettingTimeslotsLoading,
    isError: isGettingTimeslotError
  } = useGetSaleExpertTimeSlotWithOrderId(Number(id), {
    enabled: typeof id !== "undefined"
  });

  useEffect(() => {
    setHeaderTitle("درخواست کارشناسی");
  }, [setHeaderTitle]);

  return (
    <>
      {isGettingTimeslotsLoading && (
        <Stack
          sx={{
            width: "100%",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress />
        </Stack>
      )}
      {!isGettingTimeslotsLoading && !isGettingTimeslotError && (
        <InspectionRequestDataContext.Provider
          value={{
            timeslot: data.timeslot,
            time: data.time,
            address: data.address,
            setData: setData,
            selectedPackageId: data.selectedPackageId,
            selectedPackageName: data.selectedPackageName,
            selectedDate: data.selectedDate,
            finalPrice: data.finalPrice,
            packagePriceDiff: data.packagePriceDiff,
            packagePriceDiffReason: data.packagePriceDiffReason,
            selectedPackageSlug: data.selectedPackageSlug
          }}
        >
          <Stack
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
              overflow: "auto",
              bgcolor: "#fafafa"
            }}
          >
            <Outlet />
          </Stack>
        </InspectionRequestDataContext.Provider>
      )}
      {!isGettingTimeslotsLoading && isGettingTimeslotError && (
        <div>خطا در گرفتن دیتا</div>
      )}
    </>
  );
};

export default InspectionRequest;

// new Date(1696401000000).toLocaleTimeString("en", {
//   timeZone: "Iran",
//   hour: "2-digit",
//   minute: "2-digit",
//   hour12: false
// });
