import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { toEngDigits } from "@/utils/toEnglishDigit";
import toFarsiNumberText from "@/utils/toFarsiNumberText";
import { usePutLeasingDetail } from "@/services/api/leasing/detail";
import { useParams } from "react-router";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  carPrice: string;
}

const CarPriceModal: FC<Props> = ({ open, showModal, title, carPrice }) => {
  const { id } = useParams();
  const { mutateAsync, isLoading } = usePutLeasingDetail(id as string);
  const [priceValue, setPriceValue] = useState("");

  useEffect(() => {
    if (carPrice) {
      setPriceValue(carPrice);
    }
  }, [carPrice]);

  const submitHandler = () => {
    mutateAsync({
      car_price: priceValue as string
    }).then(() => {
      showModal(false);
    });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <TextField
          label="ویرایش مبلغ خودرو"
          sx={{ lineHeight: 1.7 }}
          size="small"
          value={priceValue || ""}
          onChange={(event) => setPriceValue(toEngDigits(event.target.value))}
        />
        {priceValue ? (
          <Typography sx={{ fontSize: 14, mt: 1.5 }}>
            {toFarsiNumberText(parseInt(toEngDigits(String(priceValue))))}
          </Typography>
        ) : (
          ""
        )}
        <Button onClick={submitHandler} disabled={!priceValue} sx={{ mt: 2 }}>
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: "#00000050" }} />
          ) : (
            "ویرایش مبلغ خودرو"
          )}
        </Button>
      </Stack>
    </SwipeableDrawer>
  );
};

export default CarPriceModal;
