import { jalaliToGregorian } from '@/utils/date'
import produce from 'immer'
import { UseQueryOptions, useMutation, useQuery } from 'react-query'
import { httpRest } from '../http-clients'
import { queryClient } from '@/providers/AppQueryProvider'

new Date().toLocaleDateString('fa', { month: 'long', day: 'numeric', weekday: 'long' })

export interface Shift {
  shift: {
    id: number
    date: `${number}/${number}/${number}`
    start: `${number}:${number}:${number}`
    end: `${number}:${number}:${number}`
  }
  state: 0 | 1 | 2
  is_reminded: boolean
}

export type ExpertWeeklyShifts = Shift[]

export interface UpdateExpertStateDataItem {
  shift_id: Shift['shift']['id']
  new_state: Shift['state']
}

const getExpertWeeklyShifts = () => {
  return httpRest<ExpertWeeklyShifts>({
    method: 'GET',
    url: `/evaluation/v1/expert-weekly-shifts/`,
  })
}

export const getExpertWeeklyShiftsKey = 'getExpertWeeklyShifts'

export const useExpertWeeklyShifts = (
  config?: Omit<UseQueryOptions<Shift[], unknown, Shift[], string[]>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    ...config,
    queryKey: [getExpertWeeklyShiftsKey],
    queryFn: () => {
      return getExpertWeeklyShifts().then((res) => {
        return res.data.map((item) => {
          return produce(item, (draft) => {
            const splitedDate = draft.shift.date.split('/').map(Number)
            const convertedDate = (function () {
              const temp = jalaliToGregorian(splitedDate[0], splitedDate[1], splitedDate[2])
              return [temp[1], temp[2], temp[0]]
            })().join('/')
            draft.shift.date = convertedDate as Shift['shift']['date']
          })
        })
      })
    },
  })
}

const postUpdateExpertStates = (data: UpdateExpertStateDataItem[]) => {
  return httpRest({
    method: 'POST',
    url: `evaluation/v1/update-expert-states/`,
    data,
  })
}

const updateExpertStatesKey = 'postUpdateExpertStates'

export const useUpdateExpertStates = () => {
  return useMutation({
    mutationFn: (data: Parameters<typeof postUpdateExpertStates>[0]) => {
      return postUpdateExpertStates(data)
    },
    mutationKey: [updateExpertStatesKey],
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [getExpertWeeklyShiftsKey],
      })
    },
  })
}

const postExpertVerifyTomorrowShifts = () => {
  return httpRest({
    method: 'POST',
    url: `/evaluation/v1/expert-verify-tomorrow-shifts/`,
  })
}

const postExpertVerifyTomorrowShiftsKey = 'expertVerifyTomorrowShifts'

export const useExpertVerifyTomorrowShifts = () => {
  return useMutation({
    mutationKey: [postExpertVerifyTomorrowShiftsKey],
    mutationFn: () => {
      return postExpertVerifyTomorrowShifts()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [getExpertWeeklyShiftsKey],
      })
    },
  })
}
