import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";


interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  value: string;
  onSubmit: (value: string) => void;
  isTextArea?: boolean;
  inputType?: 'text' | 'numeric'
  isLoading?: boolean
}

const TextFieldModal: FC<Props> = ({ open, showModal, title, value, isTextArea, onSubmit, inputType, isLoading }) => {
  const [inputValue, setInputValue] = useState(value);


  useEffect(() => {
    setInputValue(value)
  },[value])

  const submitHandler = () => {
    onSubmit(inputValue)
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon sx={{
            cursor: 'pointer'
          }} onClick={() => showModal(false)} />
        </Box>
        <TextField
          inputMode={inputType}
          label={title}
          multiline={isTextArea}
          sx={{ lineHeight: 1.7 }}
          rows={3}
          size="small"
          value={inputValue || ""}
          onChange={(event) => setInputValue(event.target.value)}
        />
        <Button onClick={submitHandler} disabled={!inputValue} sx={{ mt: 2 }}>
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: "#00000050" }} />
          ) : (
            "تایید"
          )}
        </Button>
      </Stack>
    </SwipeableDrawer>
  );
};

export default TextFieldModal;
