import DateInput from "@/components/DateInput";
import { Close } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";

export const DateModal = ({
  open,
  showModal,
  onSubmit,
  dateValue
}: {
  open: boolean;
  showModal: (val: boolean) => void;
  onSubmit: (val: string) => void;
  dateValue?: string;
}) => {
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    if (!dateValue || !open) return;
    setDate(dateValue);
  }, [dateValue, open]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Stack
          sx={{
            padding: "1rem 0 2rem",
            width: "100%",
            alignItems: "center",
            position: "relative"
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "6px",
              left: 0
            }}
            onClick={() => {
              showModal(false);
            }}
          >
            <Close />
          </IconButton>
          <Typography>تاریخ تنظیم سند</Typography>
        </Stack>
        <DateInput
          setValue={(val) => {
            setDate(val);
          }}
          value={date}
          jYearsArray={[1402, 1403, 1404, 1405]}
        />
        <Stack flexDirection="row" width="100%" marginTop="2rem">
          <Button
            sx={{ flexGrow: 1 }}
            variant="outlined"
            onClick={() => {
              showModal(false);
            }}
          >
            انصراف
          </Button>
          <Button
            onClick={() => {
              onSubmit(date);
              showModal(false);
            }}
            sx={{ marginLeft: "1rem", flexGrow: 1 }}
          >
            تائید
          </Button>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
};
