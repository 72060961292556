import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import { FC, useState } from "react";

import useAppDispatch from "@/hooks/useAppDispatch";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import {
  Documents,
  GetLeasingDetailResponse,
  usePutLeasingDetail
} from "@/services/api/leasing/detail";
import {
  IOrderDynamicStatesResponse, useGetMandatoryActions,
  useGetOrderDynamicStates
} from "@/services/api/leasing/requests";
import { leasingActions } from "@/store/slices/leasing";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import MandatoryActionsDrawer from "../mandatory-actions-drawer";
import RejectReasonModal from "../rejectReasonModal";
import DynamicInputsDrawer from "../dynamic-inputs-drawer";
import { AxiosError } from "axios";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  reject?: string;
  date?: string;
  docs?: Documents;
  leasingOrder?: GetLeasingDetailResponse;
  state: string;
}

const StatusModal: FC<Props> = ({ open, showModal, title, reject, state }) => {
  const { id } = useParams();
  const { mandatoryActionsDrawerStateHandler } = leasingActions;
  const dispatch = useAppDispatch();

  const [rejectReasonModal, setRejectReasonModal] = useState(false);

  const { data: dynamicStates } = useGetOrderDynamicStates(id?.toString());

  const { mutateAsync, isLoading } = usePutLeasingDetail(id as string);

  const { mutateAsync: refetchActions } =
    useGetMandatoryActions({
      onSuccess: () => {
        return
      }
    });

  const submitHandler = (state: IOrderDynamicStatesResponse) => {
    const body = {
      state: state.id?.toString()
    } as {
      state: string;
      contract_date: string;
      documents: Record<string, string>;
    };

    updateStatus(body);
  };

  const updateStatus = (body: {
    state: string;
    contract_date: string;
    documents: Record<string, string>;
  }) => {
    mutateAsync(body)
      .then(() => {
        toast.success("تغییر وضعیت درخواست لیزینگ انجام شد.");
      })
      .catch((err) => {
        toast.error("تغییر وضعیت درخواست لیزینگ انجام نشد.");
        console.error({ err });
      });
  };

  const rejectModalHandler = () => {
    showModal(false);
    setRejectReasonModal(true);
  };

  return (
    <>
      <SwipeableDrawer
        disableSwipeToOpen
        disableDiscovery
        disableBackdropTransition
        anchor="bottom"
        open={open}
        onClose={() => showModal(false)}
        onOpen={() => showModal(true)}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <Stack
          sx={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            background: "#fff",
            p: "20px"
          }}
        >
          <Box sx={styles.modalTitleArea}>
            <Typography fontWeight={600} fontSize={14}>
              {title}
            </Typography>
            <CloseIcon sx={{cursor: 'pointer'}} onClick={() => showModal(false)} />
          </Box>
          <Stack sx={{ flexDirection: "column" }}>
            <Stack
              sx={{
                flexDirection: "column",
                // flexWrap: "wrap",
                justifyContent: "space-between",
                maxHeight: "65dvh",
                overflowY: "auto",
                padding: "0.5em"
              }}
            >
              {dynamicStates?.map((item: IOrderDynamicStatesResponse) => (
                <Stack
                  key={item?.id}
                  sx={{
                    ":hover": {
                      fontWeight: '600',
                      borderColor: 'text.primary'
                    },
                    cursor: 'pointer',
                    border: `1px solid`,
                    borderColor:
                      item?.slug === state ? "primary.main" : "#A9A8AA",
                    background: "surface.default",
                    color:
                      item?.slug === state ? "primary.main" : "text.secondary",
                    fontWeight: item?.slug === state ? 600 : 400,
                    mb: 1.75,
                    borderRadius: 25,
                    justifyContent: "center",
                    alignItems: "center",
                    py: 0.75
                  }}
                  onClick={() => {
                    refetchActions({
                      stateId: item.id.toString(),
                      orderId: id ?? ""
                    }).then((res) => {

                      if (!res.data) return;
                      const mandatoryActions = res.data.filter(
                        (item) => item.params.mandatory
                      );
                      if (mandatoryActions.length) {

                        dispatch(
                          mandatoryActionsDrawerStateHandler({
                            isOpen: true,
                            notMandatory: false,
                            actions: mandatoryActions,
                            onChangeStateClicked: () =>
                              submitHandler(item)
                          })
                        );
                      } else {
                        submitHandler(item);
                      }
                    }).catch((err : AxiosError) => {
                      const message = (err?.response?.data as string[])[0] as string
                      toast.success(message, {
                        type: 'error'
                      });
                    })
                  }}
                >
                  {item?.name_fa}
                </Stack>
              ))}
            </Stack>

            {reject && (
              <Button
                sx={{ mt: "1rem", backgroundColor: "red" }}
                onClick={rejectModalHandler}
                size="medium"
              >
                {isLoading ? (
                  <CircularProgress size={20} sx={{ color: "#00000050" }} />
                ) : (
                  reject
                )}
              </Button>
            )}
          </Stack>
        </Stack>
      </SwipeableDrawer>
      <RejectReasonModal
        openModal={rejectReasonModal}
        setOpenModal={setRejectReasonModal}
      />
      <MandatoryActionsDrawer orderId={parseInt(id ?? "") ?? 0} />
      <DynamicInputsDrawer orderId={parseInt(id ?? "") ?? 0} />
    </>
  );
};

export default StatusModal;
