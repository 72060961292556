import { useRef, FC, useState } from "react";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Camera from "../Camera";

import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";

interface FileLoaderProps {
  file: File[];
  setFile: (files: File[]) => void;
  loader?: JSX.Element;
  multi?: boolean;
  title: string;
  image: string;
  stencil?: string;
  fileName?: string;
  width?: number;
  height?: number;
}

const MainCoverFileLoader: FC<FileLoaderProps> = ({
  file,
  setFile,
  loader,
  multi = true,
  image,
  title,
  fileName,
  stencil,
  height = 500,
  width = 500
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const openUpload = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleUpload = async (files: File[] | null) => {
    if (!files) return;

    const options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1024
    };

    if (multi) {
      const compressedFiles: File[] = [];

      for (const file of Array.from(files)) {
        try {
          let finalFile: any;
          if (file.type === "image/heic") {
            finalFile = await heic2any({
              blob: file,
              toType: "image/jpeg"
            });
          } else {
            finalFile = file;
          }
          let compressedFile: File;

          if (finalFile.size > 500000) {
            compressedFile = await imageCompression(finalFile, options);
          } else {
            compressedFile = finalFile;
          }
          compressedFiles.push(compressedFile);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }

      const compressedSizedFile = compressedFiles.filter(
        (item) => item.size < 1200000
      );
      setFile([...file, ...compressedSizedFile]);
    } else {
      try {
        const compressedFile = await imageCompression(files[0], options);
        setFile([compressedFile]);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files as File[] | null;

    handleUpload(fileUploaded);
  };

  return (
    <>
      <Stack
        onClick={() => setIsBottomSheetOpen(true)}
        sx={{
          border: "1px dashed #C9CCCF",
          width: "100%",
          height: "210px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
          mb: 1.5
        }}
      >
        {loader ? (
          loader
        ) : (
          <>
            <img src={image} alt="image icon" />
            <Typography
              variant="body1"
              sx={{ color: "#6E6E6E", fontSize: "14px" }}
            >
              {title}
            </Typography>
          </>
        )}

        <input
          type="file"
          accept="image/*"
          ref={hiddenFileInput}
          multiple={multi ?? false}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </Stack>
      {isBottomSheetOpen && (
        <Stack
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "white",
            borderTopLeftRadius: "0.5rem",
            borderTopRightRadius: "0.5rem",
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: 10,
            boxShadow: "rgba(0, 0, 0, 0.16) 0px -1px 4px",
            p: 2
          }}
        >
          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "1rem"
            }}
          >
            <Typography fontWeight={600}>انتخاب تصویر</Typography>
            <IconButton
              onClick={() => setIsBottomSheetOpen(false)}
              sx={{ alignSelf: "flex-start" }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
          <Stack
            component="div"
            onClick={() => {
              setIsCameraOpen(true);
              setIsBottomSheetOpen(false);
            }}
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              py: '0.5rem',
              borderBottom: (th) => `1px solid ${th.palette.grey[300]}`
            }}
          >
            <CameraAltOutlinedIcon sx={{width: '1.25rem', height: '1.25rem', color: (th) => th.palette.grey[500]}} />
            <Typography ml={1}>گرفتن عکس</Typography>
          </Stack>
          <Stack
            component="div"
            onClick={() => {
              openUpload();
              setIsBottomSheetOpen(false);
            }}
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              py: '0.5rem'
            }}
          >
            <PermMediaOutlinedIcon sx={{width: '1.25rem', height: '1.25rem', color: (th) => th.palette.grey[500]}} />
            <Typography ml={1}>آپلود از گالری</Typography>
          </Stack>
        </Stack>
      )}
      <Camera
        open={isCameraOpen}
        stencil={stencil}
        width={width}
        height={height}
        fileName={fileName}
        submitType="save-and-submit"
        setOpen={() => setIsCameraOpen(false)}
        onSubmit={(cameraImage) => {
          if (cameraImage.file) {
            handleUpload([cameraImage.file]);
          }
        }}
      />
    </>
  );
};

export default MainCoverFileLoader;
