import { Shift } from '@/services/api/shifts'
import { Box, FormControlLabel, Stack, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { styles } from './styles'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import produce from 'immer'

interface ShiftItemApproveProps {
  data: Shift[]
  actives: number[]
  setActives: React.Dispatch<React.SetStateAction<number[]>>
  date: string
}

interface ItemProps {
  isActive: boolean
  onChange: () => void
  label: string
}

const Item: FC<ItemProps> = (props) => {
  const { isActive, onChange, label } = props

  return (
    <Stack component='button' onClick={onChange} direction='row' sx={styles.checkBoxItem}>
      <FormControlLabel
        sx={{ pointerEvents: 'none' }}
        control={<Checkbox checked={isActive} />}
        label={label}
      />
    </Stack>
  )
}

const ShiftItemApprove: FC<ShiftItemApproveProps> = (props) => {
  const { data, actives, date, setActives } = props
  const [isOpen, setIsOpen] = useState(false)
  const itemsRef = useRef<HTMLDivElement>()
  const itemsParentRef = useRef<HTMLDivElement>()
  const [allActive, setAllActive] = useState(() => {
    return data.every((item) => item.state === 0)
  })
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const setHeight = () => {
    if (itemsParentRef.current) {
      if (isOpen) {
        itemsParentRef.current.style.height = `${itemsRef.current?.clientHeight || 0}px`
      } else {
        itemsParentRef.current.style.height = `0px`
      }
    }
  }

  useEffect(() => {
    const widthChangeHandler = () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
      timeoutRef.current = setTimeout(() => {
        setHeight()
      }, 300)
    }
    window.addEventListener('resize', widthChangeHandler)

    return () => {
      window.removeEventListener('resize', widthChangeHandler)
    }
  }, [])

  useEffect(setHeight, [isOpen])

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const selectAllCallBack = () => {
    const newActives = produce(actives, (draft) => {
      data.forEach((item) => {
        if (allActive) {
          draft.splice(draft.indexOf(item.shift.id), 1)
        } else if (!allActive) {
          if (!draft.includes(item.shift.id)) {
            draft.push(item.shift.id)
          }
        }
      })
    })
    setActives(newActives)
    setAllActive((prev) => !prev)
  }

  useEffect(() => {
    if (data.every((item) => actives.includes(item.shift.id))) {
      setAllActive(true)
    } else {
      setAllActive(false)
    }
  }, [actives, data])

  const header = useMemo(() => {
    return (
      <Stack
        onClick={() => {
          setIsOpen((prevValue) => !prevValue)
        }}
        direction='row'
        sx={styles.header}
      >
        <Typography fontWeight={600} fontSize='15px' color='rgba(0, 0, 0, 0.87)'>
          {new Date(date).toLocaleDateString('fa-IR', {
            month: 'long',
            day: 'numeric',
            weekday: 'long',
          })}
        </Typography>
        <KeyboardArrowDownRoundedIcon
          sx={{
            ...styles.itemArrow,
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </Stack>
    )
  }, [isOpen, setIsOpen, date])

  return (
    <Stack sx={styles.shiftItemApprove}>
      {header}
      <Box ref={itemsParentRef} sx={styles.checkboxCollapse}>
        <Box ref={itemsRef} sx={styles.checkboxContainer}>
          <Item onChange={selectAllCallBack} isActive={allActive} label='کل روز' />
          {data.map((item) => {
            const isActive = actives.includes(item.shift.id)
            const changeCallBack = () => {
              const newActives = produce(actives, (draft) => {
                if (isActive) {
                  draft.splice(actives.indexOf(item.shift.id), 1)
                } else {
                  draft.push(item.shift.id)
                }
              })
              setActives(newActives)
            }
            const label = `${item.shift.start.split(':')[0]} تا ${item.shift.end.split(':')[0]}`

            return (
              <Item
                onChange={changeCallBack}
                isActive={isActive}
                label={label}
                key={item.shift.id}
              />
            )
          })}
        </Box>
      </Box>
    </Stack>
  )
}

export default ShiftItemApprove
