import createStyle from '@/utils/createStyle'

export const styles = createStyle({
  container: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '100px',
  },
  items: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  shiftItemApprove: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #EEEEEE',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    padding: '12px',
    cursor: 'pointer',
  },
  checkboxCollapse: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    transition: 'height 0.3s',
  },
  checkboxContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
  },
  checkBoxItem: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '4px 16px',
    border: 'none',
    borderBottom: '1px solid #EEEEEE',
    backgroundColor: 'white',
    outline: 'none',
    cursor: 'pointer',
    ':last-of-type': {
      borderBottom: 'none',
    },
  },
  itemArrow: {
    width: '18px',
    height: '18px',
    color: 'rgb(0, 0, 0, 0.86)',
    transition: 'transform 0.3s',
  },
  buttonsContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    left: '0',
    padding: '16px 0',
    backgroundColor: 'white',
  },
  buttonsInnerContainer: {
    width: '100%',
    maxWidth: '500px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
  },
  button: {
    width: '150px',
  },
  emptyList: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 4,
  },
  emptyListIcon: {
    width: '130px',
    height: '130px',
    color: '',
  },
  warning: {
    width: '100%',
    padding: '10px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '16px',
    alignSelf: 'flex-start',
    backgroundColor: '#FAE8DF',
    borderRadius: '8px',
    marginBottom: '16px',
  },
})
