import { FC, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";
import ConciergeSaleIcon from "@/assets/images/car-sale-icon.svg";
import { conciergeSaleMenu } from "@/pages/ConciergeSale/config";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";

const ConciergeSaleIntro: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const navigate = useNavigate();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("دستیار فروش");
    }
  }, [setHeaderTitle]);

  return (
    <Stack
      sx={{
        width: "100%",
        p: 3,
        height: "100vh",
        overflowY: "scroll",
        bgcolor: "#FAFAFA"
      }}
    >
      <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
        <img
          style={{ width: 220 }}
          src={ConciergeSaleIcon}
          alt="Concierge Sale Icon"
        />
      </Stack>
      <Stack sx={{ mt: 3 }}>
        {conciergeSaleMenu.map(
          ({ title, icon: Icon, path, is_external_link }) => (
            <Stack
              onClick={
                is_external_link
                  ? () => window.open(path, "_parent")
                  : () => navigate(path)
              }
              sx={{
                background: "#fff",
                mb: 2,
                border: "1px solid #EEEEEE",
                borderRadius: "8px",
                p: 2,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer"
              }}
              key={title}
            >
              <Stack sx={{ flexDirection: "row" }}>
                {Icon && <Icon sx={{ mr: 1, color: "#6E6E6E" }} />}
                <Typography>{title}</Typography>
              </Stack>
              <KeyboardArrowLeftIcon sx={{ color: "#323232" }} />
            </Stack>
          )
        )}
      </Stack>
    </Stack>
  );
};

export default ConciergeSaleIntro;
