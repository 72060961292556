import type { FromSchema } from "json-schema-to-ts";
import { buildYup } from "schema-to-yup";

const motorVaEnteghaleGhodratSchema = {
  type: "object",
  properties: {
    motor_va_system_enteghale_ghodrath_azmone_ranandegi: {
      type: "object",
      properties: {
        shetabgiri: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            dor_motor_dar_shuru_harekat_be_kondi_afzayesh_peyda_mikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            motor_seda_va_larzesh_gheire_adi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            otagh_khodro_seda_va_larzesh_gheire_adi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        girboks_dandee_heyn_harekat: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            larzesh_ya_seday_gheir_adi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            ja_raftan_dandeha_dar_hal_harekat_khodro_ba_moshkel_anjam_mishavad:
              {
                type: "string",
                enum: ["no", "yes"],
                enumNames: ["خیر", "بله"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            dande_heyn_harekat_birun_mizanad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        girboks_otomatik_heyn_harekat: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            larzesh_ya_seday_gheir_adi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            taviz_dandeha_dar_heyn_harekat_ba_moshkel_anjam_mishavad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            shifter_posht_farman_ya_tiptronik_mayub_ast: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            sistem_komaki_dar_sarbalaee_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            dar_halat_awd_ya_4wd_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        difransiel_jolo_moharek_fwd: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            dar_hein_harekat_taghe_va_shock_be_samte_jolo_va_aghab_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            nashti_roghan_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            hein_harekat_larzesh_va_sedaie_gheir_adi_be_gosh_miresad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        milgardan_va_difransiel_aghab_moharek_rwd: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            dar_hein_shoroe_harekat_taghe_va_shock_be_samte_jolo_va_aghab_darad:
              {
                type: "string",
                enum: ["no", "yes"],
                enumNames: ["خیر", "بله"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            nashti_roghan_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            hein_harekat_larzesh_va_sedaie_gheir_adi_be_gosh_miresad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        milgardan_va_difransiel_charcharkh_moharek_awd_4wd: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            kilid_tanzim_mayob_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            dar_hein_shoroe_harekat_taghe_va_shock_be_samte_jolo_va_aghab_darad:
              {
                type: "string",
                enum: ["no", "yes"],
                enumNames: ["خیر", "بله"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            nashti_roghan_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            hein_harekat_larzesh_va_sedaie_gheir_adi_be_gosh_miresad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    motor: {
      type: "object",
      properties: {
        vaziat_va_amalkard_motor: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            motor_be_rahati_roshan_nemishavad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            seda_va_larzesh_gheir_adi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            daste_motor_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            tasme_ya_harzgardha_mayub_hastand_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            booye_sukht_dar_otagh_khodro_esteshmam_mishavad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            turbo_sharj_ya_super_sharj_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            sistem_hybrid_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        roghan_motor_va_sistem_khonak_kari: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            rang_roghan_motor_gheir_tabiee_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            roghan_motor_nashti_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_maye_khonak_konande_gheir_tabiee_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            maye_khonak_konande_motor_nashti_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            vaterpomp_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        hoshdardahandehaye_safhe_kilumetr: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            neshangar_damay_maye_khonak_konande_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            neshangar_meghdar_sukht_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_chek_roshan_mibashad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_batri_roshan_mibashad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_roghan_motor_roshan_mibashad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    girboks: {
      type: "object",
      properties: {
        vaziat_girboks_dandee_halat_dar_ja: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            larzesh_ya_seday_gheir_adi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            ja_raftan_dandeha_dar_halat_motor_roshan_va_tavaghof_khodro_ba_moshkel_anjam_mishavad:
              {
                type: "string",
                enum: ["no", "yes"],
                enumNames: ["خیر", "بله"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            roghan_girboks_nashti_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        vaziat_girboks_otomatik_halat_dar_ja: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            larzesh_ya_seday_gheir_adi: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            taviz_dandeha_dar_halat_darja_ba_moshkel_anjam_mishavad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            roghan_girboks_nashti_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    tajhizat_barghi: {
      type: "object",
      properties: {
        start: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            start_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        batri: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            batri_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dinam: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            dinam_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    egzoz: {
      type: "object",
      properties: {
        vaziat_egzoz: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rang_va_bo_gazhaye_khuruji_gheire_tabie_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    description: {
      type: "string",
      maxLength: 1000
    }
  },
  additionalProperties: false
} as const;

const config = {
  errMessages: {
    // lastikha_rarande_jolo: {
    //   darsade_lastik: { required: "این فیلد اجباری است." },
    //   salamat_zaheri_ring: { required: "این فیلد اجباری است." }
    // },
    // lastikha_rarande_aghab: {
    //   darsade_lastik: { required: "این فیلد اجباری است." },
    //   salamat_zaheri_ring: { required: "این فیلد اجباری است." }
    // },
    // lastikha_shagerd_jolo: {
    //   darsade_lastik: { required: "این فیلد اجباری است." },
    //   salamat_zaheri_ring: { required: "این فیلد اجباری است." }
    // },
    // lastikha_shagerd_aghab: {
    //   darsade_lastik: { required: "این فیلد اجباری است." },
    //   salamat_zaheri_ring: { required: "این فیلد اجباری است." }
    // },
    // lastikha_zapas: {
    //   vaziat_zapas: "این فیلد اجباری است.",
    //   darsade_lastik: "این فیلد اجباری است.",
    //   salamat_zaheri_ring: "این فیلد اجباری است."
    // }
  }
};

export const motorVaEnteghaleGhodratValidation = buildYup(
  motorVaEnteghaleGhodratSchema,
  config
);

export type MotorVaEnteghaleGhodratType = FromSchema<
  typeof motorVaEnteghaleGhodratSchema
>;

export default motorVaEnteghaleGhodratSchema.properties;
