import { Collapse, Radio, Stack, Typography } from "@mui/material";
import { FC, memo, useMemo } from "react";

interface PropTypes {
  checked: boolean;
  onClick: () => void;
  startText: string;
  endText: string;
  isImmediate: boolean;
  startTimestamp: number;
  available: boolean;
  activeTime: string | null;
  onTimeClick: (newValue: string | null) => void;
  currentTimestamp: number;
}

const TimeSlotItem: FC<PropTypes> = (props) => {
  const {
    checked,
    onClick,
    startText,
    endText,
    activeTime,
    isImmediate,
    available,
    startTimestamp,
    currentTimestamp,
    onTimeClick
  } = props;

  const timeSlotTotalHours =
    Number(endText.match(/^../)?.[0] || "02") -
    Number(startText.match(/^../)?.[0] || "02");

  //this is only for fall and winter season, remove it for other season
  const isFallSeason = startText === "18:00";
  const timeList = new Array(isFallSeason ? 1 : timeSlotTotalHours * 2)
    .fill(null)
    .map((_, i) => {
      const value = new Date(startTimestamp + i * 1800000).toLocaleTimeString(
        "en",
        {
          timeZone: "Iran",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false
        }
      );
      return {
        value: value,
        label: value
      };
    })
    .filter((_, i) => {
      return startTimestamp + i * 1800000 - currentTimestamp > 1800000;
    });
  //this is only for fall and winter season, remove it for other season
  if (isFallSeason && timeList.length === 0) {
    return null;
  }

  // if (!timeList.length) {
  // const nextHalfValue = new Date(
  //   currentTimestamp + 1800000
  // ).toLocaleTimeString("en", {
  //   timeZone: "Iran",
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: false
  // });
  // timeList.push({
  //   label: nextHalfValue,
  //   value: nextHalfValue
  // });
  // }

  return (
    <Stack
      component="div"
      onClick={() => {
        onClick();
        if (timeList.length === 0) {
          onTimeClick(
            new Date(currentTimestamp + 1800000).toLocaleTimeString("en", {
              timeZone: "Iran",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false
            })
          );
        } else {
          onTimeClick(null);
        }
      }}
      sx={{
        width: "100%",
        borderRadius: "0.5rem",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        border: (th) => `1px solid ${th.palette.divider}`,
        pointerEvents: available ? "all" : "none",
        cursor: "pointer",
        opacity: available ? 1 : 0.75
      }}
    >
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <Typography>
            {isImmediate ? "فوری تا ۳۰ دقیقه" : `${startText} تا ${endText}`}
          </Typography>
          {!available && (
            <Stack
              sx={{
                px: 1,
                py: 0.5,
                borderRadius: "0.5rem",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFF4F4",
                ml: 1
              }}
            >
              <Typography
                fontSize="0.6875rem"
                fontWeight={600}
                color={(th) => th.palette.error.main}
              >
                ظرفیت تکمیل
              </Typography>
            </Stack>
          )}
        </Stack>
        <Radio checked={checked} color={"secondary"} />
      </Stack>
      {timeList.length > 0 && (
        <Collapse
          mountOnEnter
          unmountOnExit
          sx={{ width: "100%" }}
          in={checked}
        >
          <Stack
            sx={{
              width: "100%",
              borderTop: (th) => `1px solid ${th.palette.divider}`,
              padding: 0.5,
              flexDirection: "row",
              flexWrap: "wrap"
            }}
          >
            {timeList.map((timeItem) => {
              return (
                <Stack
                  key={timeItem.value}
                  sx={{
                    width: "50%",
                    p: 0.5
                  }}
                >
                  <Stack
                    component="div"
                    onClick={(e) => {
                      e.stopPropagation();
                      onTimeClick(timeItem.value);
                    }}
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: (th) => `1px solid ${th.palette.divider}`,
                      borderRadius: "0.5rem"
                    }}
                  >
                    <Typography ml={1}>{timeItem.label}</Typography>
                    <Radio
                      checked={activeTime === timeItem.value}
                      color={"secondary"}
                    />
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};

export default memo(TimeSlotItem);
