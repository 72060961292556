import Universal, { CookieGetOptions, CookieSetOptions } from 'universal-cookie'

const defaultOptions: CookieSetOptions = {
  path: '/',
  maxAge: 2 * 30 * 24 * 60 * 60, // two month
}

export class Cookie {
  private cookie: Universal

  constructor() {
    this.cookie = new Universal()
  }


  set(name: string, value: Parameters<typeof this.cookie.set>[1], options?: CookieSetOptions) {
    this.cookie.set(name, value, options || defaultOptions)
  }

  get<T>(name: string, options?: CookieGetOptions) {
    return this.cookie.get<T | undefined>(name, options)
  }

  remove(name: string, options?: CookieSetOptions) {
    this.cookie.remove(name, options || defaultOptions)
  }
}

const cookie = new Cookie()

export default cookie
