import createStyle from "@/utils/createStyle";

const styles = createStyle({
  input: {
    width: 0,
    height: 0,
    display: "none"
  },
  container: {
    width: "100%",
    height: "6rem",
    justifyContent: "center",
    alignItems: "center",
    border: `1px dashed #C9CCCF`,
    p: 1,
    position: "relative",
    borderRadius: "0.5rem",
    overflow: "hidden",
    cursor: "pointer"
  },
  imageContainer: {
    width: "calc(100% - 16px)",
    height: "calc(100% - 16px)",
    borderRadius: "0.5rem",
    position: "absolute",
    overflow: "hidden"
  },
  deleteIcon: {
    color: (th) => th.palette.primary.main,
    width: "1.5rem",
    height: "1.5rem",
    position: "absolute"
  },
  uploadIcon: {

    color: "#A9A9AA",
    fontSize: 35
  },
  uploadIconImg: {
    width: '3rem',
    height: '3rem',
    color: "#A9A9AA",
    fontSize: 35
  },
  blackLayout: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "#00000038"
  },
  previewImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
});

export default styles;
