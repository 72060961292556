import { GlobalErrorModel } from "@/utils/error-handling";
import { useCallback, useEffect, useMemo, useState } from "react";

/**
 *
 * @param errorModels put all the errors of react-query in it.
 *                    *** you have to pass this value with useMemo for better performance ***
 * @param fieldNames put all the field names of your form in it.
 *                    *** you have to pass this value with useMemo for better performance ***
 */
const useErrorHandler = (
  errorModels: (GlobalErrorModel | null)[],
  fieldNames: string[]
) => {
  /**
   * this is for time that you focus on the field or start typing and
   *  you wouldn't like the field to be red.
   */
  const [resolved, setResolved] = useState<{ [key: string]: boolean }>({});

  /**
   * whenever you submit the form, you must call this function to
   *  change colors of all fields from red to normal color.
   */
  const resolveAll = useCallback(() => {
    setResolved(() => {
      return fieldNames.reduce<{ [key: string]: boolean }>((result, key) => {
        result[key] = true;

        return result;
      }, {});
    });
  }, [fieldNames]);

  // this is for reseting form for dynamic forms.
  useEffect(resolveAll, [resolveAll]);

  /**
   * this is for time that you focus on the field or start typing and
   *  you wouldn't like the field to be red. you can use it in onFocus
   *  and onChange of the field.
   */
  const resolveField = useCallback((fieldName: string) => {
    setResolved((prevResolved) => {
      return { ...prevResolved, [fieldName]: false };
    });
  }, []);

  const pureErrors = useMemo(() => {
    return errorModels.reduce<{ [key: string]: string[] }>(
      (result, errorModel) => {
        if (errorModel !== null) {
          (errorModel?.messages || []).forEach((errorModelMessageItem) => {
            if (errorModelMessageItem.type === "field") {
              if (!(errorModelMessageItem.field_name in result)) {
                result[errorModelMessageItem.field_name] = [];
              }
              result[errorModelMessageItem.field_name] = result[
                errorModelMessageItem.field_name
              ].concat(errorModelMessageItem.message);
            }
          });
        }

        return result;
      },
      {}
    );
  }, [errorModels]);

  /**
   * this is the data you need pass to fields.
   * this is an array of object that contains two property.
   * error: represents if that specific field is in error state
   * helperText: contains the text of HelperText below of each field.
   */
  const errors = useMemo(() => {
    return fieldNames.reduce<{
      [key: string]: { error: boolean; helperText: string | undefined };
    }>((result, key) => {
      result[key] = {
        error:
          key in pureErrors &&
          Boolean(pureErrors[key].length) &&
          key in resolved &&
          resolved[key],
        helperText:
          key in pureErrors && Boolean(pureErrors[key].length) && resolved[key]
            ? pureErrors[key].join(" ")
            : undefined
      };

      return result;
    }, {});
  }, [fieldNames, pureErrors, resolved]);

  return { errors, resolveField, resolveAll };
};

export default useErrorHandler;
