import { FC, useEffect, useState } from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";
import OfferCard from "@/pages/concierge-sale-offer/components/OfferCard";
import { useGetExpertOfferList } from "@/services/api/concierge-sale/supervisor-offer";
import successIcon from "@/assets/icons/sentiment-satisfied.svg";
import { useNavigate } from "react-router";
import PokerFaceIcon from "@/assets/icons/poker-face.svg";

const ConciergeSaleOffer: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const [isSuccess, setIsSuccess] = useState(false);
  const { data, isLoading, refetch } = useGetExpertOfferList();
  const navigate = useNavigate();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("آفرها");
    }
  }, [setHeaderTitle]);
  return (
    <>
      <Stack
        sx={{
          width: "100%",
          p: 3,
          height: "100vh",
          overflowY: "scroll",
          bgcolor: "#FAFAFA"
        }}
      >
        {data?.length && !isLoading ? (
          data?.map((item) => (
            <OfferCard
              key={item.order_id}
              {...item}
              setIsOpenModal={setIsSuccess}
            />
          ))
        ) : (
          <Stack
            sx={{ justifyContent: "center", alignItems: "center", mt: 12 }}
          >
            <img src={PokerFaceIcon} width={80} alt="poker face icon" />
            <Typography sx={{ fontWeight: 600, fontSize: 16, mt: 2 }}>
              درحال حاضر آفر خرید جدیدی ندارید
            </Typography>
          </Stack>
        )}
      </Stack>

      <Modal
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "330px",
            bgcolor: "background.paper",
            outline: "none",
            borderRadius: "8px",
            px: 4,
            py: 2,
            textAlign: "center"
          }}
        >
          <Typography variant="body1" sx={{ lineHeight: "1.8" }}>
            <img src={successIcon} alt="success icon" />
            <br />
            سفارش با موفقیت به دستیار تخصیص داده شده است.
          </Typography>
          <Button
            sx={{ mt: 1, height: "auto", color: "#858585" }}
            variant="text"
            onClick={() => {
              setIsSuccess(false);
              refetch();
            }}
          >
            سایر سفارشات
          </Button>
          <Button
            sx={{ mt: 1, ml: 2, height: "auto" }}
            onClick={() => {
              setIsSuccess(false);
              navigate(`/concierge-sale/requests`, {
                replace: true
              });
            }}
          >
            سفارش های من
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ConciergeSaleOffer;
