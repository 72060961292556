import createStyle from '@/utils/createStyle'
import { keyframes } from '@emotion/react'
import { styled } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

export const styles = createStyle({
  container: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  barContainer: {
    width: '100%',
    height: '4px',
    borderRadius: '2px',
    backgroundColor: '#E4E4E4',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    marginTop: '16px',
  },
  barInner: {
    position: 'absolute',
    height: '100%',
    transition: 'width 0.3s',
    borderRadius: '2px',
  },
  warning: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '8px',
    alignSelf: 'flex-start',
  },
  detailsContainer: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '35px',
  },
  itemButton: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid #EEEEEE',
    padding: '14px',
  },
  itemButtonDetailsContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkCircleRoundIcon: {
    width: '20px',
    height: '20px',
    color: '#54B254',
    marginLeft: '8px',
  },
  seprator: {
    width: '100%',
    height: '1px',
    marginTop: '16px',
    marginBottom: '16px',
    backgroundColor: '#dfdfdf',
  },
  todayShifts: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '40px',
  },
  orangeWarning: {
    width: '100%',
    padding: '10px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'flex-start',
    backgroundColor: '#FAE8DF',
    borderRadius: '8px',
    marginBottom: '16px',
  },
})

const checkCircleRoundIconAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const CheckCircle = styled(CheckCircleRoundedIcon)`
  width: 20px;
  height: 20px;
  color: #54b254;
  margin-left: 8px;
  animation: ${checkCircleRoundIconAnimation} 0.8s;
`
