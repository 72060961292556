export function pmt(
  ir: number,
  np: number,
  pvInner: number,
  fv = 0,
  type = 0
) {
  /*
   * ir   - interest rate per month نرخ
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  let result;

  if (ir === 0) return -(pvInner + fv) / np;

  const pvif = Math.pow(1 + ir, np);
  result = (-ir * (pvInner * pvif + fv)) / (pvif - 1);

  if (type === 1) result /= 1 + ir;

  return result;
}

function convNumber(expr: number, decplaces: number) {
  let str = "" + Math.round(expr * Math.pow(10, decplaces));
  while (str.length <= decplaces) {
    str = "0" + str;
  }

  const decpoint = str.length - decplaces;
  return parseFloat(
    str.substring(0, decpoint) + "." + str.substring(decpoint, str.length)
  );
}

export function pv(rate: number, nper: number, innerPmt: number) {
  if (nper == 0) {
    alert("Why do you want to test me with zeros?");
    return 0;
  }
  let pvValue = 0;
  if (rate == 0) {
    // Interest rate is 0
    pvValue = -(innerPmt * nper);
  } else {
    const x = Math.pow(1 + rate, -nper);
    const y = Math.pow(1 + rate, nper);
    pvValue = -(x * (0 * rate - innerPmt + y * innerPmt)) / rate;
  }
  pvValue = convNumber(pvValue, 2);
  return pvValue;
}
