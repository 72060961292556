import createStyle from "@/utils/createStyle";

export const styles = createStyle({
  tabContentContainer: {
    width: "100%",
    padding: "17px 17px 50px",
    height: "100vh",
    overflowY: "scroll"
  },
  boxTitle: {
    color: "#6E6E6E",
    fontSize: "14px"
  },
  emptyRequestArea: {
    textAlign: "center",
    mt: 8
  },
  emptyRequestDesc: {
    mt: "12px",
    fontWeight: "bold",
    lineHeight: "1.8",
    textAlign: "center"
  }
});
