import { useRootLayout } from "@/hooks/useRootLayout";
import { useAuthInfo } from "@/store/slices/auth";

import { makeUrl } from "@/utils/routing";
import { Box, Tab, Tabs } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { NotLoggedIn } from "./NotLoggedIn";

import { OfferTab } from "./OfferTab";
import createStyle from "@/utils/createStyle";
import { AssignedJobs } from "../inspection-jobs-dynamic/AssignedJobs";
import { DoneJobs } from "../inspection-jobs-dynamic/DoneJobs";

const styles = createStyle({
  tab: {
    flexGrow: 1,
    flex: 1,
    background: (th) => th.palette.common.white
  },
  tabsBox: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: (th) => th.palette.common.white
  }
});

const Offer: FC = () => {
  const { setHeaderTitle, setBackUrl } = useRootLayout();
  const { isLoggedIn } = useAuthInfo();
  const [selectedTab, setTab] = useState<
    "offerList" | "assignedList" | "doneList"
  >("offerList");

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("آفرها و درخواست‌های کارشناسی");
    }
    // if (setBackUrl) {
    //   setBackUrl(makeUrl("/inspection/index"));
    // }
  }, [setHeaderTitle, setBackUrl]);

  if (!isLoggedIn) {
    return <NotLoggedIn />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        overflowY: "auto",
        height: "calc(100vh - 3rem)",
        position: "relative"
      }}
    >
      <Tabs
        onChange={(_, value) => setTab(value)}
        value={selectedTab}
        sx={styles.tabsBox}
      >
        <Tab sx={styles.tab} value="offerList" label="آفرها"></Tab>
        <Tab sx={styles.tab} value="assignedList" label="انجام نشده"></Tab>
        <Tab sx={styles.tab} value="doneList" label="انجام شده"></Tab>
      </Tabs>
      {selectedTab === "offerList" ? (
        <OfferTab />
      ) : selectedTab === "assignedList" ? (
        <AssignedJobs />
      ) : (
        <DoneJobs />
      )}
    </Box>
  );
};

export default Offer;
