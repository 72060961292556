import { FC, useState } from "react";
import { Button, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SpeedIcon from "@mui/icons-material/Speed";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import {
  GetOfferListResponse,
  usePostSupervisorAcceptOrder
} from "@/services/api/concierge-sale/supervisor-offer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";

interface Props extends GetOfferListResponse {
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OfferCard: FC<Props> = ({
  order_id,
  car_brand_model_fa,
  car_brand_model_year,
  usage,
  city,
  car_color,
  address,
  setIsOpenModal
}) => {
  const { mutateAsync } = usePostSupervisorAcceptOrder(order_id);

  const submitHandler = () => {
    mutateAsync({}).then(() => setIsOpenModal(true));
  };

  const [isCopyId, setIsCopyId] = useState(false);

  const copyToClipBoard = async (data: string) => {
    await navigator.clipboard.writeText(data).then(() => setIsCopyId(true));
  };

  return (
    <>
      <Stack
        sx={{
          border: "1px solid #C9CCCF",
          bgcolor: "#fff",
          p: 1.5,
          borderRadius: 2,
          mb: 2
        }}
      >
        <Stack
          sx={{
            borderBottom: "1px solid #C9CCCF",
            pb: 1,
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <DirectionsCarIcon sx={{ color: "#00A754" }} />
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              ml: 0.5,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {`${car_brand_model_fa} - ${car_brand_model_year}` || "-"}
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 1.5
          }}
        >
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <SpeedIcon sx={{ color: "#6E6E6E" }} />
            <Typography sx={{ color: "#202021", fontSize: 16, ml: 1 }}>
              {Number(usage).toLocaleString()} کیلومتر
            </Typography>
          </Stack>
          <Stack
            sx={{
              bgcolor: "#E0F4EA",
              fontSize: 15,
              pr: 1.5,
              py: 0.5,
              borderRadius: 1,
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <IconButton onClick={() => copyToClipBoard(String(order_id))}>
              <ContentCopyIcon sx={{ fontSize: 15 }} />
            </IconButton>
            {order_id}
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            mt: 1.5,
            justifyContent: "space-between"
          }}
        >
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <InvertColorsIcon sx={{ color: "#6E6E6E" }} />
            <Typography sx={{ color: "#202021", fontSize: 16, ml: 1 }}>
              {car_color || "-"}
            </Typography>{" "}
          </Stack>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <LocationCityOutlinedIcon sx={{ color: "#6E6E6E" }} />
            <Typography
              sx={{
                color: "#202021",
                fontSize: 16,
                ml: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {city || "-"}
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
          <PlaceOutlinedIcon sx={{ color: "#6E6E6E" }} />
          <Typography
            sx={{
              color: "#202021",
              fontSize: 16,
              ml: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {address || "-"}
          </Typography>
        </Stack>
        <Button size="large" onClick={submitHandler} sx={{ mt: 1.5 }}>
          قبول می کنم
        </Button>
      </Stack>

      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: "#00a754"
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isCopyId}
        onClose={() => setIsCopyId(false)}
        message="آیدی با موفقیت کپی شد"
      />
    </>
  );
};

export default OfferCard;
