import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";
import {
  CreateInvoice,
  usePostCreateInvoice
} from "@/services/api/concierge-sale/submit-deals";
import { toEngDigits } from "@/utils/toEnglishDigit";
import { useNavigate, useParams } from "react-router";
import toFarsiNumberText from "@/utils/toFarsiNumberText";
import { setDealTab } from "@/store/slices/deal";
import { useDispatch } from "react-redux";
import { concierge_sale_service_name } from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals/config";

const PaymentSendSms: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const { id } = useParams();
  const navigate = useNavigate();
  const { mutateAsync } = usePostCreateInvoice(Number(id));
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<CreateInvoice>({
    name: null,
    phone_number: null,
    amount: null,
    concierge_sale_id: null,
    service_name: ""
  });

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("ارسال پیامک کمیسیون");
    }
  }, [setHeaderTitle]);

  useEffect(() => {
    if (id) {
      setFormData((form) => ({ ...form, concierge_sale_id: +id }));
    }
  }, [id]);

  useEffect(() => {
    if (formData.service_name === "CONCIERGE_SALE_INSPECTION") {
      setFormData((f) => ({ ...f, amount: null }));
    }
  }, [formData.service_name]);

  const changeHandler = (event: {
    target: { name: string; value: string };
  }) => {
    setFormData((form) => ({
      ...form,
      [event.target.name]: +toEngDigits(event.target.value)
    }));
  };

  const submitHandler = () => {
    setIsLoading(true);
    mutateAsync({
      ...formData,
      business_type: "P",
      send_sms: true
    })
      .then(() => {
        setIsOpenModal(true);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          px: 3,
          py: 2.5,
          height: "100vh",
          overflowY: "scroll",
          background: "#FAFAFA"
        }}
        spacing={2}
      >
        <Typography fontSize={17} lineHeight="1.7">
          لطفا مشخصات خریدار و مبلغ کمیسیون کارنامه را وارد نمایید:
        </Typography>
        <TextField
          size="small"
          label="نام خریدار"
          name="name"
          value={formData.name || ""}
          onChange={(event) =>
            setFormData((form) => ({ ...form, name: event.target.value }))
          }
          sx={{ bgcolor: "#fff" }}
        />
        <TextField
          size="small"
          label="تلفن تماس خریدار"
          name="phone_number"
          value={formData.phone_number || ""}
          onChange={(event) =>
            setFormData((form) => ({
              ...form,
              phone_number: toEngDigits(event.target.value)
            }))
          }
          inputProps={{ inputMode: "numeric" }}
          sx={{ bgcolor: "#fff" }}
        />
        <TextField
          size="small"
          label="کد فروش سفارشی"
          name="concierge_sale_id"
          value={formData.concierge_sale_id || ""}
          onChange={changeHandler}
          sx={{ bgcolor: "#fff" }}
          inputProps={{ inputMode: "numeric" }}
        />
        <Stack spacing={1}>
          <TextField
            size="small"
            label="مبلغ کمیسیون کارنامه (تومان)"
            name="amount"
            value={formData.amount || ""}
            onChange={changeHandler}
            sx={{ bgcolor: "#fff" }}
            inputProps={{ inputMode: "numeric" }}
            disabled={formData.service_name === "CONCIERGE_SALE_INSPECTION"}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "13px",
              color: "#694FB4",
              fontWeight: 500
            }}
          >
            {formData.amount
              ? `${toFarsiNumberText(
                  parseInt(toEngDigits(String(formData.amount)))
                )}`
              : ""}
          </Typography>
        </Stack>
        <FormControl>
          <Typography fontWeight={600} mb={1} fontSize={17}>
            انتخاب نوع سرویس:
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={(_e, newValue) => {
              setFormData((f) => ({ ...f, service_name: newValue }));
            }}
          >
            {concierge_sale_service_name.map((item) => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.name}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Stack>

      <Stack
        sx={{
          position: "fixed",
          bottom: 0,
          p: 2,
          width: "100%",
          bgcolor: "#fff",
          borderTop: "1px solid #ebebeb"
        }}
      >
        <Button
          onClick={submitHandler}
          disabled={
            formData.service_name === "CONCIERGE_SALE_INSPECTION"
              ? !formData.phone_number ||
                !formData.concierge_sale_id ||
                isLoading
              : !formData.phone_number ||
                !formData.concierge_sale_id ||
                !formData.amount ||
                !formData.service_name ||
                isLoading
          }
          size="large"
        >
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: "#00000050" }} />
          ) : (
            "ارسال لینک پرداخت"
          )}
        </Button>
      </Stack>

      <Modal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            bgcolor: "background.paper",
            outline: "none",
            borderRadius: "8px",
            px: 4,
            py: 2,
            textAlign: "center"
          }}
        >
          <Typography variant="body1" sx={{ lineHeight: "1.8", fontSize: 17 }}>
            لینک پرداخت با موفقیت ارسال شد
          </Typography>
          <Button
            sx={{ mt: 2, px: 3, height: "auto" }}
            onClick={() => {
              dispatch(setDealTab("payments"));
              setIsOpenModal(false);
              navigate(`/concierge-sale/requests/${id}/submit-deals`, {
                replace: true
              });
            }}
            size="small"
          >
            تایید
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentSendSms;
