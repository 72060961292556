import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { ImageUploaderProps } from ".";

const useLogic = (
  props: Pick<ImageUploaderProps, "value" | "onChange" | "variant">
) => {
  const { value, onChange, variant = "upload" } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const [isSelectVariantOpen, setIsSelectVariantOpen] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  const variantsList = useMemo(() => {
    return typeof variant === "string" ? [variant] : variant;
  }, [variant]);

  const onChangeCaller = useCallback(
    (newFile: File | null) => {
      if (typeof onChange === "function") {
        if (newFile) {
          onChange({
            url: URL.createObjectURL(newFile),
            file: newFile
          });
        } else {
          onChange(null);
        }
      }
    },
    [onChange]
  );

  const imageUrl = useMemo(() => {
    if (value) {
      if (typeof value === "string") {
        return value;
      } else {
        return URL.createObjectURL(value);
      }
    }
    return null;
  }, [value]);

  const onInputChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        onChangeCaller(file);
      }
    },
    [onChangeCaller]
  );

  const onContainerClicked = useCallback(() => {
    if (value) {
      onChangeCaller(null);
    } else if (
      (variant === "upload" ||
        (variantsList.length === 1 && variantsList[0] === "upload")) &&
      inputRef.current
    ) {
      inputRef.current.click();
    } else if (
      variant === "camera" ||
      (variantsList.length === 1 && variantsList[0] === "camera")
    ) {
      setIsCameraOpen(true);
    } else {
      setIsSelectVariantOpen(true);
    }
  }, [onChangeCaller, value, variant, variantsList]);

  const openCamera = useCallback(() => {
    setIsSelectVariantOpen(false);
    setIsCameraOpen(true);
  }, []);

  const openUpload = useCallback(() => {
    if (inputRef.current) {
      setIsSelectVariantOpen(false);
      inputRef.current.click();
    }
  }, []);

  return {
    inputRef,
    isCameraOpen,
    setIsCameraOpen,
    isSelectVariantOpen,
    setIsSelectVariantOpen,
    variantsList,
    imageUrl,
    onInputChangeHandler,
    onContainerClicked,
    openCamera,
    openUpload,
    onChangeCaller,
    date,
    setDate
  };
};

export default useLogic;
