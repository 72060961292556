import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CallMissedOutlinedIcon from "@mui/icons-material/CallMissedOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";

export function NoteStatus(status: string) {
  if (status === "note") {
    return <DescriptionOutlinedIcon sx={styles.descIcon} />;
  } else if (status === "missed-call") {
    return <CallMissedOutlinedIcon sx={styles.missCallIcon} />;
  } else if (status === "price-agreement") {
    return <MonetizationOnOutlinedIcon sx={styles.priceIcon} />;
  } else if (status === "visit") {
    return <EventNoteOutlinedIcon sx={styles.commentIcon} />;
  } else if (status === "car-status") {
    return <ListAltOutlinedIcon sx={styles.carStatusIcon} />;
  } else if (status === "call") {
    return <PhoneEnabledOutlinedIcon sx={styles.customerCallIcon} />;
  } else if (status === "note-call") {
    return <PhoneEnabledOutlinedIcon sx={styles.customerCallIcon} />;
  } else {
    return "";
  }
}
