import { http2, httpLeasing } from "@/services/http-clients";
import {
  MutationFunction,
  QueryFunction,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery
} from "react-query";

export const getLeasingLoanConfig = (params: GetLeasingLoanConfigParams) => {
  return http2<GetLeasingLoanConfigResponse>({
    method: "GET",
    url: "/leasing/v1/loan-config",
    params
  })
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      const paybackPeriodList = Object.keys(
        data.instalment_period_to_interest
      ).map((paybackPeriodItemKey) => {
        return {
          value: Number(paybackPeriodItemKey),
          dev_coefficient: Number(
            data.instalment_period_to_interest[paybackPeriodItemKey]
          )
        };
      });
      return {
        paybackPeriodList,
        sellerShare: data.seller_price,
        karnamehCommition: data.sale_karnameh_commission,
        postPrice: data.advertisement_price,
        karnamehCommitionPercent: data.karnameh_commission_percent,
        minLeasing: data.minimum_amount,
        maxLeasing: data.max_loan_price,
        minPrePaymentSharePercent: data.min_prepayment_price_percent,
        rate: data.loan_interest_rate,
        paymentPeriod: 3,
        salesExpertPhoneNumber: data.sales_expert_phone_number,
        nationality: data.nationality
      };
    });
};

export const getLeasingLoanConfigKey = (
  params: Parameters<typeof getLeasingLoanConfig>[0]
) => {
  return [
    "/leasing/v1/loan-config",
    params.concierge_sale_id || "",
    params.divar_post_token || ""
  ];
};

export const useGetLeasingLoanConfig = (
  params: Parameters<typeof getLeasingLoanConfig>[0],
  options?: UseQueryOptions<Awaited<ReturnType<typeof getLeasingLoanConfig>>>
) => {
  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLeasingLoanConfig>>
  > = () => {
    return getLeasingLoanConfig(params);
  };

  const queryKey = getLeasingLoanConfigKey(params);

  return useQuery({
    queryFn,
    queryKey,
    ...options
  });
};

const getSendUploadDocsSms = (params: GetSendUploadDocsSmsParams) => {
  return httpLeasing({
    method: "GET",
    url: '/api/v1/orders/assistant/upload-document-send-sms/',
    params: {
      'id': params.leasingOrderID,
    }
  });
};

export const getSendUploadDocsSmsKey = (params: GetSendUploadDocsSmsParams) => {
  return ["getSendUploadDocsSms", params.leasingOrderID];
};

export const useGetSendUploadDocsSms = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof getSendUploadDocsSms>>,
    unknown,
    GetSendUploadDocsSmsParams
  >
) => {
  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getSendUploadDocsSms>>,
    GetSendUploadDocsSmsParams
  > = (params) => {
    return getSendUploadDocsSms(params);
  };

  return useMutation({
    mutationFn,
    ...options
  });
};

export const getConciergeSaleCarStatus = (
  params: GetConciergeSaleCarStatusParams
) => {
  return http2<GetConciergeSaleCarStatusResponse>({
    method: "GET",
    url: `/car-health/${params.conciergeSaleId}`
  }).then((res) => res.data);
};

export const getConciergeSaleCarStatusKey = (
  params: GetConciergeSaleCarStatusParams
) => ["getConciergeSaleCarStatus", params.conciergeSaleId];

export const useGetConciergeSaleCarStatus = (
  params: GetConciergeSaleCarStatusParams,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getConciergeSaleCarStatus>>
  >
) => {
  const queryFn = () => {
    return getConciergeSaleCarStatus(params);
  };

  const queryKey = getConciergeSaleCarStatusKey(params);

  return useQuery({
    queryFn,
    queryKey,
    ...options
  });
};

export const putConciergeSaleCarStatus = (
  conciergeSaleId: string,
  params: PutConciergeSaleCarStatusParams
) => {
  return http2({
    method: "PUT",
    url: `/car-health/${conciergeSaleId}`,
    data: params
  }).then((res) => res.data);
};

export const usePutConciergeSaleCarStatus = (
  conciergeSaleId: string,
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof putConciergeSaleCarStatus>>,
    unknown,
    PutConciergeSaleCarStatusParams
  >
) => {
  const mutationFn = (params: PutConciergeSaleCarStatusParams) => {
    return putConciergeSaleCarStatus(conciergeSaleId, params);
  };

  return useMutation({
    mutationFn,
    ...options
  });
};

export const postConciergeSaleSendToPricing = (
  params: PostConciergeSaleSendToPricingParams
) => {
  return http2({
    method: "POST",
    url: `/sales-expert/${params.conciergeSaleId}/send-to-pricing/`
  }).then((res) => res.data);
};

export const usePostConciergeSaleSendToPricing = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof postConciergeSaleSendToPricing>>,
    undefined,
    PostConciergeSaleSendToPricingParams
  >
) => {
  const mutationFn = (params: PostConciergeSaleSendToPricingParams) => {
    return postConciergeSaleSendToPricing(params);
  };

  return useMutation({
    mutationFn,
    ...options
  });
};
