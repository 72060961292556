import LeasingCalculator from "@/components/LeasingCalculator";
import { useGetLeasingLoanConfig } from "@/services/api/leasing";
import { Stack } from "@mui/material";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

const LeasingCalculatorPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data: loanConfig, isError: isLoanConfigError } =
    useGetLeasingLoanConfig(
      { concierge_sale_id: id },
      {
        enabled: typeof id === "string",
        retry: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
      }
    );

  useEffect(() => {
    if (isLoanConfigError) {
      navigate(-1);
      toast.error("برای این ماشین شرایط لیزینگ وجود ندارد.", {
        position: "top-right",
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
  }, [isLoanConfigError, navigate]);

  return (
    <Stack
      sx={{
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        p: 3,
        maxHeight: "100vh",
        overflow: "auto"
      }}
    >
      {loanConfig && (
        <LeasingCalculator defaultPrepayment={0} {...loanConfig} />
      )}
    </Stack>
  );
};

export default LeasingCalculatorPage;
