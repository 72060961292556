import { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { tableData } from "@/pages/ConciergeSale/costs-report/data";
import { GetCostsReportResponse } from "@/services/api/concierge-sale/costs-report";

const CostsReportTable: FC<GetCostsReportResponse> = (data) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "8px",
        border: "1px solid #EBEBEB",
        boxShadow: "none"
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead sx={{ background: "#EDEBF4" }}>
          <TableRow>
            <TableCell
              sx={{ fontSize: "15px", lineHeight: "1.7" }}
              align="center"
            >
              عنوان
            </TableCell>
            <TableCell
              sx={{ fontSize: "15px", lineHeight: "1.7" }}
              align="center"
            >
              تعداد
            </TableCell>
            <TableCell
              sx={{ fontSize: "15px", lineHeight: "1.7" }}
              align="center"
            >
              قیمت (تومان)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((item) => (
            <TableRow
              key={item.title}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left" sx={{ lineHeight: "1.7" }}>
                {item.title}
              </TableCell>
              <TableCell align="center" sx={{ lineHeight: "1.7" }}>
                {(data as GetCostsReportResponse)?.[
                  item.count as keyof typeof data
                ] || "-"}
              </TableCell>
              <TableCell align="right" sx={{ lineHeight: "1.7" }}>
                {Number(
                  (data as GetCostsReportResponse)?.[
                    item.price as keyof typeof data
                  ]
                ).toLocaleString() || "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CostsReportTable;
