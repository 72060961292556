import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import useLogic from "@/components/CarSearchTextFeild/useLogic";
import { FC } from "react";
import { Car, FindCarsServiceNames } from "@/services/api/searchCar";
import styles from "@/components/CarSearchTextFeild/styles";
import { searchInArray } from "@/utils/search";

interface PropTypes {
  initialValue?: Car | null;
  value?: Car | null;
  onChange: (value: Car | null) => void;
  serviceName?: FindCarsServiceNames;
  size?: "medium" | "small";
}

const CarSearchTextFeild: FC<PropTypes> = (props) => {
  const {
    initialValue = null,
    value = null,
    onChange,
    serviceName = "concierge_buy",
    size = "medium"
  } = props;

  const {
    car,
    setCar,
    inputValue,
    setInputValue,
    inputFocus,
    setInputFocus,
    autoCompleteRef,
    fetchCarData,
    isFetchCarLoading,
    isFindCarLoading,
    autoCompleteOptions,
    hasSearchResult
  } = useLogic(initialValue, serviceName, null);

  return (
    <Autocomplete
      size={size}
      ref={autoCompleteRef}
      open={inputFocus}
      disabled={!fetchCarData}
      disablePortal
      onOpen={() => setInputFocus(true)}
      loading={isFindCarLoading || isFetchCarLoading}
      loadingText={
        <Stack sx={styles.loading}>
          <CircularProgress size={15} sx={styles.circularProgress} />
          <Typography sx={styles.searchText}>در حال جستجو</Typography>
        </Stack>
      }
      id="combo-search-car"
      onFocus={() => setInputFocus(true)}
      noOptionsText={
        <Stack sx={styles.notFound}>
          {inputValue?.length < 3 ? "حداقل سه حرف وارد کنید" : "موردی یافت نشد"}
        </Stack>
      }
      onBlur={() => {
        setInputFocus(false);
        if (car === null) {
          setInputValue("");
        }
      }}
      sx={styles.input}
      options={autoCompleteOptions}
      getOptionLabel={(option) => option.name}
      filterOptions={(options, { inputValue: value }) => {
        return searchInArray(options, value, (item) => [item.name]);
      }}
      isOptionEqualToValue={(option, value) =>
        option.name_en === value.name_en &&
        option.last_level === value.last_level &&
        option.last_level_id === value.last_level_id
      }
      value={value || car}
      defaultValue={initialValue || undefined}
      onChange={(_, newValue) => {
        if (newValue === null && car !== null) {
          setCar(null);
          onChange(null);
        }
        // if (
        //   newValue?.last_level === "type" ||
        //   newValue?.last_level === "model" ||
        //   (newValue?.last_level === "brand" && newValue.name === inputValue)
        // )
        else if (hasSearchResult) {
          setCar(newValue);
          onChange(newValue);
          setInputFocus(false);
          if (autoCompleteRef.current) {
            autoCompleteRef.current.blur();
          }
        }
      }}
      onSelect={(e) => {
        setInputValue((e.target as HTMLInputElement).value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // sx={{ mb: 3 }}
          label="برند - مدل - تیپ *"
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
    />
  );
};

export default CarSearchTextFeild;
