import useAppSelector from "@/hooks/useAppSelector";
import { LeasingStateActionItem } from "@/services/api/leasing/requests";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export interface State {
  isImageDrawerOpen: boolean;
  imageUrl: string;
  imageId: string;
  orderId: string;
  dynamicInputDrawer: {
    isOpen: boolean;
    action?: LeasingStateActionItem;
  };
  mandatoryActionsDrawer: {
    isOpen: boolean;
    notMandatory?: boolean;
    actions: LeasingStateActionItem[];
    onChangeStateClicked?: () => void;
  };
}

const initialState: State = {
  isImageDrawerOpen: false,
  imageUrl: "",
  imageId: "",
  orderId: "",
  dynamicInputDrawer: {
    isOpen: false
  },
  mandatoryActionsDrawer: {
    isOpen: false,
    actions: []
  }
};

const leasingSlice = createSlice({
  name: "leasing-slice",
  initialState,
  reducers: {
    setImageDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isImageDrawerOpen = action.payload;
    },
    setImageUrl(state, action: PayloadAction<string>) {
      state.imageUrl = action.payload;
    },
    setImageId(state, action: PayloadAction<string>) {
      state.imageId = action.payload;
    },
    setOrderId(state, action: PayloadAction<string>) {
      state.imageId = action.payload;
    },
    dynamicInputDrawerStateHandler: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        action?: LeasingStateActionItem;
      }>
    ) => {
      state.dynamicInputDrawer.isOpen = action.payload.isOpen;
      state.dynamicInputDrawer.action = action.payload.action;
    },
    mandatoryActionsDrawerStateHandler: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        actions: LeasingStateActionItem[];
        onChangeStateClicked?: () => void;
        notMandatory?: boolean;
      }>
    ) => {
      state.mandatoryActionsDrawer.isOpen = action.payload.isOpen;
      state.mandatoryActionsDrawer.actions = action.payload.actions;
      state.mandatoryActionsDrawer.notMandatory = action.payload.notMandatory;
      state.mandatoryActionsDrawer.onChangeStateClicked =
        action.payload.onChangeStateClicked;
    }
  }
});

export const leasingReducer = leasingSlice.reducer;
export const leasingActions = leasingSlice.actions;

export const useLeasingImage = () => {
  const dispatch = useDispatch();
  const { imageId, imageUrl, isImageDrawerOpen, orderId } = useAppSelector(
    (state) => state.leasing
  );

  const setOrderId = useCallback(
    (value: string) => {
      dispatch(leasingActions.setOrderId(value));
    },
    [dispatch]
  );

  const setImageId = useCallback(
    (value: string) => {
      dispatch(leasingActions.setImageId(value));
    },
    [dispatch]
  );
  const setImageUrl = useCallback(
    (value: string) => {
      dispatch(leasingActions.setImageUrl(value));
    },
    [dispatch]
  );
  const setImageDrawerOpen = useCallback(
    (value: boolean) => {
      dispatch(leasingActions.setImageDrawerOpen(value));
    },
    [dispatch]
  );

  return {
    imageId,
    imageUrl,
    isImageDrawerOpen,
    orderId,
    setOrderId,
    setImageId,
    setImageUrl,
    setImageDrawerOpen
  };
};
