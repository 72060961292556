import { Stack } from "@mui/material";
import { FC, PropsWithChildren, lazy } from "react";
import styles from "./styles";
import useLogic from "./useLogic";
import { SVGAdapterContext, SVGPaintObj } from "./configs";

interface PropTypes {
  paint?: SVGPaintObj;
  onClick?: (partKey: string) => void;
  watchMode?: boolean;
}

const SvgAdapter: FC<PropsWithChildren<PropTypes>> = (props) => {
  const { children, paint = {}, onClick, watchMode = false } = props;

  const { wrapperRef, componentId, classesContent } = useLogic(
    paint,
    onClick,
    watchMode
  );

  return (
    <SVGAdapterContext.Provider value={{ onClick }}>
      <Stack
        ref={wrapperRef}
        className={componentId.current}
        sx={styles.container}
      >
        {children}
      </Stack>
      <style>{`${classesContent.join("")}`}</style>
    </SVGAdapterContext.Provider>
  );
};

export default SvgAdapter;
