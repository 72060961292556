import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import cornerLogo from "./corner-logo.svg";

const useLogic = (
  open: boolean,
  setOpen: (newOpen: boolean) => void,
  initialWidth: number,
  initialHeight: number,
  fileName: string,
  onSubmit: ((cameraImage: CameraImage) => void) | undefined,
  submitType: "submit" | "save-and-submit" | "save"
) => {
  const width = 1920;
  const height = (width * initialHeight) / initialWidth;

  const [show, setShow] = useState(open);
  const showTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [image, setImage] = useState<CameraImage>({
    url: null,
    file: null,
    dataUrl: null
  });

  const cameraRef = useRef<Webcam>(null);

  const clearImage = useCallback(() => {
    setImage({
      dataUrl: null,
      file: null,
      url: null
    });
  }, []);

  useEffect(() => {
    if (typeof showTimeOut.current === "number") {
      clearTimeout(showTimeOut.current);
    }
    showTimeOut.current = setTimeout(
      () => {
        setShow(open);
        if (!open) {
          clearImage();
        }
      },
      open ? 100 : 500
    );
  }, [clearImage, open]);

  const capture = useCallback(async () => {
    if (cameraRef.current) {
      const initialImageDataUrl = cameraRef.current.getScreenshot();
      if (initialImageDataUrl) {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        if (canvas) {
          const context = canvas.getContext("2d");
          const imgg = new Image();
          imgg.src = initialImageDataUrl;
          imgg.onload = async () => {
            if (canvas) {
              if (context) {
                const hRatio = width / imgg.width;
                const vRatio = height / imgg.height;
                const ratio = Math.max(hRatio, vRatio);
                const centerShiftX = (width - imgg.width * ratio) / 2;
                const centerShiftY = (height - imgg.height * ratio) / 2;
                context.clearRect(0, 0, width, height);
                context.drawImage(
                  imgg,
                  0,
                  0,
                  imgg.width,
                  imgg.height,
                  centerShiftX,
                  centerShiftY,
                  imgg.width * ratio,
                  imgg.height * ratio
                );
                const logoImage = new Image();
                logoImage.src = cornerLogo;
                logoImage.onload = async () => {
                  context.drawImage(
                    logoImage,
                    0,
                    0,
                    logoImage.width,
                    logoImage.height,
                    20,
                    20,
                    logoImage.width,
                    logoImage.height
                  );

                  const imageDataUrl = canvas.toDataURL();
                  const downloadRes = await fetch(imageDataUrl).then((res) =>
                    res.blob()
                  );
                  const imageFile = new File([downloadRes], fileName, {
                    type: "image/jpeg"
                  });
                  const imageUrl = URL.createObjectURL(imageFile);
                  setImage({
                    dataUrl: imageDataUrl,
                    file: imageFile,
                    url: imageUrl
                  });
                };
              }
            }
          };
        }
      }
    }
  }, [fileName, height, width]);

  const saveImage = useCallback(() => {
    if (typeof image.url === "string") {
      const downloadLink = document.createElement("a");
      downloadLink.href = image.url;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [fileName, image]);

  const onCancleHandler = useCallback(() => {
    clearImage();
    setOpen(false);
  }, [clearImage, setOpen]);

  const onSubmitHandler = useCallback(() => {
    if (typeof onSubmit !== "undefined") {
      if (submitType === "save" || submitType === "save-and-submit") {
        saveImage();
      }
      if (submitType === "submit" || submitType === "save-and-submit") {
        onSubmit(image);
      }
    }

    onCancleHandler();
  }, [image, onCancleHandler, onSubmit, saveImage, submitType]);

  return {
    width,
    height,
    show,
    setShow,
    showTimeOut,
    image,
    setImage,
    cameraRef,
    clearImage,
    capture,
    saveImage,
    onCancleHandler,
    onSubmitHandler
  };
};

export default useLogic;
