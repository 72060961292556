import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    alignSelf: "center",
    maxWidth: "500px",
    width: "100%",
    maxHeight: '100vh',
    overflow: 'auto',
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "wrap",
    pt: "1rem",
    px: "0.5rem",
    pb: '5rem',
  },
  stencilItemContainer: {
    width: "50%",
    flexBasis: "50%",
    mb: "1rem",
    px: "0.5rem",
  },
  resetContainer: {
    width: '100%',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: (th) => `1px solid ${th.palette.grey[200]}`,
    bottom: 0,
    left: 0,
    p: '1rem',
    backgroundColor: (th) => th.palette.background.default
  },
  resetButton: {
    width: '100%',
    maxWidth: '31.25rem'
  }
});

export default styles;
