import { useCallback, useEffect, useState } from 'react'

const useDocumentHeight = () => {
  const getHeight = useCallback(
    () => (window?.visualViewport ? window.visualViewport.height : window.innerHeight),
    [],
  )

  const getOffset = useCallback(() => window?.visualViewport?.offsetTop, [])

  const [height, setHeight] = useState<number | undefined>(undefined)
  const [offset, setOffset] = useState<number | undefined>(undefined)

  useEffect(() => {
    setHeight(getHeight())
    setOffset(getOffset())

    const handleResize = () => {
      setHeight(getHeight())
      setOffset(getOffset())
      setTimeout(() => {
        setHeight(getHeight())
        setOffset(getOffset())
      }, 500)
    }

    window.addEventListener('scroll', handleResize)

    window.addEventListener('resize', handleResize)
    // From the top of my head this used to be required for older browsers since
    // this didn't trigger a resize event. Keeping it in to be safe.
    window.addEventListener('orientationchange', handleResize)
    // This is needed on iOS to resize the viewport when the Virtual/OnScreen
    // Keyboard opens. This does not trigger any other event, or the standard
    // resize event.
    window.visualViewport?.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('scroll', handleResize)
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
      window.visualViewport?.removeEventListener('resize', handleResize)
    }
  }, [getHeight, getOffset])

  return { height, offset }
}

export default useDocumentHeight
