import { FC, useState } from "react";
import { Box, Stack } from "@mui/material";
import SubmitDeal from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals/views/deal";
import DealPayments from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals/views/payment";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import { styles } from "@/pages/ConciergeSale/requests/detail/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { setDealTab, tabList } from "@/store/slices/deal";

const SubmitDeals: FC = () => {
  const tabItem = useSelector((state: RootState) => state.deal.tabItem);
  const dispatch = useDispatch();

  const tabItems = [
    {
      key: "deals",
      label: "ثبت معامله",
      icon: <HandshakeOutlinedIcon sx={styles.icon} />,
      render: () => <SubmitDeal />
    },
    {
      key: "payments",
      label: "پرداخت ها",
      icon: <LocalAtmOutlinedIcon sx={styles.icon} />,
      render: () => <DealPayments />
    }
  ];

  const RenderComponent = tabItems.find((item) => item.key === tabItem)?.render;

  return (
    <>
      <Stack sx={styles.tabNavContainer} direction="row">
        {tabItems.map((item) => {
          return (
            <Box
              key={item.key}
              onClick={() => dispatch(setDealTab(item.key as tabList))}
              sx={{
                ...styles.tabNavItem,
                borderBottom:
                  tabItem === item.key
                    ? "2px solid #00A754"
                    : "2px solid transparent",
                color: tabItem === item.key ? "#1C1B1F" : "#49454F",
                fontWeight: tabItem === item.key ? "bold" : "normal"
              }}
            >
              {item.icon}
              {item.label}
            </Box>
          );
        })}
      </Stack>

      {RenderComponent && <RenderComponent />}
    </>
  );
};

export default SubmitDeals;
