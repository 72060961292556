import { FC, useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Typography,
  Stack,
  Button,
  CircularProgress,
  Modal
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import CarSearchTextFeild from "@/components/CarSearchTextFeild";
import { Car } from "@/services/api/searchCar";
import { useGetSaleDetailV2, useUpdateInfoData } from "@/services/api/sales";
import { useNavigate, useParams } from "react-router";
import { appUrl } from "@/utils/env";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
}

const EditBrandModel: FC<Props> = ({ open, showModal, title }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [brandModel, setBrandModel] = useState<Car | null>(null);
  const [editAds, setEditAds] = useState(false);
  const { data, refetch } = useGetSaleDetailV2(Number(id));
  const { mutateAsync } = useUpdateInfoData(String(id));
  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    mutateAsync({
      car_brand_model: String(brandModel?.name_en)
    }).then(() => {
      if (data?.car_post) {
        setLoading(false);
        setEditAds(true);
      } else {
        refetch();
        setLoading(false);
        showModal(false);
      }
    });
  };

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => showModal(false)}
        onOpen={() => showModal(true)}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <Box sx={styles.pirceModal}>
          <Box sx={styles.modalTitleArea}>
            <Typography fontWeight={600} fontSize={14}>
              {title}
            </Typography>
            <CloseIcon onClick={() => showModal(false)} />
          </Box>
          <Stack sx={{ display: "flex", flexDirection: "column" }}>
            <CarSearchTextFeild
              initialValue={brandModel}
              serviceName="inspection"
              size="small"
              onChange={(newCar: Car | null) => {
                setBrandModel(newCar);
              }}
            />
            <Button
              disabled={!brandModel || loading}
              onClick={submitHandler}
              sx={{ mt: 2 }}
            >
              {!loading && "ثبت تغییرات"}
              {loading && (
                <CircularProgress size={20} sx={{ color: "#00000050" }} />
              )}
            </Button>
          </Stack>
        </Box>
      </SwipeableDrawer>
      <Modal
        open={editAds}
        onClose={() => setEditAds(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "320px",
            bgcolor: "background.paper",
            outline: "none",
            borderRadius: "8px",
            px: 3,
            py: 2,
            textAlign: "center"
          }}
        >
          <Typography
            variant="body1"
            sx={{ lineHeight: "1.85", fontWeight: 500, fontSize: "15px" }}
          >
            تغییرات برند، مدل و تیپ خودرو با موفقیت ثبت شد.
            <br />
            لطفاً عنوان آگهی را نیز ویرایش نمایید.{" "}
          </Typography>
          <Button
            sx={{ mt: 1.5, px: 3, height: "auto", width: "100%" }}
            onClick={() => {
              window.open(
                `${appUrl}/services/car-post-details-funnel/${data?.car_post_token}/car-pictures?conciergeSaleId=${data?.id}`,
                "_parent"
              );
            }}
          >
            ویرایش آگهی
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default EditBrandModel;
