import { ErrorType, httpLeasing } from "@/services/http-clients";
import {
  MutationFunction,
  QueryFunction,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery
} from "react-query";

//#region leasing validation send otp
const postLeasingValidationSendOtp = (
  params: PostLeasingValidationSendOtpParams
) => {
  return httpLeasing<PostLeasingValidationSendOtpResponse>({
    method: "POST",
    url: "/api/v1/orders/register/",
    data: params
  }).then((res) => res.data);
  // return http2<PostLeasingValidationSendOtpResponse>({
  //   method: "POST",
  //   url: "/api/v1/finnotech/verification/verify",
  //   data: params
  // }).then((res) => res.data);
};

export const usePostLeasingValidationSendOtp = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof postLeasingValidationSendOtp>>,
    unknown,
    PostLeasingValidationSendOtpParams
  >
) => {
  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLeasingValidationSendOtp>>,
    PostLeasingValidationSendOtpParams
  > = (params) => {
    return postLeasingValidationSendOtp(params);
  };
  return useMutation({ mutationFn, ...options });
};
//#endregion

// #region leasing instalment months
const getInstalmentMonths = (params: GetLeasingInstalmentConfigParams) => {
  return httpLeasing<GetLeasingInstalmentMonthsConfigResponse>({
    method: "GET",
    url: "/api/v1/providers/instalment-months/",
    params
  }).then((res) => res.data);
};

export const useInstalmentMonths = <
  TData = Awaited<ReturnType<typeof getInstalmentMonths>>,
  TError = ErrorType<void>
>(
  params: GetLeasingInstalmentConfigParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInstalmentMonths>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? [
    "/api/v1/providers/instalment-months/",
    params
  ];
  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInstalmentMonths>>
  > = () => getInstalmentMonths(params);

  return useQuery<
    Awaited<ReturnType<typeof getInstalmentMonths>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);
};
// endregion

//#region getProviderPackages
export const getProviderPackages = (params: GetProviderPackagesParams) => {
  return httpLeasing<GetProviderPackagesResponse>({
    method: "GET",
    url: "/api/v1/providers/plans/",
    params
  }).then((res) => {
    return res.data;
  });
};

export const getProviderPackagesKey = (params: GetProviderPackagesParams) => [
  "getProviderPackages",
  params.installment_month_count,
  params.car_price
];

export const useGetProviderPackages = (
  params: GetProviderPackagesParams,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getProviderPackages>>>
) => {
  const queryFn = () => {
    return getProviderPackages(params);
  };

  const queryKey = getProviderPackagesKey(params);

  return useQuery({
    queryFn,
    queryKey,
    ...options
  });
};
//#endregion

//#region getInstallmentInfo
export const getInstallmentInfo = (params: GetInstallmentInfoParams) => {
  return httpLeasing<GetInstallmentInfoResponse>({
    url: `/api/v1/providers/${params.id}/conditions/`
  }).then((res) => res.data);
};

export const getInstallmentInfoKey = (params: GetInstallmentInfoParams) => {
  return ["getInstallmentInfo", params.id];
};

export const useGetInstallmentInfo = (
  params: GetInstallmentInfoParams,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getInstallmentInfo>>>
) => {
  const queryFn = () => {
    return getInstallmentInfo(params);
  };

  const queryKey = getInstallmentInfoKey(params);

  return useQuery({ queryFn, queryKey, ...options });
};
//#endregion

// export const getProviderList = () => {
//   return leasingClient<ProviderList[]>({
//     url: "/api/v1/providers/?channel=B"
//   });
// };

export const getProviderList = () => {
  return httpLeasing<GetProviderPackagesResponse>({
    method: "GET",
    url: "/api/v1/providers/"
  }).then((res) => {
    return res.data;
  });
};

export const getProviderListKey = () => ["getProviderList"];

export const useGetProviderList = (
  options?: UseQueryOptions<Awaited<ReturnType<typeof getProviderList>>>
) => {
  const queryFn = () => {
    return getProviderList();
  };

  const queryKey = getProviderListKey();

  return useQuery({
    queryFn,
    queryKey,
    ...options
  });
};
