import { FC, useEffect } from "react";
import { Stack } from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";
import { useGetAllSupervisorSaleExperts } from "@/services/api/concierge-sale/supervisor-offer";
import AssistantOfferCard from "@/pages/concierge-sale-supervisor/components/AssistantOfferCard";

const AssistantList: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const { data } = useGetAllSupervisorSaleExperts();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("لیست دستیارها");
    }
  }, [setHeaderTitle]);
  return (
    <Stack
      sx={{
        width: "100%",
        px: 2.5,
        py: 2,
        height: "100vh",
        overflowY: "scroll",
        bgcolor: "#FAFAFA"
      }}
    >
      {data?.map((item) => {
        return (
          <AssistantOfferCard key={item.id} {...item} selectable={false} />
        );
      })}
    </Stack>
  );
};

export default AssistantList;
