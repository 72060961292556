import createStyle from "@/utils/createStyle";

export const styles = createStyle({
  container: {
    width: "100%",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    marginTop: "16px",
    ":last-of-type": {
      marginBottom: 10
    }
  },
  rowIcon: {
    width: "20px",
    height: "20px",
    marginRight: "8px",
    color: "white",
    backgroundColor: "#5ebc5e",
    borderRadius: "3px",
    padding: "2px"
  },
  acceptButton: {
    flexGrow: 1,
    maxWidth: "100px",
    minWidth: "fit-content",
    marginTop: "8px",
    fontSize: "0.875rem"
  },
  footer: {
    borderTop: "1px dashed #D1D1D1",
    paddingTop: 2,
    marginTop: 1
  },
  notifModal: {
    position: "fixed",
    width: "calc(100% - 32px)",
    maxWidth: "300px",
    top: "50%",
    left: "50%",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    justifyContent: "flex-start"
  },
  detailIconArea: {
    backgroundColor: "#eee",
    padding: 0.25,
    borderRadius: "4px",
    marginRight: "12px"
  },
  detailIcon: {
    color: "#6E6E6E",
    fontSize: "18px"
  },
  detailItemArea: {
    marginBottom: "12px",
    "&::after": {
      content: '""',
      width: "22px",
      height: "1px",
      border: "1px dashed #A9A8AA",
      position: "absolute",
      bottom: -7,
      transform: "rotate(90deg)",
      zIndex: -1
    }
  }
});
