import { ChangeEvent, FC, SyntheticEvent } from 'react'
import {
  Stack,
  Box,
  Button,
  Typography,
  SwipeableDrawer,
  FormControl,
  TextField,
} from '@mui/material'

import { styles } from '@/pages/ConciergeSale/requests/tabs/details/styles'

interface Props {
  open: boolean
  onClose: () => void
  onOpen: () => void
  onSubmit: (event: SyntheticEvent) => void
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  name: string
}

const CarStatusModal: FC<Props> = ({ open, onClose, onOpen, onSubmit, value, onChange, name }) => {
  return (
    <SwipeableDrawer
      anchor='bottom'
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
    >
      <Box sx={styles.carStatusModal}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={15}>
            ویرایش وضعیت خودرو
          </Typography>
        </Box>
        <form onSubmit={onSubmit} style={{ width: '100%', height: '80%' }}>
          <Stack sx={styles.formContainer}>
            <FormControl size='small' sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                sx={{ mb: 2, fontSize: '16px' }}
                id='outlined-multiline-static'
                label='وضعیت خودرو'
                multiline
                rows={4}
                value={value === 'null' ? '' : value}
                name={name}
                onChange={onChange}
              />
            </FormControl>
            <Button type='submit' sx={styles.modalBtn}>
              ثبت یادداشت
            </Button>
          </Stack>
        </form>
      </Box>
    </SwipeableDrawer>
  )
}

export default CarStatusModal
