import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type tabList = "deals" | "payments";

const dealSlice = createSlice({
  name: "deal",
  initialState: {
    tabItem: "deals"
  } as { tabItem: tabList },
  reducers: {
    setDealTab: (state, action: PayloadAction<tabList>) => {
      state.tabItem = action.payload;
    }
  }
});

export const { setDealTab } = dealSlice.actions;

export default dealSlice.reducer;
