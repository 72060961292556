import { AxiosError } from "axios";
import httpClientCreator from "./http-client-creator";

export const baseUrl = import.meta.env.VITE_BASE_URL;
export const baseUrl2 = import.meta.env.VITE_BASE_URL_2;
export const baseUrlRest = import.meta.env.VITE_BASE_URL_REST;
export const baseUrlUserTrace = import.meta.env.VITE_BASE_URL_USER_TRACE;
export const baseUrlLeasing = import.meta.env.VITE_BASE_URL_LEASING;
export const baseUrlBoogh = import.meta.env.VITE_BASE_URL_BOOGH;
export const baseUrlPostStorage = import.meta.env.VITE_BASE_URL_POST_STORAGE;
export const baseUrlChassis = import.meta.env.VITE_BASE_URL_CHASSIS;

export const http = httpClientCreator(baseUrl);
export const http2 = httpClientCreator(baseUrl2);
export const httpRest = httpClientCreator(baseUrlRest);
export const httpUserTrace = httpClientCreator(baseUrlUserTrace);
export const httpLeasing = httpClientCreator(baseUrlLeasing);
export const httpBoogh = httpClientCreator(baseUrlBoogh);
export const httpPostStorage = httpClientCreator(baseUrlPostStorage);
export const httpChassis = httpClientCreator(baseUrlChassis);

export type ErrorType<Error> = AxiosError<Error>;

export const baseUrls = {
  // api
  BASE_URL_MAIN: import.meta.env.VITE_BASE_URL_MAIN,
  BASE_URL_STAGING: import.meta.env.VITE_BASE_URL_STAGING,
  BASE_URL_TEST_1: import.meta.env.VITE_BASE_URL_TEST_1,
  BASE_URL_TEST_2: import.meta.env.VITE_BASE_URL_TEST_2,
  BASE_URL_TEST_3: import.meta.env.VITE_BASE_URL_TEST_3,
  BASE_URL_TEST_4: import.meta.env.VITE_BASE_URL_TEST_4,
  BASE_URL_TEST_5: import.meta.env.VITE_BASE_URL_TEST_5,
  BASE_URL_TEST_6: import.meta.env.VITE_BASE_URL_TEST_6,
  BASE_URL_TEST_9: import.meta.env.VITE_BASE_URL_TEST_9,
  BASE_URL_NIGHTLY: import.meta.env.VITE_BASE_URL_NIGHTLY,
  // api2
  BASE_URL_2_MAIN: import.meta.env.VITE_BASE_URL_2_MAIN,
  BASE_URL_2_STAGING: import.meta.env.VITE_BASE_URL_2_STAGING,
  BASE_URL_2_TEST_1: import.meta.env.VITE_BASE_URL_2_TEST_1,
  BASE_URL_2_TEST_2: import.meta.env.VITE_BASE_URL_2_TEST_2,
  BASE_URL_2_TEST_3: import.meta.env.VITE_BASE_URL_2_TEST_3,
  BASE_URL_2_TEST_4: import.meta.env.VITE_BASE_URL_2_TEST_4,
  BASE_URL_2_TEST_5: import.meta.env.VITE_BASE_URL_2_TEST_5,
  BASE_URL_2_TEST_6: import.meta.env.VITE_BASE_URL_2_TEST_6,
  BASE_URL_2_TEST_9: import.meta.env.VITE_BASE_URL_2_TEST_9,
  BASE_URL_2_NIGHTLY: import.meta.env.VITE_BASE_URL_2_NIGHTLY,
  // rest
  BASE_URL_REST_MAIN: import.meta.env.VITE_BASE_URL_REST_MAIN,
  BASE_URL_REST_STAGING: import.meta.env.VITE_BASE_URL_REST_STAGING,
  BASE_URL_REST_TEST_1: import.meta.env.VITE_BASE_URL_REST_TEST_1,
  BASE_URL_REST_TEST_2: import.meta.env.VITE_BASE_URL_REST_TEST_2,
  BASE_URL_REST_TEST_3: import.meta.env.VITE_BASE_URL_REST_TEST_3,
  BASE_URL_REST_TEST_4: import.meta.env.VITE_BASE_URL_REST_TEST_4,
  BASE_URL_REST_TEST_5: import.meta.env.VITE_BASE_URL_REST_TEST_5,
  BASE_URL_REST_TEST_6: import.meta.env.VITE_BASE_URL_REST_TEST_6,
  BASE_URL_REST_TEST_9: import.meta.env.VITE_BASE_URL_REST_TEST_9,
  BASE_URL_REST_NIGHTLY: import.meta.env.VITE_BASE_URL_REST_NIGHTLY,
  // user trace
  BASE_URL_USER_TRACE_MAIN: import.meta.env.VITE_BASE_URL_USER_TRACE_MAIN,
  BASE_URL_USER_TRACE_STAGING: import.meta.env.VITE_BASE_URL_USER_TRACE_STAGING,
  BASE_URL_USER_TRACE_TEST_1: import.meta.env.VITE_BASE_URL_USER_TRACE_TEST_1,
  BASE_URL_USER_TRACE_TEST_2: import.meta.env.VITE_BASE_URL_USER_TRACE_TEST_2,
  BASE_URL_USER_TRACE_TEST_3: import.meta.env.VITE_BASE_URL_USER_TRACE_TEST_3,
  BASE_URL_USER_TRACE_TEST_4: import.meta.env.VITE_BASE_URL_USER_TRACE_TEST_4,
  BASE_URL_USER_TRACE_TEST_5: import.meta.env.VITE_BASE_URL_USER_TRACE_TEST_5,
  BASE_URL_USER_TRACE_TEST_6: import.meta.env.VITE_BASE_URL_USER_TRACE_TEST_6,
  BASE_URL_USER_TRACE_TEST_9: import.meta.env.VITE_BASE_URL_USER_TRACE_TEST_9,
  BASE_URL_USER_TRACE_NIGHTLY: import.meta.env.VITE_BASE_URL_USER_TRACE_NIGHTLY,

  // leasing
  BASE_URL_LEASING_MAIN: import.meta.env.VITE_BASE_URL_LEASING_MAIN,
  BASE_URL_LEASING_TEST_1: import.meta.env.VITE_BASE_URL_LEASING_TEST_1,
  BASE_URL_LEASING_TEST_2: import.meta.env.VITE_BASE_URL_LEASING_TEST_2,
  BASE_URL_LEASING_TEST_3: import.meta.env.VITE_BASE_URL_LEASING_TEST_3,
  BASE_URL_LEASING_TEST_4: import.meta.env.VITE_BASE_URL_LEASING_TEST_4,
  BASE_URL_LEASING_TEST_5: import.meta.env.VITE_BASE_URL_LEASING_TEST_5,
  BASE_URL_LEASING_TEST_6: import.meta.env.VITE_BASE_URL_LEASING_TEST_6,
  BASE_URL_LEASING_TEST_7: import.meta.env.VITE_BASE_URL_LEASING_TEST_7,
  BASE_URL_LEASING_TEST_8: import.meta.env.VITE_BASE_URL_LEASING_TEST_8,
  BASE_URL_LEASING_TEST_9: import.meta.env.VITE_BASE_URL_LEASING_TEST_9,

  // boogh
  BASE_URL_BOOGH_MAIN: import.meta.env.VITE_BASE_URL_BOOGH_MAIN,
  BASE_URL_BOOGH_TEST_1: import.meta.env.VITE_BASE_URL_BOOGH_TEST_1,
  BASE_URL_BOOGH_TEST_2: import.meta.env.VITE_BASE_URL_BOOGH_TEST_2,
  BASE_URL_BOOGH_TEST_3: import.meta.env.VITE_BASE_URL_BOOGH_TEST_3,
  BASE_URL_BOOGH_TEST_4: import.meta.env.VITE_BASE_URL_BOOGH_TEST_4,
  BASE_URL_BOOGH_TEST_5: import.meta.env.VITE_BASE_URL_BOOGH_TEST_5,
  BASE_URL_BOOGH_TEST_6: import.meta.env.VITE_BASE_URL_BOOGH_TEST_6,
  BASE_URL_BOOGH_TEST_7: import.meta.env.VITE_BASE_URL_BOOGH_TEST_7,
  BASE_URL_BOOGH_TEST_8: import.meta.env.VITE_BASE_URL_BOOGH_TEST_8,
  BASE_URL_BOOGH_TEST_9: import.meta.env.VITE_BASE_URL_BOOGH_TEST_9,

  // post-storage
  BASE_URL_POST_STORAGE_MAIN: import.meta.env.VITE_BASE_URL_POST_STORAGE_MAIN,
  BASE_URL_POST_STORAGE_TEST_1: import.meta.env
    .VITE_BASE_URL_POST_STORAGE_TEST_1,
  BASE_URL_POST_STORAGE_TEST_2: import.meta.env
    .VITE_BASE_URL_POST_STORAGE_TEST_2,
  BASE_URL_POST_STORAGE_TEST_3: import.meta.env
    .VITE_BASE_URL_POST_STORAGE_TEST_3,
  BASE_URL_POST_STORAGE_TEST_4: import.meta.env
    .VITE_BASE_URL_POST_STORAGE_TEST_4,
  BASE_URL_POST_STORAGE_TEST_5: import.meta.env
    .VITE_BASE_URL_POST_STORAGE_TEST_5,
  BASE_URL_POST_STORAGE_TEST_6: import.meta.env
    .VITE_BASE_URL_POST_STORAGE_TEST_6,
  BASE_URL_POST_STORAGE_TEST_7: import.meta.env
    .VITE_BASE_URL_POST_STORAGE_TEST_7,
  BASE_URL_POST_STORAGE_TEST_8: import.meta.env
    .VITE_BASE_URL_POST_STORAGE_TEST_8,
  BASE_URL_POST_STORAGE_TEST_9: import.meta.env
    .VITE_BASE_URL_POST_STORAGE_TEST_9,

  // chassis
  BASE_URL_CHASSIS_MAIN: import.meta.env.VITE_BASE_URL_CHASSIS_MAIN,
  BASE_URL_CHASSIS_TEST_1: import.meta.env.VITE_BASE_URL_CHASSIS_TEST_1,
  BASE_URL_CHASSIS_TEST_2: import.meta.env.VITE_BASE_URL_CHASSIS_TEST_2,
  BASE_URL_CHASSIS_TEST_3: import.meta.env.VITE_BASE_URL_CHASSIS_TEST_3,
  BASE_URL_CHASSIS_TEST_4: import.meta.env.VITE_BASE_URL_CHASSIS_TEST_4,
  BASE_URL_CHASSIS_TEST_5: import.meta.env.VITE_BASE_URL_CHASSIS_TEST_5,
  BASE_URL_CHASSIS_TEST_6: import.meta.env.VITE_BASE_URL_CHASSIS_TEST_6,
  BASE_URL_CHASSIS_TEST_7: import.meta.env.VITE_BASE_URL_CHASSIS_TEST_7,
  BASE_URL_CHASSIS_TEST_8: import.meta.env.VITE_BASE_URL_CHASSIS_TEST_8,
  BASE_URL_CHASSIS_TEST_9: import.meta.env.VITE_BASE_URL_BOOGH_TEST_9,
  BASE_URL_CHASSIS_TEST_10: import.meta.env.VITE_BASE_URL_BOOGH_TEST_10
};
