import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  useTheme
} from "@mui/material";
import { toGregorian, toJalaali } from "jalaali-js";
import { FC, useMemo } from "react";

interface Props {
  disabled?: boolean;
  value: string;
  setValue: (date: string) => void;
  jYearsArray?: number[];
}

const DateInput: FC<Props> = ({ disabled, value, setValue, jYearsArray }) => {
  const yearList = useMemo(
    () =>
      jYearsArray?.map((item) => String(item)) ??
      new Array(4).fill(0).map((_, index) => String(index + 1401)),
    [jYearsArray]
  );

  const monthList = useMemo(
    () =>
      new Array(12)
        .fill(0)
        .map((_, index) => String(index + 1).padStart(2, "0")),
    []
  );

  const dateList = useMemo(
    () =>
      yearList.map((year) => ({
        value: year,
        months: monthList.map((month) => {
          if (["01", "02", "03", "04", "05", "06"].includes(month))
            return {
              value: month,
              days: new Array(31)
                .fill(0)
                .map((_, day) => String(day + 1).padStart(2, "0"))
            };
          else if (month === "12")
            return {
              value: month,
              days: new Array(+year % 4 === 3 ? 30 : 29)
                .fill(0)
                .map((_, day) => String(day + 1).padStart(2, "0"))
            };
          else
            return {
              value: month,
              days: new Array(30)
                .fill(0)
                .map((_, day) => String(day + 1).padStart(2, "0"))
            };
        })
      })),
    [yearList, monthList]
  );

  const theme = useTheme();

  const jDate = useMemo(() => {
    const { jd, jm, jy } = toJalaali(new Date(value ?? Date.now()));

    return {
      jd: String(jd).padStart(2, "0"),
      jm: String(jm).padStart(2, "0"),
      jy: String(jy)
    };
  }, [value]);

  const yList = dateList.map((year) => year.value);

  const mList =
    dateList
      .find((year) => year.value === String(jDate.jy).padStart(2, "0"))
      ?.months.map((month) => month.value) ?? [];

  const dList =
    dateList
      .find((year) => year.value === String(jDate.jy).padStart(2, "0"))
      ?.months.find(
        (month) => month.value === String(jDate.jm).padStart(2, "0")
      )
      ?.days.map((day) => day) ?? [];

  return (
    <Stack
      // spacing={1}
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <FormControl disabled={disabled}>
        <InputLabel size="small" id="day-label">
          روز
        </InputLabel>
        <Select
          name="day"
          label="روز"
          labelId="day-label"
          size="small"
          sx={{ minWidth: theme.spacing(12) }}
          value={jDate.jd}
          onChange={(event) => {
            const { gd, gm, gy } = toGregorian(
              +jDate.jy,
              +jDate.jm,
              +event.target.value
            );

            setValue(
              `${gy}-${String(gm).padStart(2, "0")}-${String(gd).padStart(
                2,
                "0"
              )}`
            );
          }}
        >
          {dList.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl disabled={disabled}>
        <InputLabel size="small" id="month-label">
          ماه
        </InputLabel>
        <Select
          name="month"
          label="ماه"
          labelId="month-label"
          size="small"
          sx={{ minWidth: theme.spacing(12) }}
          value={jDate.jm}
          onChange={(event) => {
            const { gd, gm, gy } = toGregorian(
              +jDate.jy,
              +event.target.value,
              +jDate.jd
            );

            setValue(
              `${gy}-${String(gm).padStart(2, "0")}-${String(gd).padStart(
                2,
                "0"
              )}`
            );
          }}
        >
          {mList.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl disabled={disabled}>
        <InputLabel size="small" id="year-label">
          سال
        </InputLabel>
        <Select
          name="year"
          label="سال"
          labelId="year-label"
          size="small"
          sx={{ minWidth: theme.spacing(12) }}
          value={jDate.jy}
          onChange={(event) => {
            const { gd, gm, gy } = toGregorian(
              +event.target.value,
              +jDate.jm,
              +jDate.jd
            );

            setValue(
              `${gy}-${String(gm).padStart(2, "0")}-${String(gd).padStart(
                2,
                "0"
              )}`
            );
          }}
        >
          {yList.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default DateInput;
