import { createContext, useContext } from "react";

export const commonFills = {
  gray: "#ADB7C9",
  blue: "#6BC2ED",
  green: "#B8EFD4",
  xmark: "url(#xmark)",
  hashur: "url(#hashur)",
  intact: "white",
  unspecified: "#6BC2ED",
  "minor-damage": "#FEA765",
  changed: "url(#xmark)",
  painted: "url(#hashur)"
};

export type SVGPaintObj = {
  [key: string]: boolean | null | keyof typeof commonFills;
};

export const SVGAdapterContext = createContext<{
  onClick?: (partKey: string) => void;
}>({
  onClick: () => null
});

export const useSVGAdapter = () => {
  return useContext(SVGAdapterContext);
};
