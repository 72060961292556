/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ChangeEvent, FC } from "react";
import { FormikType } from "./index";
import {
  useEvaluationV1PackagesList,
  useEvaluationV1ServiceTimeList,
  useEvaluationV1TimeslotAvailability,
  useInspectionCenter
} from "./services";
import { inspectionType } from "./data";
import CarSearchTextFeild from "@/components/CarSearchTextFeild";
import { Car } from "@/services/api/searchCar";
import { useGetCities } from "@/services/api/leasing/city";
import { GetLeasingDetailResponse } from "@/services/api/leasing/detail";

const FormFields: FC<{
  leasingOrder?: GetLeasingDetailResponse;
  form: {
    values: FormikType;
    setFieldValue: any;
    handleChange: any;
  };
}> = ({ form }) => {
  const { data: packages, isLoading: packagesLoading } =
    useEvaluationV1PackagesList(
      {
        brand_model: form?.values.car?.name_en,
        city_id: form?.values.city,
        is_bo: 1,
        vehicle_type: form?.values.car?.vehicle_type
      },
      {
        query: {
          enabled: !!form?.values?.city && !!form?.values?.car?.name_en,
          onSuccess(data) {
            if (data.data.data) {
              const slug = form.values.plan?.slug || "leasing-agent-inspection";
              form.setFieldValue(
                "plan",
                data.data.data?.find((item) => item?.slug === slug)
              );
            }
          }
        }
      }
    );

  const { data: serviceTimeData, isLoading: isLoadingTimeList } =
    useEvaluationV1ServiceTimeList(
      {
        city_id: form.values.city,
        brand_model: form.values.car?.name_en,
        package_id: parseInt(form.values.plan?.pk || "0"),
        vehicle_type: form.values.car?.vehicle_type
      },
      {
        query: {
          enabled:
            !!form?.values?.city &&
            !!form?.values?.car?.name_en &&
            !!form.values.plan
        }
      }
    );

  const { data: serviceIsAvailable } = useEvaluationV1TimeslotAvailability(
    {
      shift_id: form?.values.timeslot?.pk || 0,
      city_id: form?.values.city || 0,
      selected_package_id: parseInt(form.values.plan?.pk ?? ""),
      vehicle_type: form?.values.car?.vehicle_type
    },
    {
      query: {
        enabled:
          !!form?.values?.timeslot?.pk &&
          !!form?.values?.city &&
          !!form.values.plan
      }
    }
  );

  const { data: centers, isLoading: isLoadingCenters } = useInspectionCenter(
    {
      city: form?.values?.city || 0,
      selected_package_id: parseInt(form.values.plan?.pk ?? ""),
      vehicle_type: form?.values?.car?.vehicle_type,
      shift_id: form?.values?.timeslot?.pk
    },
    {
      query: {
        enabled: !!form?.values?.city && !!form.values.plan
      }
    }
  );

  const { data: cities } = useGetCities();

  function changeAssignToMeHandler(e: ChangeEvent<HTMLInputElement>) {
    if (packages && !packagesLoading) {
      const plan = packages.data.data?.find(
        (item) => item?.slug === e.target.value
      );
      if (plan) {
        form.setFieldValue("plan", plan);
      }
    }
  }

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      gap={3}
      width="100%"
      height="max-content"
      overflow={"auto"}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        gap={2}
        px={6}
        py={4}
      >
        <Typography fontSize="16px" fontWeight={600}>
          مشخصات شخصی و خودرو
        </Typography>
        <Autocomplete
          size="small"
          disabled
          disablePortal
          id="inspectionType"
          options={inspectionType}
          value={form.values.inspectionType}
          onChange={(_, newValue) => {
            form.setFieldValue("inspectionType", newValue);
          }}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true
              }}
              label="نوع درخواست"
            />
          )}
        />
        {form.values.inspectionType?.id === "G" ? (
          <TextField
            size="small"
            label="کد آگهی"
            value={form.values?.conciergeSaleCode}
            name="conciergeSaleCode"
            onChange={form.handleChange}
            type="text"
            fullWidth
          />
        ) : null}
        {form.values.inspectionType?.id === "W" ? (
          <TextField
            size="small"
            disabled
            label="توکن لیزینگ"
            value={form.values?.leasingToken}
            name="leasingToken"
            onChange={form.handleChange}
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
          />
        ) : null}
        <TextField
          size="small"
          disabled
          label="شماره موبایل"
          placeholder="شماره موبایل درخواست دهنده (**** *** 0911)"
          value={form.values.phone}
          name="phone"
          onChange={form.handleChange}
          type="tel"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />

        <TextField
          size="small"
          label="نام و نام خانوادگی مشتری"
          value={form.values.fullName}
          name="fullName"
          onChange={form.handleChange}
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
        <CarSearchTextFeild
          size="small"
          serviceName="inspection"
          value={form.values.car}
          onChange={(newCar: Car | null) => {
            form?.setFieldValue("car", newCar);
          }}
        />
        <FormControl sx={{ flex: 1, background: "white" }} variant="outlined">
          <InputLabel shrink id="demo-customized-select-label">
            شهر
          </InputLabel>
          <Select
            size="small"
            input={<OutlinedInput notched label={"شهر"} />}
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={form.values.city}
            onChange={(e) => {
              form.setFieldValue("city", e.target.value);
            }}
          >
            {cities &&
              cities.data.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack
        direction="column"
        justifyContent="flex-start"
        gap={4}
        px={6}
        py={4}
      >
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize="16px" fontWeight={600}>
            مشخصات درخواست
          </Typography>
        </Stack>

        <FormControlLabel
          control={
            <Radio
              checked={form.values.plan?.slug == "leasing-agent-inspection"}
              disabled={packagesLoading}
              value="leasing-agent-inspection"
              name="assign_to_me"
              onChange={changeAssignToMeHandler}
            />
          }
          label="اختصاص به خودم"
        />
        <FormControlLabel
          control={
            <Radio
              checked={form.values.plan?.slug == "economy-inspection-leasing"}
              disabled={packagesLoading}
              value="economy-inspection-leasing"
              name="assign_to_me"
              onChange={changeAssignToMeHandler}
            />
          }
          label="ارسال به آفر"
        />

        <Autocomplete
          size="small"
          disabled={!form?.values?.city || !form?.values?.car?.name_en}
          loading={isLoadingTimeList}
          disablePortal
          id="timeslot"
          options={serviceTimeData?.data?.data ?? ([] as any)}
          getOptionDisabled={(option) =>
            !option?.available_center && !option?.available_in_person
          }
          getOptionLabel={(option) =>
            `${
              option?.is_immediate
                ? "فوری تا ۳۰ دقیقه"
                : `${option?.title} - ${option?.start} تا ${option?.end}`
            }${
              Math.abs(option?.price_diff ?? 0) > 0
                ? ` - ${(option?.price_diff as number)?.toLocaleString(
                    "fa-IR"
                  )} تومان` +
                  " - " +
                  option?.price_diff_reason
                : ""
            }${
              !option?.available_center && !option?.available_in_person
                ? " - ظرفیت تکمیل"
                : ""
            }
            ${
              !option?.available_center && !option?.available_in_person
                ? " - ظرفیت تکمیل"
                : ""
            }
            `
          }
          value={form.values?.timeslot}
          onChange={(_, newValue) => {
            form?.setFieldValue("timeslot", newValue);
          }}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true
              }}
              label="زمان"
            />
          )}
          loadingText={
            <Stack
              sx={{
                width: "100%",
                padding: "16px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold"
              }}
            >
              <CircularProgress
                size={15}
                sx={{
                  color: (theme) => theme.palette.iconDefault.main,
                  marginBottom: 1
                }}
              />
              <Typography
                sx={{
                  color: (theme) => theme.palette.iconDefault.main,
                  fontSize: "12px"
                }}
              >
                در حال جستجو
              </Typography>
            </Stack>
          }
          noOptionsText={
            <Stack
              sx={{
                width: "100%",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                color: (theme) => theme.palette.iconDefault.main,
                fontSize: "12px"
              }}
            >
              موردی یافت نشد
            </Stack>
          }
        />
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          row
        >
          <FormControlLabel
            disabled={!serviceIsAvailable?.data?.data?.in_person?.available}
            value="in_person"
            control={
              <Radio
                checked={form?.values?.serviceType === "in_person"}
                onChange={() => {
                  form?.setFieldValue("serviceType", "in_person");
                  form?.setFieldValue("inspectionCenter", null);
                }}
                inputProps={{ "aria-label": "در محل" }}
              />
            }
            label={`
              در محل${
                serviceIsAvailable?.data?.data?.in_person?.reason
                  ? `(${serviceIsAvailable?.data?.data?.in_person?.reason})`
                  : ""
              }
            `}
          />
          <FormControlLabel
            disabled={
              !serviceIsAvailable?.data?.data?.in_center?.available ||
              !serviceIsAvailable?.data?.data?.in_center_city?.available
            }
            value="in_center"
            control={
              <Radio
                checked={form?.values?.serviceType === "in_center"}
                onChange={() => {
                  form?.setFieldValue("serviceType", "in_center");
                  form?.setFieldValue("address", null);
                  form?.setFieldValue("visitTime", null);
                }}
                inputProps={{ "aria-label": "در مراکز همکار" }}
              />
            }
            label={
              "در مراکز همکار" +
              (serviceIsAvailable?.data?.data?.in_center_city?.reason ||
              serviceIsAvailable?.data?.data?.in_center?.reason
                ? `(${
                    serviceIsAvailable?.data?.data?.in_center_city?.reason ||
                    serviceIsAvailable?.data?.data?.in_center?.reason
                  })`
                : "")
            }
          />
        </RadioGroup>
        {form?.values?.serviceType === "in_person" ? (
          <>
            <TextField
              size="small"
              label="آدرس"
              value={form.values.address}
              name="address"
              onChange={form.handleChange}
              type="text"
              fullWidth
              multiline
              rows={3}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              size="small"
              label="ساعت اعزام"
              placeholder="۱۲:۳۰"
              value={form.values.visitTime}
              name="visitTime"
              onChange={form.handleChange}
              type="text"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </>
        ) : null}
        {form?.values?.serviceType === "in_center" ? (
          <Autocomplete
            size="small"
            loading={isLoadingCenters}
            disablePortal
            id="inspectionCenter"
            options={centers?.data ?? ([] as any)}
            getOptionLabel={(option) => option?.title + " - " + option?.address}
            value={form.values?.inspectionCenter}
            onChange={(_, newValue) => {
              form?.setFieldValue("inspectionCenter", newValue);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
                label="مراکز همکار"
              />
            )}
            loadingText={
              <Stack
                sx={{
                  width: "100%",
                  padding: "16px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold"
                }}
              >
                <CircularProgress
                  size={15}
                  sx={{
                    color: (theme) => theme.palette.iconDefault.main,
                    marginBottom: 1
                  }}
                />
                <Typography
                  sx={{
                    color: (theme) => theme.palette.iconDefault.main,
                    fontSize: "12px"
                  }}
                >
                  در حال جستجو
                </Typography>
              </Stack>
            }
            noOptionsText={
              <Stack
                sx={{
                  width: "100%",
                  padding: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: (theme) => theme.palette.iconDefault.main,
                  fontSize: "12px"
                }}
              >
                موردی یافت نشد
              </Stack>
            }
          />
        ) : null}
        <TextField
          size="small"
          label="توضیحات"
          value={form.values.description}
          name="description"
          onChange={form.handleChange}
          type="text"
          fullWidth
          multiline
          rows={3}
          InputLabelProps={{
            shrink: true
          }}
        />
        {form?.values?.timeslot?.final_price ? (
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="16px" fontWeight={600}>
              قیمت کارشناسی
            </Typography>
            <Stack
              width="60%"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              gap={1}
            >
              <Typography fontSize="16px" fontWeight={600}>
                {(
                  form?.values?.timeslot?.final_price as number
                )?.toLocaleString("fa-IR")}
                تومان
              </Typography>
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default FormFields;
