export function addComma(value: string | number) {
  value = String(value)

  const result = value.replace(/([0-9]+|[۰-۹]+)/g, (token) => {
    return token.replace(
      /\B(?=(([0-9]|[۰-۹]){3})+(?!([0-9]|[۰-۹])))/g,
      /^[0-9]+$/.test(token) ? ',' : '،',
    )
  })

  if (result.startsWith('،')) {
    return result.substring(1)
  }

  return result
}
