import { Button, Stack } from "@mui/material";
import { FC, useCallback, useState } from "react";
import styles from "./styles";
import ImageUploader from "@/components/ImageUploader";
import { initialImagesState, stencilList } from "./configs";
import produce from "immer";
import { useNavigate } from "react-router";

const Stencils: FC = () => {
  const [imagesList, setImagesList] = useState(initialImagesState);

  const navigate = useNavigate();

  const resetForm = useCallback(() => {
    setImagesList(initialImagesState);
    navigate(-1);
  }, [navigate]);

  const onImageChangeHandler = useCallback(
    (key: string, value: File | null) => {
      setImagesList(
        produce((draft) => {
          draft[key] = value;
        })
      );
    },
    []
  );

  return (
    <Stack sx={styles.container}>
      {stencilList.map((stencilItem) => {
        const stencilItemValue = imagesList[stencilItem.key];
        return (
          <Stack sx={styles.stencilItemContainer} key={stencilItem.key}>
            <ImageUploader
              title={stencilItem.label}
              value={stencilItemValue}
              variant={"camera"}
              onChange={(newImage) => {
                onImageChangeHandler(stencilItem.key, newImage?.file || null);
              }}
              icon={stencilItem.icon}
              cameraProps={{
                ...stencilItem.cameraOptions,
                submitType: "save-and-submit",
                fileName: `${
                  stencilItem.cameraOptions.fileName
                }-${new Date().getTime()}`
              }}
            />
          </Stack>
        );
      })}

      <Stack sx={styles.resetContainer}>
        <Button onClick={resetForm} sx={styles.resetButton}>
          پایان عکاسی
        </Button>
      </Stack>
    </Stack>
  );
};

export default Stencils;
