import { Checkbox, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { styles } from './styles'

interface Props {
  title: string
  checked?: boolean
}

const SubmitItem: FC<Props> = ({ title, checked = true }) => {
  return (
    <Stack direction='row' sx={styles.submitItem}>
      <Checkbox sx={{ padding: '0' }} checked={checked} />
      <Typography color='rgba(0, 0, 0, 0.87)' fontSize='15px' ml={1}>
        {title}
      </Typography>
    </Stack>
  )
}

export default SubmitItem
