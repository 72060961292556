import { useSVGAdapter } from '../configs';
import { FC, useCallback } from 'react';

const SVGCircleButton: FC<{
  cx: number;
  cy: number;
  partKey: string;
  content: string;
}> = ({ cx, cy, content, partKey }) => {
  const { onClick } = useSVGAdapter();

  const onClickHandler = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick(partKey);
    }
  }, [onClick, partKey]);

  return (
    <g>
      <circle
        onClick={onClickHandler}
        r="7"
        cursor="pointer"
        fill="#D9D9D9"
        cx={cx}
        cy={cy}
      />
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        stroke="#000"
        strokeWidth="0.5px"
        dy=".3em"
        fontSize="6"
        pointerEvents="none"
      >
        {content}
      </text>
    </g>
  );
};

export default SVGCircleButton;
