import { httpLeasing } from "@/services/http-clients";
import { UseQueryOptions, useQuery } from "react-query";

// GET CITIES
export interface City {
  id: number;
  name: string;
  en_name: string;
}

export interface GetCitiesResponse {
  data: City[];
}

const getCitiesURL = "/api/v1/cities/info/";

export const getCitiesFn = async () => {
  const response = await httpLeasing<GetCitiesResponse>({
    url: getCitiesURL
  });

  return response.data;
};

export const getCitiesKey = () => {
  return [getCitiesURL];
};

export const useGetCities = (options?: UseQueryOptions<GetCitiesResponse>) => {
  return useQuery({
    queryFn: getCitiesFn,
    queryKey: getCitiesKey(),
    ...options
  });
};
