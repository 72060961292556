import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AddTaskIcon from "@mui/icons-material/AddTask";

export interface ConcierSaleMenuItems {
  title: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<object, "svg">> & {
        muiName: string;
      })
    | null;
  path: string;
  is_external_link: boolean;
}

export const conciergeSaleMenu: ConcierSaleMenuItems[] = [
  {
    title: "آفرها",
    icon: AddTaskIcon,
    path: "/concierge-sale/supervisor/offers",
    is_external_link: false
  },
  {
    title: "دستیارها",
    icon: AssignmentOutlinedIcon,
    path: "/concierge-sale/supervisor/assistants-list",
    is_external_link: false
  }
];
