import badanehSchema, {
  BadanehType
} from "@/pages/inspection-job-report/views/categories/schemas/badaneh-schema";
import { CancelOrPenaltyPayType } from "@/pages/inspection-job-report/views/categories/schemas/cancel-or-penalty-pay-schema";
import fazayeDakheliVaTajhizatSchema, {
  FazayeDakheliVaTajhizatType
} from "@/pages/inspection-job-report/views/categories/schemas/fazaye-dakheli-va-tajhizat-schema";
import lastikHaSchema, {
  LastikHaType
} from "@/pages/inspection-job-report/views/categories/schemas/lastik-ha-schema";
import motorVaEnteghaleGhodrateSchema, {
  MotorVaEnteghaleGhodratType
} from "@/pages/inspection-job-report/views/categories/schemas/motor-va-enteghale-ghodrate-schema";
import optionHaSchema, {
  OptionHaType
} from "@/pages/inspection-job-report/views/categories/schemas/option-ha-schema";
import { PaperReportCodeType } from "@/pages/inspection-job-report/views/categories/schemas/paper-report-code-schema";
import sanadVaMadrakSchema, {
  SanadVaMadrakType
} from "@/pages/inspection-job-report/views/categories/schemas/sanad-va-madrak-schema";
import { specialDescriptionType } from "@/pages/inspection-job-report/views/categories/schemas/special-description-schema";
import talighVaFarmanSchema, {
  TalighVaFarmanType
} from "@/pages/inspection-job-report/views/categories/schemas/taligh-va-farman-schema";
import { TasavireKoliType } from "@/pages/inspection-job-report/views/categories/schemas/tasavire-koli-schema";
import { DeepPartial, PayloadAction, createSlice } from "@reduxjs/toolkit";

const {
  karte_mashin,
  range_badane,
  sale_sakht,
  takhfif_sales_be_sal,
  tedad_switch,
  garanti
} = sanadVaMadrakSchema;

const {
  lastikha_rarande_aghab,
  lastikha_rarande_jolo,
  lastikha_shagerd_aghab,
  lastikha_shagerd_jolo,
  lastikha_zapas
} = lastikHaSchema;

const {
  egzoz: motorVaEnteghalEgzoz,
  girboks: motorVaEnteghalGirboks,
  motor: motorVaEnteghalMotor,
  motor_va_system_enteghale_ghodrath_azmone_ranandegi: motorVaEnteghalAzmoon,
  tajhizat_barghi: motorVaEnteghalTajhizat_barghi
} = motorVaEnteghaleGhodrateSchema;

const {
  fanarbandi_dar_ja: talighVaFarmanFanarBandi,
  farman: talighVaFarmanFarman,
  tormoz: talighVaFarmanTormoz,
  tormoz_dasti: talighVaFarmanTormozDasti,
  tormoz_farman_va_system_taligh_azmone_ranandegi:
    talighVaFarmanTormozFarmanVaSystem
} = talighVaFarmanSchema;

const {
  darbha_va_saghf,
  gelgirha,
  sotonha,
  rekabha,
  sarshasiha,
  mohavateha,
  separha,
  shisheha
} = badanehSchema;

const { janebie, refahi, iemeni } = optionHaSchema;

const {
  fazaie_dakheli,
  barf_pak_kon,
  shishe_balabar,
  ayeneha,
  cheraghha_va_tajhizat_roshanae,
  ghofl_dastgire_va_sistem_zede_serghat,
  sistem_tahvie,
  eimenie
} = fazayeDakheliVaTajhizatSchema;

// const { paper_report_code } = paperReportCodeSchema;

export type ReportState = {
  sanad_va_madrak: SanadVaMadrakType & { brand_model_slug: string };
  lastikha: LastikHaType;
  motor_va_system_enteghale_ghodrath: MotorVaEnteghaleGhodratType;
  badane: BadanehType;
  tormoz_farman_va_system_taligh: TalighVaFarmanType;
  general_images: TasavireKoliType;
  fazaie_dakhel_va_tajhizate_barghi: FazayeDakheliVaTajhizatType;
  apshen: OptionHaType;
  paper_report_code: PaperReportCodeType;
  special_description: specialDescriptionType;
  cancel_or_penalty_pay: CancelOrPenaltyPayType;
};

export const initialReportState: { result: ReportState } = {
  result: {
    cancel_or_penalty_pay: {
      expense_amount: 0,
      follow_up_number: "",
      paid_for: "",
      paid_way: ""
    },
    general_images: {},
    sanad_va_madrak: {
      brand_model: "",
      brand_model_slug: "",
      etebar_sales: "",
      shomare_shasi: "",
      karkard: 0,
      vin: "",
      description: "",
      garanti: garanti.enum[0],
      karte_mashin: karte_mashin.enum[0],
      range_badane: range_badane.enum[0],
      sale_sakht: sale_sakht.enum[0],
      takhfif_sales_be_sal: takhfif_sales_be_sal.enum[0],
      tedad_switch: tedad_switch.enum[0],
      otagh: {
        esalat: {
          taviz_kamel_rang: false,
          taviz_otagh: false
        }
      }
    },
    lastikha: {
      description: "",
      lastikha_rarande_aghab: {
        darsade_lastik:
          lastikha_rarande_aghab.properties.darsade_lastik.enum[0],
        salamat_zaheri_ring:
          lastikha_rarande_aghab.properties.salamat_zaheri_ring.enum[1]
      },
      lastikha_rarande_jolo: {
        darsade_lastik: lastikha_rarande_jolo.properties.darsade_lastik.enum[0],
        salamat_zaheri_ring:
          lastikha_rarande_jolo.properties.salamat_zaheri_ring.enum[1]
      },
      lastikha_shagerd_aghab: {
        darsade_lastik:
          lastikha_shagerd_aghab.properties.darsade_lastik.enum[0],
        salamat_zaheri_ring:
          lastikha_shagerd_aghab.properties.salamat_zaheri_ring.enum[1]
      },
      lastikha_shagerd_jolo: {
        darsade_lastik: lastikha_shagerd_jolo.properties.darsade_lastik.enum[0],
        salamat_zaheri_ring:
          lastikha_shagerd_jolo.properties.salamat_zaheri_ring.enum[1]
      },
      lastikha_zapas: {
        darsade_lastik: lastikha_zapas.properties.darsade_lastik.enum[0],
        salamat_zaheri_ring:
          lastikha_zapas.properties.salamat_zaheri_ring.enum[1],
        vaziat_zapas: lastikha_zapas.properties.vaziat_zapas.enum[0]
      }
    },
    motor_va_system_enteghale_ghodrath: {
      description: "",
      egzoz: {
        vaziat_egzoz: {
          status:
            motorVaEnteghalEgzoz.properties.vaziat_egzoz.properties.status
              .enum[0],
          images: [],
          mayub_ast:
            motorVaEnteghalEgzoz.properties.vaziat_egzoz.properties.mayub_ast
              .enum[0],
          rang_va_bo_gazhaye_khuruji_gheire_tabie_ast:
            motorVaEnteghalEgzoz.properties.vaziat_egzoz.properties
              .rang_va_bo_gazhaye_khuruji_gheire_tabie_ast.enum[0]
        }
      },
      girboks: {
        vaziat_girboks_dandee_halat_dar_ja: {
          status:
            motorVaEnteghalGirboks.properties.vaziat_girboks_dandee_halat_dar_ja
              .properties.status.enum[0],
          mayub_ast:
            motorVaEnteghalGirboks.properties.vaziat_girboks_dandee_halat_dar_ja
              .properties.mayub_ast.enum[0],
          ja_raftan_dandeha_dar_halat_motor_roshan_va_tavaghof_khodro_ba_moshkel_anjam_mishavad:
            motorVaEnteghalGirboks.properties.vaziat_girboks_dandee_halat_dar_ja
              .properties
              .ja_raftan_dandeha_dar_halat_motor_roshan_va_tavaghof_khodro_ba_moshkel_anjam_mishavad
              .enum[0],
          larzesh_ya_seday_gheir_adi:
            motorVaEnteghalGirboks.properties.vaziat_girboks_dandee_halat_dar_ja
              .properties.larzesh_ya_seday_gheir_adi.enum[0],
          roghan_girboks_nashti_darad:
            motorVaEnteghalGirboks.properties.vaziat_girboks_dandee_halat_dar_ja
              .properties.roghan_girboks_nashti_darad.enum[0],
          images: []
        },
        vaziat_girboks_otomatik_halat_dar_ja: {
          status:
            motorVaEnteghalGirboks.properties
              .vaziat_girboks_otomatik_halat_dar_ja.properties.status.enum[0],
          larzesh_ya_seday_gheir_adi:
            motorVaEnteghalGirboks.properties
              .vaziat_girboks_otomatik_halat_dar_ja.properties
              .larzesh_ya_seday_gheir_adi.enum[0],
          mayub_ast:
            motorVaEnteghalGirboks.properties
              .vaziat_girboks_otomatik_halat_dar_ja.properties.mayub_ast
              .enum[0],
          roghan_girboks_nashti_darad:
            motorVaEnteghalGirboks.properties
              .vaziat_girboks_otomatik_halat_dar_ja.properties
              .roghan_girboks_nashti_darad.enum[0],
          taviz_dandeha_dar_halat_darja_ba_moshkel_anjam_mishavad:
            motorVaEnteghalGirboks.properties
              .vaziat_girboks_otomatik_halat_dar_ja.properties
              .taviz_dandeha_dar_halat_darja_ba_moshkel_anjam_mishavad.enum[0],
          images: []
        }
      },
      tajhizat_barghi: {
        batri: {
          batri_mayub_ast:
            motorVaEnteghalTajhizat_barghi.properties.batri.properties
              .batri_mayub_ast.enum[0],
          status:
            motorVaEnteghalTajhizat_barghi.properties.batri.properties.status
              .enum[0],
          images: []
        },
        dinam: {
          dinam_mayub_ast:
            motorVaEnteghalTajhizat_barghi.properties.dinam.properties
              .dinam_mayub_ast.enum[0],
          status:
            motorVaEnteghalTajhizat_barghi.properties.dinam.properties.status
              .enum[0],
          images: []
        },
        start: {
          start_mayub_ast:
            motorVaEnteghalTajhizat_barghi.properties.start.properties
              .start_mayub_ast.enum[0],
          status:
            motorVaEnteghalTajhizat_barghi.properties.start.properties.status
              .enum[0],
          images: []
        }
      },
      motor: {
        hoshdardahandehaye_safhe_kilumetr: {
          status:
            motorVaEnteghalMotor.properties.hoshdardahandehaye_safhe_kilumetr
              .properties.status.enum[0],
          cheragh_batri_roshan_mibashad:
            motorVaEnteghalMotor.properties.hoshdardahandehaye_safhe_kilumetr
              .properties.cheragh_batri_roshan_mibashad.enum[0],
          cheragh_chek_roshan_mibashad:
            motorVaEnteghalMotor.properties.hoshdardahandehaye_safhe_kilumetr
              .properties.cheragh_chek_roshan_mibashad.enum[0],
          cheragh_roghan_motor_roshan_mibashad:
            motorVaEnteghalMotor.properties.hoshdardahandehaye_safhe_kilumetr
              .properties.cheragh_roghan_motor_roshan_mibashad.enum[0],
          neshangar_damay_maye_khonak_konande_mayub_ast:
            motorVaEnteghalMotor.properties.hoshdardahandehaye_safhe_kilumetr
              .properties.neshangar_damay_maye_khonak_konande_mayub_ast.enum[0],
          neshangar_meghdar_sukht_mayub_ast:
            motorVaEnteghalMotor.properties.hoshdardahandehaye_safhe_kilumetr
              .properties.neshangar_meghdar_sukht_mayub_ast.enum[0],
          images: []
        },
        roghan_motor_va_sistem_khonak_kari: {
          status:
            motorVaEnteghalMotor.properties.roghan_motor_va_sistem_khonak_kari
              .properties.status.enum[0],
          maye_khonak_konande_motor_nashti_darad:
            motorVaEnteghalMotor.properties.roghan_motor_va_sistem_khonak_kari
              .properties.maye_khonak_konande_motor_nashti_darad.enum[0],
          rang_maye_khonak_konande_gheir_tabiee_ast:
            motorVaEnteghalMotor.properties.roghan_motor_va_sistem_khonak_kari
              .properties.rang_maye_khonak_konande_gheir_tabiee_ast.enum[0],
          rang_roghan_motor_gheir_tabiee_ast:
            motorVaEnteghalMotor.properties.roghan_motor_va_sistem_khonak_kari
              .properties.rang_roghan_motor_gheir_tabiee_ast.enum[0],
          roghan_motor_nashti_darad:
            motorVaEnteghalMotor.properties.roghan_motor_va_sistem_khonak_kari
              .properties.roghan_motor_nashti_darad.enum[0],
          vaterpomp_mayub_ast:
            motorVaEnteghalMotor.properties.roghan_motor_va_sistem_khonak_kari
              .properties.vaterpomp_mayub_ast.enum[0],
          images: []
        },
        vaziat_va_amalkard_motor: {
          status:
            motorVaEnteghalMotor.properties.vaziat_va_amalkard_motor.properties
              .status.enum[0],
          booye_sukht_dar_otagh_khodro_esteshmam_mishavad:
            motorVaEnteghalMotor.properties.vaziat_va_amalkard_motor.properties
              .booye_sukht_dar_otagh_khodro_esteshmam_mishavad.enum[0],
          daste_motor_mayub_ast:
            motorVaEnteghalMotor.properties.vaziat_va_amalkard_motor.properties
              .daste_motor_mayub_ast.enum[0],
          motor_be_rahati_roshan_nemishavad:
            motorVaEnteghalMotor.properties.vaziat_va_amalkard_motor.properties
              .motor_be_rahati_roshan_nemishavad.enum[0],
          seda_va_larzesh_gheir_adi:
            motorVaEnteghalMotor.properties.vaziat_va_amalkard_motor.properties
              .seda_va_larzesh_gheir_adi.enum[0],
          sistem_hybrid_bedorosti_amal_nemikonad:
            motorVaEnteghalMotor.properties.vaziat_va_amalkard_motor.properties
              .sistem_hybrid_bedorosti_amal_nemikonad.enum[2],
          tasme_ya_harzgardha_mayub_hastand_ya_bedorosti_amal_nemikonad:
            motorVaEnteghalMotor.properties.vaziat_va_amalkard_motor.properties
              .tasme_ya_harzgardha_mayub_hastand_ya_bedorosti_amal_nemikonad
              .enum[0],
          turbo_sharj_ya_super_sharj_bedorosti_amal_nemikonad:
            motorVaEnteghalMotor.properties.vaziat_va_amalkard_motor.properties
              .turbo_sharj_ya_super_sharj_bedorosti_amal_nemikonad.enum[2],
          images: []
        }
      },
      motor_va_system_enteghale_ghodrath_azmone_ranandegi: {
        difransiel_jolo_moharek_fwd: {
          status:
            motorVaEnteghalAzmoon.properties.difransiel_jolo_moharek_fwd
              .properties.status.enum[1],
          dar_hein_harekat_taghe_va_shock_be_samte_jolo_va_aghab_darad:
            motorVaEnteghalAzmoon.properties.difransiel_jolo_moharek_fwd
              .properties
              .dar_hein_harekat_taghe_va_shock_be_samte_jolo_va_aghab_darad
              .enum[0],
          hein_harekat_larzesh_va_sedaie_gheir_adi_be_gosh_miresad:
            motorVaEnteghalAzmoon.properties.difransiel_jolo_moharek_fwd
              .properties
              .hein_harekat_larzesh_va_sedaie_gheir_adi_be_gosh_miresad.enum[0],
          nashti_roghan_darad:
            motorVaEnteghalAzmoon.properties.difransiel_jolo_moharek_fwd
              .properties.nashti_roghan_darad.enum[0],
          images: []
        },
        girboks_dandee_heyn_harekat: {
          status:
            motorVaEnteghalAzmoon.properties.girboks_dandee_heyn_harekat
              .properties.status.enum[1],
          dande_heyn_harekat_birun_mizanad:
            motorVaEnteghalAzmoon.properties.girboks_dandee_heyn_harekat
              .properties.dande_heyn_harekat_birun_mizanad.enum[0],
          ja_raftan_dandeha_dar_hal_harekat_khodro_ba_moshkel_anjam_mishavad:
            motorVaEnteghalAzmoon.properties.girboks_dandee_heyn_harekat
              .properties
              .ja_raftan_dandeha_dar_hal_harekat_khodro_ba_moshkel_anjam_mishavad
              .enum[0],
          larzesh_ya_seday_gheir_adi:
            motorVaEnteghalAzmoon.properties.girboks_dandee_heyn_harekat
              .properties.larzesh_ya_seday_gheir_adi.enum[0],
          mayub_ast:
            motorVaEnteghalAzmoon.properties.girboks_dandee_heyn_harekat
              .properties.mayub_ast.enum[0],
          images: []
        },
        girboks_otomatik_heyn_harekat: {
          status:
            motorVaEnteghalAzmoon.properties.girboks_otomatik_heyn_harekat
              .properties.status.enum[1],
          dar_halat_awd_ya_4wd_bedorosti_amal_nemikonad:
            motorVaEnteghalAzmoon.properties.girboks_otomatik_heyn_harekat
              .properties.dar_halat_awd_ya_4wd_bedorosti_amal_nemikonad.enum[2],
          larzesh_ya_seday_gheir_adi_darad:
            motorVaEnteghalAzmoon.properties.girboks_otomatik_heyn_harekat
              .properties.larzesh_ya_seday_gheir_adi_darad.enum[0],
          mayub_ast:
            motorVaEnteghalAzmoon.properties.girboks_otomatik_heyn_harekat
              .properties.mayub_ast.enum[0],
          shifter_posht_farman_ya_tiptronik_mayub_ast:
            motorVaEnteghalAzmoon.properties.girboks_otomatik_heyn_harekat
              .properties.shifter_posht_farman_ya_tiptronik_mayub_ast.enum[2],
          sistem_komaki_dar_sarbalaee_bedorosti_amal_nemikonad:
            motorVaEnteghalAzmoon.properties.girboks_otomatik_heyn_harekat
              .properties.sistem_komaki_dar_sarbalaee_bedorosti_amal_nemikonad
              .enum[2],
          taviz_dandeha_dar_heyn_harekat_ba_moshkel_anjam_mishavad:
            motorVaEnteghalAzmoon.properties.girboks_otomatik_heyn_harekat
              .properties
              .taviz_dandeha_dar_heyn_harekat_ba_moshkel_anjam_mishavad.enum[0],
          images: []
        },
        milgardan_va_difransiel_aghab_moharek_rwd: {
          status:
            motorVaEnteghalAzmoon.properties
              .milgardan_va_difransiel_aghab_moharek_rwd.properties.status
              .enum[1],
          dar_hein_shoroe_harekat_taghe_va_shock_be_samte_jolo_va_aghab_darad:
            motorVaEnteghalAzmoon.properties
              .milgardan_va_difransiel_aghab_moharek_rwd.properties
              .dar_hein_shoroe_harekat_taghe_va_shock_be_samte_jolo_va_aghab_darad
              .enum[0],
          hein_harekat_larzesh_va_sedaie_gheir_adi_be_gosh_miresad:
            motorVaEnteghalAzmoon.properties
              .milgardan_va_difransiel_aghab_moharek_rwd.properties
              .hein_harekat_larzesh_va_sedaie_gheir_adi_be_gosh_miresad.enum[0],
          nashti_roghan_darad:
            motorVaEnteghalAzmoon.properties
              .milgardan_va_difransiel_aghab_moharek_rwd.properties
              .nashti_roghan_darad.enum[0],
          images: []
        },
        milgardan_va_difransiel_charcharkh_moharek_awd_4wd: {
          status:
            motorVaEnteghalAzmoon.properties
              .milgardan_va_difransiel_charcharkh_moharek_awd_4wd.properties
              .status.enum[1],
          dar_hein_shoroe_harekat_taghe_va_shock_be_samte_jolo_va_aghab_darad:
            motorVaEnteghalAzmoon.properties
              .milgardan_va_difransiel_charcharkh_moharek_awd_4wd.properties
              .dar_hein_shoroe_harekat_taghe_va_shock_be_samte_jolo_va_aghab_darad
              .enum[0],
          hein_harekat_larzesh_va_sedaie_gheir_adi_be_gosh_miresad:
            motorVaEnteghalAzmoon.properties
              .milgardan_va_difransiel_charcharkh_moharek_awd_4wd.properties
              .hein_harekat_larzesh_va_sedaie_gheir_adi_be_gosh_miresad.enum[0],
          kilid_tanzim_mayob_ast:
            motorVaEnteghalAzmoon.properties
              .milgardan_va_difransiel_charcharkh_moharek_awd_4wd.properties
              .kilid_tanzim_mayob_ast.enum[0],
          nashti_roghan_darad:
            motorVaEnteghalAzmoon.properties
              .milgardan_va_difransiel_charcharkh_moharek_awd_4wd.properties
              .nashti_roghan_darad.enum[0],
          images: []
        },
        shetabgiri: {
          status:
            motorVaEnteghalAzmoon.properties.shetabgiri.properties.status
              .enum[1],
          dor_motor_dar_shuru_harekat_be_kondi_afzayesh_peyda_mikonad:
            motorVaEnteghalAzmoon.properties.shetabgiri.properties
              .dor_motor_dar_shuru_harekat_be_kondi_afzayesh_peyda_mikonad
              .enum[0],
          motor_seda_va_larzesh_gheire_adi_darad:
            motorVaEnteghalAzmoon.properties.shetabgiri.properties
              .motor_seda_va_larzesh_gheire_adi_darad.enum[0],
          otagh_khodro_seda_va_larzesh_gheire_adi_darad:
            motorVaEnteghalAzmoon.properties.shetabgiri.properties
              .otagh_khodro_seda_va_larzesh_gheire_adi_darad.enum[0],
          images: []
        }
      }
    },
    badane: {
      darbha_va_saghf: {
        saghf: {
          status: darbha_va_saghf.properties.saghf.properties.status.enum[0],
          tavizi_ast:
            darbha_va_saghf.properties.saghf.properties.tavizi_ast.enum[0],
          rang_shodegi:
            darbha_va_saghf.properties.saghf.properties.rang_shodegi.enum[0],
          abrang: darbha_va_saghf.properties.saghf.properties.abrang.enum[0],
          foro_raftegi:
            darbha_va_saghf.properties.saghf.properties.foro_raftegi.enum[0],
          safkari_bedonerang:
            darbha_va_saghf.properties.saghf.properties.safkari_bedonerang
              .enum[0],
          lise_giri:
            darbha_va_saghf.properties.saghf.properties.lise_giri.enum[0],
          khat_o_khash:
            darbha_va_saghf.properties.saghf.properties.khat_o_khash.enum[0],
          rang_sokhtegi:
            darbha_va_saghf.properties.saghf.properties.rang_sokhtegi.enum[0],
          images: []
        },
        darb_motor: {
          status:
            darbha_va_saghf.properties.darb_motor.properties.status.enum[0],
          tavizi_ast:
            darbha_va_saghf.properties.darb_motor.properties.tavizi_ast.enum[0],
          rang_shodegi:
            darbha_va_saghf.properties.darb_motor.properties.rang_shodegi
              .enum[0],
          abrang:
            darbha_va_saghf.properties.darb_motor.properties.abrang.enum[0],
          foro_raftegi:
            darbha_va_saghf.properties.darb_motor.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            darbha_va_saghf.properties.darb_motor.properties.safkari_bedonerang
              .enum[0],
          lise_giri:
            darbha_va_saghf.properties.darb_motor.properties.lise_giri.enum[0],
          khat_o_khash:
            darbha_va_saghf.properties.darb_motor.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            darbha_va_saghf.properties.darb_motor.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        dar_jolo_rast: {
          status:
            darbha_va_saghf.properties.dar_jolo_rast.properties.status.enum[0],
          tavizi_ast:
            darbha_va_saghf.properties.dar_jolo_rast.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            darbha_va_saghf.properties.dar_jolo_rast.properties.rang_shodegi
              .enum[0],
          abrang:
            darbha_va_saghf.properties.dar_jolo_rast.properties.abrang.enum[0],
          foro_raftegi:
            darbha_va_saghf.properties.dar_jolo_rast.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            darbha_va_saghf.properties.dar_jolo_rast.properties
              .safkari_bedonerang.enum[0],
          lise_giri:
            darbha_va_saghf.properties.dar_jolo_rast.properties.lise_giri
              .enum[0],
          khat_o_khash:
            darbha_va_saghf.properties.dar_jolo_rast.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            darbha_va_saghf.properties.dar_jolo_rast.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        dar_aghab_rast: {
          status:
            darbha_va_saghf.properties.dar_aghab_rast.properties.status.enum[0],
          tavizi_ast:
            darbha_va_saghf.properties.dar_aghab_rast.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            darbha_va_saghf.properties.dar_aghab_rast.properties.rang_shodegi
              .enum[0],
          abrang:
            darbha_va_saghf.properties.dar_aghab_rast.properties.abrang.enum[0],
          foro_raftegi:
            darbha_va_saghf.properties.dar_aghab_rast.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            darbha_va_saghf.properties.dar_aghab_rast.properties
              .safkari_bedonerang.enum[0],
          lise_giri:
            darbha_va_saghf.properties.dar_aghab_rast.properties.lise_giri
              .enum[0],
          khat_o_khash:
            darbha_va_saghf.properties.dar_aghab_rast.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            darbha_va_saghf.properties.dar_aghab_rast.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        dar_sandogh_aghab: {
          status:
            darbha_va_saghf.properties.dar_sandogh_aghab.properties.status
              .enum[0],
          tavizi_ast:
            darbha_va_saghf.properties.dar_sandogh_aghab.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            darbha_va_saghf.properties.dar_sandogh_aghab.properties.rang_shodegi
              .enum[0],
          abrang:
            darbha_va_saghf.properties.dar_sandogh_aghab.properties.abrang
              .enum[0],
          foro_raftegi:
            darbha_va_saghf.properties.dar_sandogh_aghab.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            darbha_va_saghf.properties.dar_sandogh_aghab.properties
              .safkari_bedonerang.enum[0],
          lise_giri:
            darbha_va_saghf.properties.dar_sandogh_aghab.properties.lise_giri
              .enum[0],
          khat_o_khash:
            darbha_va_saghf.properties.dar_sandogh_aghab.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            darbha_va_saghf.properties.dar_sandogh_aghab.properties
              .rang_sokhtegi.enum[0],
          images: []
        },
        dar_aghab_chap: {
          status:
            darbha_va_saghf.properties.dar_aghab_chap.properties.status.enum[0],
          tavizi_ast:
            darbha_va_saghf.properties.dar_aghab_chap.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            darbha_va_saghf.properties.dar_aghab_chap.properties.rang_shodegi
              .enum[0],
          abrang:
            darbha_va_saghf.properties.dar_aghab_chap.properties.abrang.enum[0],
          foro_raftegi:
            darbha_va_saghf.properties.dar_aghab_chap.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            darbha_va_saghf.properties.dar_aghab_chap.properties
              .safkari_bedonerang.enum[0],
          lise_giri:
            darbha_va_saghf.properties.dar_aghab_chap.properties.lise_giri
              .enum[0],
          khat_o_khash:
            darbha_va_saghf.properties.dar_aghab_chap.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            darbha_va_saghf.properties.dar_aghab_chap.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        dar_jolo_chap: {
          status:
            darbha_va_saghf.properties.dar_jolo_chap.properties.status.enum[0],
          tavizi_ast:
            darbha_va_saghf.properties.dar_jolo_chap.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            darbha_va_saghf.properties.dar_jolo_chap.properties.rang_shodegi
              .enum[0],
          abrang:
            darbha_va_saghf.properties.dar_jolo_chap.properties.abrang.enum[0],
          foro_raftegi:
            darbha_va_saghf.properties.dar_jolo_chap.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            darbha_va_saghf.properties.dar_jolo_chap.properties
              .safkari_bedonerang.enum[0],
          lise_giri:
            darbha_va_saghf.properties.dar_jolo_chap.properties.lise_giri
              .enum[0],
          khat_o_khash:
            darbha_va_saghf.properties.dar_jolo_chap.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            darbha_va_saghf.properties.dar_jolo_chap.properties.rang_sokhtegi
              .enum[0],
          images: []
        }
      },
      gelgirha: {
        gelgir_jolo_rast: {
          status:
            gelgirha.properties.gelgir_jolo_rast.properties.status.enum[0],
          tavizi_ast:
            gelgirha.properties.gelgir_jolo_rast.properties.tavizi_ast.enum[0],
          rang_shodegi:
            gelgirha.properties.gelgir_jolo_rast.properties.rang_shodegi
              .enum[0],
          abrang:
            gelgirha.properties.gelgir_jolo_rast.properties.abrang.enum[0],
          foro_raftegi:
            gelgirha.properties.gelgir_jolo_rast.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            gelgirha.properties.gelgir_jolo_rast.properties.safkari_bedonerang
              .enum[0],
          lise_giri:
            gelgirha.properties.gelgir_jolo_rast.properties.lise_giri.enum[0],
          khat_o_khash:
            gelgirha.properties.gelgir_jolo_rast.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            gelgirha.properties.gelgir_jolo_rast.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        gelgilr_aghab_rast: {
          status:
            gelgirha.properties.gelgilr_aghab_rast.properties.status.enum[0],
          tavizi_ast:
            gelgirha.properties.gelgilr_aghab_rast.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            gelgirha.properties.gelgilr_aghab_rast.properties.rang_shodegi
              .enum[0],
          abrang:
            gelgirha.properties.gelgilr_aghab_rast.properties.abrang.enum[0],
          foro_raftegi:
            gelgirha.properties.gelgilr_aghab_rast.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            gelgirha.properties.gelgilr_aghab_rast.properties.safkari_bedonerang
              .enum[0],
          lise_giri:
            gelgirha.properties.gelgilr_aghab_rast.properties.lise_giri.enum[0],
          khat_o_khash:
            gelgirha.properties.gelgilr_aghab_rast.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            gelgirha.properties.gelgilr_aghab_rast.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        gelgir_aghab_chap: {
          status:
            gelgirha.properties.gelgir_aghab_chap.properties.status.enum[0],
          tavizi_ast:
            gelgirha.properties.gelgir_aghab_chap.properties.tavizi_ast.enum[0],
          rang_shodegi:
            gelgirha.properties.gelgir_aghab_chap.properties.rang_shodegi
              .enum[0],
          abrang:
            gelgirha.properties.gelgir_aghab_chap.properties.abrang.enum[0],
          foro_raftegi:
            gelgirha.properties.gelgir_aghab_chap.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            gelgirha.properties.gelgir_aghab_chap.properties.safkari_bedonerang
              .enum[0],
          lise_giri:
            gelgirha.properties.gelgir_aghab_chap.properties.lise_giri.enum[0],
          khat_o_khash:
            gelgirha.properties.gelgir_aghab_chap.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            gelgirha.properties.gelgir_aghab_chap.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        gelgir_jolo_chap: {
          status:
            gelgirha.properties.gelgir_jolo_chap.properties.status.enum[0],
          tavizi_ast:
            gelgirha.properties.gelgir_jolo_chap.properties.tavizi_ast.enum[0],
          rang_shodegi:
            gelgirha.properties.gelgir_jolo_chap.properties.rang_shodegi
              .enum[0],
          abrang:
            gelgirha.properties.gelgir_jolo_chap.properties.abrang.enum[0],
          foro_raftegi:
            gelgirha.properties.gelgir_jolo_chap.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            gelgirha.properties.gelgir_jolo_chap.properties.safkari_bedonerang
              .enum[0],
          lise_giri:
            gelgirha.properties.gelgir_jolo_chap.properties.lise_giri.enum[0],
          khat_o_khash:
            gelgirha.properties.gelgir_jolo_chap.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            gelgirha.properties.gelgir_jolo_chap.properties.rang_sokhtegi
              .enum[0],
          images: []
        }
      },
      sotonha: {
        sotunhaye_jolo_rast: {
          status:
            sotonha.properties.sotunhaye_jolo_rast.properties.status.enum[0],
          tavizi_ast:
            sotonha.properties.sotunhaye_jolo_rast.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sotonha.properties.sotunhaye_jolo_rast.properties.rang_shodegi
              .enum[0],
          abrang:
            sotonha.properties.sotunhaye_jolo_rast.properties.abrang.enum[0],
          foro_raftegi:
            sotonha.properties.sotunhaye_jolo_rast.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            sotonha.properties.sotunhaye_jolo_rast.properties.safkari_bedonerang
              .enum[0],
          lise_giri:
            sotonha.properties.sotunhaye_jolo_rast.properties.lise_giri.enum[0],
          khat_o_khash:
            sotonha.properties.sotunhaye_jolo_rast.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            sotonha.properties.sotunhaye_jolo_rast.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        sotunhaye_vasat_rast: {
          status:
            sotonha.properties.sotunhaye_vasat_rast.properties.status.enum[0],
          tavizi_ast:
            sotonha.properties.sotunhaye_vasat_rast.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sotonha.properties.sotunhaye_vasat_rast.properties.rang_shodegi
              .enum[0],
          abrang:
            sotonha.properties.sotunhaye_vasat_rast.properties.abrang.enum[0],
          foro_raftegi:
            sotonha.properties.sotunhaye_vasat_rast.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            sotonha.properties.sotunhaye_vasat_rast.properties
              .safkari_bedonerang.enum[0],
          lise_giri:
            sotonha.properties.sotunhaye_vasat_rast.properties.lise_giri
              .enum[0],
          khat_o_khash:
            sotonha.properties.sotunhaye_vasat_rast.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            sotonha.properties.sotunhaye_vasat_rast.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        sotunhaye_aghab_rast: {
          status:
            sotonha.properties.sotunhaye_aghab_rast.properties.status.enum[0],
          tavizi_ast:
            sotonha.properties.sotunhaye_aghab_rast.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sotonha.properties.sotunhaye_aghab_rast.properties.rang_shodegi
              .enum[0],
          abrang:
            sotonha.properties.sotunhaye_aghab_rast.properties.abrang.enum[0],
          foro_raftegi:
            sotonha.properties.sotunhaye_aghab_rast.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            sotonha.properties.sotunhaye_aghab_rast.properties
              .safkari_bedonerang.enum[0],
          lise_giri:
            sotonha.properties.sotunhaye_aghab_rast.properties.lise_giri
              .enum[0],
          khat_o_khash:
            sotonha.properties.sotunhaye_aghab_rast.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            sotonha.properties.sotunhaye_aghab_rast.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        sotunhaye_aghab_chap: {
          status:
            sotonha.properties.sotunhaye_aghab_chap.properties.status.enum[0],
          tavizi_ast:
            sotonha.properties.sotunhaye_aghab_chap.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sotonha.properties.sotunhaye_aghab_chap.properties.rang_shodegi
              .enum[0],
          abrang:
            sotonha.properties.sotunhaye_aghab_chap.properties.abrang.enum[0],
          foro_raftegi:
            sotonha.properties.sotunhaye_aghab_chap.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            sotonha.properties.sotunhaye_aghab_chap.properties
              .safkari_bedonerang.enum[0],
          lise_giri:
            sotonha.properties.sotunhaye_aghab_chap.properties.lise_giri
              .enum[0],
          khat_o_khash:
            sotonha.properties.sotunhaye_aghab_chap.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            sotonha.properties.sotunhaye_aghab_chap.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        sotunhaye_vasat_chap: {
          status:
            sotonha.properties.sotunhaye_vasat_chap.properties.status.enum[0],
          tavizi_ast:
            sotonha.properties.sotunhaye_vasat_chap.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sotonha.properties.sotunhaye_vasat_chap.properties.rang_shodegi
              .enum[0],
          abrang:
            sotonha.properties.sotunhaye_vasat_chap.properties.abrang.enum[0],
          foro_raftegi:
            sotonha.properties.sotunhaye_vasat_chap.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            sotonha.properties.sotunhaye_vasat_chap.properties
              .safkari_bedonerang.enum[0],
          lise_giri:
            sotonha.properties.sotunhaye_vasat_chap.properties.lise_giri
              .enum[0],
          khat_o_khash:
            sotonha.properties.sotunhaye_vasat_chap.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            sotonha.properties.sotunhaye_vasat_chap.properties.rang_sokhtegi
              .enum[0],
          images: []
        },
        sotunhaye_jolo_chap: {
          status:
            sotonha.properties.sotunhaye_jolo_chap.properties.status.enum[0],
          tavizi_ast:
            sotonha.properties.sotunhaye_jolo_chap.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sotonha.properties.sotunhaye_jolo_chap.properties.rang_shodegi
              .enum[0],
          abrang:
            sotonha.properties.sotunhaye_jolo_chap.properties.abrang.enum[0],
          foro_raftegi:
            sotonha.properties.sotunhaye_jolo_chap.properties.foro_raftegi
              .enum[0],
          safkari_bedonerang:
            sotonha.properties.sotunhaye_jolo_chap.properties.safkari_bedonerang
              .enum[0],
          lise_giri:
            sotonha.properties.sotunhaye_jolo_chap.properties.lise_giri.enum[0],
          khat_o_khash:
            sotonha.properties.sotunhaye_jolo_chap.properties.khat_o_khash
              .enum[0],
          rang_sokhtegi:
            sotonha.properties.sotunhaye_jolo_chap.properties.rang_sokhtegi
              .enum[0],
          images: []
        }
      },
      rekabha: {
        rekab_rast: {
          status: rekabha.properties.rekab_rast.properties.status.enum[0],
          tavizi_ast:
            rekabha.properties.rekab_rast.properties.tavizi_ast.enum[0],
          rang_shodegi:
            rekabha.properties.rekab_rast.properties.rang_shodegi.enum[0],
          abrang: rekabha.properties.rekab_rast.properties.abrang.enum[0],
          foro_raftegi:
            rekabha.properties.rekab_rast.properties.foro_raftegi.enum[0],
          safkari_bedonerang:
            rekabha.properties.rekab_rast.properties.safkari_bedonerang.enum[0],
          lise_giri: rekabha.properties.rekab_rast.properties.lise_giri.enum[0],
          khat_o_khash:
            rekabha.properties.rekab_rast.properties.khat_o_khash.enum[0],
          rang_sokhtegi:
            rekabha.properties.rekab_rast.properties.rang_sokhtegi.enum[0],
          images: []
        },
        rekab_chap: {
          status: rekabha.properties.rekab_chap.properties.status.enum[0],
          tavizi_ast:
            rekabha.properties.rekab_chap.properties.tavizi_ast.enum[0],
          rang_shodegi:
            rekabha.properties.rekab_chap.properties.rang_shodegi.enum[0],
          abrang: rekabha.properties.rekab_chap.properties.abrang.enum[0],
          foro_raftegi:
            rekabha.properties.rekab_chap.properties.foro_raftegi.enum[0],
          safkari_bedonerang:
            rekabha.properties.rekab_chap.properties.safkari_bedonerang.enum[0],
          lise_giri: rekabha.properties.rekab_chap.properties.lise_giri.enum[0],
          khat_o_khash:
            rekabha.properties.rekab_chap.properties.khat_o_khash.enum[0],
          rang_sokhtegi:
            rekabha.properties.rekab_chap.properties.rang_sokhtegi.enum[0],
          images: []
        }
      },
      sarshasiha: {
        sarshasi_jolo_rast: {
          status:
            sarshasiha.properties.sarshasi_jolo_rast.properties.status.enum[0],
          tavizi_ast:
            sarshasiha.properties.sarshasi_jolo_rast.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sarshasiha.properties.sarshasi_jolo_rast.properties.rang_shodegi
              .enum[0],
          asib_didegi_darad:
            sarshasiha.properties.sarshasi_jolo_rast.properties
              .asib_didegi_darad.enum[0],
          images: []
        },
        sarshasi_jolo_chap: {
          status:
            sarshasiha.properties.sarshasi_jolo_chap.properties.status.enum[0],
          tavizi_ast:
            sarshasiha.properties.sarshasi_jolo_chap.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sarshasiha.properties.sarshasi_jolo_chap.properties.rang_shodegi
              .enum[0],
          asib_didegi_darad:
            sarshasiha.properties.sarshasi_jolo_chap.properties
              .asib_didegi_darad.enum[0],
          images: []
        },
        sarshasi_aghab_rast: {
          status:
            sarshasiha.properties.sarshasi_aghab_rast.properties.status.enum[0],
          tavizi_ast:
            sarshasiha.properties.sarshasi_aghab_rast.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sarshasiha.properties.sarshasi_aghab_rast.properties.rang_shodegi
              .enum[0],
          asib_didgegi:
            sarshasiha.properties.sarshasi_aghab_rast.properties.asib_didgegi
              .enum[0],
          images: []
        },
        sarshasi_aghab_chap: {
          status:
            sarshasiha.properties.sarshasi_aghab_chap.properties.status.enum[0],
          tavizi_ast:
            sarshasiha.properties.sarshasi_aghab_chap.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            sarshasiha.properties.sarshasi_aghab_chap.properties.rang_shodegi
              .enum[0],
          asib_didgegi:
            sarshasiha.properties.sarshasi_aghab_chap.properties.asib_didgegi
              .enum[0],
          images: []
        }
      },
      mohavateha: {
        mohavate_motor: {
          status:
            mohavateha.properties.mohavate_motor.properties.status.enum[0],
          tavizi_ast:
            mohavateha.properties.mohavate_motor.properties.tavizi_ast.enum[0],
          rang_shodegi:
            mohavateha.properties.mohavate_motor.properties.rang_shodegi
              .enum[0],
          asib_didgegi:
            mohavateha.properties.mohavate_motor.properties.asib_didgegi
              .enum[0],
          images: []
        },
        mohavate_sandogh: {
          status:
            mohavateha.properties.mohavate_sandogh.properties.status.enum[0],
          tavizi_ast:
            mohavateha.properties.mohavate_sandogh.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            mohavateha.properties.mohavate_sandogh.properties.rang_shodegi
              .enum[0],
          asib_didgegi:
            mohavateha.properties.mohavate_sandogh.properties.asib_didgegi
              .enum[0],
          images: []
        },
        mohavate_charkh_zapas: {
          status:
            mohavateha.properties.mohavate_charkh_zapas.properties.status
              .enum[0],
          tavizi_ast:
            mohavateha.properties.mohavate_charkh_zapas.properties.tavizi_ast
              .enum[0],
          rang_shodegi:
            mohavateha.properties.mohavate_charkh_zapas.properties.rang_shodegi
              .enum[0],
          asib_didgegi:
            mohavateha.properties.mohavate_charkh_zapas.properties.asib_didgegi
              .enum[0],
          images: []
        }
      },
      separha: {
        separ_jolo: {
          status: separha.properties.separ_jolo.properties.status.enum[0],
          khat_o_khash_ya_ghalam_giri_ya_rang_shodegi:
            separha.properties.separ_jolo.properties
              .khat_o_khash_ya_ghalam_giri_ya_rang_shodegi.enum[0],
          salamat_zaheri_nadarad:
            separha.properties.separ_jolo.properties.salamat_zaheri_nadarad
              .enum[0],
          images: []
        },
        separ_aghab: {
          status: separha.properties.separ_aghab.properties.status.enum[0],
          khat_o_khash_ya_ghalam_giri_ya_rang_shodegi:
            separha.properties.separ_aghab.properties
              .khat_o_khash_ya_ghalam_giri_ya_rang_shodegi.enum[0],
          salamat_zaheri_nadarad:
            separha.properties.separ_aghab.properties.salamat_zaheri_nadarad
              .enum[0],
          images: []
        }
      },
      shisheha: {
        shishe_va_navarhaye_jolo: {
          status:
            shisheha.properties.shishe_va_navarhaye_jolo.properties.status
              .enum[0],
          salamat_zaheri_nadarad:
            shisheha.properties.shishe_va_navarhaye_jolo.properties
              .salamat_zaheri_nadarad.enum[0],
          sehat_nasb:
            shisheha.properties.shishe_va_navarhaye_jolo.properties.sehat_nasb
              .enum[0],
          images: []
        },
        shishe_va_navar_abgir_dar_ranande: {
          status:
            shisheha.properties.shishe_va_navar_abgir_dar_ranande.properties
              .status.enum[0],
          salamat_zaheri_nadarad:
            shisheha.properties.shishe_va_navar_abgir_dar_ranande.properties
              .salamat_zaheri_nadarad.enum[0],
          sehat_nasb:
            shisheha.properties.shishe_va_navar_abgir_dar_ranande.properties
              .sehat_nasb.enum[0],
          images: []
        },
        shishe_va_navar_abgir_dar_aghab_rast: {
          status:
            shisheha.properties.shishe_va_navar_abgir_dar_aghab_rast.properties
              .status.enum[0],
          salamat_zaheri_nadarad:
            shisheha.properties.shishe_va_navar_abgir_dar_aghab_rast.properties
              .salamat_zaheri_nadarad.enum[0],
          sehat_nasb:
            shisheha.properties.shishe_va_navar_abgir_dar_aghab_rast.properties
              .sehat_nasb.enum[0],
          images: []
        },
        shishe_va_navarhaye_aghab: {
          status:
            shisheha.properties.shishe_va_navarhaye_aghab.properties.status
              .enum[0],
          salamat_zaheri_nadarad:
            shisheha.properties.shishe_va_navarhaye_aghab.properties
              .salamat_zaheri_nadarad.enum[0],
          sehat_nasb:
            shisheha.properties.shishe_va_navarhaye_aghab.properties.sehat_nasb
              .enum[0],
          images: []
        },
        shishe_va_navar_abgir_dar_aghab_chap: {
          status:
            shisheha.properties.shishe_va_navar_abgir_dar_aghab_chap.properties
              .status.enum[0],
          salamat_zaheri_nadarad:
            shisheha.properties.shishe_va_navar_abgir_dar_aghab_chap.properties
              .salamat_zaheri_nadarad.enum[0],
          sehat_nasb:
            shisheha.properties.shishe_va_navar_abgir_dar_aghab_chap.properties
              .sehat_nasb.enum[0],
          images: []
        },
        shishe_va_navar_abgir_dar_sarneshin_jolo: {
          status:
            shisheha.properties.shishe_va_navar_abgir_dar_sarneshin_jolo
              .properties.status.enum[0],
          salamat_zaheri_nadarad:
            shisheha.properties.shishe_va_navar_abgir_dar_sarneshin_jolo
              .properties.salamat_zaheri_nadarad.enum[0],
          sehat_nasb:
            shisheha.properties.shishe_va_navar_abgir_dar_sarneshin_jolo
              .properties.sehat_nasb.enum[0],
          images: []
        }
      },
      description: ""
    },
    apshen: {
      janebie: {
        bogh: {
          status: janebie.properties.bogh.properties.status.enum[0],
          bedorosti_amal_nemikonad:
            janebie.properties.bogh.properties.bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        tanzim_konande_mogheyate_gharbilak: {
          status:
            janebie.properties.tanzim_konande_mogheyate_gharbilak.properties
              .status.enum[0],
          bedorosti_amal_nemikonad:
            janebie.properties.tanzim_konande_mogheyate_gharbilak.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        sistem_soti_tasviri: {
          status:
            janebie.properties.sistem_soti_tasviri.properties.status.enum[0],
          bedorosti_amal_nemikonad:
            janebie.properties.sistem_soti_tasviri.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        jak_ya_achar_charkh: {
          status:
            janebie.properties.jak_ya_achar_charkh.properties.status.enum[0],
          vojod_nadarad_ya_bedorosti_amal_nemikonad:
            janebie.properties.jak_ya_achar_charkh.properties
              .vojod_nadarad_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        anten: {
          status: janebie.properties.anten.properties.status.enum[0],
          vojod_nadarad_ya_mayub_ast:
            janebie.properties.anten.properties.vojod_nadarad_ya_mayub_ast
              .enum[0],
          images: []
        },
        mekanizm_sandoghparan: {
          status:
            janebie.properties.mekanizm_sandoghparan.properties.status.enum[0],
          bedorosti_amal_nemikonad:
            janebie.properties.mekanizm_sandoghparan.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        darbazkon_pomp_benzin: {
          status:
            janebie.properties.darbazkon_pomp_benzin.properties.status.enum[0],
          mayub_ast:
            janebie.properties.darbazkon_pomp_benzin.properties.mayub_ast
              .enum[0],
          images: []
        }
      },
      refahi: {
        namayeshgar_lcd: {
          status: refahi.properties.namayeshgar_lcd.properties.status.enum[1],
          mayub_ast:
            refahi.properties.namayeshgar_lcd.properties.mayub_ast.enum[0],
          images: []
        },
        sunroof_ya_panaroma: {
          status:
            refahi.properties.sunroof_ya_panaroma.properties.status.enum[1],
          mayub_ast_ya_bedorosti_amal_nemikonad:
            refahi.properties.sunroof_ya_panaroma.properties
              .mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        koroz_kontorol: {
          status: refahi.properties.koroz_kontorol.properties.status.enum[1],
          bedorosti_amal_nemikonad:
            refahi.properties.koroz_kontorol.properties.bedorosti_amal_nemikonad
              .enum[0],
          images: []
        },
        sistem_otomatik_faalsazi_cgeraghha: {
          status:
            refahi.properties.sistem_otomatik_faalsazi_cgeraghha.properties
              .status.enum[1],
          bedorosti_amal_nemikonad:
            refahi.properties.sistem_otomatik_faalsazi_cgeraghha.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        sistem_vorod_bedone_kilid: {
          status:
            refahi.properties.sistem_vorod_bedone_kilid.properties.status
              .enum[1],
          bedorosti_amal_nemikonad:
            refahi.properties.sistem_vorod_bedone_kilid.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        garmkon_farman: {
          status: refahi.properties.garmkon_farman.properties.status.enum[1],
          bedorosti_amal_nemikonad:
            refahi.properties.garmkon_farman.properties.bedorosti_amal_nemikonad
              .enum[0],
          images: []
        },
        sensore_baran: {
          status: refahi.properties.sensore_baran.properties.status.enum[1],
          mayub_ast:
            refahi.properties.sensore_baran.properties.mayub_ast.enum[0],
          images: []
        },
        kilid_tnazim_vaziat_ranandegi: {
          status:
            refahi.properties.kilid_tnazim_vaziat_ranandegi.properties.status
              .enum[1],
          bedorosti_amal_nemikonad:
            refahi.properties.kilid_tnazim_vaziat_ranandegi.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        namyeshgar_kilometr_roye_shishe: {
          status:
            refahi.properties.namyeshgar_kilometr_roye_shishe.properties.status
              .enum[1],
          bedorosti_amal_nemikonad:
            refahi.properties.namyeshgar_kilometr_roye_shishe.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        aouto_park: {
          status: refahi.properties.aouto_park.properties.status.enum[1],
          bedorosti_amal_nemikonad:
            refahi.properties.aouto_park.properties.bedorosti_amal_nemikonad
              .enum[0],
          images: []
        },
        masazhor_sandali: {
          status: refahi.properties.masazhor_sandali.properties.status.enum[1],
          bedorosti_amal_nemikonad:
            refahi.properties.masazhor_sandali.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        tanzim_konande_ertefa_sandogh_aghab: {
          status:
            refahi.properties.tanzim_konande_ertefa_sandogh_aghab.properties
              .status.enum[1],
          bedorosti_amal_nemikonad:
            refahi.properties.tanzim_konande_ertefa_sandogh_aghab.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        pardehaye_khodro: {
          status: refahi.properties.pardehaye_khodro.properties.status.enum[1],
          mayub_ast_ya_bedorosti_amal_nemikonad:
            refahi.properties.pardehaye_khodro.properties
              .mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        cheragh_shor_jolo: {
          status: refahi.properties.cheragh_shor_jolo.properties.status.enum[1],
          mayub_ast:
            refahi.properties.cheragh_shor_jolo.properties.mayub_ast.enum[0],
          images: []
        }
      },
      iemeni: {
        namayeshgar_noghte_kor_bsd_ya_bsm: {
          status:
            iemeni.properties.namayeshgar_noghte_kor_bsd_ya_bsm.properties
              .status.enum[1],
          bedorosti_amal_nemikonad:
            iemeni.properties.namayeshgar_noghte_kor_bsd_ya_bsm.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        sensore_park_mayob_ast: {
          status:
            iemeni.properties.sensore_park_mayob_ast.properties.status.enum[1],
          bedorosti_amal_nemikonad:
            iemeni.properties.sensore_park_mayob_ast.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        dorbin_sisado_shast: {
          status:
            iemeni.properties.dorbin_sisado_shast.properties.status.enum[1],
          mayub_ast:
            iemeni.properties.dorbin_sisado_shast.properties.mayub_ast.enum[0],
          images: []
        },
        dorbin_dande_aghab: {
          status:
            iemeni.properties.dorbin_dande_aghab.properties.status.enum[1],
          mayub_ast:
            iemeni.properties.dorbin_dande_aghab.properties.mayub_ast.enum[0],
          images: []
        },
        hoshdar_dahande_harekat_bein_khotot: {
          status:
            iemeni.properties.hoshdar_dahande_harekat_bein_khotot.properties
              .status.enum[1],
          mayub_ast:
            iemeni.properties.hoshdar_dahande_harekat_bein_khotot.properties
              .mayub_ast.enum[0],
          images: []
        },
        tormoz_otomatik_auto_hold: {
          status:
            iemeni.properties.tormoz_otomatik_auto_hold.properties.status
              .enum[1],
          bedorosti_amal_nemikonad:
            iemeni.properties.tormoz_otomatik_auto_hold.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        }
      },
      description: ""
    },
    tormoz_farman_va_system_taligh: {
      description: "",
      fanarbandi_dar_ja: {
        fanarbandi_dar_ja_samte_ranande_aghab: {
          status:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_ranande_aghab.properties.status.enum[0],
          asib_didegi_darad:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_ranande_aghab.properties
              .asib_didegi_darad.enum[0],
          roghan_zadegi_darad:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_ranande_aghab.properties
              .roghan_zadegi_darad.enum[0],
          images: []
        },
        fanarbandi_dar_ja_samte_ranande_jolo: {
          status:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_ranande_jolo.properties.status.enum[0],
          asib_didegi_darad:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_ranande_jolo.properties.asib_didegi_darad
              .enum[0],
          roghan_zadegi_darad:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_ranande_jolo.properties
              .roghan_zadegi_darad.enum[0],
          images: []
        },
        fanarbandi_dar_ja_samte_sarneshin_aghab: {
          status:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_sarneshin_aghab.properties.status
              .enum[0],
          asib_didegi_darad:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_sarneshin_aghab.properties
              .asib_didegi_darad.enum[0],
          roghan_zadegi_darad:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_sarneshin_aghab.properties
              .roghan_zadegi_darad.enum[0],
          images: []
        },
        fanarbandi_dar_ja_samte_sarneshin_jolo: {
          status:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_sarneshin_jolo.properties.status.enum[0],
          asib_didegi_darad:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_sarneshin_jolo.properties
              .asib_didegi_darad.enum[0],
          roghan_zadegi_darad:
            talighVaFarmanFanarBandi.properties
              .fanarbandi_dar_ja_samte_sarneshin_jolo.properties
              .roghan_zadegi_darad.enum[0],
          images: []
        }
      },
      farman: {
        farman_barghi: {
          status:
            talighVaFarmanFarman.properties.farman_barghi.properties.status
              .enum[0],
          niruye_komaki_barghi_eps_mayub_ast:
            talighVaFarmanFarman.properties.farman_barghi.properties
              .niruye_komaki_barghi_eps_mayub_ast.enum[0],
          images: []
        },
        farman_hydrolik: {
          status:
            talighVaFarmanFarman.properties.farman_hydrolik.properties.status
              .enum[0],
          mayub_ast:
            talighVaFarmanFarman.properties.farman_hydrolik.properties.mayub_ast
              .enum[0],
          rang_maye_hydrolik_monaseb_nist:
            talighVaFarmanFarman.properties.farman_hydrolik.properties
              .rang_maye_hydrolik_monaseb_nist.enum[0],
          sistem_hydrolik_nashti_darad:
            talighVaFarmanFarman.properties.farman_hydrolik.properties
              .sistem_hydrolik_nashti_darad.enum[0],
          images: []
        },
        farman_mamooli: {
          status:
            talighVaFarmanFarman.properties.farman_mamooli.properties.status
              .enum[0],
          khalasi_darad:
            talighVaFarmanFarman.properties.farman_mamooli.properties
              .khalasi_darad.enum[0],
          mayub_ast:
            talighVaFarmanFarman.properties.farman_mamooli.properties.mayub_ast
              .enum[0],
          images: []
        }
      },
      tormoz: {
        vaziat_tormoz_dar_halat_darja: {
          status:
            talighVaFarmanTormoz.properties.vaziat_tormoz_dar_halat_darja
              .properties.status.enum[0],
          boster_amalkard_monaseb_nadarad:
            talighVaFarmanTormoz.properties.vaziat_tormoz_dar_halat_darja
              .properties.boster_amalkard_monaseb_nadarad.enum[0],
          halat_esfanji_darad:
            talighVaFarmanTormoz.properties.vaziat_tormoz_dar_halat_darja
              .properties.halat_esfanji_darad.enum[0],
          roghan_tormoz_nashti_darad:
            talighVaFarmanTormoz.properties.vaziat_tormoz_dar_halat_darja
              .properties.roghan_tormoz_nashti_darad.enum[0],
          roghan_tormoz_rang_gheir_tabie_darad:
            talighVaFarmanTormoz.properties.vaziat_tormoz_dar_halat_darja
              .properties.roghan_tormoz_rang_gheir_tabie_darad.enum[0],
          images: []
        }
      },
      tormoz_dasti: {
        tormoz_dasti_barghi: {
          status:
            talighVaFarmanTormozDasti.properties.tormoz_dasti_barghi.properties
              .status.enum[0],
          tormoz_dasti_barghi_epb_be_doristi_amal_nemikonad:
            talighVaFarmanTormozDasti.properties.tormoz_dasti_barghi.properties
              .tormoz_dasti_barghi_epb_be_doristi_amal_nemikonad.enum[0],
          images: []
        },
        tormoz_dasti_mamooli: {
          status:
            talighVaFarmanTormozDasti.properties.tormoz_dasti_mamooli.properties
              .status.enum[0],
          bedorosti_amal_nemikonad:
            talighVaFarmanTormozDasti.properties.tormoz_dasti_mamooli.properties
              .bedorosti_amal_nemikonad.enum[0],
          images: []
        }
      },
      tormoz_farman_va_system_taligh_azmone_ranandegi: {
        fanarbandi_samte_ranande_aghab: {
          status:
            talighVaFarmanTormozFarmanVaSystem.properties
              .fanarbandi_samte_ranande_aghab.properties.status.enum[0],
          mayub_ast_ya_seday_gheir_adi_darad:
            talighVaFarmanTormozFarmanVaSystem.properties
              .fanarbandi_samte_ranande_aghab.properties
              .mayub_ast_ya_seday_gheir_adi_darad.enum[0],
          images: []
        },
        fanarbandi_samte_ranande_jolo: {
          status:
            talighVaFarmanTormozFarmanVaSystem.properties
              .fanarbandi_samte_ranande_jolo.properties.status.enum[0],
          mayub_ast_ya_seday_gheir_adi_darad:
            talighVaFarmanTormozFarmanVaSystem.properties
              .fanarbandi_samte_ranande_jolo.properties
              .mayub_ast_ya_seday_gheir_adi_darad.enum[0],
          images: []
        },
        fanarbandi_samte_sarneshin_aghab: {
          status:
            talighVaFarmanTormozFarmanVaSystem.properties
              .fanarbandi_samte_sarneshin_aghab.properties.status.enum[0],
          mayub_ast_ya_seday_gheir_adi_darad:
            talighVaFarmanTormozFarmanVaSystem.properties
              .fanarbandi_samte_sarneshin_aghab.properties
              .mayub_ast_ya_seday_gheir_adi_darad.enum[0],
          images: []
        },
        fanarbandi_samte_sarneshin_jolo: {
          status:
            talighVaFarmanTormozFarmanVaSystem.properties
              .fanarbandi_samte_sarneshin_jolo.properties.status.enum[0],
          mayub_ast_ya_seday_gheir_adi_darad:
            talighVaFarmanTormozFarmanVaSystem.properties
              .fanarbandi_samte_sarneshin_jolo.properties
              .mayub_ast_ya_seday_gheir_adi_darad.enum[0],
          images: []
        },
        farman_azmone_ranandegi: {
          status:
            talighVaFarmanTormozFarmanVaSystem.properties
              .farman_azmone_ranandegi.properties.status.enum[0],
          farman_dar_heyn_harekat_be_yek_samt_keshide_mishavad:
            talighVaFarmanTormozFarmanVaSystem.properties
              .farman_azmone_ranandegi.properties
              .farman_dar_heyn_harekat_be_yek_samt_keshide_mishavad.enum[0],
          farman_dar_heyn_harekat_sedyae_gheir_adi_ya_larzesh_darad:
            talighVaFarmanTormozFarmanVaSystem.properties
              .farman_azmone_ranandegi.properties
              .farman_dar_heyn_harekat_sedyae_gheir_adi_ya_larzesh_darad
              .enum[0],
          gharbilak_dar_heyn_harekat_tanzim_nist:
            talighVaFarmanTormozFarmanVaSystem.properties
              .farman_azmone_ranandegi.properties
              .gharbilak_dar_heyn_harekat_tanzim_nist.enum[0],
          images: []
        },
        taligh: {
          status:
            talighVaFarmanTormozFarmanVaSystem.properties.taligh.properties
              .status.enum[0],
          sistem_jolobandi_mayub_ast:
            talighVaFarmanTormozFarmanVaSystem.properties.taligh.properties
              .sistem_jolobandi_mayub_ast.enum[0],
          sistem_taligh_aghab_mayub_ast:
            talighVaFarmanTormozFarmanVaSystem.properties.taligh.properties
              .sistem_taligh_aghab_mayub_ast.enum[0],
          images: []
        },
        vaziat_amalkard_tormoz: {
          status:
            talighVaFarmanTormozFarmanVaSystem.properties.vaziat_amalkard_tormoz
              .properties.status.enum[0],
          abs_mayub_ast:
            talighVaFarmanTormozFarmanVaSystem.properties.vaziat_amalkard_tormoz
              .properties.abs_mayub_ast.enum[1],
          esp_mayub_ast:
            talighVaFarmanTormozFarmanVaSystem.properties.vaziat_amalkard_tormoz
              .properties.esp_mayub_ast.enum[2],
          tormoz_dar_heyn_harekat_amalkard_monaseb_nadarad:
            talighVaFarmanTormozFarmanVaSystem.properties.vaziat_amalkard_tormoz
              .properties.tormoz_dar_heyn_harekat_amalkard_monaseb_nadarad
              .enum[0],
          tormoz_dar_heyn_harekat_be_yek_samt_keshide_mishavad:
            talighVaFarmanTormozFarmanVaSystem.properties.vaziat_amalkard_tormoz
              .properties.tormoz_dar_heyn_harekat_be_yek_samt_keshide_mishavad
              .enum[0],
          tormoz_dar_heyn_harekat_seday_gheir_adi_darad:
            talighVaFarmanTormozFarmanVaSystem.properties.vaziat_amalkard_tormoz
              .properties.tormoz_dar_heyn_harekat_seday_gheir_adi_darad.enum[0],
          images: []
        }
      }
    },
    paper_report_code: {
      paper_report_code: ""
    },
    special_description: {
      special_description: ""
    },
    fazaie_dakhel_va_tajhizate_barghi: {
      description: "",
      fazaie_dakheli: {
        sandali_tajhizat_va_trime_samte_ranande: {
          status:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_ranande
              .properties.status.enum[0],
          sandali_va_trim_salamat_zaheri_ya_estehkame_nasb_nadarad:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_ranande
              .properties
              .sandali_va_trim_salamat_zaheri_ya_estehkame_nasb_nadarad.enum[0],
          tanzimat_vaziat_sandali_barghi_mekaniki_bedorosti_amal_nemikonad:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_ranande
              .properties
              .tanzimat_vaziat_sandali_barghi_mekaniki_bedorosti_amal_nemikonad
              .enum[0],
          garmkon_ya_tahviye_sandali_mayub_ast:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_ranande
              .properties.garmkon_ya_tahviye_sandali_mayub_ast.enum[2],
          sistem_hafeze_yekparche_sandali_bedorosti_amal_nemikonad:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_ranande
              .properties
              .sistem_hafeze_yekparche_sandali_bedorosti_amal_nemikonad.enum[2],
          images: []
        },
        sandali_tajhizat_va_trime_samte_sarneshin: {
          status:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_sarneshin
              .properties.status.enum[0],
          sandali_va_trim_salamat_zaheri_ya_estehkame_nasb_nadarad:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_sarneshin
              .properties
              .sandali_va_trim_salamat_zaheri_ya_estehkame_nasb_nadarad.enum[0],
          tanzimat_vaziat_sandali_barghi_mekaniki_bedorosti_amal_nemikonad:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_sarneshin
              .properties
              .tanzimat_vaziat_sandali_barghi_mekaniki_bedorosti_amal_nemikonad
              .enum[0],
          garmkon_ya_tahviye_sandali_mayub_ast:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_sarneshin
              .properties.garmkon_ya_tahviye_sandali_mayub_ast.enum[2],
          sistem_hafeze_yekparche_sandali_bedorosti_amal_nemikonad:
            fazaie_dakheli.properties.sandali_tajhizat_va_trime_samte_sarneshin
              .properties
              .sistem_hafeze_yekparche_sandali_bedorosti_amal_nemikonad.enum[2],
          images: []
        },
        dashbord: {
          status: fazaie_dakheli.properties.dashbord.properties.status.enum[0],
          dashbord_salamate_zaheri_nadarad:
            fazaie_dakheli.properties.dashbord.properties
              .dashbord_salamate_zaheri_nadarad.enum[0],
          images: []
        },
        sandali_tajhizat_va_trimhaye_aghab: {
          status:
            fazaie_dakheli.properties.sandali_tajhizat_va_trimhaye_aghab
              .properties.status.enum[0],
          salamat_zaheri_ya_estehkame_nasb_nadarad:
            fazaie_dakheli.properties.sandali_tajhizat_va_trimhaye_aghab
              .properties.salamat_zaheri_ya_estehkame_nasb_nadarad.enum[0],
          tanzimat_vaziat_sandali_barghi_mekaniki_bedorosti_amal_nemikonad:
            fazaie_dakheli.properties.sandali_tajhizat_va_trimhaye_aghab
              .properties
              .tanzimat_vaziat_sandali_barghi_mekaniki_bedorosti_amal_nemikonad
              .enum[0],
          garmkon_ya_tahviye_sandali_mayub_ast:
            fazaie_dakheli.properties.sandali_tajhizat_va_trimhaye_aghab
              .properties.garmkon_ya_tahviye_sandali_mayub_ast.enum[2],
          images: []
        },
        sandali_tajhizat_va_trimhaye_radife_sevom: {
          status:
            fazaie_dakheli.properties.sandali_tajhizat_va_trimhaye_radife_sevom
              .properties.status.enum[1],
          sandali_va_trim_salamat_zaheri_ya_estehkame_nasb_nadarad:
            fazaie_dakheli.properties.sandali_tajhizat_va_trimhaye_radife_sevom
              .properties
              .sandali_va_trim_salamat_zaheri_ya_estehkame_nasb_nadarad.enum[0],
          tanzimat_vaziat_sandaliha_mayub_ast:
            fazaie_dakheli.properties.sandali_tajhizat_va_trimhaye_radife_sevom
              .properties.tanzimat_vaziat_sandaliha_mayub_ast.enum[0],
          images: []
        }
      },
      barf_pak_kon: {
        barf_pak_kon_jolo: {
          status:
            barf_pak_kon.properties.barf_pak_kon_jolo.properties.status.enum[0],
          barf_pak_kon_va_shishe_shur_jolo_bedorosti_amal_nemikonad:
            barf_pak_kon.properties.barf_pak_kon_jolo.properties
              .barf_pak_kon_va_shishe_shur_jolo_bedorosti_amal_nemikonad
              .enum[0],
          daste_barf_pak_kon_mayub_ast_ya_bedorosti_amal_nemikonad:
            barf_pak_kon.properties.barf_pak_kon_jolo.properties
              .daste_barf_pak_kon_mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        bar_pak_kon: {
          status:
            barf_pak_kon.properties.barf_pak_kon_jolo.properties.status.enum[0],
          barf_pak_kon_va_shishe_shur_jolo_bedorosti_amal_nemikonad:
            barf_pak_kon.properties.bar_pak_kon.properties
              .barf_pak_kon_va_shishe_shur_jolo_bedorosti_amal_nemikonad
              .enum[0],
          daste_barf_pak_kon_mayub_ast_ya_bedorosti_amal_nemikonad:
            barf_pak_kon.properties.bar_pak_kon.properties
              .daste_barf_pak_kon_mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        }
      },
      shishe_balabar: {
        samte_ranande_jolo: {
          status:
            shishe_balabar.properties.samte_ranande_jolo.properties.status
              .enum[0],
          shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad:
            shishe_balabar.properties.samte_ranande_jolo.properties
              .shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad
              .enum[0],
          images: []
        },
        samte_ranande_aghab: {
          status:
            shishe_balabar.properties.samte_ranande_aghab.properties.status
              .enum[0],
          shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad:
            shishe_balabar.properties.samte_ranande_aghab.properties
              .shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad
              .enum[0],
          images: []
        },
        samte_sarneshin_aghab: {
          status:
            shishe_balabar.properties.samte_sarneshin_aghab.properties.status
              .enum[0],
          shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad:
            shishe_balabar.properties.samte_sarneshin_aghab.properties
              .shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad
              .enum[0],
          images: []
        },
        samte_sarneshin_jolo: {
          status:
            shishe_balabar.properties.samte_sarneshin_jolo.properties.status
              .enum[0],
          shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad:
            shishe_balabar.properties.samte_sarneshin_jolo.properties
              .shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad
              .enum[0],
          images: []
        }
      },
      ayeneha: {
        samte_ranande: {
          status: ayeneha.properties.samte_ranande.properties.status.enum[0],
          salamat_zaheri_nadarad:
            ayeneha.properties.samte_ranande.properties.salamat_zaheri_nadarad
              .enum[0],
          mekanizm_tanzim_shishe_ayene_bedorosti_amal_nemikonad:
            ayeneha.properties.samte_ranande.properties
              .mekanizm_tanzim_shishe_ayene_bedorosti_amal_nemikonad.enum[0],
          mekanizm_jam_shavandegi_ayene_bedorosti_amal_nemikonad:
            ayeneha.properties.samte_ranande.properties
              .mekanizm_jam_shavandegi_ayene_bedorosti_amal_nemikonad.enum[0],
          rahnama_mayub_ast_ya_mayub_ast_ya_bedorosti_amal_nemikonad:
            ayeneha.properties.samte_ranande.properties
              .rahnama_mayub_ast_ya_mayub_ast_ya_bedorosti_amal_nemikonad
              .enum[0],
          images: []
        },
        samte_sarneshin: {
          status: ayeneha.properties.samte_sarneshin.properties.status.enum[0],
          salamat_zaheri_nadarad:
            ayeneha.properties.samte_sarneshin.properties.salamat_zaheri_nadarad
              .enum[0],
          mekanizm_tanzim_shishe_ayene_bedorosti_amal_nemikonad:
            ayeneha.properties.samte_sarneshin.properties
              .mekanizm_tanzim_shishe_ayene_bedorosti_amal_nemikonad.enum[0],
          mekanizm_jam_shavandegi_ayene_bedorosti_amal_nemikonad:
            ayeneha.properties.samte_sarneshin.properties
              .mekanizm_jam_shavandegi_ayene_bedorosti_amal_nemikonad.enum[0],
          rahnama_mayub_ast_ya_mayub_ast_ya_bedorosti_amal_nemikonad:
            ayeneha.properties.samte_sarneshin.properties
              .rahnama_mayub_ast_ya_mayub_ast_ya_bedorosti_amal_nemikonad
              .enum[0],
          images: []
        },
        dide_aghab: {
          status: ayeneha.properties.dide_aghab.properties.status.enum[0],
          ayene_did_aghab_salamat_zaheri_nadarad:
            ayeneha.properties.dide_aghab.properties
              .ayene_did_aghab_salamat_zaheri_nadarad.enum[0],
          sensor_nur_ya_elektrokoromik_ayene_mayub_ast_ya_bedorosti_amal_nemikonad:
            ayeneha.properties.dide_aghab.properties
              .sensor_nur_ya_elektrokoromik_ayene_mayub_ast_ya_bedorosti_amal_nemikonad
              .enum[2],
          images: []
        }
      },
      cheraghha_va_tajhizat_roshanae: {
        cheraghha_va_tajhizat_roshanae_samte_ranande_jolo: {
          status:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_jolo.properties
              .status.enum[0],
          cheragh_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_jolo.properties
              .cheragh_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          rahnama_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_jolo.properties
              .rahnama_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          meh_shekan_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_jolo.properties
              .meh_shekan_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          cheragh_taviz_shode_ast:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_jolo.properties
              .cheragh_taviz_shode_ast.enum[0],
          images: []
        },
        cheraghha_va_tajhizat_roshanae_samte_sarneshin_jolo: {
          status:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_jolo.properties
              .status.enum[0],
          cheragh_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_jolo.properties
              .cheragh_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          rahnama_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_jolo.properties
              .rahnama_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          meh_shekan_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_jolo.properties
              .meh_shekan_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          cheragh_taviz_shode_ast:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_jolo.properties
              .cheragh_taviz_shode_ast.enum[0],
          images: []
        },
        cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_jolo: {
          status:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_jolo.properties
              .status.enum[0],
          daste_rahnam_mayob_ast_ya_bedorosti_amal_nemikonad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_jolo.properties
              .daste_rahnam_mayob_ast_ya_bedorosti_amal_nemikonad.enum[0],
          di_light_mayob_ast_ya_bedorosti_amal_nemikonad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_jolo.properties
              .di_light_mayob_ast_ya_bedorosti_amal_nemikonad.enum[2],
          images: []
        },
        cheraghha_va_tajhizat_roshanae_samte_ranande_aghab: {
          status:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_aghab.properties
              .status.enum[0],
          cheragh_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_aghab.properties
              .cheragh_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          rahnama_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_aghab.properties
              .rahnama_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          cheragh_tormoz_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_aghab.properties
              .cheragh_tormoz_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          cheragh_taviz_shode_ast:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_ranande_aghab.properties
              .cheragh_taviz_shode_ast.enum[0],
          images: []
        },
        cheraghha_va_tajhizat_roshanae_samte_sarneshin_aghab: {
          status:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_aghab.properties
              .status.enum[0],
          cheragh_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_aghab.properties
              .cheragh_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          rahnama_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_aghab.properties
              .rahnama_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          cheragh_tormoz_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_aghab.properties
              .cheragh_tormoz_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          cheragh_taviz_shode_ast:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_samte_sarneshin_aghab.properties
              .cheragh_taviz_shode_ast.enum[0],
          images: []
        },
        cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_aghab: {
          status:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_aghab.properties
              .status.enum[0],
          meh_shekan_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_aghab.properties
              .meh_shekan_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          cheragh_tormoze_sevom_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_aghab.properties
              .cheragh_tormoze_sevom_mayob_ast_ya_bedorosti_amal_nemionad
              .enum[0],
          cheragh_dande_aghab_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_aghab.properties
              .cheragh_dande_aghab_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          cheragh_pelak_mayob_ast_ya_bedorosti_amal_nemionad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_aghab.properties
              .cheragh_pelak_mayob_ast_ya_bedorosti_amal_nemionad.enum[0],
          images: []
        },
        cheraghha_va_tajhizat_roshanae_dakhel_khodro: {
          status:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_dakhel_khodro.properties.status
              .enum[0],
          roshanayie_poste_amper_mayob_ast_ya_bedorosti_tanzim_nemishavad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_dakhel_khodro.properties
              .roshanayie_poste_amper_mayob_ast_ya_bedorosti_tanzim_nemishavad
              .enum[0],
          tanzim_konande_cheraghhaye_jolo_mayob_ast:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_dakhel_khodro.properties
              .tanzim_konande_cheraghhaye_jolo_mayob_ast.enum[0],
          felasher_mayob_ast:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_dakhel_khodro.properties
              .felasher_mayob_ast.enum[0],
          cheraghaie_saghfi_ya_laye_darb_mayob_hastand_ya_bedorosti_amal_nemikonad:
            cheraghha_va_tajhizat_roshanae.properties
              .cheraghha_va_tajhizat_roshanae_dakhel_khodro.properties
              .cheraghaie_saghfi_ya_laye_darb_mayob_hastand_ya_bedorosti_amal_nemikonad
              .enum[0],
          images: []
        }
      },
      ghofl_dastgire_va_sistem_zede_serghat: {
        ghofl_markazi_dozdgir_va_sistem_zed_serghat: {
          status:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_markazi_dozdgir_va_sistem_zed_serghat.properties.status
              .enum[0],
          dozdgir_ya_sistem_zed_serghat_bedorosti_amal_nemikonad:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_markazi_dozdgir_va_sistem_zed_serghat.properties
              .dozdgir_ya_sistem_zed_serghat_bedorosti_amal_nemikonad.enum[0],
          ghofl_markazi_mayub_ast_ya_bedorosti_amal_nemikonad:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_markazi_dozdgir_va_sistem_zed_serghat.properties
              .ghofl_markazi_mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        ghofl_ya_dar_baz_kon_dar_motor: {
          status:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dar_baz_kon_dar_motor.properties.status.enum[0],
          mayub_ast_ya_bedorosti_amal_nemikonad:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dar_baz_kon_dar_motor.properties
              .mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        ghofl_ya_dastgire_dar_samte_ranande_jolo: {
          status:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dastgire_dar_samte_ranande_jolo.properties.status
              .enum[0],
          mayub_ast_ya_bedorosti_amal_nemikonad:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dastgire_dar_samte_ranande_jolo.properties
              .mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        ghofl_ya_dastgire_dar_samte_ranande_aghab: {
          status:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dastgire_dar_samte_ranande_aghab.properties.status
              .enum[0],
          mayub_ast_ya_bedorosti_amal_nemikonad:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dastgire_dar_samte_ranande_aghab.properties
              .mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        ghofl_ya_dar_baz_kon_sandogh_aghab: {
          status:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dar_baz_kon_sandogh_aghab.properties.status.enum[0],
          mayub_ast_ya_bedorosti_amal_nemikonad:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dar_baz_kon_sandogh_aghab.properties
              .mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        ghofl_ya_dastgire_dar_samte_sarneshin_aghab: {
          status:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dastgire_dar_samte_sarneshin_aghab.properties.status
              .enum[0],
          mayub_ast_ya_bedorosti_amal_nemikonad:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dastgire_dar_samte_sarneshin_aghab.properties
              .mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        },
        ghofl_ya_dastgire_dar_samte_sarneshin_jolo: {
          status:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dastgire_dar_samte_sarneshin_jolo.properties.status
              .enum[0],
          mayub_ast_ya_bedorosti_amal_nemikonad:
            ghofl_dastgire_va_sistem_zede_serghat.properties
              .ghofl_ya_dastgire_dar_samte_sarneshin_jolo.properties
              .mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          images: []
        }
      },
      sistem_tahvie: {
        sarneshinan_jolo: {
          status:
            sistem_tahvie.properties.sarneshinan_jolo.properties.status.enum[1],
          sistem_tahvie_mayub_ast_ya_sedaye_gheire_adi_darad:
            sistem_tahvie.properties.sarneshinan_jolo.properties
              .sistem_tahvie_mayub_ast_ya_sedaye_gheire_adi_darad.enum[0],
          daricheha_mayub_hastan:
            sistem_tahvie.properties.sarneshinan_jolo.properties
              .daricheha_mayub_hastan.enum[0],
          sistem_tahvie_dual_mayub_ast_ya_sedaye_gheire_adi_darad:
            sistem_tahvie.properties.sarneshinan_jolo.properties
              .sistem_tahvie_dual_mayub_ast_ya_sedaye_gheire_adi_darad.enum[2],
          mahfaze_khonak_konande_mayub_ast:
            sistem_tahvie.properties.sarneshinan_jolo.properties
              .mahfaze_khonak_konande_mayub_ast.enum[2],
          images: []
        },
        sarneshinane_aghab: {
          status:
            sistem_tahvie.properties.sarneshinane_aghab.properties.status
              .enum[1],
          salamat_zaheri_nadarad:
            sistem_tahvie.properties.sarneshinane_aghab.properties
              .salamat_zaheri_nadarad.enum[0],
          sistem_tahvie_mayub_ast_ya_sedaye_gheire_adi_darad:
            sistem_tahvie.properties.sarneshinane_aghab.properties
              .sistem_tahvie_mayub_ast_ya_sedaye_gheire_adi_darad.enum[0],
          daricheha_mayub_hastan:
            sistem_tahvie.properties.sarneshinane_aghab.properties
              .daricheha_mayub_hastan.enum[0],
          images: []
        }
      },
      eimenie: {
        eyrbagha: {
          status: eimenie.properties.eyrbagha.properties.status.enum[0],
          adame_vojode_eirbagha:
            eimenie.properties.eyrbagha.properties.adame_vojode_eirbagha
              .enum[0],
          cheragh_eyrbag_roshan_ast:
            eimenie.properties.eyrbagha.properties.cheragh_eyrbag_roshan_ast
              .enum[0],
          cheragh_eyrbag_bedorosti_amal_nemikonad:
            eimenie.properties.eyrbagha.properties
              .cheragh_eyrbag_bedorosti_amal_nemikonad.enum[0],
          sabeghe_amalkard_eyrbag_vojod_darad:
            eimenie.properties.eyrbagha.properties
              .sabeghe_amalkard_eyrbag_vojod_darad.enum[0],
          images: []
        },
        kamarband: {
          status: eimenie.properties.kamarband.properties.status.enum[0],
          kamarband_mayub_ast_ya_bedorosti_amal_nemikonad:
            eimenie.properties.kamarband.properties
              .kamarband_mayub_ast_ya_bedorosti_amal_nemikonad.enum[0],
          sabeghe_amalkard_kamarband_vujud_darad:
            eimenie.properties.kamarband.properties
              .sabeghe_amalkard_kamarband_vujud_darad.enum[0],
          images: []
        }
      }
    }
  }
};

const report = createSlice({
  name: "report",
  initialState: initialReportState,
  reducers: {
    setReport: (state, action) => {
      state.result = action.payload;
    },
    resetReport: (state) => {
      state.result = initialReportState.result;
    },
    setCancel: (
      state,
      action: PayloadAction<DeepPartial<CancelOrPenaltyPayType>>
    ) => {
      state.result.cancel_or_penalty_pay = Object.assign(
        {},
        state.result.cancel_or_penalty_pay,
        action.payload
      );
    },
    setSanadVaMadrak: (
      state,
      action: PayloadAction<DeepPartial<SanadVaMadrakType>>
    ) => {
      state.result.sanad_va_madrak = Object.assign(
        {},
        state.result.sanad_va_madrak,
        action.payload
      );
    },
    setLastikHa: (state, action: PayloadAction<DeepPartial<LastikHaType>>) => {
      state.result.lastikha = Object.assign(
        {},
        state.result.lastikha,
        action.payload
      );
    },
    setMotorVaEnteghalGhodrat: (
      state,
      action: PayloadAction<DeepPartial<LastikHaType>>
    ) => {
      state.result.motor_va_system_enteghale_ghodrath = Object.assign(
        {},
        state.result.motor_va_system_enteghale_ghodrath,
        action.payload
      );
    },
    setTalighVaFarman: (
      state,
      action: PayloadAction<DeepPartial<TalighVaFarmanType>>
    ) => {
      state.result.tormoz_farman_va_system_taligh = Object.assign(
        {},
        state.result.tormoz_farman_va_system_taligh,
        action.payload
      );
    },
    setBadaneh: (state, action: PayloadAction<DeepPartial<BadanehType>>) => {
      state.result.badane = Object.assign(
        {},
        state.result.badane,
        action.payload
      );
    },
    setTasavireKoli: (
      state,
      action: PayloadAction<DeepPartial<TasavireKoliType>>
    ) => {
      state.result.general_images = Object.assign(
        {},
        state.result.general_images,
        action.payload
      );
    },
    setFazayeDakhelVaTajhizat: (
      state,
      action: PayloadAction<DeepPartial<FazayeDakheliVaTajhizatType>>
    ) => {
      state.result.fazaie_dakhel_va_tajhizate_barghi = Object.assign(
        {},
        state.result.fazaie_dakhel_va_tajhizate_barghi,
        action.payload
      );
    },
    setApshenHa: (state, action: PayloadAction<DeepPartial<BadanehType>>) => {
      state.result.apshen = Object.assign(
        {},
        state.result.apshen,
        action.payload
      );
    },
    setCodeBargeKarshenasi: (
      state,
      action: PayloadAction<DeepPartial<PaperReportCodeType>>
    ) => {
      state.result.paper_report_code = Object.assign(
        {},
        state.result.paper_report_code,
        action.payload
      );
    },
    setToziheEkhtesasi: (
      state,
      action: PayloadAction<DeepPartial<specialDescriptionType>>
    ) => {
      state.result.special_description = Object.assign(
        {},
        state.result.special_description,
        action.payload
      );
    }
  }
});

export const {
  setReport,
  resetReport,
  setSanadVaMadrak,
  setLastikHa,
  setMotorVaEnteghalGhodrat,
  setBadaneh,
  setApshenHa,
  setCodeBargeKarshenasi,
  setFazayeDakhelVaTajhizat,
  setTalighVaFarman,
  setTasavireKoli,
  setToziheEkhtesasi,
  setCancel
} = report.actions;

export default report.reducer;
