import { useUpdateLeasingOrder } from "@/services/api/leasing/requests";
import { Box, Button, CircularProgress, Stack, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { SwipeableDrawer } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  nationalCode: string;
}

const NationalCodeModal: FC<Props> = ({
  open,
  showModal,
  title,
  nationalCode
}) => {
  const { id } = useParams();
  const [code, setCode] = useState("");
  const { mutateAsync, isLoading } = useUpdateLeasingOrder();

  useEffect(() => {
    if (nationalCode) {
      setCode(nationalCode);
    }
  }, [nationalCode]);

  const submitHandler = () => {
    mutateAsync({
      id: String(id),
      customer: { national_code: code }
    }).then(() => {
      showModal(false);
    });
  };
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack gap={"20px"}>
          <TextField
            label="ویرایش کدملی"
            sx={{ lineHeight: 1.7 }}
            size="small"
            value={code || ""}
            onChange={(event) => setCode(event.target.value)}
          />
        </Stack>
        <Button
          onClick={submitHandler}
          disabled={code.length !== 10}
          sx={{ mt: 2 }}
        >
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: "#00000050" }} />
          ) : (
            " ویرایش کدملی"
          )}
        </Button>
      </Stack>
    </SwipeableDrawer>
  );
};

export default NationalCodeModal;
