import { useShifts } from "@/components/layouts/ShiftsLayout";
import { Stack, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { styles } from "./styles";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import ItemButton from "./ItemButton";
import { useRootLayout } from "@/hooks/useRootLayout";
import { Shift } from "@/services/api/shifts";
import TodayShifts from "./TodayShifts";

const ShiftsSummary: FC = () => {
  const { weeklyExpertData, groupedWeeklyExpertData } = useShifts();
  const [firstRender, setFirstRender] = useState(true);
  const { setHeaderTitle } = useRootLayout();

  useEffect(() => {
    if (typeof setHeaderTitle === "function") {
      setHeaderTitle("شیفت‌های کاری");
    }
  }, []);

  const summaryData = useMemo(() => {
    return weeklyExpertData.reduce(
      (result, current) => {
        return {
          total: result.total + 1,
          active:
            current.state === 0 || current.state === 2
              ? result.active + 1
              : result.active
        };
      },
      { total: 0, active: 0 }
    );
  }, [weeklyExpertData]);

  useEffect(() => {
    setTimeout(() => {
      setFirstRender(false);
    }, 100);
  }, []);

  const nextDayApproved = useMemo(() => {
    const nextDaysList = (() => {
      const todayDate = new Date();
      todayDate.setHours(0);
      todayDate.setMinutes(0);
      todayDate.setSeconds(0);
      return Object.keys(groupedWeeklyExpertData).filter((itemKey) => {
        if (new Date(itemKey).getTime() > todayDate.getTime()) {
          return true;
        } else {
          return false;
        }
      });
    })();

    const nextDayDateJalali =
      nextDaysList.length > 0
        ? (nextDaysList[0] as Shift["shift"]["date"])
        : null;

    const nextDay = (() => {
      if (nextDayDateJalali === null) {
        return [];
      } else {
        return groupedWeeklyExpertData[nextDayDateJalali].filter(
          (item) => item.state === 0
        );
      }
    })();

    return Boolean(
      Boolean(nextDay.length) && nextDay.every((item) => item.is_reminded)
    );
  }, [groupedWeeklyExpertData]);

  const summarySection = useMemo(() => {
    return (
      <>
        <Typography color="#464646" fontSize="14px" component="span">
          {`تعداد شیفت‌ها: `}
          <Typography
            fontWeight="bold"
            color={summaryData.active < 10 ? "#D70000" : "#21BE21"}
            component="span"
          >
            {summaryData.active}
          </Typography>
          {" از "}
          <Typography fontWeight="bold" component="span">
            {summaryData.total}
          </Typography>
        </Typography>

        <Stack direction="row" sx={{ ...styles.barContainer }}>
          <Stack
            sx={{
              ...styles.barInner,
              width: firstRender
                ? "0"
                : `${(summaryData.active / summaryData.total) * 100}%`,
              backgroundColor: summaryData.active < 10 ? "#D70000" : "#21BE21"
            }}
          />
        </Stack>

        <Stack direction="row" sx={styles.warning}>
          <ReportGmailerrorredOutlinedIcon color="error" />
          <Typography ml={1} color="rgba(0, 0, 0, 0.48)" fontSize="12px">
            انتخاب حداقل ۱۰ شیفت در ۷ روز اجباری می‌باشد
          </Typography>
        </Stack>
      </>
    );
  }, [summaryData, firstRender]);

  const detailsSection = (
    <Stack spacing={1} sx={styles.detailsContainer}>
      <ItemButton
        title="رزرو شیفت کاری"
        hasTick={summaryData.active >= 10}
        to="/inspection/shifts/approve"
      />
      <ItemButton
        title="شیفت‌های فردا"
        hasTick={nextDayApproved}
        to="/inspection/shifts/submit-tommorow"
      />
    </Stack>
  );

  return (
    <Stack sx={styles.container}>
      {summarySection}
      {detailsSection}
      <TodayShifts />
    </Stack>
  );
};

export default ShiftsSummary;
