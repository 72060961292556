import { AxiosError } from "axios";

interface GlobalErrorModelFieldItem {
  type: "field";
  message: string[];
  color: string;
  field_name: string;
}

interface GlobalErrorModelNotifItem {
  type: "notif";
  message: string[];
  color: string;
  field_name?: never;
}

type GlobalErrorModelItem =
  | GlobalErrorModelFieldItem
  | GlobalErrorModelNotifItem;

export type GlobalErrorModel = {
  code: number;
  messages: GlobalErrorModelItem[];
  data: unknown;
};

export const isGlobalErrorModel = (
  error: AxiosError
): error is AxiosError<GlobalErrorModel> => {
  if (
    error.response &&
    error.response.data !== null &&
    typeof error.response.data === "object" &&
    "code" in error.response.data &&
    "messages" in error.response.data &&
    "data" in error.response.data &&
    Array.isArray(error.response?.data.messages)
  ) {
    return true;
  }
  return false;
};
