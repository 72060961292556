import type { FromSchema } from "json-schema-to-ts";
import { buildYup } from "schema-to-yup";

const sanadVaMadrakSchema = {
  type: "object",
  properties: {
    brand_model: {
      type: "string"
    },
    sale_sakht: {
      type: "string",
      enum: [
        "",
        "۱۴۰۲",
        "۱۴۰۱",
        "۱۴۰۰",
        "۱۳۹۹",
        "۱۳۹۸",
        "۱۳۹۷",
        "۱۳۹۶",
        "۱۳۹۵",
        "۱۳۹۴",
        "۱۳۹۳",
        "۱۳۹۲",
        "۱۳۹۱",
        "۱۳۹۰",
        "۱۳۸۹",
        "۱۳۸۸",
        "۱۳۸۷",
        "۱۳۸۶",
        "۱۳۸۵",
        "۱۳۸۴",
        "۱۳۸۳",
        "۱۳۸۲",
        "۱۳۸۱",
        "۱۳۸۰",
        "۱۳۷۹",
        "۱۳۷۸",
        "۱۳۷۷",
        "۱۳۷۶",
        "۱۳۷۵",
        "۱۳۷۴",
        "۱۳۷۳",
        "۱۳۷۲",
        "۱۳۷۱",
        "۱۳۷۰",
        "۱۳۶۹",
        "۱۳۶۸",
        "۱۳۶۷",
        "۱۳۶۶",
        "قبل از ۱۳۶۶"
      ],
      enumNames: [
        "شروع نکردم",
        "۲۰۲۳ - ۱۴۰۲",
        "۲۰۲۲ - ۱۴۰۱",
        "۲۰۲۱ - ۱۴۰۰",
        "۲۰۲۰ - ۱۳۹۹",
        "۲۰۱۹ - ۱۳۹۸",
        "۲۰۱۸ - ۱۳۹۷",
        "۲۰۱۷ - ۱۳۹۶",
        "۲۰۱۶ - ۱۳۹۵",
        "۲۰۱۵ - ۱۳۹۴",
        "۲۰۱۴ - ۱۳۹۳",
        "۲۰۱۳ - ۱۳۹۲",
        "۲۰۱۲ - ۱۳۹۱",
        "۲۰۱۱ - ۱۳۹۰",
        "۲۰۱۰ - ۱۳۸۹",
        "۲۰۰۹ - ۱۳۸۸",
        "۲۰۰۸ - ۱۳۸۷",
        "۲۰۰۷ - ۱۳۸۶",
        "۲۰۰۶ - ۱۳۸۵",
        "۲۰۰۵ - ۱۳۸۴",
        "۲۰۰۴ - ۱۳۸۳",
        "۲۰۰۳ - ۱۳۸۲",
        "۲۰۰۲ - ۱۳۸۱",
        "۲۰۰۱ - ۱۳۸۰",
        "۲۰۰۰ - ۱۳۷۹",
        "۱۹۹۹ - ۱۳۷۸",
        "۱۹۹۸ - ۱۳۷۷",
        "۱۹۹۷ - ۱۳۷۶",
        "۱۹۹۶ - ۱۳۷۵",
        "۱۹۹۵ - ۱۳۷۴",
        "۱۹۹۴ - ۱۳۷۳",
        "۱۹۹۳ - ۱۳۷۲",
        "۱۹۹۲ - ۱۳۷۱",
        "۱۹۹۱ - ۱۳۷۰",
        "۱۹۹۰ - ۱۳۶۹",
        "۱۹۸۹ - ۱۳۶۸",
        "۱۹۸۸ - ۱۳۶۷",
        "۱۹۸۷ - ۱۳۶۶",
        "قبل ﺍﺯ ۱۹۸۷ - قبل ﺍﺯ ۱۳۶۶"
      ]
    },
    karkard: {
      type: "integer",
      minimum: 1
    },
    range_badane: {
      type: "string",
      enum: [
        "",
        "سفید",
        "مشکی",
        "خاکستری",
        "نقره‌ای",
        "نوک‌مدادی",
        "آبی",
        "سفید صدفی",
        "قرمز",
        "بژ",
        "قهوه‌ای",
        "آلبالویی",
        "اطلسی",
        "بادمجانی",
        "برنز",
        "بنفش",
        "پوست‌پیازی",
        "تیتانیوم",
        "خاکی",
        "دلفینی",
        "ذغالی",
        "زرد",
        "زرشکی",
        "زیتونی",
        "سبز",
        "سربی",
        "سرمه‌ای",
        "طلایی",
        "طوسی",
        "عدسی",
        "عنابی",
        "کربن‌بلک",
        "کرم",
        "گیلاسی",
        "مسی",
        "موکا",
        "نارنجی",
        "نقرآبی",
        "یشمی"
      ],
      enumNames: [
        "شروع نکردم",
        "سفید",
        "مشکی",
        "خاکستری",
        "نقره‌ای",
        "نوک‌مدادی",
        "آبی",
        "سفید صدفی",
        "قرمز",
        "بژ",
        "قهوه‌ای",
        "آلبالویی",
        "اطلسی",
        "بادمجانی",
        "برنز",
        "بنفش",
        "پوست‌پیازی",
        "تیتانیوم",
        "خاکی",
        "دلفینی",
        "ذغالی",
        "زرد",
        "زرشکی",
        "زیتونی",
        "سبز",
        "سربی",
        "سرمه‌ای",
        "طلایی",
        "طوسی",
        "عدسی",
        "عنابی",
        "کربن‌بلک",
        "کرم",
        "گیلاسی",
        "مسی",
        "موکا",
        "نارنجی",
        "نقرآبی",
        "یشمی"
      ]
    },
    vin: {
      type: "string"
    },
    shomare_shasi: {
      type: "string"
    },
    karte_mashin: {
      type: "string",
      enum: ["", "True", "False", "NotShowing"],
      enumNames: ["شروع نکردم", "دارد", "ندارد", "از سوی مالک ارائه نشد"]
    },
    etebar_sales: {
      type: "string",
      pattern:
        "([۱-۹][۳-۴][۰-۹][۰-۹])\\/(۰?[۱-۹]|۱[۰۱۲])\\/(۰?[۱-۹]|[۱۲][۰-۹]|۳[۰۱])$"
    },
    takhfif_sales_be_sal: {
      type: "string",
      enum: [
        "",
        "False",
        "NotShowing",
        "1TakhfifSales",
        "2TakhfifSales",
        "3TakhfifSales",
        "4TakhfifSales",
        "5TakhfifSales",
        "6TakhfifSales",
        "7TakhfifSales",
        "8TakhfifSales",
        "9TakhfifSales",
        "10TakhfifSales",
        "11TakhfifSales",
        "12TakhfifSales",
        "13TakhfifSales",
        "14TakhfifSales"
      ],
      enumNames: [
        "شروع نکردم",
        "ندارد",
        "از سوی مالک ارائه نشد",
        "یک سال یا ۵٪",
        "۲ سال یا ۱۰٪",
        "۳ سال یا ۱۵٪",
        "۴ سال یا ۲۰٪",
        "۵ سال یا ۲۵٪",
        "۶ سال یا ۳۰٪",
        "۷ سال یا ۳۵٪",
        "۸ سال یا ۴۰٪",
        "۹ سال یا ۴۵٪",
        "۱۰ سال یا ۵۰٪",
        "۱۱ سال یا ۵۵٪",
        "۱۲ سال یا ۶۰٪",
        "۱۳ سال یا ۶۵٪",
        "۱۴ سال و بیشتر یا ۷۰٪"
      ]
    },
    tedad_switch: {
      type: "string",
      enum: ["", "True", "False", "NotShowing"],
      enumNames: ["شروع نکردم", "دارد", "ندارد", "ازسوی مالک ارائه نشد"]
    },
    garanti: {
      type: "string",
      enum: ["", "True", "False", "NotShowing"],
      enumNames: ["شروع نکردم", "دارد", "ندارد", "ازسوی مالک ارائه نشد"]
    },
    otagh: {
      type: "object",
      properties: {
        esalat: {
          type: "object",
          properties: {
            taviz_kamel_rang: {
              type: "boolean"
            },
            taviz_otagh: {
              type: "boolean"
            }
          },
          additionalProperties: false,
          required: []
        }
      },
      additionalProperties: false,
      required: []
    },
    description: {
      type: "string",
      maxLength: 1000
    }
  },
  required: [
    "brand_model",
    "sale_sakht",
    "karkard"
    // "range_badane",
    // "vin",
    // "shomare_shasi",
    // "karte_mashin",
    // "takhfif_sales_be_sal",
    // "tedad_switch",
    // "garanti"
  ],
  additionalProperties: false
} as const;

const config = {
  errMessages: {
    brand_model: { required: "این فیلد اجباری است." },
    sale_sakht: { required: "این فیلد اجباری است." },
    karkard: { required: "این فیلد اجباری است." },
    // range_badane: { required: "این فیلد اجباری است." },
    // vin: { required: "این فیلد اجباری است." },
    // shomare_shasi: { required: "این فیلد اجباری است." },
    // karte_mashin: { required: "این فیلد اجباری است." },
    etebar_sales: {
      // required: "این فیلد اجباری است.",
      pattern: "تاریخ معتبر نیست."
    },
    // takhfif_sales_be_sal: { required: "این فیلد اجباری است." },
    // tedad_switch: { required: "این فیلد اجباری است." },
    // garanti: { required: "این فیلد اجباری است." },
    description: { maxLength: "تعداد کاراکترها بیشتر از ۱۰۰۰ است." }
  }
};

export const sanadVaMadrakValidation = buildYup(sanadVaMadrakSchema, config);
export type SanadVaMadrakType = FromSchema<typeof sanadVaMadrakSchema>;
export default sanadVaMadrakSchema.properties;
