import React, { FC, useState } from "react";
import {
  Stack,
  CircularProgress,
  Typography,
  Box,
  TextField,
  Button
} from "@mui/material";
import RequestCard from "@/pages/ConciergeSale/requests/requestCard";
import { styles } from "@/pages/ConciergeSale/requests/tabs/styles";
import {
  useGetPaginationData,
  useGetSaleExpert,
  useGetSearchAllRequest
} from "@/services/api/sales";
import coolIcom from "@/assets/icons/cool.svg";
import { useDebounce } from "@/hooks/useDebounce";
import { toEngDigits } from "@/utils/toEnglishDigit";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const AllRequest: FC = () => {
  const { data, isLoading } = useGetSaleExpert("all");
  const [searchItem, setSearchItem] = useState("");
  const searchDebouns = useDebounce(searchItem, 500);
  const { data: searchData } = useGetSearchAllRequest(searchDebouns);

  const {
    data: pData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useGetPaginationData("all", 1);

  return (
    <>
      {!isLoading && (
        <Stack sx={styles.tabContentContainer}>
          {data?.items?.length ? (
            <>
              <TextField
                InputProps={{
                  startAdornment: (
                    <SearchRoundedIcon sx={{ color: "#6E6E6E", mr: 0.75 }} />
                  )
                }}
                value={searchItem}
                onChange={(event) =>
                  setSearchItem(toEngDigits(event.target.value))
                }
                sx={{
                  mb: 2,
                  "& input::placeholder": { fontSize: "13.5px" }
                }}
                size="small"
                placeholder="جستجوی نام مشتری یا کد سفارش یا شماره موبایل"
                type="search"
              />
              {searchItem
                ? searchData?.items?.map((item) => (
                    <RequestCard key={item.id} {...item} showStatus />
                  ))
                : pData?.pages?.map((page, index) => (
                    <React.Fragment key={index}>
                      {page?.items?.map((item) => (
                        <RequestCard key={item.id} {...item} showStatus />
                      ))}
                    </React.Fragment>
                  ))}

              {hasNextPage && (
                <Button
                  sx={{ mb: 2 }}
                  variant="outlined"
                  disabled={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                >
                  {isFetchingNextPage ? (
                    <CircularProgress size={20} sx={{ color: "#00000050" }} />
                  ) : (
                    "مشاهده سایر درخواست‌ها"
                  )}
                </Button>
              )}
            </>
          ) : (
            <Box sx={styles.emptyRequestArea}>
              <img src={coolIcom} alt="cool icon" />
              <Typography sx={styles.emptyRequestDesc} variant="body1">
                دمت گرم!
                <br />
                با همه ی مشتری ها تماس گرفتی
              </Typography>
            </Box>
          )}
        </Stack>
      )}

      {isLoading && (
        <Stack
          width="100%"
          height="400px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size="50px" />
        </Stack>
      )}
    </>
  );
};

export default AllRequest;
