import { FC, useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Typography,
  Stack,
  Snackbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useNavigate, useParams } from "react-router";
import { useGetSaleDetailV2, useSubmitDivarAds } from "@/services/api/sales";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import LadderIcon from "@/assets/icons/ladder.svg";
import DivarModal from "@/pages/ConciergeSale/requests/tabs/details/info/divarModal";
import { appUrl } from "@/utils/env";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  setLadderModal: (item: boolean) => void;
}

const AdsModal: FC<Props> = ({ open, showModal, setLadderModal }) => {
  const { id } = useParams();
  const { data } = useGetSaleDetailV2(Number(id));
  const { refetch: submitDivar } = useSubmitDivarAds(Number(id));
  const [toast, setToast] = useState(false);
  const [divarModal, setDivarModal] = useState(false);
  const navigate = useNavigate();

  const copyToClipBoard = async (data: string) => {
    await navigator.clipboard.writeText(data).then(() => setToast(true));
  };

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => showModal(false)}
        onOpen={() => showModal(true)}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <Box sx={styles.historyPriceModal}>
          <Box sx={styles.modalTitleArea}>
            <Typography fontWeight={600} fontSize={14}>
              آگهی ها
            </Typography>
            <CloseIcon onClick={() => showModal(false)} />
          </Box>
          <Stack>
            <Stack
              sx={{
                flexDirection: "row",
                py: "14px",
                borderBottom: "1px solid #E2E2E2",
                justifyContent: "space-between"
              }}
            >
              <Typography variant="h4" sx={{ fontSize: "15px" }}>
                آگهی دیوار
              </Typography>
              {data?.dealership_manage_token ? (
                <Stack sx={{ flexDirection: "row" }}>
                  <img
                    src={LadderIcon}
                    onClick={() => {
                      showModal(false);
                      setLadderModal(true);
                    }}
                    alt="ladder icon"
                  />

                  <ModeEditOutlineOutlinedIcon
                    onClick={() => setDivarModal(true)}
                    sx={{ fontSize: "20px", color: "#6E6E6E", ml: "16px" }}
                  />

                  <ContentCopyIcon
                    onClick={() =>
                      copyToClipBoard(String(data?.dealership_manage_token))
                    }
                    sx={styles.copyIcon}
                  />
                </Stack>
              ) : (
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                    color: "#202021"
                  }}
                  onClick={() => {
                    submitDivar().then((data) =>
                      window.open(data?.data?.url, "_parent")
                    );
                  }}
                >
                  انتشار در دیوار
                  <ChevronLeftOutlinedIcon sx={{ color: "#202021" }} />
                </Typography>
              )}
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                py: "14px",
                justifyContent: "space-between"
              }}
            >
              <Typography variant="h4" sx={{ fontSize: "15px" }}>
                آگهی کارنامه
              </Typography>
              <Stack sx={{ flexDirection: "row" }}>
                <ModeEditOutlineOutlinedIcon
                  onClick={() => {
                    if (typeof data?.car_post_token == "string") {
                      window.open(
                        `${appUrl}/user-profile/car-post/details/${data?.car_post_token}`,
                        "_parent"
                      );
                    }
                  }}
                  sx={{ fontSize: "20px", color: "#6E6E6E", ml: "16px" }}
                />

                <ContentCopyIcon
                  onClick={() =>
                    copyToClipBoard(String(data?.karnameh_post_link))
                  }
                  sx={styles.copyIcon}
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </SwipeableDrawer>

      <DivarModal
        title="ویرایش آگهی دیوار"
        open={divarModal}
        showModal={setDivarModal}
      />

      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: "#00a754"
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toast}
        onClose={() => setToast(false)}
        message="کپی شد"
      />
    </>
  );
};

export default AdsModal;
