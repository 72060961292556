// import createStyle from 'utils/createStyle';

import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: "100%",
    borderRadius: "0.5rem",
    border: "1px solid #ebebeb",
    p: "1rem",
    mb: "0.5rem",
    transition: "border-color 0.3s",
    cursor: "pointer"
  },
  headerContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  seprator: {
    width: "100%",
    borderTop: "1px solid #ebebeb",
    my: "1rem"
  },
  prepaymentContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  installmentMonthCountContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    mt: "1rem"
  },
  descriptionItem: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    mb: "1rem",
    ":last-of-type": {
      mb: 0
    }
  },
  infoIcon: {
    width: "0.625rem",
    height: "0.625rem"
  }
});

export default styles;
