import { Box, Button, IconButton, Stack } from "@mui/material";
import { FC } from "react";
import Webcam from "react-webcam";
import useLogic from "./useLogic";
import styles from "./styles";

import CircleIcon from "@mui/icons-material/Circle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const submitButtonLables = {
  submit: "تائید",
  save: "ذخیره",
  "save-and-submit": "تائید و ذخیره"
};

interface PropTypes {
  open: boolean;
  setOpen: (newOpen: boolean) => void;
  width?: number;
  height?: number;
  stencil?: string;
  fileName?: string;
  onSubmit?: (cameraImage: CameraImage) => void;
  submitType?: "submit" | "save-and-submit" | "save";
}

const Camera: FC<PropTypes> = (props) => {
  const {
    open,
    setOpen,
    width: initialWidth = 1,
    height: initialHeight = 1,
    stencil,
    fileName = "camera-image",
    onSubmit,
    submitType = "submit"
  } = props;

  const {
    width,
    height,
    show,
    image,
    cameraRef,
    clearImage,
    capture,
    onCancleHandler,
    onSubmitHandler
  } = useLogic(
    open,
    setOpen,
    initialWidth,
    initialHeight,
    fileName,
    onSubmit,
    submitType
  );

  if (!show && !open) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          ...styles.backdropContainer,
          opacity: show && open ? 1 : 0,
          pointerEvents: show && open ? "all" : "none"
        }}
        component="div"
        onClick={() => setOpen(false)}
      />
      <Stack
        sx={{
          ...styles.container,
          opacity: show && open ? 1 : 0,
          pointerEvents: show && open ? "all" : "none",
          aspectRatio: `${width} / ${height}`
        }}
      >
        {typeof image.dataUrl !== "string" && (
          <>
            <Stack
              component="div"
              sx={{
                ...styles.cameraContainer,
                aspectRatio: `${width} / ${height}`,
                overflow: "hidden"
              }}
            >
              <Webcam
                ref={cameraRef}
                className="camera-webcam"
                forceScreenshotSourceSize={true}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  facingMode: "environment",
                  aspectRatio: width / height,
                  width,
                  height
                }}
              />
              <style>
                {`
                  .camera-webcam {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                `}
              </style>
              {typeof stencil === "string" && (
                <Stack
                  component="img"
                  src={stencil}
                  sx={{
                    ...styles.stencilImage,
                    aspectRatio: `${width} / ${height}`
                  }}
                  alt="stencil"
                />
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              sx={styles.buttonsContainer}
            >
              <IconButton onClick={capture} sx={styles.circleIconButton}>
                <CircleIcon sx={styles.circleIcon} />
              </IconButton>
            </Stack>
          </>
        )}
        {typeof image.dataUrl === "string" && (
          <>
            <Stack
              sx={{
                ...styles.cameraContainer,
                aspectRatio: `${width} / ${height}`
              }}
            >
              <Stack
                component="img"
                alt="preview"
                src={image.dataUrl}
                sx={styles.imagePreview}
              />
              {typeof stencil === "string" && (
                <Stack
                  component="img"
                  sx={{
                    ...styles.stencilImage,
                    aspectRatio: `${width} / ${height}`
                  }}
                  src={stencil}
                  alt="stencil"
                />
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="nowrap"
              sx={styles.buttonsContainer}
            >
              <Button
                onClick={clearImage}
                sx={styles.repeatButton}
                variant="outlined"
              >
                گرفتن مجدد
              </Button>
              <Button
                onClick={onSubmitHandler}
                sx={styles.submitButton}
              >
                {submitButtonLables[submitType]}
              </Button>
            </Stack>
          </>
        )}
        <IconButton
          onClick={onCancleHandler}
          sx={styles.cancelButton}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
    </>
  );
};

export default Camera;
