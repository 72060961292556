import { Button, Stack, Typography } from "@mui/material";
import emptyState from "@/assets/icons/empty-inspection-jobs-list.svg";
import IsLoading from "./views/IsLoading";
import IsError from "./views/IsError";
import { MuiImage } from "./configs";
import { DoneOrders, useGetDoneOrders } from "@/services/api/inspection-jobs";
import JobDoneItem from "./views/JobDoneItem";
import { useNavigate } from "react-router";
import { makeUrl } from "@/utils/routing";
import AddOutlined from "@mui/icons-material/AddOutlined";
import { useRootLayout } from "@/hooks/useRootLayout";
import { useMemo, useState } from "react";

const pageSize = 10;

export const DoneJobs = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { setHeaderTitle, setIsLoading, setBackUrl } = useRootLayout();

  const {
    data: doneJobs,
    isLoading: doneJobsLoading,
    isError: doneJobsError
  } = useGetDoneOrders(
    {
      page,
      page_size: pageSize
    },
    {
      // enabled: currentTab === 2,
      onSuccess(data) {
        const currentIDs = data?.results?.map((a) => a?.id?.toString());
        const currentKeys = Object.keys(localStorage);
        currentKeys?.forEach((key) => {
          if (
            new RegExp("^[0-9]{6}-s*").test(key) &&
            !currentIDs.includes(key.split("-")?.[0])
          ) {
            localStorage?.removeItem(key);
          }
        });
      },
      onSettled: () => {
        setIsLoading(false);
      }
    }
  );

  const { list, isEmpty } = useMemo(
    () => ({
      list: doneJobs?.results ?? [],
      isEmpty: (doneJobs?.results?.length ?? 0) < 1
    }),
    [doneJobs]
  );

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      {doneJobsLoading ? (
        <IsLoading />
      ) : doneJobsError ? (
        <IsError />
      ) : isEmpty ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            position: "absolute",
            top: "7rem",
            right: 0,
            left: 0,
            bottom: "7rem"
          }}
        >
          <MuiImage
            src={emptyState}
            sx={(theme) => ({
              width: theme.spacing(14),
              height: theme.spacing(14)
            })}
          />
          <Typography fontWeight={500} color="rgba(0, 0, 0, 0.60)">
            در حال حاضر سفارش انجام شده‌ای وجود ندارد.
          </Typography>
        </Stack>
      ) : (
        <Stack gap={2}>
          {(list as DoneOrders[]).map((job) => (
            <JobDoneItem key={job.id} {...job} />
          ))}
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            {page > 1 ? (
              <Button
                size="small"
                onClick={() => {
                  setPage((curr) => curr - 1);
                }}
              >
                قبلی
              </Button>
            ) : (
              <Stack></Stack>
            )}
            {(doneJobs?.count ?? 0) > page * pageSize ? (
              <Button
                size="small"
                onClick={() => {
                  setPage((curr) => curr + 1);
                }}
              >
                بعدی
              </Button>
            ) : null}
          </Stack>
        </Stack>
      )}
      <Button
        color="secondary"
        startIcon={<AddOutlined />}
        sx={{
          position: "absolute",
          right: (th) => th.spacing(2),
          bottom: (th) => th.spacing(7)
        }}
        onClick={() => {
          navigate(makeUrl(`/inspection/inspection-jobs-dynamic/new-order`));
        }}
      >
        درخواست جدید
      </Button>
    </Stack>
  );
};
