import { createContext, useContext, useEffect } from "react";

interface RootLayoutContext {
  headerTitle: string;
  setHeaderTitle: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  backUrl: string | null | number;
  setBackUrl: React.Dispatch<React.SetStateAction<string | null | number>>;
}

interface useRootLayoutConfig {
  back?: string;
}

export const rootLayoutContext = createContext<RootLayoutContext>({
  headerTitle: "",
  setHeaderTitle: () => null,
  isLoading: false,
  setIsLoading: () => null,
  backUrl: null,
  setBackUrl: () => null
});

export const useRootLayout = (config?: useRootLayoutConfig) => {
  const context = useContext(rootLayoutContext);

  useEffect(() => {
    if (typeof config?.back === "string") {
      context.setBackUrl(config.back);

      return () => {
        context.setBackUrl(null);
      };
    }
  }, []);

  return context;
};
