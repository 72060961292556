import { SectionRow } from "@/services/api/inspection-jobs";
import { createSlice } from "@reduxjs/toolkit";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReportState = Record<string, any>;

export const initialReportState: {
  data: ReportState;
  schema: ReportState;
  categoryList: SectionRow[];
  inspectionToken?: string;
} = {
  data: {},
  schema: {},
  categoryList: []
};

const report = createSlice({
  name: "report",
  initialState: initialReportState,
  reducers: {
    setReport: (state, action) => {
      state.data = action.payload;
    },
    setReportSchema: (state, action) => {
      state.schema = action.payload;
    },
    resetReport: (state) => {
      state.data = initialReportState.data;
      state.schema = initialReportState.schema;
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setInspectionToken: (state, action) => {
      state.inspectionToken = action.payload;
    }
  }
});

export const {
  setReport,
  resetReport,
  setCategoryList,
  setReportSchema,
  setInspectionToken
} = report.actions;

export default report.reducer;
