import {
  Button,
  FormControlLabel,
  Radio,
  Stack,
  Typography
} from "@mui/material";
import styles from "./styles";
import { useNavigate, useParams } from "react-router";
import {
  getConciergeSaleCarStatusKey,
  useGetConciergeSaleCarStatus,
  usePutConciergeSaleCarStatus
} from "@/services/api/leasing";
import { useFormik } from "formik";
import VerticalFullCar from "@/components/SvgAdapter/components/svgs/VerticalFullCar";
import ExtraContents from "@/components/SvgAdapter/components/svgs/VerticalFullCar/ExtraContents";

import { useMemo, useState } from "react";
import SvgAdapter from "@/components/SvgAdapter";
import ChangePartBottomSheet from "../../ChangePartBottomSheet";
import { SVGPaintObj } from "@/components/SvgAdapter/configs";
import produce from "immer";
import { queryClient } from "@/providers/AppQueryProvider";

const partsList = [
  "hood",
  "ceil",
  "trunk",
  "leftBackCol",
  "leftBackFender",
  "leftFrontDoor",
  "leftBackDoor",
  "leftFrontFender",
  "leftCenterCol",
  "leftFrontCol",
  "rightBackCol",
  "rightBackFender",
  "rightFrontDoor",
  "rightBackDoor",
  "rightFrontFender",
  "rightCenterCol",
  "rightFrontCol"
];

const ChangedParts = () => {
  const [openingModalKey, setOpeningModalKey] = useState<string | null>(null);

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { mutateAsync: updateCarStatus, isLoading: isUpdateCarStatusLoading } =
    usePutConciergeSaleCarStatus(id as string, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getConciergeSaleCarStatusKey({
            conciergeSaleId: id as string
          })
        });
      }
    });

  const { data: carStatus } = useGetConciergeSaleCarStatus(
    {
      conciergeSaleId: id as string
    },
    { retry: false }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...carStatus
    },
    onSubmit: () => undefined
  });

  const clearAll = () => {
    const formikHealthDetail = formik.values.health_detail;
    const formikHealthFlags = formik.values?.health_flags || {};
    if (typeof formikHealthDetail !== "undefined") {
      formik.setFieldValue(
        "health_detail",
        produce(formikHealthDetail, (draft) => {
          partsList.forEach((partItem) => {
            draft[
              partItem as keyof GetConciergeSaleCarStatusResponse["health_detail"]
            ] = "intact";
          });
        })
      );
      formik.setFieldValue(
        "health_flags",
        produce(formikHealthFlags, (draft) => {
          draft.healthy_body = true;
          draft.room_changed = false;
        })
      );
    }
  };

  const onSubmitHandler = () => {
    const formikValues = formik.values;
    if (
      typeof formikValues.health_detail !== "undefined" &&
      typeof formikValues.health_flags !== "undefined" &&
      typeof formikValues.health_detail_version !== "undefined"
    ) {
      updateCarStatus({
        health_detail: formikValues.health_detail,
        health_detail_version: formikValues.health_detail_version,
        health_flags: formikValues.health_flags,
        description: formikValues.description || ""
      }).then(() => {
        navigate(-1);
      });
    }
  };

  const setAllChanged = () => {
    const formikHealthDetail = formik.values.health_detail;
    const formikHealthFlags = formik.values?.health_flags || {};
    if (typeof formikHealthDetail !== "undefined") {
      formik.setFieldValue(
        "health_detail",
        produce(formikHealthDetail, (draft) => {
          partsList.forEach((partItem) => {
            draft[
              partItem as keyof GetConciergeSaleCarStatusResponse["health_detail"]
            ] = "changed";
          });
        })
      );
      formik.setFieldValue(
        "health_flags",
        produce(formikHealthFlags, (draft) => {
          draft.healthy_body = false;
          draft.room_changed = true;
        })
      );
    }
  };

  const canSubmit = useMemo(() => {
    let isSubmitted = false;
    if (
      formik.values?.health_flags?.healthy_body ||
      formik.values?.health_flags?.room_changed
    ) {
      isSubmitted = true;
    }
    partsList.forEach((partKey) => {
      if (
        formik.values?.health_detail?.[
          partKey as keyof GetConciergeSaleCarStatusResponse["health_detail"]
        ] !== "intact"
      ) {
        isSubmitted = true;
      }
    });

    return isSubmitted;
  }, [
    formik.values?.health_detail,
    formik.values?.health_flags?.healthy_body,
    formik.values?.health_flags?.room_changed
  ]);

  return (
    <Stack sx={styles.container}>
      <Typography fontSize="0.875rem">
        کدام قسمت خودروی شما آسیب دیده است؟
      </Typography>

      <Typography mt="0.25rem" mb="0.5rem" fontSize="0.75rem">
        درصورتیکه بدنه آسیب‌دیدگی دارد لطفا روی تصویر مشخص کنید:
      </Typography>

      <FormControlLabel
        value="active"
        checked={formik.values?.health_flags?.healthy_body || false}
        onChange={(_, newChecked) => {
          if (newChecked) {
            clearAll();
          }
        }}
        control={<Radio />}
        label={
          <Typography fontSize="0.875rem">بدنه کاملا سالم است.</Typography>
        }
      />
      <FormControlLabel
        value="disactive"
        checked={formik.values?.health_flags?.room_changed || false}
        onChange={(_, newChecked) => {
          if (newChecked) {
            setAllChanged();
          }
        }}
        control={<Radio />}
        label={
          <Typography fontSize="0.875rem">اتاق کلا تعویض شده است.</Typography>
        }
      />

      <Stack sx={styles.svgContainer}>
        <SvgAdapter
          paint={(formik.values.health_detail || {}) as SVGPaintObj}
          onClick={(partKey) => {
            setOpeningModalKey(partKey);
          }}
        >
          <VerticalFullCar>
            <ExtraContents />
          </VerticalFullCar>
        </SvgAdapter>
      </Stack>

      <Stack sx={styles.buttonContainer}>
        <Button
          disabled={isUpdateCarStatusLoading || !canSubmit}
          onClick={onSubmitHandler}
          fullWidth
        >
          ثبت وضعیت بدنه
        </Button>
      </Stack>

      <ChangePartBottomSheet
        open={typeof openingModalKey === "string"}
        title="انتخاب نوع آسیب"
        value={(() => {
          if (
            typeof openingModalKey === "string" &&
            typeof formik.values.health_detail !== "undefined"
          ) {
            return (
              formik.values.health_detail?.[
                openingModalKey as keyof GetConciergeSaleCarStatusResponse["health_detail"]
              ] || null
            );
          } else {
            return null;
          }
        })()}
        onClose={() => setOpeningModalKey(null)}
        onSubmit={(newValue) => {
          const newFormikHealthDetails = formik.values.health_detail;
          const formikHealthFlags = formik.values?.health_flags || {};

          if (typeof newFormikHealthDetails !== "undefined") {
            formik.setFieldValue(
              "health_detail",
              produce(newFormikHealthDetails, (draft) => {
                draft[
                  openingModalKey as keyof GetConciergeSaleCarStatusResponse["health_detail"]
                ] = newValue;
              })
            );
            formik.setFieldValue(
              "health_flags",
              produce(formikHealthFlags, (draft) => {
                draft.healthy_body = false;
                draft.room_changed = false;
              })
            );
          }
        }}
      />
    </Stack>
  );
};

export default ChangedParts;
