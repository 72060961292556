import { FC } from "react";
import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
// import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

interface Props {
  description: Record<
    string,
    { title: string; features: Record<string, string> }
  >;
  slug: string;
  id?: number;
  title: string;
  price: number;
  onClick: (slug: string) => void;
  selectedItem: null | string;
}

const ServiceCard: FC<Props> = ({
  slug,
  title,
  price,
  description,
  onClick,
  selectedItem
}) => {
  const packageItem = description
    ? Object.values(description).map((item) => item)
    : [];

  return (
    <Stack
      component="div"
      sx={{
        border:
          selectedItem === slug ? "1px solid #694FB4" : "1px solid #EBEBEB",
        borderRadius: 2,
        bgcolor: "#fff",
        p: 2
      }}
      onClick={() => onClick(slug)}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #EBEBEB"
        pb={1.75}
      >
        <FormControlLabel
          value={slug}
          control={<Radio color="secondary" size="small" />}
          label={
            <Typography fontWeight={500} lineHeight={1.7} fontSize="0.75rem">
              {title ?? "-"}
            </Typography>
          }
        />

        <Typography fontWeight={500} fontSize="0.75rem">
          {Number(price).toLocaleString()} تومان
        </Typography>
      </Stack>
      <Stack>
        {packageItem.map((item) => (
          <Stack
            key={item.title}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            pt={1}
            pb={1.5}
            sx={{
              borderBottom: `1px solid #EBEBEB`,
              "&:last-child": {
                borderBottom: 0,
                pb: 0
              },
              "&:before": {
                display: "none"
              }
            }}
          >
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <DoneRoundedIcon sx={{ fontSize: 17, color: "#6e6e6e" }} />
              <Typography color="#6e6e6e" fontSize="0.75rem">
                {item.title}
              </Typography>
            </Stack>
            {/* <ArrowDropDownRoundedIcon sx={{ color: "#6e6e6e" }} /> */}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default ServiceCard;
