import type { FromSchema } from "json-schema-to-ts";
import { buildYup } from "schema-to-yup";

const fazayeDakheliVaTajhizatSchema = {
  type: "object",
  properties: {
    fazaie_dakheli: {
      type: "object",
      properties: {
        sandali_tajhizat_va_trime_samte_ranande: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            sandali_va_trim_salamat_zaheri_ya_estehkame_nasb_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            tanzimat_vaziat_sandali_barghi_mekaniki_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            garmkon_ya_tahviye_sandali_mayub_ast: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            sistem_hafeze_yekparche_sandali_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sandali_tajhizat_va_trime_samte_sarneshin: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            sandali_va_trim_salamat_zaheri_ya_estehkame_nasb_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            tanzimat_vaziat_sandali_barghi_mekaniki_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            garmkon_ya_tahviye_sandali_mayub_ast: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            sistem_hafeze_yekparche_sandali_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dashbord: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            dashbord_salamate_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sandali_tajhizat_va_trimhaye_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            salamat_zaheri_ya_estehkame_nasb_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            tanzimat_vaziat_sandali_barghi_mekaniki_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            garmkon_ya_tahviye_sandali_mayub_ast: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sandali_tajhizat_va_trimhaye_radife_sevom: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            sandali_va_trim_salamat_zaheri_ya_estehkame_nasb_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            tanzimat_vaziat_sandaliha_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    barf_pak_kon: {
      type: "object",
      properties: {
        barf_pak_kon_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            barf_pak_kon_va_shishe_shur_jolo_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            daste_barf_pak_kon_mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        bar_pak_kon: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            barf_pak_kon_va_shishe_shur_jolo_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            daste_barf_pak_kon_mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    shishe_balabar: {
      type: "object",
      properties: {
        samte_ranande_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad:
              {
                type: "string",
                enum: ["no", "yes"],
                enumNames: ["خیر", "بله"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        samte_ranande_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad:
              {
                type: "string",
                enum: ["no", "yes"],
                enumNames: ["خیر", "بله"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        samte_sarneshin_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad:
              {
                type: "string",
                enum: ["no", "yes"],
                enumNames: ["خیر", "بله"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        samte_sarneshin_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            shishe_bala_bar_mekaniki_ya_barghi_mayub_ast_ya_sedaye_gheyre_adi_darad:
              {
                type: "string",
                enum: ["no", "yes"],
                enumNames: ["خیر", "بله"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    ayeneha: {
      type: "object",
      properties: {
        samte_ranande: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            mekanizm_tanzim_shishe_ayene_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            mekanizm_jam_shavandegi_ayene_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            rahnama_mayub_ast_ya_mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        samte_sarneshin: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            mekanizm_tanzim_shishe_ayene_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            mekanizm_jam_shavandegi_ayene_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            rahnama_mayub_ast_ya_mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dide_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            ayene_did_aghab_salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sensor_nur_ya_elektrokoromik_ayene_mayub_ast_ya_bedorosti_amal_nemikonad:
              {
                type: "string",
                enum: ["no", "yes", "unavailable"],
                enumNames: ["خیر", "بله", "ندارد"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    cheraghha_va_tajhizat_roshanae: {
      type: "object",
      properties: {
        cheraghha_va_tajhizat_roshanae_samte_ranande_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            cheragh_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rahnama_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            meh_shekan_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            cheragh_taviz_shode_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        cheraghha_va_tajhizat_roshanae_samte_sarneshin_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            cheragh_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rahnama_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            meh_shekan_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            cheragh_taviz_shode_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            daste_rahnam_mayob_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            di_light_mayob_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        cheraghha_va_tajhizat_roshanae_samte_ranande_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            cheragh_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rahnama_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_tormoz_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_taviz_shode_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        cheraghha_va_tajhizat_roshanae_samte_sarneshin_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            cheragh_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            rahnama_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_tormoz_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_taviz_shode_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        cheraghha_va_tajhizat_roshanae_tajhizat_roshanae_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            meh_shekan_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            cheragh_tormoze_sevom_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_dande_aghab_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_pelak_mayob_ast_ya_bedorosti_amal_nemionad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        cheraghha_va_tajhizat_roshanae_dakhel_khodro: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            roshanayie_poste_amper_mayob_ast_ya_bedorosti_tanzim_nemishavad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            tanzim_konande_cheraghhaye_jolo_mayob_ast: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            felasher_mayob_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheraghaie_saghfi_ya_laye_darb_mayob_hastand_ya_bedorosti_amal_nemikonad:
              {
                type: "string",
                enum: ["no", "yes"],
                enumNames: ["خیر", "بله"],
                errors: {
                  required: "این فیلد اجباری است."
                }
              },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    ghofl_dastgire_va_sistem_zede_serghat: {
      type: "object",
      properties: {
        ghofl_markazi_dozdgir_va_sistem_zed_serghat: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            dozdgir_ya_sistem_zed_serghat_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            ghofl_markazi_mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        ghofl_ya_dar_baz_kon_dar_motor: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        ghofl_ya_dastgire_dar_samte_ranande_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        ghofl_ya_dastgire_dar_samte_ranande_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        ghofl_ya_dar_baz_kon_sandogh_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        ghofl_ya_dastgire_dar_samte_sarneshin_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        ghofl_ya_dastgire_dar_samte_sarneshin_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    sistem_tahvie: {
      type: "object",
      properties: {
        sarneshinan_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            sistem_tahvie_mayub_ast_ya_sedaye_gheire_adi_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            daricheha_mayub_hastan: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sistem_tahvie_dual_mayub_ast_ya_sedaye_gheire_adi_darad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            mahfaze_khonak_konande_mayub_ast: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sarneshinane_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            salamat_zaheri_nadarad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sistem_tahvie_mayub_ast_ya_sedaye_gheire_adi_darad: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            daricheha_mayub_hastan: {
              type: "string",
              enum: ["no", "yes", "unavailable"],
              enumNames: ["خیر", "بله", "ندارد"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    eimenie: {
      type: "object",
      properties: {
        eyrbagha: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            adame_vojode_eirbagha: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_eyrbag_roshan_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            cheragh_eyrbag_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sabeghe_amalkard_eyrbag_vojod_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        kamarband: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unknown", "detail", "checked_out"],
              enumNames: ["", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            kamarband_mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            sabeghe_amalkard_kamarband_vujud_darad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    description: {
      type: "string",
      maxLength: 1000
    }
  },
  additionalProperties: false
} as const;

export const fazayeDakheliVaTajhizatValidation = buildYup(
  fazayeDakheliVaTajhizatSchema
);

export type FazayeDakheliVaTajhizatType = FromSchema<
  typeof fazayeDakheliVaTajhizatSchema
>;

export default fazayeDakheliVaTajhizatSchema.properties;
