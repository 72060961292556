import { FromSchema } from "json-schema-to-ts";
import { buildYup } from "schema-to-yup";

const optionHaSchema = {
  type: "object",
  properties: {
    janebie: {
      type: "object",
      properties: {
        bogh: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        tanzim_konande_mogheyate_gharbilak: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sistem_soti_tasviri: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        jak_ya_achar_charkh: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            vojod_nadarad_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        anten: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            vojod_nadarad_ya_mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        mekanizm_sandoghparan: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        darbazkon_pomp_benzin: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    refahi: {
      type: "object",
      properties: {
        namayeshgar_lcd: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sunroof_ya_panaroma: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        koroz_kontorol: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sistem_otomatik_faalsazi_cgeraghha: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sistem_vorod_bedone_kilid: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        garmkon_farman: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sensore_baran: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        kilid_tnazim_vaziat_ranandegi: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        namyeshgar_kilometr_roye_shishe: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        aouto_park: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        masazhor_sandali: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        tanzim_konande_ertefa_sandogh_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        pardehaye_khodro: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast_ya_bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        cheragh_shor_jolo: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    iemeni: {
      type: "object",
      properties: {
        namayeshgar_noghte_kor_bsd_ya_bsm: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        sensore_park_mayob_ast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dorbin_sisado_shast: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        dorbin_dande_aghab: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        hoshdar_dahande_harekat_bein_khotot: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            mayub_ast: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        },
        tormoz_otomatik_auto_hold: {
          type: "object",
          properties: {
            status: {
              type: "string",
              enum: ["", "unavailable", "unknown", "detail", "checked_out"],
              enumNames: ["", "ندارد", "نامشخص", "ریز موارد", "بررسی شد"]
            },
            bedorosti_amal_nemikonad: {
              type: "string",
              enum: ["no", "yes"],
              enumNames: ["خیر", "بله"]
            },
            images: {
              type: "array",
              items: {
                type: "string"
              },
              maxItems: 5,
              minItems: 0
            }
          },
          additionalProperties: false,
          required: ["status"]
        }
      },
      additionalProperties: false,
      required: []
    },
    description: {
      type: "string",
      maxLength: 1000
    }
  },
  additionalProperties: false
} as const;

export const optionHaValidation = buildYup(optionHaSchema);
export type OptionHaType = FromSchema<typeof optionHaSchema>;
export default optionHaSchema.properties;
