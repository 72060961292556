import { INSPECTION_ORDER_STATE } from "@/services/api/inspection-jobs";
import { Theme, alpha } from "@mui/material";

export const {
  STATE_CONFIRMED_ASSIGNED,
  STATE_EXPERT_EN_ROUTE,
  STATE_EXPERT_ARRIVED,
  STATE_EXPERT_STARTED
} = INSPECTION_ORDER_STATE;

export const inspectionStates = [
  STATE_CONFIRMED_ASSIGNED,
  STATE_EXPERT_EN_ROUTE,
  STATE_EXPERT_ARRIVED,
  STATE_EXPERT_STARTED
];

export const getColor = (
  theme: Theme,
  givenState: INSPECTION_ORDER_STATE,
  currentState?: INSPECTION_ORDER_STATE
) => {
  const befors = inspectionStates.slice(
    0,
    inspectionStates.findIndex((state) => state === currentState)
  );

  if (currentState === givenState)
    return {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    };
  else if (befors.includes(givenState))
    return {
      color: "white",
      backgroundColor: theme.palette.primary.main
    };

  return {};
};

export const intervalCalc = (startTime: string) => {
  const now = Date.now();

  const startedAt = (now - new Date(startTime ?? now).getTime()) / 1000;

  const hours = Math.floor(startedAt / (60 * 60))
    .toString()
    .padStart(2, "0");

  const minutes = Math.floor((startedAt % (60 * 60)) / 60)
    .toString()
    .padStart(2, "0");

  const seconds = Math.floor(startedAt % 60)
    .toString()
    .padStart(2, "0");

  return { hours, minutes, seconds };
};
