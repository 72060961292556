import { FC, useState, ChangeEvent, SyntheticEvent } from "react";
import {
  Stack,
  Box,
  SelectChangeEvent,
  Typography,
  CircularProgress
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  useGetSaleDetailV2,
  useGetSaleNoteList,
  usePostConciergeSaleNote,
  useUpdateConciergeSaleNote
} from "@/services/api/sales";
import { useParams } from "react-router";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { NoteStatus } from "@/pages/ConciergeSale/requests/tabs/details/note/noteStatus";
import NoteModal from "@/pages/ConciergeSale/requests/tabs/details/note/noteModal";
const Notes: FC = () => {
  const [open, setOpen] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [formData, setFormData] = useState({
    order_id: id,
    category: "",
    content: ""
  });

  const [noteData, setNoteData] = useState({
    uuid: "",
    category: "",
    content: ""
  });

  const { data, isLoading, refetch } = useGetSaleNoteList(String(id));
  const { data: DetailData } = useGetSaleDetailV2(Number(id));

  const { mutateAsync } = usePostConciergeSaleNote();
  const { mutateAsync: updateMutateAsync } = useUpdateConciergeSaleNote(
    noteData.uuid
  );

  const changeHandler = (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const submitHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    mutateAsync(formData).then(() => {
      refetch();
      setLoading(false);
      setOpen(false);
      setFormData({ ...formData, category: "", content: "" });
    });
  };

  const updateChangeHandler = (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNoteData({ ...noteData, [event.target.name]: event.target.value });
  };

  const updateHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    updateMutateAsync({
      category: noteData.category,
      content: noteData.content
    }).then(() => {
      refetch();
      setLoading(false);
      setEditNote(false);
    });
  };

  const createNoteTime = (time: string) => {
    const noteDate = new Date(time).toLocaleDateString("fa-IR");
    const noteTime = new Date(time).toLocaleTimeString("fa-IR").substring(0, 5);
    return `${noteTime} - ${noteDate}`;
  };

  return (
    <>
      {!isLoading && (
        <Stack sx={styles.noteContainer}>
          {DetailData?.operator_note && (
            <Stack
              sx={{
                border: "1px solid #6E6E6E",
                py: "21px",
                px: "16px",
                borderRadius: "8px",
                mb: "16px",
                fontSize: "14px",
                background: "#eee"
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: "#6E6E6E",
                  fontSize: "14px",
                  fontWeight: "bold",
                  mb: "8px"
                }}
              >
                یادداشت عملیات
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#6E6E6E", lineHeight: "1.7", fontSize: "14px" }}
              >
                {DetailData?.operator_note}
              </Typography>
            </Stack>
          )}

          {data?.length ? (
            data?.map((item) => (
              <Box
                key={item.uuid}
                sx={styles.noteBox}
                onClick={() => {
                  setEditNote(true);
                  setNoteData({ ...item });
                }}
              >
                <Stack>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "medium",
                      color: "#6E6E6E"
                    }}
                  >
                    {item?.agent_fullname}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "14px",
                      color: "#6E6E6E",
                      mt: "8px"
                    }}
                  >
                    تاریخ ایجاد: {createNoteTime(item?.created_at)}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "14px",
                      color: "#6E6E6E",
                      mt: "6px",
                      mb: "12px"
                    }}
                  >
                    تاریخ بروزرسانی:{" "}
                    {createNoteTime(item?.last_modified as string)}
                  </Typography>
                </Stack>

                <Box sx={styles.noteDescContainer}>
                  {NoteStatus(item.category)}
                  <Typography variant="body1" sx={styles.noteDesc}>
                    {item?.content}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1" sx={styles.boxTitle}>
              یادداشتی ثبت نشده است.
            </Typography>
          )}
        </Stack>
      )}

      {isLoading && (
        <Stack
          width="100%"
          height="400px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size="50px" />
        </Stack>
      )}

      <Box sx={styles.circleNewNote} onClick={() => setOpen(true)}>
        <AddOutlinedIcon sx={{ color: "#fff" }} />
      </Box>

      <NoteModal
        title="افزودن یادداشت"
        submitHandler={submitHandler}
        changeHandler={changeHandler}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        category={formData?.category}
        content={formData?.content}
        loading={loading}
      />

      <NoteModal
        title="ویرایش یادداشت"
        submitHandler={updateHandler}
        changeHandler={updateChangeHandler}
        open={editNote}
        onOpen={() => setEditNote(true)}
        onClose={() => setEditNote(false)}
        category={noteData?.category}
        content={noteData?.content}
        loading={loading}
      />
    </>
  );
};

export default Notes;
