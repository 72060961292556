/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Car, useFindCarsV1Exact } from "@/services/api/searchCar";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Drawer
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import FormFields from "./fields";
import {
  EvaluationV1PackagesList200DataItem,
  EvaluationV1ServiceTimeList200DataItem,
  InspectionCenterItem,
  useEvaluationV1InspectionCreate
} from "./services";
import toEnNumber from "@/utils/toEnNumber";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useParams } from "react-router";
import { useGetLeasingDetail } from "@/services/api/leasing/detail";
import { useRootLayout } from "@/hooks/useRootLayout";
import { getAuthInfo } from "@/utils/auth";

export interface FormikType {
  car: Car | null;
  inspectionType: { label: string; id: string };
  phone: string;
  fullName: string;
  city: number | null;
  plan: EvaluationV1PackagesList200DataItem;
  timeslot: EvaluationV1ServiceTimeList200DataItem | null;
  serviceType: "in_center" | "in_person" | null;
  address: string | null;
  description: string | null;
  inspectionCenter: InspectionCenterItem | null;
  discountCode: string | null;
  visitTime: string | null;
  conciergeSaleCode: string | null;
  leasingToken: string | null;
  isSendToOffer: boolean;
}

const ISPage = () => {
  const { setHeaderTitle, setBackUrl } = useRootLayout();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("درخواست کارشناسی");
      setBackUrl(-1);
    }
  }, [setHeaderTitle, setBackUrl]);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: leasingOrder } = useGetLeasingDetail(id as string, {
    enabled: !!id
  });
  const useLoggedInInfo = useMemo(getAuthInfo, []);
  const dataFromDetail = {
    phone: useLoggedInInfo?.username,
    fullName: leasingOrder?.customer.full_name,
    city: leasingOrder?.city,
    address: leasingOrder?.address,
    leasingToken: leasingOrder?.uuid
  };

  const { mutate, data: findedCar } = useFindCarsV1Exact({
    serviceName: "inspection"
  });

  const isMount = useRef(false);

  useEffect(() => {
    if (leasingOrder?.car_brand_model) {
      mutate({
        text: leasingOrder.car_brand_model,
        serviceName: "inspection"
      });

      isMount.current = true;
    }
  }, [leasingOrder?.car_brand_model, mutate]);

  const formInitialValues = Object.assign(
    {
      inspectionType: { label: "خرید قسطی", id: "W" },
      car: findedCar
        ? {
            ...findedCar?.data,
            name: findedCar?.data.name_en
          }
        : null,
      phone: "",
      fullName: "",
      city: null,
      plan: "leasing-agent-inspection",
      timeslot: null,
      serviceType: null,
      address: null,
      inspectionCenter: null,
      discountCode: null,
      visitTime: null,
      conciergeSaleCode: null,
      leasingToken: null,
      description: null,
      isSendToOffer: true
    },
    dataFromDetail
  );

  const {
    mutateAsync: submitOrder,
    isLoading,
    data: res
  } = useEvaluationV1InspectionCreate();
  const formik = useFormik<FormikType>({
    initialValues: formInitialValues,
    enableReinitialize: true,
    onSubmit: (data) => {
      const {
        car,
        city,
        fullName,
        phone,
        inspectionType,
        plan,
        timeslot,
        serviceType,
        address,
        inspectionCenter,
        visitTime,
        discountCode,
        conciergeSaleCode,
        leasingToken,
        description,
        isSendToOffer
      } = data;
      if (
        ((inspectionType?.id === "G" && conciergeSaleCode) ||
          inspectionType?.id === "O" ||
          inspectionType?.id === "V" ||
          (inspectionType?.id == "W" && leasingToken)) &&
        car?.name_en &&
        phone &&
        fullName &&
        city &&
        timeslot?.pk &&
        ((serviceType === "in_person" && address && !!visitTime) ||
          (serviceType === "in_center" && inspectionCenter?.id))
      ) {
        const formData = {
          inspection_type: inspectionType?.id,
          customer_name: fullName,
          phone_number: toEnNumber(phone ?? "")!,
          city,
          time_slot_id: timeslot?.pk,
          brand_model: car?.name_en,
          package: plan?.slug,
          visit_place:
            serviceType === "in_person" ? address! : inspectionCenter!.address!,
          description: description!,
          visit_time:
            serviceType === "in_person"
              ? toEnNumber(visitTime ?? "")!
              : timeslot?.start,
          center:
            serviceType === "in_person" ? "in_person" : inspectionCenter?.slug,
          concierge_sale: conciergeSaleCode!,
          leasing_token: leasingToken!,
          discount_code: discountCode ? discountCode : null,
          vehicle_type: car!.vehicle_type!,
          is_send_to_offer: isSendToOffer
        };
        submitOrder({
          data: formData
        })
          .then((res) => {
            if (res?.data?.is_reserve) {
              toast.success(
                "درخواست شما با موفقیت رزرو شد و در انتظار پرداخت است."
              );
            } else {
              setIsModalOpen(true);
            }
            formik.resetForm();
          })
          .catch((err) => {
            if (inspectionType?.id === "W") {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error(err?.response?.message);
            }
          });
      } else {
        toast.warning("تمامی فیلد ها باید تکمیل باشد");
      }
    }
  });
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        width: "calc(100% + 32px)",
        height: "100vh",
        overflow: "hidden",
        gridColumn: "span 12",
        background: "#fff"
      }}
    >
      <Stack height="100%" p={3} pb={0} pt={0} overflow="hidden">
        <FormFields leasingOrder={leasingOrder} form={formik} />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        width="100%"
        p={3}
        gap={1.5}
        sx={(theme) => ({
          backgroundColor: theme.palette.surface.default,
          borderTop: `1px solid ${theme.palette.background.paper}`
        })}
      >
        <Button
          variant="contained"
          onClick={() => {
            formik.setFieldValue(
              "isSendToOffer",
              formik.values.plan?.slug === "economy-inspection-leasing"
            );
            formik.handleSubmit();
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={15}
              sx={{
                color: (theme) => theme.palette.iconDefault.main,
                marginBottom: 1
              }}
            />
          ) : (
            "ثبت سفارش"
          )}
        </Button>
      </Stack>
      <Drawer
        anchor="bottom"
        sx={{
          minHeight: "15vh"
        }}
        open={isModalOpen}
        // onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // position: "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            // width: 500,
            bgcolor: "#FFFFFF",
            borderRadius: "8px",
            p: 2,
            minWidth: "fit-content"
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              py: 2,
              px: 4
            }}
          >
            <Typography>
              سفارش با کد
              <Typography
                component={"span"}
                lineHeight={0}
                fontWeight="600"
                fontSize="14px"
                color="#000000"
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#ededed",
                  borderRadius: "4px",
                  px: 1,
                  mx: 1
                }}
              >
                {res?.data?.id}
                <IconButton
                  onClick={() => {
                    if (navigator?.clipboard?.writeText && res?.data?.id) {
                      navigator.clipboard
                        .writeText(res?.data?.id?.toString())
                        .then(() => {
                          toast.info(`کد سفارش ${res?.data?.id} کپی شد`);
                        });
                    }
                  }}
                >
                  <ContentCopyIcon
                    sx={{
                      width: "15px",
                      height: "15px"
                    }}
                  />
                </IconButton>
              </Typography>
              {formik.values.isSendToOffer
                ? "با موفقیت ثبت و به آفر ارسال شد."
                : "با موفقیت ثبت شد و آماده تخصیص به کارشناس است."}
            </Typography>
          </Stack>
          <Button
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            متوجه شدم
          </Button>
        </Box>
      </Drawer>
    </Stack>
  );
};
export default ISPage;
