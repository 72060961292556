import { pmt } from "@/utils/loanCalculate";

export const calculateMinPrePayment = (
  minPrePaymentSharePercent: number,
  sellerShare: number,
  maxLeasing: number,
  devCoefficient: number,
  karnamehCommition: number,
  karnamehCommitionPercent: number
) => {
  const adsPayment = karnamehCommition + sellerShare;
  return (
    Math.ceil(
      Math.max(
        adsPayment -
          ((minPrePaymentSharePercent / 100) *
            sellerShare *
            (1 - devCoefficient * 1.09)) /
            (1 + karnamehCommitionPercent / 100),
        adsPayment -
          (maxLeasing * (1 - devCoefficient * 1.09)) /
            (1 + karnamehCommitionPercent / 100)
      ) / 1000000
    ) * 1000000
  );
};

export const calculateMaxPrePayment = (
  sellerShare: number,
  minLeasing: number,
  devCoefficient: number,
  karnamehCommition: number,
  karnamehCommitionPercent: number
) => {
  const adsPayment = karnamehCommition + sellerShare;
  return (
    Math.floor(
      (adsPayment -
        (minLeasing * (1 - devCoefficient * 1.09)) /
          (1 + karnamehCommitionPercent / 100)) /
        1000000
    ) * 1000000
  );
};

export const calculateFacilityAmount = (
  karnamehCommitionPercent: number,
  sellerShare: number,
  prePayment: number,
  karnamehCommition: number,
  devCoefficient: number
) => {
  const adsPayment = karnamehCommition + sellerShare;
  return (
    ((1 + karnamehCommitionPercent / 100) * (adsPayment - prePayment)) /
    (1 - devCoefficient * 1.09)
  );
};

export const calculateInstallmentAmount = (
  rate: number,
  paymentPeriod: number,
  paybackPeriod: number,
  facilityAmount: number
) => {
  return Math.floor(
    -1 *
      pmt(
        rate / (12 / paymentPeriod),
        paybackPeriod / paymentPeriod,
        facilityAmount
      )
  );
};

export const calculateMonthlyInstallmentAmount = (
  installmentAmount: number,
  paymentPeriod: number
) => {
  return Math.ceil(installmentAmount / paymentPeriod / 1000) * 1000;
};

export const calculateFinalRefund = (
  prePayment: number,
  installmentAmount: number,
  paybackPeriod: number,
  paymentPeriod: number
) => {
  return (
    Math.ceil(
      (prePayment + (installmentAmount * paybackPeriod) / paymentPeriod) / 1000
    ) * 1000
  );
};

export const calculateKarnamehIncome = (
  karnamehCommition: number,
  karnamehCommitionPercent: number,
  sellerShare: number,
  prePayment: number
) => {
  const adsPayment = karnamehCommition + sellerShare;
  return (
    (1 + karnamehCommitionPercent / 100) * (adsPayment - prePayment) -
    (sellerShare - prePayment)
  );
};

export const calculateAmountOfCheckToSeller = (
  karnamehCommitionPercent: number,
  karnamehCommition: number,
  sellerShare: number,
  prePayment: number
) => {
  const adsPayment = karnamehCommition + sellerShare;
  return (1 + karnamehCommitionPercent / 100) * (adsPayment - prePayment);
};

export const CalculateTotalAmountPaidBuyerToSeller = (
  prePayment: number,
  karnamehCommitionPercent: number,
  sellerShare: number,
  karnamehCommition: number
) => {
  return (
    prePayment -
    ((1 + karnamehCommitionPercent / 100) *
      (karnamehCommition + sellerShare - prePayment) -
      (sellerShare - prePayment))
  );
};
