import { FC, useEffect, useState } from "react";
import { useRootLayout } from "@/hooks/useRootLayout";
import { CircularProgress, Stack, Typography } from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import {
  GetCostsReportResponse,
  useGetCostsReport
} from "@/services/api/concierge-sale/costs-report";
import {
  costStatistics,
  periodTimeItems
} from "@/pages/ConciergeSale/costs-report/data";
import CostsReportTable from "@/pages/ConciergeSale/costs-report/components/costsReportTable";

const CostsReport: FC = () => {
  const [periodDays, setPeriodDays] = useState(periodTimeItems[0].days);
  const getTodayDate = new Date();
  const monthDate = new Date();
  monthDate.setDate(monthDate.getDate() - periodDays);

  const { setHeaderTitle } = useRootLayout();

  const { data, isLoading } = useGetCostsReport({
    from_date: monthDate.toLocaleDateString("en-CA", { timeZone: "Iran" }),
    to_date: getTodayDate.toLocaleDateString("en-CA", { timeZone: "Iran" })
  });

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("گزارش هزینه ها");
    }
  }, [setHeaderTitle]);

  return (
    <Stack
      sx={{
        width: "100%",
        py: 3,
        height: "100vh",
        overflowY: "scroll",
        bgcolor: "#FAFAFA"
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          px: 3,
          mb: 1.5
        }}
      >
        {periodTimeItems.map((item) => (
          <Stack
            key={item.days}
            sx={{
              background: periodDays === item.days ? "#EDEBF4" : "#fff",
              display: "inline-flex",
              border: "1px solid",
              borderColor: periodDays === item.days ? "#694FB4" : "#C9CCCF",
              borderRadius: "8px",
              py: 1,
              px: 1.5,
              color: periodDays === item.days ? "#694FB4" : "#6E6E6E",
              whiteSpace: "nowrap",
              mr: 1,
              mb: 1,
              fontSize: 14
            }}
            onClick={() => setPeriodDays(item.days)}
          >
            {item.title}
          </Stack>
        ))}
      </Stack>
      <Stack
        sx={{
          background: "#fff",
          borderTop: "1px solid #EBEBEB",
          borderBottom: "1px solid #EBEBEB",
          py: 2,
          px: 3
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "1px solid #EBEBEB",
            pb: 1.5
          }}
        >
          <LeaderboardIcon sx={{ color: "#6E6E6E", fontSize: 20 }} />
          <Typography sx={{ fontSize: 15, ml: 1, fontWeight: 500 }}>
            جدول هزینه‌ و درآمد
          </Typography>
        </Stack>
        <Stack sx={{ mt: 1.5, fontSize: 14 }}>
          {typeof data !== "undefined" ? (
            <CostsReportTable {...data} />
          ) : (
            <Typography sx={{ fontSize: "15px" }}>
              اطلاعاتی وجود ندارد.
            </Typography>
          )}
        </Stack>
      </Stack>

      <Stack
        sx={{
          background: "#fff",
          borderTop: "1px solid #EBEBEB",
          borderBottom: "1px solid #EBEBEB",
          pt: 2,
          mt: 1
        }}
      >
        <Stack sx={{ px: 3 }}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5
            }}
          >
            <RequestPageIcon sx={{ color: "#6E6E6E", fontSize: 20 }} />
            <Typography sx={{ fontSize: 15, ml: 1, fontWeight: 500 }}>
              آمار هزینه‌ها
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          {costStatistics.map((item, index) => (
            <Stack
              key={item.en_title}
              sx={{
                fontSize: 14,
                background: index % 2 === 0 ? "#fafafa" : "#fff",
                px: 3,
                py: 2,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Typography sx={{ fontSize: "15px" }}>{item.fa_title}</Typography>
              <Typography sx={{ fontSize: "15px" }}>
                {(data as GetCostsReportResponse)?.[
                  item.en_title as keyof typeof data
                ] || "-"}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CostsReport;
