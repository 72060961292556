import { toEngDigits } from "@/utils/toEnglishDigit";

type SearchInArray = <T>(
  items: T[],
  searchValue: string,
  getSearchableItems: (item: T) => string[]
) => T[];

export const searchInArray: SearchInArray = (
  items,
  searchValue,
  getSearchableItems
) => {
  const mainSearchValue = toEngDigits(searchValue.replace(/(‌| )/g, ""));

  const regExpression = `^.*${[...Array.from(mainSearchValue)].join(".*")}.*$`;

  const result = items.filter((item) => {
    const searchableItems = getSearchableItems(item).map((i) => {
      return toEngDigits(i.replace(" ", "").replaceAll("‌", ""));
    });
    let isMatch = false;
    searchableItems.forEach((searchableItem) => {
      if (RegExp(regExpression, "i").test(searchableItem)) {
        isMatch = true;
      }
    });
    return isMatch;
  });

  const sortedResult = result.sort((a, b) => {
    const searchableItemsOfA = getSearchableItems(a).map((i) => {
      return toEngDigits(i.replace(" ", "").replaceAll("‌", ""));
    });

    const searchableItemsOfB = getSearchableItems(b).map((i) => {
      return toEngDigits(i.replace(" ", "").replaceAll("‌", ""));
    });

    if (
      searchableItemsOfA.some((i) => i.includes(mainSearchValue)) &&
      !searchableItemsOfB.some((i) => i.includes(mainSearchValue))
    ) {
      return -1;
    } else if (
      !searchableItemsOfA.some((i) => i.includes(mainSearchValue)) &&
      searchableItemsOfB.some((i) => i.includes(mainSearchValue))
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedResult;
};
