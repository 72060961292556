import { FC, useState, useEffect } from "react";
import { Stack, Box } from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";

import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";

import Info from "@/pages/ConciergeSale/requests/tabs/details/info";
import Notes from "@/pages/ConciergeSale/requests/tabs/details/notes";

import { styles } from "@/pages/ConciergeSale/requests/detail/styles";

const RequestDetail: FC = () => {
  const [tabItem, setTabItem] = useState("info");
  const { setHeaderTitle } = useRootLayout();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("جزئیات سفارش");
    }
  }, []);

  const tabItems = [
    {
      key: "info",
      label: "اطلاعات",
      icon: <ListAltOutlinedIcon sx={styles.icon} />,
      render: () => <Info />
    },
    {
      key: "notes",
      label: "یادداشت ها",
      icon: <CommentOutlinedIcon sx={styles.icon} />,
      render: () => <Notes />
    }
  ];

  const RenderComponent = tabItems.find((item) => item.key === tabItem)?.render;

  return (
    <>
      <Stack sx={styles.tabNavContainer} direction="row">
        {tabItems.map((item) => {
          return (
            <Box
              key={item.key}
              onClick={() => setTabItem(item.key)}
              sx={{
                ...styles.tabNavItem,
                borderBottom:
                  tabItem === item.key
                    ? "2px solid #00A754"
                    : "2px solid transparent",
                color: tabItem === item.key ? "#000" : "#49454F"
              }}
            >
              {item.icon}
              {item.label}
            </Box>
          );
        })}
      </Stack>

      {RenderComponent && <RenderComponent />}
    </>
  );
};

export default RequestDetail;
