import { STATE_CONFIRMED_ASSIGNED } from "@/pages/inspection-job-details-dynamic/configs";
import {
  InspectionJobStateInfoKey,
  inspectionJobStateInfo
} from "@/pages/inspection-jobs-dynamic/configs";
import { AssiegedOrders } from "@/services/api/inspection-jobs";
import { makeUrl } from "@/utils/routing";
import { Button, Chip, Divider, Stack, Typography, alpha } from "@mui/material";
import { useMemo, type FC } from "react";
import { useNavigate } from "react-router";

const JobItem: FC<AssiegedOrders> = (props) => {
  const {
    visit_place: address,
    brand_model: car,
    id,
    selected_package_name: inspectionPackage,
    my_share: share,
    state,
    visit_time,
    shift
  } = props;

  const navigate = useNavigate();

  const { color, faName } = useMemo(
    () =>
      inspectionJobStateInfo[state as InspectionJobStateInfoKey] ?? {
        faName: "تخصیص نهایی",
        nextStateActionTitle: "اعزام شدم",
        color: "#009C05",
        title: "درحال اعزام به محل کارشناسی هستم.",
        warnningMessage: "قبل از اعزام با مشتری تماس بگیرید",
        actionTitle: "تأیید"
      },
    [state]
  );

  const time = useMemo(() => {
    const isToday =
      new Date(shift.date).toDateString() === new Date().toDateString();
    const clock = `${shift.start?.slice(0, 2)} تا ${shift.end?.slice(0, 2)}`;

    return `${isToday ? "امروز" : "فردا"} - ${clock} ${
      visit_time ? `(${visit_time?.slice(0, 5)})` : ""
    }`;
  }, [shift.date, shift.end, shift.start, visit_time]);

  return (
    <Stack
      spacing={2}
      sx={{
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.16)",
        p: 2,
        borderRadius: 2
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize="1rem" fontWeight={500}>
          {time}
        </Typography>

        {state !== STATE_CONFIRMED_ASSIGNED ? (
          <Chip
            size="small"
            sx={(theme) => ({
              borderRadius: 8,
              paddingRight: 1,
              paddingLeft: 1,
              color,
              backgroundColor: alpha(color, 0.1),
              height: theme.spacing(4)
            })}
            label={faName}
          />
        ) : null}
      </Stack>

      <Typography>شماره سفارش: {id}</Typography>
      <Typography>{address}</Typography>
      <Typography>{car}</Typography>
      <Typography>{inspectionPackage}</Typography>
      <Divider sx={{ borderStyle: "dashed" }} />

      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography>
          سهم شما:{" "}
          <Typography component="span" fontWeight={600}>
            {share.toLocaleString("fa-IR")} تومان
          </Typography>
        </Typography>

        <Button
          size="large"
          onClick={() => {
            navigate(`/inspection/inspection-jobs-dynamic/${id}`);
          }}
          sx={{ flex: 1 }}
        >
          {state !== STATE_CONFIRMED_ASSIGNED ? "ادامه" : "جزئیات"}
        </Button>
      </Stack>
    </Stack>
  );
};

export default JobItem;
