import {
  CameraRounded,
  CloseRounded,
  CollectionsRounded
} from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { Variants } from "..";
import styles from "./styles";

interface PropTypes {
  variantsList: Variants[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openCamera: () => void;
  openUpload: () => void;
}

const VariantModal: FC<PropTypes> = (props) => {
  const { variantsList, open, setOpen, openCamera, openUpload } = props;

  const [isSelectVariantShow, setIsSelectVariantShow] = useState(false);
  const isSelectVariantTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (typeof isSelectVariantTimeout.current === "number") {
      clearTimeout(isSelectVariantTimeout.current);
    }
    isSelectVariantTimeout.current = setTimeout(
      () => {
        setIsSelectVariantShow(open);
      },
      open ? 100 : 300
    );
  }, [open]);

  if (!open && !isSelectVariantShow) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          ...styles.modalBackdrop,
          opacity: open && isSelectVariantShow ? 1 : 0,
          pointerEvents: open && isSelectVariantShow ? "all" : "none"
        }}
        component="div"
        onClick={() => setOpen(false)}
      />
      <Stack
        sx={{
          ...styles.modalContainer,
          opacity: open && isSelectVariantShow ? 1 : 0,
          transform:
            open && isSelectVariantShow ? "translateY(0)" : "translateY(50px)",
          pointerEvents: open && isSelectVariantShow ? "all" : "none"
        }}
      >
        <Stack sx={styles.modalHeader}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseRounded sx={{ color: (th) => th.palette.grey[500] }} />
          </IconButton>
        </Stack>

        {variantsList.includes("camera") && (
          <Stack component="div" onClick={openCamera} sx={styles.modalItem}>
            <CameraRounded sx={styles.modalItemIcon} />
            <Typography>گرفتن عکس</Typography>
          </Stack>
        )}

        {variantsList.includes("upload") && (
          <Stack component="div" onClick={openUpload} sx={styles.modalItem}>
            <CollectionsRounded sx={styles.modalItemIcon} />
            <Typography>آپلود از گالری</Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default VariantModal;
