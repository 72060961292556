import React, { FC } from "react";
import {
  Stack,
  CircularProgress,
  Typography,
  Box,
  Button
} from "@mui/material";
import RequestCard from "@/pages/ConciergeSale/requests/requestCard";
import { styles } from "@/pages/ConciergeSale/requests/tabs/styles";
import { useGetPaginationData } from "@/services/api/sales";
import famousIcon from "@/assets/icons/famous.svg";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const Negotiation: FC = () => {
  const {
    data: pData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useGetPaginationData("P", 1);

  return (
    <>
      {!isLoading && (
        <Stack sx={styles.tabContentContainer}>
          <Link
            to="/concierge-sale/requests/pricing"
            style={{
              display: "flex",
              alignItems: "center",
              color: "#694FB4",
              textDecoration: "underline",
              marginBottom: "18px"
            }}
          >
            سفارش‌های در انتظار قیمت‌گذاری
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
          </Link>
          {pData?.pages?.map((page, index) =>
            page?.items?.length ? (
              <React.Fragment key={index}>
                {page?.items?.map((item) => (
                  <RequestCard key={item.id} {...item} />
                ))}
              </React.Fragment>
            ) : (
              <Box key={page.pages} sx={styles.emptyRequestArea}>
                <img src={famousIcon} alt="famous icon" />
                <Typography sx={styles.emptyRequestDesc} variant="body1">
                  تبریک!
                  <br />
                  با همه مشتری ها توافق قیمت کردی
                </Typography>
              </Box>
            )
          )}

          {hasNextPage && (
            <Button
              sx={{ mb: 2 }}
              variant="outlined"
              disabled={isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              {isFetchingNextPage ? (
                <CircularProgress size={20} sx={{ color: "#00000050" }} />
              ) : (
                "مشاهده سایر درخواست‌ها"
              )}
            </Button>
          )}
        </Stack>
      )}

      {isLoading && (
        <Stack
          width="100%"
          height="400px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size="50px" />
        </Stack>
      )}
    </>
  );
};

export default Negotiation;
