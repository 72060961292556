import queryString from "query-string";

export const getQueryItem = (
  query: queryString.ParsedQuery<string>,
  key: string
) => {
  if (key in query) {
    if (typeof query[key] === "string") {
      return query[key];
    } else if (Array.isArray(query[key])) {
      return query[key]?.at(-1);
    }
  }
  return "";
};

export const getQueryAsArray = (
  query: queryString.ParsedQuery<string>,
  key: string
) => {
  if (key in query) {
    if (typeof query[key] === "string") {
      return [query[key] as string];
    } else {
      return query[key] as string[];
    }
  } else {
    return [];
  }
};
