import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import AddTaskIcon from "@mui/icons-material/AddTask";

export interface ConcierSaleMenuItems {
  title: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<object, "svg">> & {
        muiName: string;
      })
    | null;
  path: string;
  is_external_link: boolean;
}

export const conciergeSaleMenu: ConcierSaleMenuItems[] = [
  {
    title: "آفرها",
    icon: AddTaskIcon,
    path: "/concierge-sale/offers",
    is_external_link: false
  },
  {
    title: "لیست لیدها",
    icon: AssignmentOutlinedIcon,
    path: "/concierge-sale/requests",
    is_external_link: false
  },
  {
    title: "گزارش هزینه ها",
    icon: LeaderboardOutlinedIcon,
    path: "/concierge-sale/costs-report",
    is_external_link: false
  }
  // {
  //   title: "استعلام سوابق",
  //   icon: RestoreOutlinedIcon,
  //   path: "https://karnameh.com/services/car-authentication/car-info",
  //   is_external_link: true
  // }
];
