import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { SwipeableDrawer } from "@mui/material";
import { FC, memo, useEffect, useState } from "react";
import { useParams } from "react-router";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useUpdateLeasingOrder } from "@/services/api/leasing/requests";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  firstName: string;
  lastName: string;
}

const NameModal: FC<Props> = ({
  open,
  showModal,
  title,
  firstName,
  lastName
}) => {
  const { id } = useParams();
  const [name, setName] = useState({ first_name: "", last_name: "" });
  const { mutateAsync, isLoading } = useUpdateLeasingOrder();

  useEffect(() => {
    if (firstName || lastName) {
      setName({ first_name: firstName, last_name: lastName });
    }
  }, [firstName, lastName]);

  const submitHandler = () => {
    mutateAsync({
      id: String(id),
      customer: name
    }).then(() => {
      showModal(false);
    });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack gap={"20px"}>
          <TextField
            label="ویرایش  نام"
            sx={{ lineHeight: 1.7 }}
            size="small"
            value={name.first_name || ""}
            onChange={(event) =>
              setName({ ...name, first_name: event.target.value })
            }
          />
          <TextField
            label="ویرایش  نام خانوادگی"
            sx={{ lineHeight: 1.7 }}
            size="small"
            value={name.last_name || ""}
            onChange={(event) =>
              setName({ ...name, last_name: event.target.value })
            }
          />
        </Stack>
        <Button onClick={submitHandler} disabled={!name} sx={{ mt: 2 }}>
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: "#00000050" }} />
          ) : (
            "ویرایش"
          )}
        </Button>
      </Stack>
    </SwipeableDrawer>
  );
};

export default memo(NameModal);
