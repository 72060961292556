import { Stack, Tabs, Tab, Button } from "@mui/material";
import { FC, useEffect } from "react";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router";
import { styles } from "./styles";
import { useRootLayout } from "@/hooks/useRootLayout";
import { AddRounded } from "@mui/icons-material";
import { makeUrl } from "@/utils/routing";

const ConciergeSaleLayout: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setHeaderTitle } = useRootLayout();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("لیست درخواست ها");
    }
  }, []);

  const tabsList = [
    { pathname: "/concierge-sale/requests", value: "initial" },
    {
      pathname: "/concierge-sale/requests/negotiation",
      value: "negotiation"
    },
    {
      pathname: "/concierge-sale/requests/advertised",
      value: "advertised"
    },
    { pathname: "/concierge-sale/requests/all", value: "all" }
  ];

  const matchedTab = tabsList.find((tabItem) =>
    matchPath(tabItem.pathname, pathname)
  )?.value;

  return (
    <Stack sx={styles.container}>
      <Tabs
        sx={styles.tabs}
        onChange={(_e, newValue) => {
          const findedTab = tabsList.find(
            (tabItem) => tabItem.value === newValue
          );
          if (findedTab) {
            navigate({
              pathname: findedTab.pathname
            });
          }
        }}
        value={matchedTab}
      >
        <Tab sx={styles.tab} value="initial" label="تماس اولیه" />
        <Tab sx={styles.tab} value="negotiation" label="مذاکره قیمت" />
        <Tab sx={styles.tab} value="advertised" label="آگهی شده" />
        <Tab sx={styles.tab} value="all" label="همه" />
      </Tabs>

      <Outlet />
      <Button
        sx={styles.submitOrderBtn}
        color="secondary"
        onClick={() => {
          navigate(makeUrl("/concierge-sale/submit-order"));
        }}
        startIcon={<AddRounded />}
      >
        درخواست جدید
      </Button>
    </Stack>
  );
};

export default ConciergeSaleLayout;
