import { httpRest } from "@/services/http-clients";
import { useQuery, UseQueryOptions } from "react-query";

//#region Get Inspection Sale Package

export interface GetInspectionSalePackageParams {
  city_id?: number;
  brand_model?: string;
}

export interface GetInspectionSalePackageResponse {
  pk: number;
  title: string;
  slug: string;
  in_person: boolean;
  price: number;
  discount: number;
  latitude: number;
  longitude: number;
  address: string;
  duration: string;
  guarantee: string;
  price_estimation_report: boolean;
  technical_report: boolean;
  body_color_report: boolean;
  description: Record<
    string,
    { title: string; features: Record<string, string> }
  >;
  display_priority: number;
}

const getInspectionSalePackage = async (
  params?: GetInspectionSalePackageParams
) => {
  return httpRest<GetInspectionSalePackageResponse[]>({
    method: "GET",
    url: `/evaluation/v1/sale-packages/`,
    params: {
      city_id: params?.city_id,
      brand_model: params?.brand_model
    }
  })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return [];
    });
};

const getInspectionSalePackageKey = "getInspectionSalePackage";

export const useGetInspectionSalePackage = (
  params?: GetInspectionSalePackageParams,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getInspectionSalePackage>>>
) => {
  return useQuery({
    queryKey: [getInspectionSalePackageKey],
    staleTime: 0,
    queryFn: () => {
      return getInspectionSalePackage(params);
    },
    ...options
  });
};

//#endregion
