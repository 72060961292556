import { CssBaseline, ThemeProvider } from '@mui/material'
import { FC, ReactNode } from 'react'
import theme from '@/theme'

interface Props {
  children: ReactNode
}

const AppMuiProvider: FC<Props> = (props) => {
  const { children } = props

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default AppMuiProvider
