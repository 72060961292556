import { Route } from "react-router";

import LeasingRequestDetail from "@/pages/leasing/request-detail";
import LeasingSubmitOrder from "@/pages/leasing/submit-order";
import LeasingInspectionOrder from "@/pages/leasing/submit-inpection";
import LeasingPayment from "@/pages/leasing/payment";
import LeasingSendSms from "@/pages/leasing/payment/sendSms";
import Requests from "@/pages/leasing/requests";

const LeasingRoutes = () => {
  return (
    <Route path="leasing">
      <Route path="requests" element={<Requests />} />

      <Route path="requests/:id">
        <Route index element={<LeasingRequestDetail />} />

        <Route path="payment">
          <Route index element={<LeasingPayment />} />
          <Route path="send-sms" element={<LeasingSendSms />} />
        </Route>
        <Route path="submit-inspection" element={<LeasingInspectionOrder />} />
      </Route>

      <Route path="submit-order" element={<LeasingSubmitOrder />} />
    </Route>
  );
};

export default LeasingRoutes;
