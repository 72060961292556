import {
  calculateFacilityAmount,
  calculateFinalRefund,
  calculateInstallmentAmount,
  calculateMaxPrePayment,
  calculateMinPrePayment,
  calculateMonthlyInstallmentAmount,
  calculateKarnamehIncome,
  calculateAmountOfCheckToSeller,
  CalculateTotalAmountPaidBuyerToSeller
} from "./configs";
import { useCallback, useEffect, useMemo, useState } from "react";

const useLeasingCalculator = (
  paybackPeriodList: {
    value: number;
    dev_coefficient: number;
  }[],
  sellerShare: number,
  karnamehCommition: number,
  postPrice: number,
  karnamehCommitionPercent: number,
  minLeasing: number,
  maxLeasing: number,
  minPrePaymentSharePercent: number,
  rate: number,
  paymentPeriod: number,
  defaultPrepayment: number,
  defaultPaybackPeriod?: number
) => {
  const [prePayment, setPrePayment] = useState(defaultPrepayment);

  const [paybackPeriod, setPaybackPeriod] = useState(() => {
    if (typeof defaultPaybackPeriod === "undefined") {
      return paybackPeriodList[0].value;
    }
    const findedPaybackPeriodIndex = paybackPeriodList.findIndex(
      (paybackPeriodItem) => {
        return paybackPeriodItem.value === defaultPaybackPeriod;
      }
    );
    if (findedPaybackPeriodIndex !== -1) {
      return paybackPeriodList[findedPaybackPeriodIndex].value;
    }
    return paybackPeriodList[0].value;
  });

  const devCoefficient =
    paybackPeriodList.find(
      (paybackPeriodItem) => paybackPeriodItem.value === paybackPeriod
    )?.dev_coefficient || paybackPeriodList[0].dev_coefficient;

  const minPrePayment = useMemo(() => {
    return calculateMinPrePayment(
      minPrePaymentSharePercent,
      sellerShare,
      maxLeasing,
      devCoefficient,
      karnamehCommition,
      karnamehCommitionPercent
    );
  }, [
    devCoefficient,
    karnamehCommition,
    karnamehCommitionPercent,
    maxLeasing,
    minPrePaymentSharePercent,
    sellerShare
  ]);

  const maxPrePayment = useMemo(() => {
    return calculateMaxPrePayment(
      sellerShare,
      minLeasing,
      devCoefficient,
      karnamehCommition,
      karnamehCommitionPercent
    );
  }, [
    devCoefficient,
    karnamehCommition,
    karnamehCommitionPercent,
    minLeasing,
    sellerShare
  ]);

  const facilityAmount = useMemo(() => {
    return calculateFacilityAmount(
      karnamehCommitionPercent,
      sellerShare,
      prePayment,
      karnamehCommition,
      devCoefficient
    );
  }, [
    devCoefficient,
    karnamehCommition,
    karnamehCommitionPercent,
    prePayment,
    sellerShare
  ]);

  const installmentAmount = useMemo(() => {
    return calculateInstallmentAmount(
      rate,
      paymentPeriod,
      paybackPeriod,
      facilityAmount
    );
  }, [facilityAmount, paybackPeriod, paymentPeriod, rate]);

  const monthlyInstallmentAmount = useMemo(() => {
    return calculateMonthlyInstallmentAmount(installmentAmount, paymentPeriod);
  }, [installmentAmount, paymentPeriod]);

  const finalRefund = useMemo(() => {
    return calculateFinalRefund(
      prePayment,
      installmentAmount,
      paybackPeriod,
      paymentPeriod
    );
  }, [installmentAmount, paybackPeriod, paymentPeriod, prePayment]);

  const karnamehIncome = useMemo(() => {
    return calculateKarnamehIncome(
      karnamehCommition,
      karnamehCommitionPercent,
      sellerShare,
      prePayment
    );
  }, [karnamehCommition,karnamehCommitionPercent, sellerShare, prePayment]);

  const amountOfCheckToSeller = useMemo(() => {
    return calculateAmountOfCheckToSeller(
      karnamehCommitionPercent,
      karnamehCommition,
      sellerShare,
      prePayment
    );
  }, [karnamehCommitionPercent, karnamehCommition, sellerShare, prePayment]);

  const totalAmountPaidBuyerToSeller = useMemo(() => {
    return CalculateTotalAmountPaidBuyerToSeller(
      prePayment,
      karnamehCommitionPercent,
      sellerShare,
      karnamehCommition
    );
  }, [prePayment, karnamehCommitionPercent, sellerShare, karnamehCommition]);

  useEffect(() => {
    setPrePayment((prevPrePayment) => {
      if (prevPrePayment < minPrePayment) {
        return minPrePayment;
      } else if (prevPrePayment > maxPrePayment) {
        return maxPrePayment;
      }
      return prevPrePayment;
    });
  }, [minPrePayment, maxPrePayment]);

  const onPrePaymentChangeHandler = useCallback(
    (_event: Event, newPrePayment: number | number[]) => {
      setPrePayment(
        Array.isArray(newPrePayment) ? newPrePayment[0] : newPrePayment
      );
    },
    []
  );

  const factorList = [
    {
      label: "مبلغ پیش‌پرداخت:",
      value: `${Number(prePayment).toLocaleString()} تومان`
    },
    { label: "دوره بازپرداخت:", value: `${paybackPeriod} ماهه` },
    {
      label: "مبلغ اقساط ماهانه:",
      value: `${Number(monthlyInstallmentAmount).toLocaleString()} تومان`
    },
    {
      label: "تعداد چک مورد نیاز:",
      value: `${paybackPeriod / paymentPeriod + 1} برگ`
    },
    {
      label: "مبلغ بازپرداخت نهایی:",
      value: `${Number(finalRefund).toLocaleString()} تومان`
    }
  ];

  return {
    prePayment,
    paybackPeriod,
    setPaybackPeriod,
    minPrePayment,
    maxPrePayment,
    onPrePaymentChangeHandler,
    factorList,
    monthlyInstallmentAmount,
    karnamehIncome,
    amountOfCheckToSeller,
    totalAmountPaidBuyerToSeller
  };
};

export default useLeasingCalculator;
