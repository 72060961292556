import carFront from "@/assets/icons/car-front.svg";
import carBack from "@/assets/icons/car-back.svg";
import carFrontLeft from "@/assets/icons/car-front-left.svg";
import carBackRight from "@/assets/icons/car-back-right.svg";
import carLeft from "@/assets/icons/car-left.svg";
import carRight from "@/assets/icons/car-right.svg";
import carSpeedMeter from "@/assets/icons/car-speed-meter.svg";
import Camera from "@/components/Camera";

import carFrontStencil from "@/assets/images/stencils/front-1920-1080.svg";
import carBackStencil from "@/assets/images/stencils/back-1920-1080.svg";
import carFrontLeftStencil from "@/assets/images/stencils/front-left-1920-1080.svg";
import carBackRightStencil from "@/assets/images/stencils/back-right-1920-1080.svg";
import carRightStencil from "@/assets/images/stencils/right-1920-1080.svg";
import carLeftStencil from "@/assets/images/stencils/left-1920-1080.svg";

export const stencilList: {
  key: string;
  icon: string;
  label: string;
  cameraOptions: Omit<Parameters<typeof Camera>[0], "open" | "setOpen" | 'onSubmit' | 'submitType'>;
}[] = [
  {
    key: "car-front",
    icon: carFront,
    label: "نمای جلو",
    cameraOptions: {
      fileName: "car-front",
      width: 1920,
      height: 1080,
      stencil: carFrontStencil,
    }
  },
  {
    key: "car-back",
    icon: carBack,
    label: "نمای عقب",
    cameraOptions: {
      fileName: "car-back",
      width: 1920,
      height: 1080,
      stencil: carBackStencil,
    }
  },
  {
    key: "car-front-left",
    icon: carFrontLeft,
    label: "جلو سمت چپ",
    cameraOptions: {
      fileName: "car-front-left",
      width: 1920,
      height: 1080,
      stencil: carFrontLeftStencil,
    }
  },
  {
    key: "car-back-right",
    icon: carBackRight,
    label: "عقب سمت راست",
    cameraOptions: {
      fileName: "car-back-right",
      width: 1920,
      height: 1080,
      stencil: carBackRightStencil,
    }
  },
  {
    key: "car-left",
    icon: carLeft,
    label: "سمت چپ",
    cameraOptions: {
      fileName: "car-left",
      width: 1920,
      height: 1080,
      stencil: carLeftStencil,
    },
  },
  {
    key: "car-right",
    icon: carRight,
    label: "سمت راست",
    cameraOptions: {
      fileName: "car-right",
      width: 1920,
      height: 1080,
      stencil: carRightStencil,
    },
  },
  {
    key: "car-speed-meter",
    icon: carSpeedMeter,
    label: "کیلومترشمار",
    cameraOptions: {
      fileName: "car-speed-meter",
      width: 1920,
      height: 1080,
    }
  },
];

export const initialImagesState = stencilList.reduce<
  Record<string, File | null>
>((result, stencilItem) => {
  result[stencilItem.key] = null;
  return result;
}, {});
