import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    px: "1rem",
    py: "1.5rem",
    height: "100vh",
    overflowY: 'auto'

  },
  usageAndorment: {
    color: (th) => th.palette.grey[500],
    ml: "0.5rem"
  },
});

export default styles;
