import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: "100%",
    userSelect: "none"
  }
});

export default styles;
