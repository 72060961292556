import {
  MutationFunction,
  QueryFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from "react-query";
import { httpRest, http2 } from "@/services/http-clients";

export type FindCarsServiceNames =
  | "concierge_sell"
  | "concierge_buy"
  | "inspection";

export type VehicleType = "light_car" | "motor_cycle";
export interface Car {
  last_level: string;
  last_level_id: number;
  name: string;
  name_en: string;
  name_fa: string;
  vehicle_type: VehicleType;
}

export interface CarConfig {
  min_kilometers?: number;
  max_kilometers?: number;
  min_price?: number;
  max_price?: number;
  min_manufacture_year?: number;
  djalali_date?: number;
}

// #region Request: GET /find_cars/v1/fetch_cars, params: service_name

interface FindCarsV1FetchCarsParams {
  serviceName: FindCarsServiceNames;
}

interface FindCarsV1FetchCarsResponse {
  data: Car[];
}

export const findCarsV1FetchCars = (params: FindCarsV1FetchCarsParams) => {
  return httpRest<FindCarsV1FetchCarsResponse>({
    method: "GET",
    url: `/find_cars/v1/fetch_cars?service_name=${params.serviceName}`
  });
};

export const findCarsV1FetchCarsKey = () => {
  return ["findCarsV1FetchCars", findCarsV1FetchCars];
};

export const useFindCarsV1FetchCars = (
  params: FindCarsV1FetchCarsParams,
  options?: Omit<
    UseQueryOptions<
      FindCarsV1FetchCarsResponse,
      unknown,
      FindCarsV1FetchCarsResponse,
      ReturnType<typeof findCarsV1FetchCarsKey>
    >,
    "queryFn" | "queryKey"
  >
) => {
  const queryKey = findCarsV1FetchCarsKey();
  return useQuery({
    ...options,
    queryFn: () => {
      return findCarsV1FetchCars(params).then((res) => res.data);
    },
    queryKey
  });
};

// #endregion

// #region Request: GET /find_cars/v1/search, params: service_name, text

interface FindCarsV1SearchParams {
  serviceName: FindCarsServiceNames;
  text: string;
}

interface FindCarsV1SearchResponse {
  data: Car[];
}

export const findCarsV1Search = (params: FindCarsV1SearchParams) => {
  return httpRest<FindCarsV1SearchResponse>({
    method: "GET",
    url: `/find_cars/v1/search?service_name=${params.serviceName}&text=${params.text}`
  }).then((res) => {
    return res.data;
  });
};

export const useFindCarsV1Search = (
  initialParams: Omit<FindCarsV1SearchParams, "text">,
  options?: UseMutationOptions<
    FindCarsV1SearchResponse,
    unknown,
    FindCarsV1SearchParams,
    unknown
  >
) => {
  const mutationFn: MutationFunction<
    FindCarsV1SearchResponse,
    Omit<FindCarsV1SearchParams, "serviceName">
  > = (params) => {
    return findCarsV1Search({ ...initialParams, ...params });
  };
  return useMutation({
    mutationFn,
    ...options
  });
};

// #endregion

// #region GET /cars/v1/-/get-assistant-buy-configs, params: last_level, last_level_id
interface GetBuyAssisstantCarConfigParams {
  levelId: string;
  lastLevelId: number;
}

export const getBuyAssisstantCarConfig = (
  params: GetBuyAssisstantCarConfigParams
) => {
  return http2<CarConfig>({
    method: "GET",
    url: `/cars/v1/configs/by-level-id/${params.levelId}/${params.lastLevelId}`
  });
};

export const useGetBuyAssisstantCarConfig = (
  params: GetBuyAssisstantCarConfigParams
  // options?: UseMutationOptions<
  //   Awaited<ReturnType<typeof getBuyAssisstantCarConfig>>,
  //   unknown,
  //   GetBuyAssisstantCarConfigParams,
  //   unknown
  // >
) => {
  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBuyAssisstantCarConfig>>
  > = () => {
    return getBuyAssisstantCarConfig(params);
  };

  const queryKey = [
    "getBuyAssisstantCarConfig",
    params.lastLevelId,
    params.levelId
  ];

  return useQuery({
    enabled: !!params.lastLevelId && !!params.levelId,
    queryFn,
    queryKey
  });
};

// #endregion

export interface ExactCarDetails {
  data: {
    last_level: string;
    last_level_id: number;
    name_fa: string;
    name_en: string;
  };
}

interface GetExactSearchParams {
  text: string;
  service_name: "concierge_sell" | "concierge_buy" | "inspection";
}

export const getExactSearch = (params: GetExactSearchParams) => {
  return httpRest<ExactCarDetails | null>({
    url: "/find_cars/v1/search/exact/",
    method: "GET",
    params: params
  })
    .then((data) => {
      if (!data || !data.data || !data.data?.data) {
        return null;
      }

      return {
        last_level: data.data.data.last_level,
        last_level_id: data.data.data.last_level_id,
        name: data.data.data.name_fa,
        name_en: data.data.data.name_en
      } as Car;
    })
    .then((res) => {
      return res;
    });
};

// #region Request: GET /find_cars/v1/search, params: service_name, text

interface FindCarsV1ExactParams {
  serviceName: FindCarsServiceNames;
  text: string;
}

interface FindCarsV1ExactResponse {
  data: Car;
}

export const findCarsV1Exact = (params: FindCarsV1ExactParams) => {
  return httpRest<FindCarsV1ExactResponse>({
    method: "GET",
    url: `/find_cars/v1/search/exact/`,
    params: {
      service_name: params.serviceName,
      text: params.text
    }
  }).then((res) => {
    return res.data;
  });
};

export const useFindCarsV1Exact = (
  initialParams: Omit<FindCarsV1ExactParams, "text">,
  options?: UseMutationOptions<
    FindCarsV1ExactResponse,
    unknown,
    FindCarsV1ExactParams,
    unknown
  >
) => {
  const mutationFn: MutationFunction<
    FindCarsV1ExactResponse,
    Omit<FindCarsV1ExactParams, "serviceName">
  > = (params) => {
    return findCarsV1Exact({ ...initialParams, ...params });
  };
  return useMutation({
    mutationFn,
    ...options
  });
};

// #endregion
