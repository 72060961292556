import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./styles";
import { CloseRounded } from "@mui/icons-material";
import { useParams } from "react-router";
import {
  useGetCarStatusColors,
  useGetSaleDetailV2
} from "@/services/api/sales";

interface PropTypes {
  open: boolean;
  onClose: () => void;
}

const carStatuses = {
  no_color: "بدون رنگ",
  partial_scratches: "خط و خش جزئی",
  smoothing_polishing: "صافکاری و لیسه",
  paint_stain_or_replacement: "لکه رنگ یا تعویض",
  fully_painted: "تمام رنگ(شامل سقف و ستون)"
};

const SeePrevCarStatusBottomSheet: FC<PropTypes> = (props) => {
  const { open, onClose } = props;

  const { id } = useParams<{ id: string }>();

  const { data: saleOrderData } = useGetSaleDetailV2(Number(id));

  const { data: colorList } = useGetCarStatusColors();

  const [show, setShow] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (typeof timeoutRef.current === "number") {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(
      () => {
        setShow(open);
      },
      open ? 50 : 300
    );
  }, [open]);

  if (!show && !open) {
    return null;
  }

  return (
    <>
      <Stack
        component="div"
        onClick={onClose}
        sx={{
          ...styles.backdrop,
          opacity: open && show ? 1 : 0,
          pointerEvents: open && show ? "all" : "none"
        }}
      />
      <Stack
        sx={{
          ...styles.container,
          opacity: show && open ? 1 : 0,
          transform: open && show ? "translateY(0)" : "translateY(50%)"
        }}
      >
        <Stack sx={styles.header}>
          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
          <Typography fontSize="1.25rem" fontWeight={600}>
            وضعیت بدنه سابق
          </Typography>
        </Stack>

        <Stack sx={styles.contents}>
          <Typography
            component="span"
            fontSize="0.875rem"
            mb={"0.5rem"}
            mt="0.5rem"
            color={(th) => th.palette.grey[700]}
          >
            رنگ:{" "}
            <Typography
              component="span"
              fontSize="0.875rem"
              mb={"0.5rem"}
              mt="0.5rem"
            >
              {colorList && typeof saleOrderData?.car_color === "string"
                ? (colorList as { [key: string]: string })[
                    saleOrderData?.car_color as string
                  ]
                : "-"}
            </Typography>
          </Typography>
          <Typography
            component="span"
            fontSize="0.875rem"
            mb={"0.5rem"}
            mt="0.5rem"
            color={(th) => th.palette.grey[700]}
          >
            وضعیت بدنه:{" "}
            <Typography
              component="span"
              fontSize="0.875rem"
              mb={"0.5rem"}
              mt="0.5rem"
            >
              {saleOrderData?.car_status?.selected_part
                ? carStatuses?.[
                    saleOrderData?.car_status
                      ?.selected_part as keyof typeof carStatuses
                  ]
                : "-"}
            </Typography>
          </Typography>
          <Typography
            component="span"
            fontSize="0.875rem"
            mb={"0.5rem"}
            mt="0.5rem"
            color={(th) => th.palette.grey[700]}
          >
            آپشن‌ها:{" "}
            <Typography
              component="span"
              fontSize="0.875rem"
              mb={"0.5rem"}
              mt="0.5rem"
            >
              {saleOrderData?.car_options || "-"}
            </Typography>
          </Typography>
          <Typography
            component="span"
            fontSize="0.875rem"
            mb={"0.5rem"}
            mt="0.5rem"
            color={(th) => th.palette.grey[700]}
          >
            تعداد لکه رنگ:{" "}
            <Typography
              component="span"
              fontSize="0.875rem"
              mb={"0.5rem"}
              mt="0.5rem"
            >
              {saleOrderData?.car_status.extra_info.count || "-"}
            </Typography>
          </Typography>
          {!!saleOrderData?.front_chassis_painted_or_damaged && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!saleOrderData?.front_chassis_painted_or_damaged}
                />
              }
              label="رنگ شدگی یا آسیب شدید شاسی جلو"
            />
          )}
          {!!saleOrderData?.rear_chassis_painted_or_damaged && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!saleOrderData?.rear_chassis_painted_or_damaged}
                />
              }
              label="اسیب شاسی عقب"
            />
          )}
          {!!saleOrderData?.roof_painted_or_replacement && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!saleOrderData?.roof_painted_or_replacement}
                />
              }
              label="رنگ یا تعویض سقف"
            />
          )}

          <Typography
            component="span"
            fontSize="0.875rem"
            mb={"0.5rem"}
            mt="0.5rem"
            color={(th) => th.palette.grey[700]}
          >
            توضیحات:{" "}
            <Typography
              component="span"
              fontSize="0.875rem"
              mb={"0.5rem"}
              mt="0.5rem"
            >
              {saleOrderData?.car_status_description || "-"}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default SeePrevCarStatusBottomSheet;
