import { Box, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { styles } from './styles'
import SubmitItem from '../ShiftsSubmitTomorrow/SubmitItem'
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined'
import { useShifts } from '@/components/layouts/ShiftsLayout'

const TodayShifts: FC = () => {
  const { groupedWeeklyExpertData } = useShifts()

  const todayKey =
    Object.keys(groupedWeeklyExpertData).find(
      (itemKey) => new Date().toLocaleDateString() === new Date(itemKey).toLocaleDateString(),
    ) || null

  if (todayKey === null) {
    return (
      <>
        <Box sx={styles.seprator} />
        <Typography
          fontWeight='bold'
          fontSize='14px'
          alignSelf='flex-start'
          color='rgba(0, 0, 0, 0.87)'
          mb={1}
        >
          شیفت‌های امروز (
          {new Date().toLocaleDateString('fa', { month: 'long', day: 'numeric', year: 'numeric' })})
        </Typography>
        <Stack direction='row' sx={styles.orangeWarning}>
          <ReportGmailerrorredOutlinedIcon color='error' />
          <Typography ml={1} color='rgba(0, 0, 0, 0.48)' fontSize='12px'>
            شیفتی برای امروز تعیین نشده است. شما آفری دریافت نخواهید کرد.
          </Typography>
        </Stack>
      </>
    )
  } else {
    return (
      <>
        <Box sx={styles.seprator} />
        <Typography
          fontWeight='bold'
          fontSize='14px'
          alignSelf='flex-start'
          color='rgba(0, 0, 0, 0.87)'
          mb={2}
        >
          شیفت‌های امروز (
          {new Date().toLocaleDateString('fa', { month: 'long', day: 'numeric', year: 'numeric' })})
        </Typography>
        <Stack sx={styles.todayShifts} spacing={1}>
          {groupedWeeklyExpertData[todayKey as keyof typeof groupedWeeklyExpertData].map((item) => {
            return (
              <SubmitItem
                key={item.shift.id}
                title={`${Number(item.shift.start.substring(0, 2))} تا ${Number(
                  item.shift.end.substring(0, 2),
                )}`}
                checked={item.state === 0 || item.state === 2}
              />
            )
          })}
        </Stack>
      </>
    )
  }
}

export default TodayShifts
