import BarCode from "@/assets/icons/barcode.svg";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals/style";
import { useLeasingImage } from "@/store/slices/leasing";
import {
  DateRange,
  KeyboardArrowLeftOutlined,
  Payment,
  ViewList
} from "@mui/icons-material";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonIcon from "@mui/icons-material/Person";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import { Button, Chip, Snackbar, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";

interface Props {
  created_at: string;
  closed_at?: string;
  state: string;
  name: string;
  phone_number: string;
  amount: number;
  invoice_token: string;
  link: string;
  service_name: string;
  id: number;
  order?: number;
  image?: string;
  paid_at?: string;
}

const LeasingPaymentCard: FC<Props> = ({
  created_at,
  closed_at,
  amount,
  state,
  link,
  name,
  service_name,
  invoice_token,
  phone_number,
  id,
  image,
  paid_at
}) => {
  const [toast, setToast] = useState(false);
  const { setImageDrawerOpen, setImageId, setImageUrl } = useLeasingImage();

  const copyToClipBoard = async (data: string) => {
    await navigator.clipboard.writeText(data).then(() => setToast(true));
  };
  const statusBadge = (state: string) => {
    switch (state) {
      case "S":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#EBF8F1",
              color: "#00A754"
            }}
            label="پرداخت موفق"
          />
        );
      case "I":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#EDEBF4",
              color: "#694FB4"
            }}
            label="در انتظار پرداخت"
          />
        );
      case "P":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#EDEBF4",
              color: "#694FB4"
            }}
            label="در حال بازگشت"
          />
        );
      case "F":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#FFF4F4",
              color: "#CD0000"
            }}
            label="پرداخت ناموفق"
          />
        );
      case "E":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#FFF4F4",
              color: "#CD0000"
            }}
            label="خطای بازگشت وجه"
          />
        );
      case "C":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#FFF4F4",
              color: "#CD0000"
            }}
            label="لغو شده توسط کاربر"
          />
        );
      case "R":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#FFF1E5",
              color: "#FA6B00"
            }}
            label="بازگشت وجه"
          />
        );
      default:
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#eee",
              color: "#000"
            }}
            label="نامشخص"
          />
        );
    }
  };

  const serviceNameConfig = {
    //
    LEASING_DEPOSIT: "هزینه بیعانه",
    LEASING_VALIDATION: "هزینه اعتبارسنجی",
    LEASING_WALLET: "تخلیه کیف پول"
  };

  type serviceNameKeys = "LEASING_DEPOSIT" | "LEASING_VALIDATION";

  return (
    <>
      <Stack
        sx={{
          background: "#fff",
          border: "1px solid #EBEBEB",
          p: 1.5,
          borderRadius: 1,
          mb: 1.25
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #EBEBEB",
            pb: 1
          }}
        >
          <Typography sx={{ fontWeight: 600, fontSize: 15 }}>
            {new Date(closed_at || created_at).toLocaleTimeString("fa", {
              hour: "numeric",
              minute: "2-digit"
            })}{" "}
            - {new Date(closed_at || created_at).toLocaleDateString("fa")}
          </Typography>

          {statusBadge(state)}
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
          <PersonIcon sx={{ color: "#6E6E6E", fontSize: "26px" }} />
          <Typography fontSize="15px" sx={{ ml: 1 }}>
            {name || "-"}
          </Typography>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
          <PhoneEnabledIcon
            sx={{ color: "#6E6E6E", mr: 2, fontSize: "26px" }}
          />
          <Typography fontSize="18px">{phone_number || "-"}</Typography>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
          <ViewList sx={{ color: "#6E6E6E", mr: 2, fontSize: "26px" }} />
          <Typography fontSize="18px">{id || "-"}</Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 1.5
          }}
        >
          <Stack flexDirection="row" alignItems="center">
            <PaymentsIcon sx={{ color: "#6E6E6E", mr: 2, fontSize: "26px" }} />
            <Typography fontSize="18px">
              {amount ? `${Number(amount).toLocaleString()} تومان` : "-"}
            </Typography>
          </Stack>
          {image && (
            <Stack
              flexDirection="row"
              alignItems="center"
              onClick={() => {
                setImageUrl(image);
                setImageId(String(id));
                setImageDrawerOpen(true);
              }}
            >
              <Typography
                fontSize="0.75rem"
                fontWeight="600"
                color={(theme) => theme.palette.secondary.main}
              >
                مشاهده رسید
              </Typography>
              <KeyboardArrowLeftOutlined
                width="1.5rem"
                height="1.5rem"
                sx={{ color: (theme) => theme.palette.secondary.main }}
              />
            </Stack>
          )}
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
          <img style={{ width: "26px" }} src={BarCode} alt="barcode icon" />
          <Typography fontSize="18px" ml={2}>
            {invoice_token || "-"}
          </Typography>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
          <Payment sx={{ color: "#6E6E6E", mr: 2, fontSize: "26px" }} />
          <Typography fontSize="18px">
            {serviceNameConfig[service_name as serviceNameKeys]}
          </Typography>
        </Stack>
        {paid_at && (
          <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
            <DateRange sx={{ color: "#6E6E6E", mr: 2, fontSize: "26px" }} />
            <Typography fontSize="18px" sx={{ direction: "rtl" }}>
              {new Date(paid_at).toLocaleDateString("fa-IR")}
            </Typography>
          </Stack>
        )}
        {!image && (
          <Button
            variant="outlined"
            size="medium"
            sx={{ mt: 1.5 }}
            onClick={() => copyToClipBoard(String(link))}
          >
            کپی لینک پرداخت
          </Button>
        )}
      </Stack>

      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: "#00a754"
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toast}
        onClose={() => setToast(false)}
        message="لینک پرداخت کپی شد"
      />
    </>
  );
};

export default LeasingPaymentCard;
