import { Box, CircularProgress, Divider } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import { Button, SwipeableDrawer } from "@mui/material";
import { Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ProviderList, useProviderList } from "@/services/api/leasing/detail";
import styles from "./style";
import { styles as modalStyle } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useUpdateLeasingOrder } from "@/services/api/leasing/requests";
import { useParams } from "react-router";

type Props = {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  installmentCoefficientData: any;
  providerId: number;
};

const ChangePackageModal: FC<Props> = ({
  open,
  showModal,
  title,
  installmentCoefficientData,
  providerId
}) => {
  const { id } = useParams();
  const { data: providerItems, isSuccess } = useProviderList();

  const [selectedProvider, setSelectedProvider] = useState<ProviderList | null>(
    providerItems?.length ? providerItems[0] : null
  );

  const [selectedMonth, setSelectedMonth] = useState(
    installmentCoefficientData
  );

  const { mutateAsync, isLoading } = useUpdateLeasingOrder();

  const submitHandler = () => {
    mutateAsync({
      id: String(id),
      package: {
        provider_id: selectedProvider?.id,
        instalment_month_count: selectedMonth?.installment_month_count
      }
    }).then(() => {
      showModal(false);
    });
  };

  useEffect(() => {
    if (isSuccess) {
      const currentProvier = providerItems.find(
        (item) => item.id === providerId
      );
      setSelectedProvider(currentProvier ? currentProvier : providerItems[0]);
    }
  }, [isSuccess, providerId, providerItems]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={modalStyle.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Divider />
        <Stack>
          <Stack marginTop={"20px"}>
            <Typography fontSize="16px" fontWeight={600} mb={"20px"}>
              انتخاب تامین کننده
            </Typography>
            <Stack sx={styles.monthListContainer}>
              {providerItems?.map((item) => {
                return (
                  <Stack key={item.id} sx={styles.monthItemContainer}>
                    <Stack
                      component="div"
                      onClick={() => {
                        setSelectedProvider(item);
                        const currentMonth =
                          item.installment_coefficient_data.find(
                            (item) =>
                              item.installment_month_count ===
                              selectedMonth.installment_month_count
                          );
                        setSelectedMonth(
                          currentMonth
                            ? currentMonth
                            : item.installment_coefficient_data[0]
                        );
                      }}
                      sx={{
                        ...styles.monthItem,

                        borderColor: (th) =>
                          selectedProvider?.id === item.id
                            ? th.palette.secondary.main
                            : th.palette.surface.default
                      }}
                    >
                      <Typography
                        fontSize="0.875rem"
                        color={(th) =>
                          selectedProvider?.id === item.id
                            ? th.palette.secondary.main
                            : th.palette.iconDefault.main
                        }
                        sx={{ transition: "color 0.3s" }}
                      >
                        {item?.name_fa}
                      </Typography>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
            <Divider />
          </Stack>
          <Stack marginY={"20px"}>
            <Typography fontSize="16px" fontWeight={600} mb={"20px"}>
              مدت بازپرداخت{" "}
            </Typography>
            <Stack sx={styles.monthListContainer}>
              {selectedProvider?.installment_coefficient_data?.map(
                (item, i) => {
                  return (
                    <Stack
                      key={item.installment_month_count + i}
                      sx={styles.monthItemContainer}
                    >
                      <Stack
                        component="div"
                        onClick={() => {
                          setSelectedMonth(item);
                        }}
                        sx={{
                          ...styles.monthItem,

                          borderColor: (th) =>
                            selectedMonth.installment_month_count ===
                            item.installment_month_count
                              ? th.palette.secondary.main
                              : th.palette.surface.default
                        }}
                      >
                        <Typography
                          fontSize="0.875rem"
                          color={(th) =>
                            selectedMonth.installment_month_count ==
                            item.installment_month_count
                              ? th.palette.secondary.main
                              : th.palette.iconDefault.main
                          }
                          sx={{ transition: "color 0.3s" }}
                        >
                          {item.installment_month_count} ماهه
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                }
              )}
            </Stack>
            <Divider />
          </Stack>
          {(selectedMonth?.instalment_amount ||
            selectedMonth?.leasing_amount) && (
            <Stack marginTop={"20px"}>
              {selectedMonth?.leasing_amount && (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize="16px" fontWeight={600} mb={"20px"}>
                    مبلغ وام
                  </Typography>
                  <Typography fontSize="16px" mb={"20px"}>
                    {selectedMonth?.leasing_amount} تومان
                  </Typography>
                </Stack>
              )}
              {selectedMonth?.instalment_amount && (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize="16px" fontWeight={600} mb={"20px"}>
                    مبلغ اقساط{" "}
                  </Typography>
                  <Typography fontSize="16px" mb={"20px"}>
                    {selectedMonth?.instalment_amount} تومان
                  </Typography>
                </Stack>
              )}
              <Divider />
            </Stack>
          )}
          <Stack marginTop={"20px"} gap={1}>
            {selectedProvider?.instalment_notices?.map((item, i) => {
              return (
                <Stack direction={"row"} key={item + i} gap={1}>
                  <ErrorIcon fontSize={"small"} color="secondary" />
                  <Typography fontSize={"14px"} fontWeight={500}>
                    {item}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
        <Button onClick={submitHandler} sx={{ mt: 2 }}>
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: "#00000050" }} />
          ) : (
            "ویرایش"
          )}
        </Button>
      </Stack>
    </SwipeableDrawer>
  );
};

export default ChangePackageModal;
