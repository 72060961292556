import { Stack } from '@mui/material'
import React, { FC } from 'react'
import { LoadingGradient, styles } from './styles'
import logo from '@/assets/icons/logo.svg'

type RGB = `rgb(${number}, ${number}, ${number})`
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`
type HEX = `#${string}`

type Color = RGB | RGBA | HEX

interface Props {
  backgroundColor?: Color
  size?: string
}

export const CircularProgress: FC<Props> = ({ backgroundColor = 'white', size = '188px' }) => {
  return (
    <Stack
      sx={{
        ...styles.loadingContainer,
        width: size,
        height: size,
      }}
      justifyContent='center'
      alignItems='center'
    >
      <LoadingGradient />
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ ...styles.loadingInnerContainer, backgroundColor }}
      >
        <img src={logo} alt='karnameh' />
      </Stack>
    </Stack>
  )
}
