import { Box, Stack, Typography } from "@mui/material";

import DriveEtaIcon from "@mui/icons-material/DriveEta";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import { styles } from "./styles";
import { FC } from "react";
import { useNavigate } from "react-router";
import { addComma } from "@/utils/number";
import WarningIcon from "@mui/icons-material/Warning";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

interface Props {
  id: number;
  applicant_full_name: string;
  car_brand_model: string;
  karnameh_pricing_lower_bound: number;
  karnameh_pricing_upper_bound: number;
  address: string;
  car_brand_model_year: string;
  car_brand_model_pe: string;
  status?: string;
  showStatus?: boolean;
  health_state?: string;
  lead_health_notif?: string;
  phone_number?: string;
  city?: string;
  is_canceled?: boolean;
  expiration_time?: string | null;
  is_new_financial_model: boolean;
}

const RequestCard: FC<Props> = ({
  car_brand_model,
  car_brand_model_year,
  id,
  applicant_full_name,
  karnameh_pricing_lower_bound,
  karnameh_pricing_upper_bound,
  address,
  status,
  showStatus = false,
  health_state,
  lead_health_notif,
  car_brand_model_pe,
  city,
  is_canceled,
  expiration_time,
  is_new_financial_model
}) => {
  const navigate = useNavigate();

  const renderStatus = () =>
    is_canceled ? (
      <Box sx={styles.cancelBadge}>لغو شده</Box>
    ) : !is_canceled && status === "P" ? (
      <Box sx={styles.negotiationBadge}>مذاکره قیمت</Box>
    ) : !is_canceled && status === "D" ? (
      <Box sx={styles.advertisedBadge}>آگهی شده</Box>
    ) : !is_canceled && status === "SC" ? (
      <Box sx={styles.initialBadge}>تماس اولیه</Box>
    ) : !is_canceled && status === "PE" ? (
      <Box sx={styles.pricingBadge}>قیمت گذاری</Box>
    ) : !is_canceled && status === "F" ? (
      <Box sx={styles.soldBadge}>فروخته شد</Box>
    ) : !is_canceled && status === "RC" ? (
      <Box sx={styles.negotiationBadge}>بررسی مجدد</Box>
    ) : null;
  return (
    <Box
      onClick={() => navigate(`/concierge-sale/requests/${id}`)}
      sx={{
        border:
          health_state === "R"
            ? "1px solid #DF1D17"
            : health_state === "Y"
            ? "1px solid #E0B017"
            : "1px solid #C9CCCF",
        borderRadius: "8px",
        padding: "17px",
        marginBottom: "16px",
        background:
          health_state === "R"
            ? "#FFF4F4"
            : health_state === "Y"
            ? "#FFFFF1"
            : "transparent"
      }}
    >
      <Box sx={styles.titleArea}>
        <DriveEtaIcon sx={styles.driverIcon} />
        <Typography variant="h4" sx={styles.title}>
          {car_brand_model_pe || car_brand_model} - {car_brand_model_year}
        </Typography>
      </Box>
      {showStatus ? (
        <>
          <Box sx={styles.userContainer}>
            <Box component="div" sx={styles.cardUserNameArea}>
              <Box component="span" sx={styles.name}>
                {renderStatus()}
              </Box>
            </Box>

            <Stack flexDirection="row" alignItems="center">
              {expiration_time && (
                <WarningIcon sx={{ fontSize: 19, color: "#CD0000", mr: 1 }} />
              )}
              <Stack alignItems="flex-end">
                <Box component="span" sx={styles.badge}>
                  {id}
                </Box>
                {is_new_financial_model && (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    bgcolor="#FFE8F7"
                    px={0.75}
                    py={0.25}
                    ml={1}
                    mt={1}
                    borderRadius={1.5}
                  >
                    <MonetizationOnOutlinedIcon
                      sx={{
                        color: "#FC00A3",
                        fontSize: 20,
                        mr: 0.25
                      }}
                    />
                    <Typography
                      color="#FC00A3"
                      fontSize="0.875rem"
                      fontWeight={600}
                    >
                      جدید
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Box>
          <Box sx={styles.cardItem}>
            <Box component="div" sx={styles.cardUserNameArea}>
              <PersonOutlineIcon sx={styles.cardIcon} />
              <Box component="span" sx={styles.name}>
                {applicant_full_name}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={styles.userContainer}>
          <Box component="div" sx={styles.cardUserNameArea}>
            <PersonOutlineIcon sx={styles.cardIcon} />
            <Box component="span" sx={styles.name}>
              {applicant_full_name}
            </Box>
          </Box>
          <Stack alignItems="flex-end">
            <Stack flexDirection="row" alignItems="center">
              {expiration_time && (
                <WarningIcon sx={{ fontSize: 19, color: "#CD0000", mr: 1 }} />
              )}
              <Box component="span" sx={styles.badge}>
                {id}{" "}
              </Box>
            </Stack>
            {is_new_financial_model && (
              <Stack
                flexDirection="row"
                alignItems="center"
                bgcolor="#FFE8F7"
                px={0.75}
                py={0.25}
                ml={1}
                mt={1}
                borderRadius={1.5}
              >
                <MonetizationOnOutlinedIcon
                  sx={{
                    color: "#FC00A3",
                    fontSize: 20,
                    mr: 0.25
                  }}
                />
                <Typography
                  color="#FC00A3"
                  fontSize="0.875rem"
                  fontWeight={600}
                >
                  جدید
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      )}

      <Box sx={styles.cardItem}>
        <AttachMoneyIcon sx={styles.cardIcon} />
        <Typography variant="body1" style={{ fontSize: "15px" }}>
          {karnameh_pricing_lower_bound && karnameh_pricing_upper_bound
            ? ` از ${addComma(karnameh_pricing_lower_bound)} تا ${addComma(
                karnameh_pricing_upper_bound
              )}
          تومان`
            : "قیمت وارد نشده است"}
        </Typography>
      </Box>

      <Box sx={styles.cardItem}>
        <FmdGoodOutlinedIcon sx={styles.cardIcon} />
        <Typography variant="body1" sx={styles.address}>
          {city || "-"}
        </Typography>
      </Box>

      <Box sx={styles.cardItem}>
        <PinDropOutlinedIcon sx={styles.cardIcon} />
        <Typography variant="body1" sx={styles.address}>
          {address || "-"}
        </Typography>
      </Box>

      {lead_health_notif && (
        <Box
          sx={{
            borderTop: "1px solid #C9CCCF",
            mt: "16px",
            pt: "12px",
            display: "flex",
            alignItems: "center",
            color:
              health_state === "R"
                ? "#CD0000"
                : health_state === "Y"
                ? "#E0B017"
                : "#00A754"
          }}
        >
          <ReportProblemOutlinedIcon
            sx={{
              mr: 1
            }}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "15px",
              color:
                health_state === "G" || health_state === null
                  ? "#000000"
                  : "inherit"
            }}
          >
            {lead_health_notif}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default RequestCard;
