import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Radio, Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import styles from "./styles";

interface PropTypes extends GetProviderPackagesItem {
  setSelectedPackageState: (item: GetProviderPackagesItem) => void;
  selectedPackageState: GetProviderPackagesItem | null;
}

const PackageItem: FC<PropTypes> = (props) => {
  const {
    title,
    description,
    id,
    installment_month_counts: installmentMonthCountList,
    prepayment,
    price,
    setSelectedPackageState,
    selectedPackageState,
    carPrice,
    name_fa
  } = props;

  const onPackageSelectHandler = useCallback(() => {
    setSelectedPackageState({
      description,
      id,
      installment_month_counts: installmentMonthCountList,
      prepayment,
      price,
      title,
      carPrice,
      name_fa
    });
  }, [
    carPrice,
    description,
    id,
    installmentMonthCountList,
    prepayment,
    price,
    setSelectedPackageState,
    title
  ]);

  const isSelected =
    selectedPackageState?.id === id &&
    selectedPackageState.prepayment === prepayment;

  return (
    <Stack
      component="div"
      onClick={onPackageSelectHandler}
      sx={{
        ...styles.container,
        borderColor: (th) =>
          isSelected ? th.palette.secondary.main : "#ebebeb"
      }}
    >
      <Stack sx={styles.headerContainer}>
        <Radio color="secondary" checked={isSelected} sx={{ p: 0 }} />
        <Typography fontWeight={600} ml="0.5rem">
          {title}
        </Typography>
      </Stack>

      <Stack sx={styles.seprator} />

      <Stack sx={styles.prepaymentContainer}>
        <Typography fontSize="0.75rem">پرداخت نقدی زمان خرید:</Typography>
        <Typography fontSize="0.875rem">
          {Number(prepayment).toLocaleString()} تومان
        </Typography>
      </Stack>

      {installmentMonthCountList.map((installmentMonthCountItem) => {
        return (
          <Stack
            key={installmentMonthCountItem.name}
            sx={styles.installmentMonthCountContainer}
          >
            <Typography fontSize="0.75rem">
              {installmentMonthCountItem.name}
            </Typography>
            <Typography fontSize="0.875rem">
              {installmentMonthCountItem.value}
            </Typography>
          </Stack>
        );
      })}

      {!!description.length && <Stack sx={styles.seprator} />}

      {description.map((descriptionItem, descriptionIndex) => {
        return (
          <Stack key={descriptionIndex} sx={styles.descriptionItem}>
            <InfoRoundedIcon color="secondary" sx={styles.infoIcon} />
            <Typography fontSize="0.6875rem" ml="0.5rem">
              {descriptionItem}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default PackageItem;
