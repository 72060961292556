import { FC } from "react";
import { Box, Stack, SwipeableDrawer, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { GetAdPerformanceResponse } from "@/services/api/leasing/adPerformance";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  data: GetAdPerformanceResponse;
}
const AdPerformanceModal: FC<Props> = ({ open, showModal, title, data }) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #E2E2E2",
              pb: 1,
              mb: 1
            }}
          >
            <Typography sx={{ fontSize: 16 }}>تعداد بازدید</Typography>
            <Typography sx={{ fontSize: 16 }}>
              {data.viewers_count ? data.viewers_count : "-"}
            </Typography>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 1,
              mb: 1
            }}
          >
            {" "}
            <Typography sx={{ fontSize: 16 }}>تعداد تماس</Typography>
            <Typography sx={{ fontSize: 16 }}>
              {data.callers_count ? data.callers_count : "-"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
};

export default AdPerformanceModal;
