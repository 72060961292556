import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./styles";
import { CloseRounded, InfoRounded } from "@mui/icons-material";

interface PropTypes {
  open: boolean;
  title?: string;
  onClose: () => void;
  value: string | null;
  onSubmit: (key: string | null) => void;
}

const ChangePartBottomSheet: FC<PropTypes> = (props) => {
  const { open, title, onClose, onSubmit: propsOnSubmit, value } = props;

  const [show, setShow] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (typeof timeoutRef.current === "number") {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(
      () => {
        setShow(open);
      },
      open ? 50 : 300
    );
  }, [open]);

  if (!show && !open) {
    return null;
  }

  return (
    <>
      <Stack
        component="div"
        onClick={onClose}
        sx={{
          ...styles.backdrop,
          opacity: open && show ? 1 : 0,
          pointerEvents: open && show ? "all" : "none"
        }}
      />
      <Stack
        sx={{
          ...styles.container,
          opacity: show && open ? 1 : 0,
          transform: open && show ? "translateY(0)" : "translateY(50%)"
        }}
      >
        <Stack sx={styles.header}>
          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
          <Typography fontSize="1.25rem" fontWeight={600}>
            {title || ""}
          </Typography>
        </Stack>

        <Stack sx={styles.contents}>
          <Typography>نقص‌ این ناحیه را مشخص نمایید:</Typography>

          <RadioGroup
            value={value}
            onChange={(_e, newValue) => {
              propsOnSubmit(newValue);
              onClose();
            }}
          >
            <FormControlLabel
              value="intact"
              control={<Radio />}
              label={<Typography fontSize="0.875rem">سالم</Typography>}
            />
            <FormControlLabel
              value="changed"
              control={<Radio />}
              label={<Typography fontSize="0.875rem">تعویض</Typography>}
            />
            <FormControlLabel
              value="painted"
              control={<Radio />}
              label={<Typography fontSize="0.875rem">رنگ شدگی</Typography>}
            />
            <FormControlLabel
              value="minor-damage"
              control={<Radio />}
              label={
                <Typography fontSize="0.875rem">
                  آسیب ظاهری یا صافکاری بدون رنگ
                </Typography>
              }
            />
          </RadioGroup>

          <Stack sx={styles.messageBox}>
            <InfoRounded color="info" />
            <Typography
              component="span"
              fontSize="0.75rem"
              color={(th) => th.palette.info.main}
            >
              هرگونه آسیب ظاهری که تعویض یا رنگ نشده است. شامل:{" "}
              <Typography
                component="span"
                fontSize="0.75rem"
                color={(th) => th.palette.info.main}
                fontWeight={600}
              >
                ضربه، ساییدگی، فرورفتگی، شکستگی یا صافکاری بدون رنگ
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ChangePartBottomSheet;
