import { useRef, FC } from "react";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import { Stack, Typography } from "@mui/material";

interface FileLoaderProps {
  file: File[];
  setFile: (files: File[]) => void;
  loader?: JSX.Element;
  multi?: boolean;
  title: string;
  image: string;
}

const FileLoader: FC<FileLoaderProps> = ({
  file,
  setFile,
  loader,
  multi = true,
  image,
  title
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files;

    if (!fileUploaded) return;

    const options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1024
    };

    if (multi) {
      const compressedFiles: File[] = [];

      for (const file of Array.from(fileUploaded)) {
        try {
          let finalFile: any;
          if (file.type === "image/heic") {
            finalFile = await heic2any({
              blob: file,
              toType: "image/jpeg"
            });
          } else {
            finalFile = file;
          }
          let compressedFile: File;

          if (finalFile.size > 500000) {
            compressedFile = await imageCompression(finalFile, options);
          } else {
            compressedFile = finalFile;
          }
          compressedFiles.push(compressedFile);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }

      const compressedSizedFile = compressedFiles.filter(
        (item) => item.size < 1200000
      );
      setFile([...file, ...compressedSizedFile]);
    } else {
      try {
        const compressedFile = await imageCompression(fileUploaded[0], options);
        setFile([compressedFile]);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  return (
    <Stack
      onClick={handleClick}
      sx={{
        border: "1px dashed  #C9CCCF",
        width: "31%",
        height: "100px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        mb: 1.5
      }}
    >
      {loader ? (
        loader
      ) : (
        <>
          <img src={image} alt="image icon" />
          <Typography
            variant="body1"
            sx={{ color: "#6E6E6E", fontSize: "14px" }}
          >
            {title}
          </Typography>
        </>
      )}

      <input
        type="file"
        accept="image/*"
        ref={hiddenFileInput}
        multiple={multi ?? false}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </Stack>
  );
};

export default FileLoader;
