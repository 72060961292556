import { FC, useEffect } from "react";
import { useRootLayout } from "@/hooks/useRootLayout";
import LeasingPaymentCard from "@/pages/leasing/payment/components/card";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import {
  GetPaymentListsResponse,
  useGetPaymentLists
} from "@/services/api/concierge-sale/submit-deals";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { useGetPosePayments } from "@/services/api/leasing/requests";
import { ImageDrawer } from "./components/ImageDrawer";
import { useLeasingImage } from "@/store/slices/leasing";
import { useGetLeasingDetail } from "@/services/api/leasing/detail";

const LeasingPayment: FC = () => {
  const { id } = useParams();
  const { isImageDrawerOpen, setImageDrawerOpen } = useLeasingImage();
  const { data: leasingData } = useGetLeasingDetail(id as string, {
    enabled: !!id
  });

  const { setHeaderTitle, setBackUrl } = useRootLayout();
  const { data, isLoading } = useGetPaymentLists(
    {
      leasing_id: id as string,
      service_name: ["LEASING_DEPOSIT", "LEASING_VALIDATION", "LEASING_WALLET"]
    },
    {
      enabled: !!id
    }
  );

  const { data: parentData, isLoading: parentIsLoading } = useGetPaymentLists(
    {
      leasing_id: leasingData?.parent_id?.toString(),
      service_name: ["LEASING_DEPOSIT", "LEASING_VALIDATION", "LEASING_WALLET"]
    },
    {
      enabled: !!leasingData?.parent_id
    }
  );
  const { data: poseData, isLoading: isPoseLoading } = useGetPosePayments({
    id: id ?? ""
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("پرداخت ها");
      setBackUrl(`/leasing/requests/${id}/`);
    }
  }, [setHeaderTitle, setBackUrl, id]);

  if (isLoading || parentIsLoading || isPoseLoading) {
    return (
      <Stack
        width="100%"
        height="400px"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size="50px" />
      </Stack>
    );
  }

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          px: "24px",
          pt: "20px",
          height: "100vh",
          overflowY: "scroll",
          background: "#FAFAFA",
          pb: 10
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            mb: 2
          }}
          flexDirection="row"
          display="flex"
          gap={1}
        >
          شماره سفارش{" "}
          <Box
            sx={{
              fontWeight: 500
            }}
          >
            {id}
          </Box>
        </Typography>
        {(data as GetPaymentListsResponse[])?.length ||
        (parentData as GetPaymentListsResponse[])?.length ||
        poseData?.length ? (
          [
            ...((poseData || [])?.map((item) => ({
              ...item,
              state: "S", //"S" for success
              link: "",
              name: "",
              service_name: "LEASING_DEPOSIT",
              invoice_token: "-",
              phone_number: "-"
            })) ?? []),
            ...((data || []) as GetPaymentListsResponse[]),
            ...((parentData || []) as GetPaymentListsResponse[])
          ]
            ?.sort((a, b) => {
              const da = new Date(a.created_at),
                db = new Date(b.created_at);
              if (da.getTime() > db.getTime()) return -1;
              return 1;
            })
            ?.sort((a, b) => {
              if (a.state === "S" && b.state !== "S") {
                return -1;
              } else if (a.state !== "S" && b.state === "S") {
                return 1;
              } else {
                return 0;
              }
            })
            ?.map((item) => <LeasingPaymentCard key={item.id} {...item} />)
        ) : (
          <Stack sx={{ alignItems: "center", mt: 8 }}>
            <LocalAtmIcon sx={{ fontSize: "54px", color: "#A9A9AA" }} />
            <Typography
              sx={{
                color: "#A9A9AA",
                fontSize: "16px",
                mt: 1,
                fontWeight: 500,
                textAlign: "center"
              }}
            >
              در حال حاضر هیچ پیامک پرداختی ثبت نشده است.
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          p: 2,
          borderTop: "1px solid #ebebeb",
          background: "#fff",
          flexDirection: "row"
        }}
      >
        <Button
          variant="outlined"
          sx={{
            fontSize: "0.875rem",
            fontWeight: "600",
            flexGrow: 1,
            padding: "8px 0"
          }}
          onClick={() =>
            navigate(
              `/leasing/requests/${id}/payment/send-sms?upload-receipt=true`,
              {
                replace: true
              }
            )
          }
        >
          استفاده از کارت‌خوان
        </Button>
        <Button
          sx={{
            fontSize: "0.875rem",
            fontWeight: "600",
            flexGrow: 1,
            marginLeft: "1rem",
            padding: "8px 0"
          }}
          onClick={() =>
            navigate(`/leasing/requests/${id}/payment/send-sms`, {
              replace: true
            })
          }
        >
          ساخت لینک پرداخت جدید
        </Button>
      </Stack>

      <ImageDrawer
        open={isImageDrawerOpen}
        onClose={() => {
          setImageDrawerOpen(false);
        }}
      />
    </>
  );
};

export default LeasingPayment;
