import { useEffect, useRef, useState } from "react";
import {
  Car,
  FindCarsServiceNames,
  useFindCarsV1FetchCars,
  useFindCarsV1Search
} from "@/services/api/searchCar";

const useLogic = (
  initialValue: Car | null,
  serviceName: FindCarsServiceNames,
  value: Car | null
) => {
  const [car, setCar] = useState<Car | null>(value || initialValue);
  const [inputValue, setInputValue] = useState(
    value?.name || initialValue?.name || ""
  );

  useEffect(() => {
    setCar(value || null);
    setInputValue(value?.name || "");
  }, [value]);

  // For opening and closing the drop down.
  const [inputFocus, setInputFocus] = useState(false);
  const autoCompleteRef = useRef<HTMLElement | null>();

  // For implementing debounce on typing.
  const inputValueTimeOut = useRef<NodeJS.Timeout | null>(null);

  /**
   * This is initial list of the auto complete component.
   * The list only contains the list of item with last_level: "brand"
   *  and there aren't any item with last_level: "model" | "type".
   */
  const { data: fetchCarData, isLoading: isFetchCarLoading } =
    useFindCarsV1FetchCars({
      serviceName: serviceName
    });

  /**
   * This query will be called whenever the user enter 2 or more characters in
   *  auto complete input field.
   * The response of this query are items that last_level: "model" | "type".
   */
  const {
    data: findCars,
    mutate: findCarMutate,
    isLoading: isFindCarLoading
  } = useFindCarsV1Search({
    serviceName: serviceName
  });

  /**
   * This will handling request whenever the value of input will be changed
   */
  useEffect(() => {
    if (inputValueTimeOut.current !== null) {
      clearTimeout(inputValueTimeOut.current);
      inputValueTimeOut.current = null;
    }
    inputValueTimeOut.current = setTimeout(() => {
      if (inputValue.length >= 2) {
        findCarMutate({ serviceName: serviceName, text: inputValue });
      }
      inputValueTimeOut.current = null;
    }, 100);
  }, [findCarMutate, inputValue, serviceName]);

  /**
   * This will be return the list of auto complete component.
   * If the length of searching value will be 1 or 2 characters,
   *  it will return only the list of items with last_level: "brand".
   * Else: will return list of list of items with last_level: "model" | "type".
   */
  const autoCompleteOptions: Car[] = (() => {
    if (isFetchCarLoading || isFindCarLoading) {
      return [];
    } else if (inputValue.length < 2) {
      return fetchCarData?.data || [];
    }

    return findCars?.data || fetchCarData?.data || [];
  })();

  const hasSearchResult =
    autoCompleteOptions.length &&
    autoCompleteOptions.length !== (fetchCarData?.data || []).length;

  return {
    car,
    setCar,
    inputValue,
    setInputValue,
    inputFocus,
    setInputFocus,
    autoCompleteRef,
    fetchCarData,
    isFetchCarLoading,
    isFindCarLoading,
    autoCompleteOptions,
    hasSearchResult
  };
};

export default useLogic;
