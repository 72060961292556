import {
  ExpertWeeklyShifts,
  Shift,
  useExpertWeeklyShifts
} from "@/services/api/shifts";
import { Box, Stack } from "@mui/material";
import { createContext, FC, useContext, useEffect, useMemo } from "react";
import { Outlet } from "react-router";
import { styles } from "./styles";
import { CircularProgress } from "@/components/CircularProgress";
import { useRootLayout } from "@/hooks/useRootLayout";

interface ShiftsContext {
  weeklyExpertData: ExpertWeeklyShifts;
  groupedWeeklyExpertData: Record<`${number}/${number}/${number}`, Shift[]>;
}

const ShiftsContext = createContext<ShiftsContext>({
  weeklyExpertData: [],
  groupedWeeklyExpertData: {}
});

export const useShifts = () => useContext(ShiftsContext);

const ShiftsLayout: FC = () => {
  const {
    setHeaderTitle,
    setIsLoading: setHeaderLoading,
    isLoading: headerLoading
  } = useRootLayout();
  const {
    data: weeklyExpertData,
    isLoading,
    isRefetching
  } = useExpertWeeklyShifts({ refetchInterval: 30000 });

  const groupedWeeklyExpertData = useMemo(() => {
    return weeklyExpertData?.reduce<Record<Shift["shift"]["date"], Shift[]>>(
      (result, current) => {
        if (current.shift.date in result) {
          result[current.shift.date].push(current);
        } else {
          result[current.shift.date] = [current];
        }
        return result;
      },
      {}
    );
  }, [weeklyExpertData]);

  const providerValue = useMemo(
    () => ({
      weeklyExpertData,
      groupedWeeklyExpertData
    }),
    [weeklyExpertData]
  );

  useEffect(() => {
    if (isLoading) {
      setHeaderTitle("در حال پردازش...");
    } else if (isRefetching) {
      setHeaderLoading(true);
    } else if (headerLoading) {
      setHeaderLoading(false);
    }
  }, [isLoading, isRefetching]);

  return (
    <Box
      sx={{
        width: "100%",
        overflowY: "auto",
        height: "calc(100vh - 3rem)"
      }}
    >
      <Stack sx={styles.container}>
        {typeof providerValue.weeklyExpertData !== "undefined" && (
          <ShiftsContext.Provider value={providerValue as ShiftsContext}>
            <Outlet />
          </ShiftsContext.Provider>
        )}
        <Stack
          sx={{
            ...styles.loading,
            opacity: isLoading ? 1 : 0,
            pointerEvents: isLoading ? "all" : "none"
          }}
        >
          <CircularProgress />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ShiftsLayout;
