import { StateActionParamInput } from "@/services/api/leasing/requests";
import * as Yup from "yup";

export const buildDynamicYupSchema = (inputs: StateActionParamInput[] = []) => {
  const shape: Record<string, Yup.AnySchema> = {}; // Changed type to Yup.AnySchema to handle multiple types

  inputs.forEach((input) => {
    let validator: Yup.AnySchema;

    switch (input.type) {
      case "number":
        validator = Yup.number().typeError("باید یک عدد معتبر باشد");
        if (input.required) {
          validator = validator.required(`${input.title ?? ""} الزامی میباشد!`);
        }
        break;

      case "file":
      case "image":
        validator = Yup.object({
          key: Yup.string().required("فایل الزامی میباشد!"),
          url: Yup.string()
            .url("لینک فایل معتبر نمیباشد")
            .required("لینک فایل الزامی میباشد!")
        });
        if (input.required) {
          validator = validator.required(
            `${input.title ?? "تصویر"} الزامی میباشد!`
          );
        }
        break;

      default:
        validator = Yup.string();
        validator.default(input.default_value ?? "");
        if (input.required) {
          validator = validator.required(`${input.title ?? ""} الزامی میباشد!`);
        }
        break;
    }

    shape[input.name] = validator;
  });

  return Yup.object().shape(shape);
};
