import { useRootLayout } from "@/hooks/useRootLayout";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { FC, useEffect, useMemo } from "react";
import { styles } from "./styles";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { useShifts } from "@/components/layouts/ShiftsLayout";
import { Shift, useExpertVerifyTomorrowShifts } from "@/services/api/shifts";
import SubmitItem from "./SubmitItem";
import { useNavigate } from "react-router";

const ShiftsSubmitTomorrow: FC = () => {
  const { groupedWeeklyExpertData } = useShifts();
  const { setHeaderTitle } = useRootLayout();
  const navigate = useNavigate();
  const { mutateAsync, isLoading: isMutateLoading } =
    useExpertVerifyTomorrowShifts();

  useEffect(() => {
    if (typeof setHeaderTitle === "function") {
      setHeaderTitle("شیفت‌های فردا");
    }
  }, []);

  const nextDaysList = useMemo(() => {
    const todayDate = new Date();
    todayDate.setHours(0);
    todayDate.setMinutes(0);
    todayDate.setSeconds(0);
    return Object.keys(groupedWeeklyExpertData).filter((itemKey) => {
      if (new Date(itemKey).getTime() > todayDate.getTime()) {
        return true;
      } else {
        return false;
      }
    });
  }, [groupedWeeklyExpertData]);

  const nextDayDateJalali = useMemo(() => {
    return nextDaysList.length > 0
      ? (nextDaysList[0] as Shift["shift"]["date"])
      : null;
  }, [nextDaysList]);

  const nextDay = useMemo(() => {
    if (nextDayDateJalali === null) {
      return [];
    } else {
      return groupedWeeklyExpertData[nextDayDateJalali].filter(
        (item) => item.state === 0
      );
    }
  }, [groupedWeeklyExpertData, nextDayDateJalali]);

  const nextDayDate = useMemo(() => {
    if (nextDayDateJalali === null) {
      return null;
    } else {
      return nextDayDateJalali.split("/").map(Number);
    }
  }, [nextDayDateJalali]);

  const isTommorow = useMemo(() => {
    if (nextDayDate === null) {
      return false;
    } else {
      const tommorow = new Date();
      tommorow.setDate(tommorow.getDate() + 1);
      if (
        nextDayDate[2] === tommorow.getFullYear() &&
        nextDayDate[0] === tommorow.getMonth() + 1 &&
        nextDayDate[1] === tommorow.getDate()
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [nextDayDate]);

  const isLeave = useMemo(() => {
    return nextDay.every((item) => item.state === 1);
  }, [nextDay]);

  const submit = () => {
    if (isTommorow) {
      mutateAsync().then(() => {
        navigate(-1);
      });
    } else {
      navigate(-1);
    }
  };

  const topSection = useMemo(() => {
    return (
      <>
        {nextDayDate !== null && (
          <Typography fontSize="14px">
            شیفت‌های انتخاب شده برای{isTommorow ? ` فردا ` : " "}(
            {new Date(
              nextDayDate[2],
              nextDayDate[0] - 1,
              nextDayDate[1]
            ).toLocaleDateString("fa-IR", {
              month: "long",
              day: "numeric",
              weekday: "long"
            })}
            )
          </Typography>
        )}
        {nextDayDate === null && (
          <Stack
            direction="row"
            sx={{ ...styles.warning, marginBottom: "0", marginTop: "0" }}
          >
            <ReportGmailerrorredOutlinedIcon sx={{ color: "#D7570F" }} />
            <Typography ml={1} color="#D7570F" fontSize="12px">
              در حال حاظر داده ای از روز کاری بعدی وجود ندارد.
            </Typography>
          </Stack>
        )}

        <Stack direction="row" sx={styles.warning}>
          <ReportGmailerrorredOutlinedIcon sx={{ color: "#D7570F" }} />
          <Typography ml={1} color="#D7570F" fontSize="12px">
            درصورت نیاز به تغییر با پشتیبانی تماس بگیرید.
          </Typography>
        </Stack>
      </>
    );
  }, [nextDayDate]);

  return (
    <Stack sx={styles.container}>
      {topSection}

      {nextDayDate !== null && (
        <Stack sx={styles.itemsContainer} spacing={1}>
          {isLeave && <SubmitItem title="مرخصی هستم" />}
          {!isLeave &&
            nextDay.map((item) => {
              const title = `${item.shift.start.split(":")[0]} تا ${
                item.shift.end.split(":")[0]
              }`;
              return <SubmitItem key={item.shift.id} title={title} />;
            })}
        </Stack>
      )}

      <Stack
        sx={{
          flexGrow: "1",
          width: "100%"
        }}
      />
      <Stack direction="row" sx={styles.buttonsContainer}>
        <Stack direction="row" spacing={1} sx={styles.buttonsInnerContainer}>
          <Button
            onClick={() => window.open("tel:02179237")}
            variant="outlined"
            sx={styles.button}
          >
            تماس با پشتیبانی
          </Button>
          <Button
            disabled={nextDayDate === null || isMutateLoading}
            onClick={submit}
            sx={styles.button}
          >
            {isMutateLoading && (
              <CircularProgress
                size="15px"
                sx={{ color: "rgba(0, 0, 0, 0.12)" }}
              />
            )}
            {!isMutateLoading && (isTommorow ? "تائید و بازگشت" : "بازگشت")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ShiftsSubmitTomorrow;
