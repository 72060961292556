import { Stack, Typography } from "@mui/material";
import { FC } from "react";

const IsError: FC = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0
      }}
    >
      <Typography fontWeight={500} color="rgba(0, 0, 0, 0.60)">
        ظاهراً مشکلی پیش آمده است!
      </Typography>
    </Stack>
  );
};

export default IsError;
