/** Converts numbers in a string to English numebrs */
export const toEngDigits = function (input: string) {
  input = input.toString();
  if (!input) return "";
  return input.replace(/[۰-۹]/g, function (chr) {
    const persian = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    return persian.indexOf(chr).toString();
  });
};

export const faNumToEn = (value: string) => {
  return value.replace(/[۰-۹]/g, (token) => {
    return String.fromCharCode(
      token.charCodeAt(0) - ("۰".charCodeAt(0) - "0".charCodeAt(0))
    );
  });
};
