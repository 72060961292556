import carIcon from "@/assets/images/offer-not-found.webp";
import { CircularProgress } from "@/components/CircularProgress";
import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import { OfferItem, useGetOffers } from "@/services/api/offer";
import { setBlockingState } from "@/store/slices/offer";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Alert,
  AlertColor,
  Button,
  Modal,
  Stack,
  Typography
} from "@mui/material";
import queryString from "query-string";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  CancelFirstBlocking,
  CancelFirstWarn,
  CancelGuideInfo,
  CancelSecondBlocking,
  CancelSecondWarn
} from "../CancelOfferInfo";
import Filters from "../filters";
import { filtersList } from "../filters/data";
import Item from "../item";
import { styles } from "../styles";
import { getQueryAsArray, getQueryItem } from "../offerUtils";
import { LoadingOfferItem } from "../LoadingOfferItem";
import { getAuthInfo } from "@/utils/auth";

const errorMessages = [
  {
    en: "inspection was not removed from offer list",
    fa: "کارشناس دیگری درخواست را قبول کرد."
  },
  {
    en: "expert is not valid",
    fa: "کارشناس معتبر نیست"
  },
  {
    en: "offer could not be approved",
    fa: "درخواست مورد نظر قابل پذیرش نمی‌باشد"
  },
  {
    en: "inspection not found",
    fa: "کارشناس دیگری زودتر از شما درخواست را قبول کرد."
  }
];

interface BlockingState {
  severity: AlertColor;
  title: ReactNode;
}

export const OfferTab = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = queryString.parse(search);
  const [filterOpen, setFilterOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState<{
    show: boolean;
    message: string;
  }>({
    show: false,
    message: ""
  });
  const [blockingTime, setBlockingTime] = useState("");

  const blockingState = useAppSelector((state) => state.offer.blockingState);

  const { data, isLoading, refetch } = useGetOffers();

  const { severity, title } = useMemo(
    () =>
      ({
        safe: {
          severity: "info",
          title: <CancelGuideInfo />
        },
        firstWarn: {
          severity: "warning",
          title: <CancelFirstWarn />
        },
        firstBlocking: {
          severity: "error",
          title: <CancelFirstBlocking blockingTime={blockingTime} />
        },
        secondWarn: {
          severity: "warning",
          title: <CancelSecondWarn />
        },
        secondBlocking: {
          severity: "error",
          title: <CancelSecondBlocking blockingTime={blockingTime} />
        }
      }[blockingState] as BlockingState),
    [blockingState, blockingTime]
  );

  const filteredData = useMemo(() => {
    if (typeof data === "undefined") {
      return [];
    }

    const itemsWitoutVisitTime: OfferItem[] = [];

    let newData = [...(data?.orders ?? [])];

    // sort filters
    const sortFilter = getQueryItem(query, "sort");
    if (sortFilter === "high-price") {
      newData.sort((a, b) => (a.price > b.price ? -1 : 1));
    } else if (sortFilter === "newest") {
      newData.sort((a, b) => {
        if (a.visit_time === null && b.visit_time !== null) {
          return -1;
        } else if (b.visit_time === null && a.visit_time !== null) {
          return 1;
        } else if (b.visit_time === null && a.visit_time === null) {
          return 0;
        }
        return new Date(a.visit_time as string).getTime() <
          new Date(b.visit_time as string).getTime()
          ? -1
          : 1;
      });
    } else {
      newData.sort((a, b) =>
        new Date(a.offer_sent_at as string).getTime() <
        new Date(b.offer_sent_at as string).getTime()
          ? -1
          : 1
      );
    }

    // zone filters
    const zoneFilter = getQueryAsArray(query, "zone");
    if (zoneFilter?.length) {
      newData = newData.filter((dataItem) => {
        return zoneFilter.includes(dataItem.district?.toString() || "-1");
      });
    }

    // package filters
    const packageFilter = getQueryAsArray(query, "package");
    if (packageFilter?.length) {
      newData = newData.filter((dataItem) =>
        packageFilter.includes(dataItem.selected_package.id.toString())
      );
    }

    // order
    const orderFilter = getQueryAsArray(query, "order");
    if (
      orderFilter?.includes("motor_cycle") &&
      orderFilter?.includes("light_car")
    ) {
      return newData;
    } else if (orderFilter?.includes("motor_cycle")) {
      newData = newData?.filter((item) => {
        return item.vehicle_type === "motor_cycle";
      });
    } else if (orderFilter?.includes("light_car")) {
      newData = newData?.filter((item) => {
        return item.vehicle_type === "light_car";
      });
    } else if (orderFilter?.includes("special")) {
      newData = newData?.filter((item) => {
        return item.selected_package.slug.toLowerCase().includes("special");
      });
    }

    return newData.concat(itemsWitoutVisitTime);
  }, [query, data]);

  const activeFilters = Object.keys(query).map((queryKey) => {
    const findedFilterItem = filtersList.find(
      (filterItem) => filterItem.key === queryKey
    );
    return getQueryAsArray(query, queryKey).map((queryItemValue) => {
      return (
        <Stack
          direction="row"
          component="button"
          onClick={() => {
            const newQuery = { ...query };
            newQuery[queryKey] = getQueryAsArray(query, queryKey);
            (newQuery[queryKey] as string[]).splice(
              (newQuery[queryKey] as string[]).indexOf(queryItemValue),
              1
            );
            navigate(
              { search: queryString.stringify(newQuery) },
              { replace: true }
            );
          }}
          sx={styles.activeFilterButton}
          key={`${queryKey}-${queryItemValue}`}
        >
          <Typography lineHeight={0} color="#49454F" fontSize="14px">
            {
              findedFilterItem?.data.find(
                (dataItem: { value: string }) =>
                  dataItem.value === queryItemValue
              )?.label
            }
          </Typography>
          <ClearRoundedIcon
            sx={{
              color: "#49454F",
              width: "18px",
              height: "18px",
              marginLeft: 0.5
            }}
          />
        </Stack>
      );
    });
  });

  const approvedItem = (failed = false, message: string) => {
    refetch();

    if (failed) {
      const showMessage =
        errorMessages.find((errorItem) => errorItem.en === message)?.fa ||
        message ||
        "متاسفانه مشکلی پیش آمده است.";
      setErrorModal({ show: true, message: showMessage });
    }
  };

  useEffect(() => {
    if (!data) return;
    if (!Object.keys(data?.blacklist ?? {}).includes("cancellation_count"))
      return;

    const currentTime = data?.current_time?.current_time;
    const cancellationCount = data?.blacklist?.cancellation_count;
    const time = data?.blacklist?.time;

    if (!time || !cancellationCount || !currentTime) return;

    if (cancellationCount === 1) dispatch(setBlockingState("firstWarn"));
    else if (cancellationCount === 2) {
      const blockingTime = new Date(time);
      blockingTime.setHours(blockingTime.getHours() + 1);

      if (blockingTime.getTime() > new Date(currentTime).getTime())
        dispatch(setBlockingState("secondWarn"));
      else {
        setBlockingTime(
          blockingTime.toLocaleString("fa", {
            hour: "2-digit",
            minute: "2-digit"
          })
        );
        dispatch(setBlockingState("firstBlocking"));
      }
    } else if (cancellationCount === 3) {
      const blockingTime = new Date(time);
      blockingTime.setHours(blockingTime.getHours() + 24);

      const dateBlock = blockingTime.toLocaleString("fa", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });

      const timeBlock = blockingTime.toLocaleString("fa", {
        hour: "2-digit",
        minute: "2-digit"
      });

      dispatch(setBlockingState("secondBlocking"));

      setBlockingTime(`تاریخ: ${dateBlock} - ساعت: ${timeBlock}`);
    }
  }, [dispatch, data]);

  useEffect(() => {
    refetch();
  }, [search]);

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={styles.container}
    >
      {!isLoading && (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          paddingBottom="2rem"
          sx={styles.innerContainer}
        >
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight="bold" fontSize="16px" color="rgb(0,0,0)">
              لیست آفرها
            </Typography>
            {/* <Typography>{`${getAuthInfo()?.first_name} ${getAuthInfo()?.last_name}`}</Typography> */}
            {/* TODO: uncomment this as soon as possible and implement new filters */}
            {/* <Button
              onClick={() => {
                // navigate({ hash: "filter" });
                setFilterOpen(true);
              }}
              variant="text"
              color="inherit"
              startIcon={<TuneIcon sx={{ marginLeft: "8px" }} />}
            >
              فیلتر
            </Button> */}
          </Stack>
          <Stack
            width="100%"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            overflow="auto"
            minHeight={activeFilters.length ? "40px" : "auto"}
            sx={{
              "&::-webkit-scrollbar": {
                display: "none"
              }
            }}
          >
            {activeFilters}
          </Stack>

          {/* <Alert
            variant="standard"
            severity={severity}
            sx={{
              borderRadius: 2,
              border: "1px solid",
              borderColor: (theme) => theme.palette[severity].main,
              padding: 1,
              mt: 1
            }}
          >
            {title}
          </Alert> */}

          {!filteredData.length && (
            <Stack
              sx={styles.notFoundContainer}
              width="100%"
              flexGrow={1}
              mt={20}
              justifyContent="center"
              alignItems="center"
            >
              <img src={carIcon} alt="offer not found" />
              <Typography
                color="rgba(0, 0, 0, 0.6)"
                fontSize="14px"
                textAlign="center"
              >
                در حال حاضر درخواست فعالی وجود ندارد!
              </Typography>
            </Stack>
          )}
          {filteredData.map((itemDetails) => {
            if (itemDetails.status === "offer")
              return (
                <Item
                  approved={approvedItem}
                  key={itemDetails.id}
                  {...itemDetails}
                />
              );
            return <LoadingOfferItem key={itemDetails.id} {...itemDetails} />;
          })}
        </Stack>
      )}
      {isLoading && (
        <Stack
          width="100%"
          height="400px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size="200px" />
        </Stack>
      )}
      <Filters open={filterOpen} setOpen={setFilterOpen} />
      <Modal
        open={successModal}
        onClose={() => setSuccessModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack direction="column" sx={styles.notifModal}>
          <CheckCircleOutlineIcon sx={styles.tickIcon} />
          <Typography
            fontSize={"16px"}
            textAlign="center"
            id="modal-modal-description"
            sx={{ my: 2 }}
          >
            درخواست با موفقیت به نام شما ثبت شد.
          </Typography>
          <Button
            onClick={() => setSuccessModal(false)}
            sx={{
              width: "100%"
            }}
          >
            مشاهده درخواست‌ها
          </Button>
        </Stack>
      </Modal>
      <Modal
        open={errorModal.show}
        onClose={() =>
          setErrorModal((prevState) => ({
            show: false,
            message: prevState.message
          }))
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack direction="column" sx={styles.notifModal}>
          <HighlightOffIcon sx={styles.cancelIcon} />
          <Typography
            fontSize={"16px"}
            textAlign="center"
            id="modal-modal-description"
            sx={{ my: 2 }}
          >
            {errorModal.message}
          </Typography>
          <Button
            onClick={() =>
              setErrorModal((prevState) => ({
                show: false,
                message: prevState.message
              }))
            }
            sx={{
              width: "100%"
            }}
          >
            بستن
          </Button>
        </Stack>
      </Modal>
    </Stack>
  );
};
