import React, { FC } from "react";
import {
  Stack,
  CircularProgress,
  Typography,
  Box,
  Button
} from "@mui/material";
import RequestCard from "@/pages/ConciergeSale/requests/requestCard";
import { styles } from "@/pages/ConciergeSale/requests/tabs/styles";
import { useGetPaginationData } from "@/services/api/sales";
import coolIcom from "@/assets/icons/cool.svg";

const InitialContact: FC = () => {
  const {
    data: pData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useGetPaginationData("SC", 1);

  return (
    <>
      {!isLoading && (
        <Stack sx={styles.tabContentContainer}>
          {pData?.pages?.map((page, index) =>
            page?.items?.length ? (
              <React.Fragment key={index}>
                {page?.items?.map((item) => (
                  <RequestCard key={item.id} {...item} />
                ))}
              </React.Fragment>
            ) : (
              <Box key={page.pages} sx={styles.emptyRequestArea}>
                <img src={coolIcom} alt="cool icon" />
                <Typography sx={styles.emptyRequestDesc} variant="body1">
                  دمت گرم!
                  <br />
                  با همه ی مشتری ها تماس گرفتی
                </Typography>
              </Box>
            )
          )}

          {hasNextPage && (
            <Button
              sx={{ mb: 2 }}
              variant="outlined"
              disabled={isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              {isFetchingNextPage ? (
                <CircularProgress size={20} sx={{ color: "#00000050" }} />
              ) : (
                "مشاهده سایر درخواست‌ها"
              )}
            </Button>
          )}
        </Stack>
      )}

      {isLoading && (
        <Stack
          width="100%"
          height="400px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size="50px" />
        </Stack>
      )}
    </>
  );
};

export default InitialContact;
