import AppMuiProvider from "@/providers/AppMuiProvider";
import RootRouter from "@/router/RootRouter";
import React from "react";
import { createRoot } from "react-dom/client";
import {
  Replay,
  init as initSentry,
  BrowserTracing,
  reactRouterV6Instrumentation
} from "@sentry/react";
import { HttpClient } from "@sentry/integrations";

import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";
import AppCacheProvider from "./providers/AppCacheProvider";
import AppQueryProvider from "./providers/AppQueryProvider";
import AppReduxProvider from "./providers/AppReduxProvider";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { registerSW } from "virtual:pwa-register";

initSentry({
  dsn: "https://5b9fb1a04c2f4c87a19fb22fad611fed@kar-sentry.karnameh.com/19",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Replay(),
    new HttpClient() as any
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0
});

const container = document.getElementById("root");
if (!container) throw new Error("root not found!");

const root = createRoot(container);

const intervalMS = (60 * 60 * 1000) / 12; // 5 mins

registerSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    r &&
      setInterval(async () => {
        if (r.installing || !navigator) return;

        if ("connection" in navigator && !navigator.onLine) return;

        const resp = await fetch(swUrl, {
          cache: "no-store",
          headers: {
            cache: "no-store",
            "cache-control": "no-cache"
          }
        });

        if (resp?.status === 200) await r.update();
      }, intervalMS);
  },
  onRegisterError(error) {
    console.log({ error });
  }
});

root.render(
  <React.StrictMode>
    <AppCacheProvider>
      <AppMuiProvider>
        <AppReduxProvider>
          <AppQueryProvider>
            <BrowserRouter>
              <RootRouter />
            </BrowserRouter>
            <ToastContainer />
          </AppQueryProvider>
        </AppReduxProvider>
      </AppMuiProvider>
    </AppCacheProvider>
  </React.StrictMode>
);
