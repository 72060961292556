import createStyle from "@/utils/createStyle";

const styles = createStyle({
  backdropContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#00000040",
    zIndex: 29
  },
  container: {
    width: "100%",
    height: "100%",
    maxWidth: { xs: "unset", md: "500px" },
    maxHeight: { xs: "unset", md: "800px" },
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: (th) => `1px solid ${th.palette.background.paper}`,
    borderRadius: "0.5rem",
    backgroundColor: (th) => th.palette.background.default,
    transition: "opacity 0.5s",
    zIndex: 30,
    overflowY: "auto",
    overflowX: "hidden",
    justifyContent: "center",
    alignItems: "center",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  stencilImage: {
    width: "100%",
    objectFit: "contain",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  cameraContainer: {
    width: "100%",
    maxHeight: "100vh",
    position: "relative",
    alignItems: "center",
    justifyContent: "center"
  },
  captureImageButton: {
    position: "absolute",
    bottom: "1rem",
    left: "50%",
    transform: "translateX(-50%)"
  },
  buttonsContainer: {
    width: "100%",
    position: "absolute",
    bottom: "1rem",
    left: "50%",
    transform: "translateX(-50%)"
  },
  circleIconButton: {
    width: "5.25rem",
    height: "5.25rem",
    mb: "2.5rem",
    border: (th) => `3px solid ${th.palette.common.black}`
  },
  circleIcon: {
    width: "5.25rem",
    height: "5.25rem",
    color: (th) => th.palette.common.black
  },
  imagePreview: {
    width: "100%",
    objectFit: "contain",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  repeatButton: {
    flexBasis: "50%",
    flexGrow: 1,
    mr: "0.25rem",
    ml: "1rem"
  },
  submitButton: {
    flexBasis: "50%",
    flexGrow: 1,
    ml: "0.25rem",
    mr: "1rem"
  },
  cancelButton: {
    position: "absolute",
    top: "1rem",
    right: "1rem"
  }
});

export default styles;
