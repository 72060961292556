import createStyle from "@/utils/createStyle";

export const styles = createStyle({
  tabNavContainer: {
    width: "100%",
    borderBottom: "1px solid #C9CCCF",
    backgroundColor: "#E0F4EA"
  },
  tabNavItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    py: "13px",
    fontSize: "14px",
    cursor: "pointer"
  },
  tabContentContainer: {
    width: "100%",
    padding: "17px"
  },
  tabItem: {
    flex: "1",
    textAlign: "center",
    py: "13px",
    fontSize: "14px"
  },
  icon: {
    marginRight: "8px"
  }
});
