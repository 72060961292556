import { Stack, Typography } from "@mui/material";

export const CancelGuideInfo = () => {
  return (
    <Stack height="100%">
      <Typography fontSize="14px" fontWeight={700} mb={1}>
        در قبول سفارش‌هایتان دقت کنید!
      </Typography>

      <Typography fontSize="13px" fontWeight={400}>
        - در صورت لغو{" "}
        <Typography fontSize="13px" fontWeight={700} component="span">
          ۲ سفارش
        </Typography>{" "}
        در روز به مدت{" "}
        <Typography fontSize="13px" fontWeight={700} component="span">
          ۱ ساعت
        </Typography>
      </Typography>

      <Typography fontSize="13px" fontWeight={400} mb={1}>
        - در صورت لغو{" "}
        <Typography fontSize="13px" fontWeight={700} component="span">
          ۳ سفارش
        </Typography>{" "}
        در روز به مدت{" "}
        <Typography fontSize="13px" fontWeight={700} component="span">
          ۲۴ ساعت
        </Typography>
      </Typography>

      <Typography fontSize="13px" fontWeight={400}>
        حساب شما{" "}
        <Typography fontSize="13px" fontWeight={700} component="span">
          مسدود
        </Typography>{" "}
        خواهد شد و مجاز به قبول درخواست جدید{" "}
        <Typography fontSize="13px" fontWeight={700} component="span">
          نخواهید
        </Typography>{" "}
        بود!
      </Typography>
    </Stack>
  );
};

export const CancelFirstWarn = () => {
  return (
    <Stack height="100%">
      <Typography fontSize="14px" fontWeight={400}>
        اگر تا ۲۴ ساعت آینده یک سفارش دیگر را لغو کنید، حساب کاربری شما به مدت{" "}
        <Typography fontSize="14px" fontWeight={700} component="span">
          ۱ ساعت
        </Typography>{" "}
        مسدود خواهد شد.
      </Typography>
    </Stack>
  );
};

export const CancelFirstBlocking = ({
  blockingTime
}: {
  blockingTime: string;
}) => {
  return (
    <Stack height="100%">
      <Typography fontSize="14px" fontWeight={400} mb={1}>
        حساب کاربری شما به مدت{" "}
        <Typography fontSize="14px" fontWeight={700} component="span">
          ۱ ساعت
        </Typography>{" "}
        مسدود است!
      </Typography>

      <Typography fontSize="13px" fontWeight={400}>
        :شما تا ساعت{" "}
        <Typography fontSize="13px" fontWeight={700} component="span">
          {blockingTime}
        </Typography>{" "}
        امکان قبول درخواست جدیدی را ندارید.
      </Typography>
    </Stack>
  );
};

export const CancelSecondWarn = () => {
  return (
    <Stack height="100%">
      <Typography fontSize="14px" fontWeight={400}>
        اگر تا ۲۴ ساعت آینده یک سفارش دیگر را لغو کنید، حساب کاربری شما به مدت{" "}
        <Typography fontSize="14px" fontWeight={700} component="span">
          ۲۴ ساعت
        </Typography>{" "}
        مسدود خواهد شد.
      </Typography>
    </Stack>
  );
};

export const CancelSecondBlocking = ({
  blockingTime
}: {
  blockingTime: string;
}) => {
  return (
    <Stack height="100%">
      <Typography fontSize="14px" fontWeight={400} mb={1}>
        حساب کاربری شما به مدت{" "}
        <Typography fontSize="14px" fontWeight={700} component="span">
          ۲۴ ساعت
        </Typography>{" "}
        مسدود است!
      </Typography>

      <Typography fontSize="13px" fontWeight={400}>
        شما تا{" "}
        <Typography fontSize="13px" fontWeight={700} component="span">
          {blockingTime}
        </Typography>{" "}
        امکان قبول درخواست جدیدی را ندارید.
      </Typography>
    </Stack>
  );
};
