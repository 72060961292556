import createStyle from "@/utils/createStyle";

const styles = createStyle({
  modalBackdrop: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: '#00000047',
    transition: 'opacity 0.3s',
    top: 0,
    left: 0,
    zIndex: 11
  },
  modalContainer: {
    width: '100%',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    position: 'fixed',
    bottom: 0,
    backgroundColor: (th) => th.palette.background.default,
    left: 0,
    zIndex: 12,
    transition: 'transform 0.3s, opacity 0.3s'
  },
  modalHeader: {
    width: '100%',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    py: '0.25rem',
    px: '1rem',
    borderBottom: (th) => `1px solid ${th.palette.grey[200]}`
  },
  modalItem: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    py: '0.5rem',
    px: '1rem',
    ":last-of-type": {
      mb: '1rem'
    }
  },
  modalItemIcon: {
    width: '1rem',
    height: '1rem',
    mr: '0.5rem',
  },
})

export default styles;