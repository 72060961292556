import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

interface CardDetail {
  id: number;
  full_name: string;
  created_at: string;
  provider: string;
  amount: number;
  status_fa: string;
  status?: string;
  onClick: () => void;
  instalment_month_count: number;
  isRejected?: boolean;
  providerId?: string;
}

const RequestCard: FC<CardDetail> = ({
  id,
  full_name,
  created_at,
  provider,
  status_fa,
  onClick,
  instalment_month_count,
  isRejected,
  providerId
}) => {
  return (
    <Stack
      sx={{
        background: "#fff",
        border: "1px solid #C9CCCF",
        borderRadius: "8px",
        p: 1.5,
        mb: 2
      }}
      onClick={onClick}
    >
      <Stack
        sx={{
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "space-between",
          borderBottom: "1px solid #C9CCCF",
          pb: 1.25,
          gap: "4px"
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "start",
            justifyContent: "end",
            width: "100%"
          }}
        >
          {isRejected && (
            <Typography
              sx={{
                bgcolor: "red",
                color: "white",
                borderRadius: 25,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                py: 0.65,
                fontSize: 12,
                fontWeight: 500,
                textAlign: "center",
                px: 1,
                textWrap: "nowrap"
              }}
            >
              لغو شده
            </Typography>
          )}
          {providerId && (
            <Typography
              sx={{
                bgcolor: "#694FB4",
                color: "white",
                fontSize: 12,
                fontWeight: 500,
                borderRadius: 25,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                py: 0.65,
                px: 1,
                textWrap: "nowrap"
              }}
            >
              ترکیبی ({providerId})
            </Typography>
          )}

          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 500,
              background: "#EBF8F1",
              color: "#2EB974",
              borderRadius: 25,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              py: 0.65,
              px: 1,
              textWrap: "nowrap"
            }}
          >
            {status_fa}
          </Typography>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          <PersonIcon sx={{ mr: 0.5, color: "#00A754", fontSize: 24 }} />
          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
            {full_name}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 1.5
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          <DateRangeOutlinedIcon sx={{ color: "#6E6E6E", fontSize: 22 }} />
          <Typography sx={{ fontSize: 17, ml: 1, color: "#202021" }}>
            {new Date(created_at).toLocaleDateString("fa", {
              timeZone: "Iran",
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            })}
          </Typography>
        </Stack>
        <Stack
          sx={{
            fontSize: 16,
            background: "#E0F4EA",
            color: "#202021",
            px: 1,
            borderRadius: 1
          }}
        >
          {id}
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
        <StoreMallDirectoryOutlinedIcon
          sx={{ color: "#6E6E6E", fontSize: 22 }}
        />
        <Typography sx={{ fontSize: 17, ml: 1, color: "#202021" }}>
          {provider}
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
        <MonetizationOnOutlinedIcon sx={{ color: "#6E6E6E", fontSize: 22 }} />
        {instalment_month_count ? (
          <Typography sx={{ fontSize: 17, ml: 1, color: "#202021" }}>
            {instalment_month_count} ماهه
          </Typography>
        ) : (
          <Typography sx={{ fontSize: 17, ml: 1, color: "#202021" }}>
            -
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default RequestCard;
